define("web-client/components/company-settings-sections/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    color: "primary",
    attributeBindings: ["color"],
    reduxStore: Ember.inject.service(),
    actions: {}
  });

  _exports.default = _default;
});