define("web-client/components/reset-password-dialog/component", ["exports", "web-client/mixins/auth-base-dialog", "ember-cp-validations"], function (_exports, _authBaseDialog, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    userID: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "User ID can't be blank"
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    classNames: ['size-3'],
    emailAddress: '',
    showErrors: false,
    userID: '',
    defaultAction: 'doReset',
    actions: {
      goToVerifyReset() {
        this.goToPasswordResetVerification();
      },

      doReset: function doReset() {
        let self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.get("requestPasswordReset")(self.get('userID'));
          }
        }).finally(function () {
          self.set('showErrors', true);
        });
      },
      getUserId: function getUserId() {
        this.goToRetrieveUserID();
      },

      goToLogin() {
        this.element.style.display = 'none';
        this.reduxStore.dispatch({
          type: 'SET_PASSWORD_RESET_SUCCESS',
          passwordResetRequestSuccessful: false
        });
        this.goToLogin();
      }

    }
  });

  _exports.default = _default;
});