define("web-client/reducers/import-data", ["exports", "lodash-es/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    passwords: Ember.ArrayProxy.extend({
      filterOnFavorites: false,
      totalCount: 0,
      currentPage: 1,
      pageSize: 75,
      filterText: '',
      selected: null,
      sortDirection: Ember.computed('sortName', function () {
        let sortedOnProperty = (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }], item => {
          return item.active !== undefined;
        })[0].name;

        if (this.get(sortedOnProperty) === true) {
          return 'ASC';
        } else {
          return 'DESC';
        }
      }),
      sortName: Ember.computed('sortOnName', 'sortOnURL', 'sortOnLastUpdate', 'sortOnLastPasswordUpdate', function () {
        let sortName = (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }], item => {
          return item.active !== undefined;
        })[0].sortName;
        return sortName;
      }),
      sortOnName: true,
      sortOnURL: undefined,
      sortOnLastUpdate: undefined,
      sortOnLastPasswordUpdate: undefined,

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    preImportData: Ember.ArrayProxy.extend({
      filterOnFavorites: false,
      totalCount: 0,
      currentPage: 1,
      pageSize: 75,
      filterText: '',
      selected: null,
      sortDirection: Ember.computed('sortName', function () {
        let sortedOnProperty = (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }], item => {
          return item.active !== undefined;
        })[0].name;

        if (this.get(sortedOnProperty) === true) {
          return 'ASC';
        } else {
          return 'DESC';
        }
      }),
      sortName: Ember.computed('sortOnName', 'sortOnURL', 'sortOnLastUpdate', 'sortOnLastPasswordUpdate', function () {
        let sortName = (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }], item => {
          return item.active !== undefined;
        })[0].sortName;
        return sortName;
      }),
      sortOnName: true,
      sortOnURL: undefined,
      sortOnLastUpdate: undefined,
      sortOnLastPasswordUpdate: undefined,

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    })
  }).create();

  _exports.default = _default;
});