define("web-client/services/init-app-utils/get-settings-data", ["exports", "web-client/api", "lodash-es/has", "lodash-es/merge", "web-client/utils/default-app-settings", "web-client/api/idle-timer", "web-client/helpers/merge-deep", "idle-timer", "neo-async", "debug"], function (_exports, _api, _has, _merge, _defaultAppSettings, _idleTimer, _mergeDeep, _idleTimer2, _neoAsync, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSettingsData;
  const log = (0, _debug.default)("");

  function getGUID() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    } // separate out the mime component


    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // write the bytes of the string to a typed array

    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {
      type: mimeString
    });
  }

  function getSettingsData() {
    const reduxStore = this.reduxStore;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: "Loading Account Settings"
      });
      (0, _api.getSettingsData)().then(function (data) {
        if ((0, _has.default)(data, "data")) {
          reduxStore.dispatch({
            type: 'UPDATE_SETTINGS',
            settings: (0, _mergeDeep.mergeDeep)(_defaultAppSettings.default, JSON.parse(data.data))
          });
        } else {
          reduxStore.dispatch({
            type: 'UPDATE_SETTINGS',
            settings: _defaultAppSettings.default
          });
        }

        if ((0, _idleTimer.getTimer)()) {
          (0, _idleTimer.getTimer)().destroy();
          (0, _idleTimer.setTimer)(null);
        }

        (0, _idleTimer.setTimer)((0, _idleTimer2.default)({
          idleTime: reduxStore.get('state.settings.lockTime.effective') * 60 * 1000,
          callback: function callback() {
            // Lock it up
            if (storage.get('sessionToken')) {
              storage.remove('sessionToken');
              session.set('timedout', true);
              window.location.reload(true);
            }
          },
          activeCallback: function activeCallback() {}
        }));
        return (0, _api.setSessionTimeout)(reduxStore.get('state.settings.logoutTime.effective'));
      }).then(() => {
        _neoAsync.default.series([function (callback) {
          if (reduxStore.get('state.settings.hasSystemDefaultImage') === true) {
            const defaultImage = "data:image/png;base64," + new Identicon((0, _api.sha256asHex)(reduxStore.get('state.userId').toString()), 100).toString();
            const blob = dataURItoBlob(defaultImage);
            const fd = new FormData();
            fd.append('fileData', blob, "avatar.png");
            (0, _api.uploadAvatar)(fd).then(function () {
              reduxStore.dispatch({
                type: 'SET_HAS_SYSTEM_DEFAULT_IMAGE',
                hasSystemDefaultImage: false
              });
              (0, _api.updateSettingsData)('hasSystemDefaultImage', 'false').then(function ()
              /*data*/
              {
                callback();
              }).catch(function ()
              /*data*/
              {
                callback();
              });
            }).catch(() => {
              callback();
            });
          } else {
            callback();
          }
        }, function (callback) {
          if (!(0, _has.default)(reduxStore.get('state.settings'), 'dbKey') && reduxStore.get('state.publicKey')) {
            const guid = getGUID();
            reduxStore.dispatch({
              type: 'SET_DECRYPTED_DB_KEY',
              decryptedDbKey: guid
            });
            (0, _api.rsaEncrypt)(guid, reduxStore.get('state.publicKey')).then(encryptedGroupKey => {
              return (0, _api.updateSettingsData)('dbKey', encryptedGroupKey);
            }).then(() => {
              callback();
            }).catch(() => {
              callback();
            });
          } else {
            callback();
          }
        }, function (callback) {
          if ((0, _has.default)(reduxStore.get('state.settings'), 'dbKey') && reduxStore.get('state.decryptedPrivateKey')) {
            (0, _api.rsaDecrypt)(reduxStore.get('state.settings.dbKey'), reduxStore.get('state.decryptedPrivateKey')).then(decryptedDbKey => {
              if (decryptedDbKey) {
                reduxStore.dispatch({
                  type: 'SET_DECRYPTED_DB_KEY',
                  decryptedDbKey: decryptedDbKey
                });
                callback();
              } else {
                const guid = getGUID();
                reduxStore.dispatch({
                  type: 'SET_DECRYPTED_DB_KEY',
                  decryptedDbKey: guid
                });
                (0, _api.rsaEncrypt)(guid, reduxStore.get('state.publicKey')).then(encryptedGroupKey => {
                  return (0, _api.updateSettingsData)('dbKey', encryptedGroupKey);
                }).then(() => {
                  callback();
                }).catch(() => {
                  callback();
                });
              }
            }).catch(() => {
              callback();
            });
          } else {
            callback();
          }
        }], function () {
          callback();
        });
      }).catch(function (e) {
        callback();
      });
    };
  }
});