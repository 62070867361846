define("web-client/components/team-dialog-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FFS/w5kq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[\"Team Action Menu\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"menu-replacement\"],[9],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Manage Members\",\"Manage Members\",[29,\"perform\",[[25,[\"manageMembers\"]]],null]]]],false],[0,\"\\n\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Delete\",\"Delete Team\",[29,\"perform\",[[25,[\"deleteTeam\"]]],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,[[\"small\"],[true]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/team-dialog-menu/template.hbs"
    }
  });

  _exports.default = _default;
});