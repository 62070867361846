define("web-client/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mediaXS: '(min-width:0px) and (max-width: 300px)',
    mediaSM: '(min-width:301px) and (max-width: 768px)',
    mediaMD: '(min-width:767px) and (max-width: 992px)',
    mediaLG: '(min-width: 993px) and (max-width: 1199px)',
    mediaXL: '(min-width: 1200px)'
  };
  _exports.default = _default;
});