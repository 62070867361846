define("web-client/components/domain-listing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'domain-listing',
    classNames: ["setting-item"],
    classNameBindings: ["layoutDirection"],
    lines: "full",
    attributeBindings: ['lines'],
    selected: false,
    showSelected: false,
    gestures: Ember.inject.service(),
    media: Ember.inject.service(),
    actions: {},
    domains: {},
    layoutDirection: "horizontal",

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    showDialog: function showDialog() {//  (component "nb-button" size="small"  text="edit" iconOnly="false" color="dark"
      //        on-tap=(service-task-action "global-actions" "showManageLabelsDialog")
      //      )
    }
  });

  _exports.default = _default;
});