define("web-client/components/settings-sidebar-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'ion-list',
    classNames: ['fade-me-in'],
    globalActions: Ember.inject.service(),

    didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.later(this, () => {
        if (this.element) {
          this.element.classList.add('fade-in');
        }
      }, 1);
    }

  });

  _exports.default = _default;
});