define("web-client/components/billing-history-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PUEThUYq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-col\"],[11,\"class\",\"hide-sm hide-xs\"],[9],[0,\"\\n  \"],[1,[25,[\"billingItem\",\"status\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ion-col\"],[9],[0,\"\\n  \"],[1,[25,[\"billingItem\",\"date\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ion-col\"],[11,\"class\",\"hide-sm hide-xs\"],[9],[0,\"\\n  \"],[1,[25,[\"billingItem\",\"paymentSources\",\"firstObject\",\"description\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ion-col\"],[11,\"class\",\"hide-sm hide-xs \"],[9],[0,\"\\n  \"],[1,[29,\"format-currency\",[[25,[\"totalAmount\"]]],null],false],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[29,\"lt\",[[25,[\"refunded\"]],0],null]],null,{\"statements\":[[0,\"    Refunded: \"],[1,[29,\"format-currency\",[[25,[\"refunded\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"ion-col\"],[11,\"class\",\"content-align-right\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"displayLinks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"class\",\"link payment-link\"],[11,\"target\",\"_blank\"],[12,\"href\",[30,[[23,\"viewReceiptLink\"]]]],[9],[0,\"View \"],[10],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"link payment-link\"],[12,\"href\",[30,[[23,\"downloadReceiptLink\"]]]],[9],[0,\"Download \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/billing-history-item/template.hbs"
    }
  });

  _exports.default = _default;
});