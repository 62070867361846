define("web-client/components/passwords-sidebar-menu/ion-treeview-connections-group-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f67wWMbF",
    "block": "{\"symbols\":[\"connection\"],\"statements\":[[4,\"if\",[[29,\"not\",[[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"button\",\"item\",\"tap\",\"selected\",\"showExpander\",\"expanded\"],[\"Connections\",\"contacts\",false,[25,[\"allConnectionsTeams\"]],[29,\"service-task-action\",[\"global-actions\",\"selectAllConnectionsForPasswordFilter\"],null],[29,\"array-contains-array\",[[24,0,[\"reduxStore\",\"state\",\"data\",\"teams\",\"selected\"]],[25,[\"allConnectionsTeams\"]]],null],true,[25,[\"expanded\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ion-list\"],[11,\"class\",\"sub-items\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[25,[\"expanded\"]],[25,[\"loaded\"]],[25,[\"filterText\"]]],null]],null,{\"statements\":[[4,\"each\",[[25,[\"connections\"]]],null,{\"statements\":[[1,[29,\"passwords-sidebar-menu/ion-treeview-connection-item\",null,[[\"text\",\"showExpander\",\"item\"],[[24,1,[\"nickname\"]],true,[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/passwords-sidebar-menu/ion-treeview-connections-group-item/template.hbs"
    }
  });

  _exports.default = _default;
});