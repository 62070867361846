define("web-client/components/session-item-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/merge"], function (_exports, _dialogBase, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-5',
    classNames: ['session-item-dialog'],
    content: null,
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    name: Ember.computed.alias('editTeam.name'),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    currentTab: "details",
    activeTab: Ember.computed("currentTab", function () {
      switch (this.currentTab) {
        case "details":
          return "";

        case "members":
          return "right";
      }
    }),
    isDetailsTabActive: Ember.computed('currentTab', function () {
      return this.currentTab === "details";
    }),
    isMembersTabActive: Ember.computed('currentTab', function () {
      return this.currentTab === "members";
    }),
    isPasswordsTabActive: Ember.computed('currentTab', function () {
      return this.currentTab === "passwords";
    }),
    showErrors: false,
    showDefaultPermissionsErrors: Ember.computed('showErrors', 'defaultPermissions', function () {
      return this.showErrors || this.defaultPermissions !== null;
    }),

    init() {
      this._super.apply(this, arguments);

      if (this.team) {
        this.set('isEditMode', true);
        this.set('editTeam', Ember.copy(this.team));
        this.set('data.connectionsForTeam.currentTeam', this.team);
      } else {
        this.set('isEditMode', false);
        this.set('team', Ember.Object.extend(Ember.Copyable, {
          name: '',
          description: '',
          defaultRole: 3,

          copy() {
            return (0, _merge.default)({}, this);
          }

        }).create());
        this.set('editTeam', Ember.copy(this.team));
      }
    },

    isUserManagement: false,
    actions: {
      setTeamMembership(connection) {
        this.setTeamMembership.perform(connection);
      },

      doUserManagement() {
        this.set('isUserManagement', !this.isUserManagement);
      },

      doPasswordManagement() {
        this.set('isPasswordManagement', !this.isPasswordManagement);
      },

      setCurrentTab(tab) {
        this.set('currentTab', tab);
      },

      setDefaultPermissions(value) {
        this.set("editTeam.defaultRole", value);
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});