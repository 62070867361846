define("web-client/validators/conditionsaccepted", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Conditionsaccepted = _base.default.extend({
    validate(value, options
    /*, model, attribute*/
    ) {
      if (!value) {
        return options.message;
      } else {
        return true;
      }
    }

  });

  var _default = Conditionsaccepted;
  _exports.default = _default;
});