define("web-client/components/header-navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-toolbar',
    classNames: ["ion-color", "ion-color-primary", "ion-hide-md-down"],
    color: "primary",
    attributeBindings: ["color"],
    reduxStore: Ember.inject.service(),

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('ionTabsDidChange', function () {//console.log('event ionTabsDidChange')
      });
    },
    licensed: Ember.computed('reduxStore.state.licenseSummary.licensed', function () {
      return this.get('reduxStore.state.licenseSummary.licensed');
    })
  });

  _exports.default = _default;
});