define("web-client/components/company-override-setting-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0t2tqESd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"company-override-setting-display\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"company-override-setting-display-header\"],[9],[0,\"\\n    \"],[1,[23,\"displayHeader\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"company-override-setting-display-text\"],[9],[0,\"\\n    \"],[1,[23,\"displayText\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"company-override-setting-display-text\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"bold\"],[9],[0,\"Effective:\"],[10],[0,\" \"],[1,[23,\"effectText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/company-override-setting-display/template.hbs"
    }
  });

  _exports.default = _default;
});