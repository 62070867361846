define("web-client/dashboard/security-stats/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5NQp1031",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n\"],[4,\"nb-layout-row\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"nb-layout-column\",null,[[\"col-xs\",\"col-sm\",\"col-lg\",\"col-md\"],[12,12,6,12]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"stat-section\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"section-header\"],[9],[0,\"Password Strength Stats\"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"security-stat-wrapper\"],[9],[0,\"\\n            \"],[1,[29,\"security-stat-chart\",null,[[\"typeName\",\"statData\",\"schema\"],[\"Passwords\",[25,[\"securityStats\"]],[25,[\"securityStatsSchema\"]]]]],false],[0,\"\\n\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"nb-layout-column\",null,[[\"col-xs\",\"col-sm\",\"col-lg\",\"col-md\"],[12,12,6,12]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"stat-section\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"section-header\"],[9],[0,\"Password Update Stats\"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"security-stat-wrapper\"],[9],[0,\"\\n\\n            \"],[1,[29,\"security-stat-chart\",null,[[\"typeName\",\"statData\",\"schema\"],[\"Passwords\",[25,[\"passwordAgeStats\"]],[25,[\"passwordAgeStatsSchema\"]]]]],false],[0,\"\\n\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/dashboard/security-stats/template.hbs"
    }
  });

  _exports.default = _default;
});