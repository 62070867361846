define("web-client/components/quick-copy-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api/get-extra-fields-for-password", "lodash-es/cloneDeep", "lodash-es/each"], function (_exports, _dialogBase, _getExtraFieldsForPassword, _cloneDeep, _each) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    classNames: ['quick-copy-dialog'],
    standardDialogs: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    name: Ember.computed.alias('password.name'),
    email: Ember.computed.alias('password.email'),
    url: Ember.computed.alias('password.url'),
    extraFields: "",
    password: '',

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return "cancelDialog";
    }),
    quickCopyFields: Ember.computed('password', 'extraFields', function () {
      let fields = Ember.A([]);

      if (this.password.name) {
        fields.pushObject({
          name: "Name",
          value: this.password.name
        });
      }

      if (this.password.password) {
        fields.pushObject({
          name: "Password",
          value: this.password.password
        });
      }

      if (this.password.username) {
        fields.pushObject({
          name: "Username",
          value: this.password.username
        });
      }

      if (this.password.email) {
        fields.pushObject({
          name: "Email",
          value: this.password.email
        });
      }

      if (this.password.url) {
        fields.pushObject({
          name: "Website",
          value: this.password.url
        });
      }

      if (this.password.notes) {
        fields.pushObject({
          name: "Notes",
          value: this.password.notes
        });
      }

      if (this.password.sharedNotes) {
        fields.pushObject({
          name: "Shared Notes",
          value: this.password.sharedNotes
        });
      }

      (0, _each.default)(this.extraFields, extraField => {
        fields.pushObject({
          name: extraField.name,
          value: extraField.data
        });
      });
      return fields;
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('password', (0, _cloneDeep.default)(this.pw));
      (0, _getExtraFieldsForPassword.getExtraFieldsForPassword)(this.get('password.id')).then(data => {
        this.set('extraFields', Ember.A((0, _cloneDeep.default)(data)));
      });
    },

    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    },

    afterShow() {
      this._super.apply(this, arguments); //  var input = this.get('element').querySelector("* > input:first-of-type");
      //input.focus();

    }

  });

  _exports.default = _default;
});