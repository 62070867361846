define("web-client/components/application-left-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uFu7EwDw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-header\"],[9],[0,\"\\n  \"],[7,\"ion-toolbar\"],[11,\"color\",\"primary\"],[11,\"class\",\"ion-color ion-color-primary\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"logo\"],[11,\"src\",\"/static-assets/images/logo.png\"],[11,\"slot\",\"start\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"reduxStore\",\"state\",\"isLocked\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"nb-button\",null,[[\"text\",\"icon\",\"slot\",\"on-tap\"],[\"Lock\",\"lock\",\"end\",[29,\"service-action\",[\"global-actions\",\"removePackingKey\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"ion-toolbar\"],[11,\"color\",\"primary\"],[11,\"slot\",\"first\"],[11,\"class\",\"ion-color ion-color-primary\"],[9],[0,\"\\n    \"],[7,\"ion-label\"],[11,\"class\",\"sidebar-title\"],[9],[1,[25,[\"reduxStore\",\"state\",\"sidebarTitle\"]],false],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[7,\"ion-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[29,\"outlet\",[\"left-sidebar\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/application-left-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});