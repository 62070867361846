define("web-client/components/verify-password-reset-dialog/component", ["exports", "web-client/api", "web-client/mixins/auth-base-dialog", "ember-cp-validations"], function (_exports, _api, _authBaseDialog, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 500,
      message: "Password must be between 8 and 500 characters"
    })],
    verificationCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your new password"
    })],
    newPassword2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your new password"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: "You must retype your password exactly as before"
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    classNames: ['size-3'],
    content: null,
    success: false,
    newPassword: '',
    newPassword2: '',
    verificationCode: '',
    showVerificationCodeErrors: Ember.computed('verificationCode', 'showErrors', function () {
      return this.showErrors || this.verificationCode.length;
    }),
    showNewPasswordErrors: Ember.computed('newPassword', 'showErrors', function () {
      return this.showErrors || this.newPassword.length;
    }),
    showNewPassword2Errors: Ember.computed('newPassword2', 'showErrors', function () {
      return this.showErrors || this.newPassword2.length;
    }),
    showErrors: false,
    defaultAction: 'resetPassword',
    passwordStats: Ember.computed("newPassword", function () {
      if (!this.liveStatsObj) {
        this.liveStatsObj = Ember.Object.extend({
          password: Ember.Object.extend({
            score: 0,
            crack_time_display: "instant",
            scoreCSS: Ember.computed('score', function () {
              return 'score-' + (this.score ? this.score : 0);
            })
          }).create()
        }).create();
      }

      let self = this;
      (0, _api.getScore)(self.get('newPassword'), []).then(function (data) {
        if (data !== 'unavailable') {
          self.liveStatsObj.password.set('score', data.score);
          self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
        } else {
          self.liveStatsObj.password.set('score', 0);
          self.liveStatsObj.password.set('crack_time_display', "instant");
        }
      });
      return this.liveStatsObj;
    }),
    actions: {
      resetPassword: function resetPassword() {
        var self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.resetPassword(self.get('verificationCode').replace(/\s/g, ''), self.get('newPassword'));
          }
        }).finally(function ()
        /*e*/
        {
          self.set('showErrors', true);
        });
      },

      goToLogin() {
        this.goToLogin();
      }

    }
  });

  _exports.default = _default;
});