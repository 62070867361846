define("web-client/components/registration-features-team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+dTWps95",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"mainwrap\"],[9],[0,\"\\n\\t\"],[7,\"h3\"],[9],[0,\"Included Features\"],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"All Platforms Supported\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Store Unlimited Passwords\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Create Unlimited Team's Of Users\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Secure Password Sharing with Teams\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Private and Shared Notes\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Generate Strong Passwords\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Two Factor Authentication\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Labeling Organisation of Passwords\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Import & Export Passwords\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Transfer & Acquire Ownership of Passwords\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Emergency Access Codes\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Password History\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Yubi Key Support\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Login IP and Address History\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/registration-features-team/template.hbs"
    }
  });

  _exports.default = _default;
});