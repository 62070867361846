define("web-client/actions/show-username-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = showUsernameDialog;

  function showUsernameDialog() {
    this.standardDialogs.showDialog('username-dialog', {});
  }
});