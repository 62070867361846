define("web-client/components/manage-labels-dialog/label-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZSj04XDk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-icon\"],[11,\"name\",\"pricetag\"],[12,\"class\",[30,[\"item-icon \",[25,[\"item\",\"color\"]]]]],[9],[10],[0,\"\\n\"],[7,\"ion-label\"],[9],[1,[25,[\"item\",\"label\"]],false],[10],[0,\"\\n\"],[7,\"ion-checkbox\"],[11,\"slot\",\"start\"],[12,\"value\",[30,[[25,[\"item\",\"value\"]]]]],[12,\"checked\",[30,[[23,\"selected\"]]]],[9],[10],[0,\"\\n\\n\"],[7,\"ion-buttons\"],[11,\"class\",\"manage-label-buttons\"],[9],[0,\"\\n  \"],[1,[29,\"nb-button\",null,[[\"icon\",\"shape\",\"size\",\"color\",\"on-tap\"],[\"trash\",\"round\",\"small\",\"dark\",[29,\"service-action\",[\"global-actions\",\"deleteLabel\",[25,[\"item\",\"id\"]]],null]]]],false],[0,\"\\n  \"],[1,[29,\"nb-button\",null,[[\"icon\",\"shape\",\"size\",\"color\",\"on-tap\"],[\"create\",\"round\",\"small\",\"dark\",[29,\"service-action\",[\"global-actions\",\"showEditLabelDialog\",[25,[\"item\"]]],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/manage-labels-dialog/label-item/template.hbs"
    }
  });

  _exports.default = _default;
});