define("web-client/mixins/authed-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    router: Ember.inject.service(),

    activate() {
      /*
          let showInstallDialog = storage.get('show-install-dialog',true) ;
           if (this.get('reduxStore.state.loggedIn') &&   window.bv.name === "Chrome" &&  showInstallDialog && !this.get('media.isMobile') && !this.get('media.isTablet'))
          {
            scheduleOnce('afterRender', function () {
              setTimeout(() => {
                if ( !$('body').hasClass('addon-installed') )
                      getOwner(self).lookup('service-action "global-actions":helpers').compute([ 'showInstallExtensionDialog' ])();
                //  self.send('showInstallExtensionDialog');
                }, 1000);
            });
          }
          */
    }

  });

  _exports.default = _default;
});