define("web-client/components/connection-management-grid/component", ["exports", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/each", "web-client/api"], function (_exports, _filter, _sortBy, _each, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: [''],
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    items: {},
    filterUsername: '',
    filterTeamSharedToMe: '',
    filterTeamSharedByMe: '',
    filterCompany: '',
    filteredItems: Ember.computed('filterUsername', 'filterTeamSharedToMe', 'filterTeamSharedByMe', 'filterCompany', 'items', function () {
      let connectionData = this.items;
      let data = (0, _filter.default)(connectionData, item => {
        return item.nickname.toLowerCase().includes(this.filterUsername.toLowerCase()) && this.doesItemContainFilterText(item.ownedTeams, this.filterTeamSharedToMe) && this.doesItemContainFilterText(item.memberOfTeams, this.filterTeamSharedByMe);
      });
      return (0, _sortBy.default)(data, ["nickname", "active"]);
    }),
    doesItemContainFilterText: function doesItemContainFilterText(item, theFilter) {
      let returnVal = false; // If the filter is empty then everything matches

      if (!theFilter) {
        returnVal = true;
      } else {// A filter is present so we must check.
      }

      if (!returnVal) {
        item.forEach(function (team, index) {
          if (theFilter) {
            if (team.teamName.toLowerCase().includes(theFilter.toLowerCase())) {
              returnVal = true;
            }
          } else {
            returnVal = true;
          }
        });
      }

      return returnVal;
    },
    filter: function filter() {//      console.log("filtering");
      // The filtering is done by the computed function once the filters are updaated.
    },

    init() {
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      showConnectionDialog: function showConnectionDialog(connection) {
        this.globalActions.showConnectionDialog(connection);
      },
      filterByUsername: function filterByUsername(search) {
        this.set('filterUsername', search);
        this.filter();
      },
      filterByTeamSharedToMe: function filterByTeamSharedToMe(search) {
        this.set('filterTeamSharedToMe', search);
        this.filter();
      },
      filterByTeamSharedByMe: function filterByTeamSharedByMe(search) {
        this.set('filterTeamSharedByMe', search);
        this.filter();
      },
      filterByCompany: function filterByCompany(search) {
        this.set('filterCompany', search);
        this.filter();
      },
      resetFilters: function resetFilters() {
        document.getElementById("filterByUsername").value = '';
        document.getElementById("filterByTeamSharedToMe").value = '';
        document.getElementById("filterByTeamSharedByMe").value = ''; //document.getElementById("filterByCompany").value='';

        this.set('filterUsername', '');
        this.set('filterTeamSharedToMe', '');
        this.set('filterTeamSharedByMe', '');
        this.set('filterCompany', '');
        this.filter();
      }
    }
  });

  _exports.default = _default;
});