define("web-client/components/confirm-dialog/dialog-types/dialog-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    type: 'confirm-show-edit-members-dialog',
    title: "Dialog.ConfirmShowEditMembersDialog.Heading.Text",
    continueButtonText: "Dialog.ConfirmShowEditMembersDialog.GoToMembersButton.Text",
    cancelButtonText: "Common.Buttons.Close.Text",
    continueButtonTitle: "Dialog.ConfirmShowEditMembersDialog.GoToMembersButton.Title",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmShowEditMembersDialog.Message.Text"
  }, {
    type: 'confirm-import-complete',
    title: "Dialog.ConfirmImportComplete.Heading.Text",
    continueButtonText: "Dialog.ConfirmImportComplete.GoToPasswordsButton.Text",
    cancelButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Dialog.ConfirmImportComplete.GoToPasswordsButton.Title",
    cancelButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.ConfirmImportComplete..Message.Text"
  }, {
    type: 'confirm-make-connection-inactive',
    title: "Dialog.ConfirmMakeConnectionInactive.Heading.Text",
    continueButtonText: "Common.Buttons.ConfirmMakeConnectionInactive.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.ConfirmMakeConnectionInactive.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmMakeConnectionInactive.Message.Text"
  }, {
    type: 'confirm-downgrade-default-card',
    title: "Dialog.ConfirmDowngradeDefaultCard.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDowngradeDefaultCard.Message.Text"
  }, {
    type: 'confirm-remove-addon',
    title: "Dialog.ConfirmRemoveAddon.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveAddon.Message.Text"
  }, {
    type: 'confirm-make-connection-active',
    title: "Dialog.ConfirmMakeConnectionActive.Heading.Text",
    continueButtonText: "Common.Buttons.ConfirmMakeConnectionActive.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.ConfirmMakeConnectionActive.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmMakeConnectionActive.Message.Text"
  }, {
    type: 'confirm-delete-import-entry',
    title: "Dialog.ConfirmDeleteImportEntry.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteImportEntry.Message.Text"
  }, {
    type: 'confirm-remove-unowned-from-selected',
    title: "Dialog.ConfirmRemoveUnownedFromSelected.Heading.Text",
    continueButtonText: "Dialog.ConfirmRemoveUnownedFromSelected.RemoveButton.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Dialog.ConfirmRemoveUnownedFromSelected.RemoveButton.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveUnownedFromSelected.Message.Text"
  }, {
    type: 'confirm-remove-inactive-from-selected',
    title: "Dialog.ConfirmRemoveInactiveFromSelected.Heading.Text",
    continueButtonText: "Dialog.ConfirmRemoveInactiveFromSelected.RemoveButton.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Dialog.ConfirmRemoveInactiveFromSelected.RemoveButton.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveInactiveFromSelected.Message.Text"
  }, {
    type: 'confirm-delete-password-entry',
    title: "Dialog.ConfirmDeletePasswordDialog.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeletePasswordDialog.Message.Text"
  }, {
    type: 'confirm-delete-connection',
    title: "Dialog.ConfirmDeleteConnection.Heading.Text",
    continueButtonText: "Dialog.ConfirmDeleteConnection.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Dialog.ConfirmDeleteConnection.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteConnection.Message.Text"
  }, {
    type: 'confirm-delete-org-invite',
    title: "Dialog.ConfirmDeleteOrgInvite.Heading.Text",
    continueButtonText: "Dialog.ConfirmDeleteOrgInvite.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Dialog.ConfirmDeleteOrgInvite.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteOrgInvite.Message.Text"
  }, {
    type: 'confirm-delete-org-member',
    title: "Dialog.ConfirmDeleteOrgMember.Heading.Text",
    continueButtonText: "Dialog.ConfirmDeleteOrgMember.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Dialog.ConfirmDeleteOrgMember.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteOrgMember.Message.Text"
  }, {
    type: 'confirm-delete-extra-field',
    title: "Dialog.ConfirmDeleteExtraField.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteExtraField.Message.Text"
  }, {
    type: 'confirm-delete-history-item',
    title: "Dialog.ConfirmDeleteHistoryItem.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteHistoryItem.Message.Text"
  }, {
    type: 'confirm-undo-password-changes',
    title: "Dialog.ConfirmUndoPasswordChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmUndoPasswordChanges.Message.Text"
  }, {
    type: 'confirm-close-and-cancel-password-changes',
    title: "Dialog.ConfirmCloseAndCancelPasswordChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmCloseAndCancelPasswordChanges.Message.Text"
  }, {
    type: 'do-import-now',
    title: "Dialog.DoImportNow.Heading.Text",
    continueButtonText: "Dialog.DoImportNow.Buttons.ImportNow.Text",
    continueButtonTitle: "Dialog.DoImportNow.Buttons.ImportNow.Title",
    cancelButtonText: "Dialog.DoImportNow.Buttons.Preview.Text",
    cancelButtonTitle: "Dialog.DoImportNow.Buttons.Preview.Title",
    message: "Dialog.DoImportNow.Message.Text"
  }, {
    type: 'confirm-sign-out',
    title: "Dialog.ConfirmSignOut.Heading.Text",
    continueButtonText: "Common.Buttons.Yes.Text",
    continueButtonTitle: "Common.Buttons.Yes.Title",
    cancelButtonText: "Common.Buttons.No.Text",
    cancelButtonTitle: "Common.Buttons.No.Title",
    message: "Dialog.ConfirmSignOut.Message.Text"
  }, {
    type: 'confirm-kill-session',
    title: "Dialog.ConfirmKillSession.Heading.Text",
    continueButtonText: "Common.Buttons.Yes.Text",
    continueButtonTitle: "Common.Buttons.Yes.Title",
    cancelButtonText: "Common.Buttons.No.Text",
    cancelButtonTitle: "Common.Buttons.No.Title",
    message: "Dialog.ConfirmKillSession.Message.Text"
  }, {
    type: 'confirm-change-nickname',
    title: "Dialog.ConfirmChangeNickname.Heading.Text",
    continueButtonText: "Common.Buttons.Yes.Text",
    continueButtonTitle: "Common.Buttons.Yes.Title",
    cancelButtonText: "Common.Buttons.No.Text",
    cancelButtonTitle: "Common.Buttons.No.Title",
    message: "Dialog.ConfirmChangeNickname.Message.Text"
  }, {
    type: 'confirm-delete-account',
    title: "Dialog.ConfirmDeleteAccount.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteAccount.Message.Text"
  }, {
    type: 'confirm-delete-team',
    title: "Dialog.ConfirmDeleteTeam.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteTeam.Message.Text"
  }, {
    type: 'confirm-delete-label',
    title: "Dialog.ConfirmDeleteLabel.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteLabel.Message.Text"
  }, {
    type: 'confirm-delete-allowed-domain',
    title: "Dialog.ConfirmDeleteAllowedDomain.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteAllowedDomain.Message.Text"
  }, {
    type: 'confirm-delete-verified-domain',
    title: "Dialog.ConfirmDeleteVerifiedDomain.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteVerifiedDomain.Message.Text"
  }, {
    type: 'confirm-remove-label',

    /*  title: "Dialog.ConfirmDeleteLabel.Heading.Text",*/
    continueButtonText: "Common.Buttons.Remove.Text",
    continueButtonTitle: "Common.Buttons.Remove.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveLabel.Message.Text"
  }, {
    type: 'confirm-remove-team-from-password',

    /*  title: "Dialog.ConfirmDeleteLabel.Heading.Text",*/
    continueButtonText: "Common.Buttons.Remove.Text",
    continueButtonTitle: "Common.Buttons.Remove.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveTeamFromPassword.Message.Text"
  }, {
    type: 'confirm-discard-extra-field-changes',
    title: "Dialog.ConfirmDiscardExtraFieldChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardExtraFieldChanges.Message.Text"
  }, {
    type: 'confirm-discard-bulk-label-changes',
    title: "Dialog.ConfirmDiscardBulkLabelChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardBulkLabelChanges.Message.Text"
  }, {
    type: 'confirm-discard-bulk-team-changes',
    title: "Dialog.ConfirmDiscardBulkTeamChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardBulkTeamChanges.Message.Text"
  }, {
    type: 'confirm-discard-all-filters',
    title: "Dialog.ConfirmDiscardAllFilters.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardAllFilters.Message.Text"
  }, {
    type: 'confirm-discard-all-label-filters',
    title: "Dialog.ConfirmDiscardAllLabelFilters.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardAllLabelFilters.Message.Text"
  }, {
    type: 'confirm-discard-all-team-filters',
    title: "Dialog.ConfirmDiscardAllTeamFilters.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardAllTeamFilters.Message.Text"
  }, {
    type: 'confirm-discard-team-from-filter',
    title: "Dialog.ConfirmDiscardTeamFromFilter.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardTeamFromFilter.Message.Text"
  }, {
    type: 'confirm-discard-label-from-filter',
    title: "Dialog.ConfirmDiscardLabelFromFilter.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardLabelFromFilter.Message.Text"
  }, {
    type: 'confirm-discard-favorites-from-filter',
    title: "Dialog.ConfirmDiscardFavoritesFromFilter.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardFavoritesFromFilter.Message.Text"
  }, {
    type: 'confirm-discard-selected-from-filter',
    title: "Dialog.ConfirmDiscardSelectedFromFilter.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardSelectedFromFilter.Message.Text"
  }, {
    type: 'confirm-discard-textsearch-from-filter',
    title: "Dialog.ConfirmDiscardTextSearchFromFilter.Heading.Text",
    continueButtonText: "Common.Buttons.Clear.Text",
    continueButtonTitle: "Common.Buttons.Clear.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardTextSearchFromFilter.Message.Text"
  }, {
    type: 'confirm-open-selected-urls',
    title: "Dialog.ConfirmOpenSelectedURLs.Heading.Text",
    continueButtonText: "Common.Buttons.Open.Text",
    continueButtonTitle: "Common.Buttons.Open.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmOpenSelectedURLs.Message.Text"
  }, {
    type: 'confirm-remove-yubikey',
    title: "Dialog.ConfirmRemoveYubikey.Heading.Text",
    continueButtonText: "Common.Buttons.Remove.Text",
    continueButtonTitle: "Common.Buttons.Remove.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveYubikey.Message.Text"
  }, {
    type: 'confirm-remove-google-auth',
    title: "Dialog.ConfirmRemoveGoogleAuth.Heading.Text",
    continueButtonText: "Common.Buttons.Deactivate.Text",
    continueButtonTitle: "Common.Buttons.Deactivate.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveGoogleAuth.Message.Text",
    message2: "Dialog.ConfirmRemoveMFA.Logout.Text"
  }, {
    type: 'confirm-remove-microsoft-auth',
    title: "Dialog.ConfirmRemoveMicrosoftAuth.Heading.Text",
    continueButtonText: "Common.Buttons.Deactivate.Text",
    continueButtonTitle: "Common.Buttons.Deactivate.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmRemoveMicrosoftAuth.Message.Text",
    message2: "Dialog.ConfirmRemoveMFA.Logout.Text"
  }, {
    type: 'confirm-delete-selected-connections',
    title: "Dialog.ConfirmDeleteConnection.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteSelectedConnections.Message.Text"
  }, {
    type: 'confirm-discard-label-changes',
    title: "Dialog.ConfirmDiscardLabelChanges.Heading.Text",
    continueButtonText: "Common.Buttons.Discard.Text",
    continueButtonTitle: "Common.Buttons.Discard.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDiscardLabelChanges.Message.Text"
  }, {
    type: 'confirm-cancel-sent-invite',
    title: "Dialog.ConfirmCancelSentRequest.Heading.Text",
    continueButtonText: "Dialog.ConfirmCancelSentRequest.Button.CancelInvite.Text",
    continueButtonTitle: "Dialog.ConfirmCancelSentRequest.Button.CancelInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmCancelSentRequest.Message.Text"
  }, {
    type: 'confirm-cancel-sent-invites',
    title: "Dialog.ConfirmCancelSentRequests.Heading.Text",
    continueButtonText: "Dialog.ConfirmCancelSentRequests.Button.CancelInvite.Text",
    continueButtonTitle: "Dialog.ConfirmCancelSentRequests.Button.CancelInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmCancelSentRequests.Message.Text"
  }, {
    type: 'confirm-delete-received-invite',
    title: "Dialog.ConfirmDeleteReceivedRequest.Heading.Text",
    continueButtonText: "Dialog.ConfirmDeleteReceivedRequest.Button.DeleteInvite.Text",
    continueButtonTitle: "Dialog.ConfirmDeleteReceivedRequest.Button.DeleteInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmDeleteReceivedRequest.Message.Text"
  }, {
    type: 'confirm-delete-received-invites',
    title: "Dialog.ConfirmDeleteReceivedRequests.Heading.Text",
    continueButtonText: "Dialog.ConfirmDeleteReceivedRequests.Button.DeleteInvite.Text",
    continueButtonTitle: "Dialog.ConfirmDeleteReceivedRequests.Button.DeleteInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmDeleteReceivedRequests.Message.Text"
  }, {
    type: 'confirm-accept-received-invite',
    title: "Dialog.ConfirmAcceptReceivedInvite.Heading.Text",
    continueButtonText: "Dialog.ConfirmAcceptReceivedInvite.Button.AcceptInvite.Text",
    continueButtonTitle: "Dialog.ConfirmAcceptReceivedInvite.Button.AcceptInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmAcceptReceivedInvite.Message.Text"
  }, {
    type: 'confirm-accept-received-invites',
    title: "Dialog.ConfirmAcceptReceivedInvites.Heading.Text",
    continueButtonText: "Dialog.ConfirmAcceptReceivedInvites.Button.AcceptInvite.Text",
    continueButtonTitle: "Dialog.ConfirmAcceptReceivedInvites.Button.AcceptInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmAcceptReceivedInvites.Message.Text"
  }, {
    type: 'updates_available',
    title: "Dialog.ConfirmUpdatesAvailable.Heading.Text",
    continueButtonText: "Dialog.ConfirmUpdatesAvailable.Button.AcceptInvite.Text",
    continueButtonTitle: "Dialog.ConfirmUpdatesAvailable.Button.AcceptInvite.Title",
    cancelButtonText: "Dialog.ConfirmUpdatesAvailable.Button.Wait.Title",
    cancelButtonTitle: "Dialog.ConfirmUpdatesAvailable.Button.Wait.Text",
    message: "Dialog.ConfirmUpdatesAvailable.Message.Text"
  }, {
    type: 'confirm-accept-received-invites',
    title: "Dialog.ConfirmAcceptReceivedInvites.Heading.Text",
    continueButtonText: "Dialog.ConfirmAcceptReceivedInvites.Button.AcceptInvite.Text",
    continueButtonTitle: "Dialog.ConfirmAcceptReceivedInvites.Button.AcceptInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmAcceptReceivedInvites.Message.Text"
  }, {
    type: 'confirm-reject-received-invite',
    title: "Dialog.ConfirmRejectReceivedInvite.Heading.Text",
    continueButtonText: "Dialog.ConfirmRejectReceivedInvite.Button.RejectInvite.Text",
    continueButtonTitle: "Dialog.ConfirmRejectReceivedInvite.Button.RejectInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmRejectReceivedInvite.Message.Text"
  }, {
    type: 'confirm-reject-received-invites',
    title: "Dialog.ConfirmRejectReceivedInvites.Heading.Text",
    continueButtonText: "Dialog.ConfirmRejectReceivedInvites.Button.RejectInvite.Text",
    continueButtonTitle: "Dialog.ConfirmRejectReceivedInvites.Button.RejectInvite.Title",
    cancelButtonText: "Common.Buttons.Close.Text",
    cancelButtonTitle: "Common.Buttons.Close.Title",
    message: "Dialog.ConfirmRejectReceivedInvites.Message.Text"
  }, {
    type: 'confirm-turn-on-searchable',
    title: "Dialog.ConfirmTurnOnSearchable.Heading.Text",
    continueButtonText: "Common.Buttons.Show.Text",
    continueButtonTitle: "Common.Buttons.Show.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmTurnOnSearchable.Message.Text"
  }, {
    type: 'confirm-turn-off-searchable',
    title: "Dialog.ConfirmTurnOffSearchable.Heading.Text",
    continueButtonText: "Common.Buttons.Hide.Text",
    continueButtonTitle: "Common.Buttons.Hide.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmTurnOffSearchable.Message.Text"
  }, {
    type: 'must-have-nickname',
    title: "Dialog.MusthHaveNickname.Heading.Text",
    continueButtonText: "Dialog.MusthHaveNickname.SetNickname.Text",
    continueButtonTitle: "Dialog.MusthHaveNickname.SetNickname.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.MusthHaveNickname.Message.Text"
  }, {
    type: 'must-have-nickname-to-connect',
    title: "Dialog.MusthHaveNicknameToConnect.Heading.Text",
    continueButtonText: "Dialog.MusthHaveNicknameToConnect.SetNickname.Text",
    continueButtonTitle: "Dialog.MusthHaveNicknameToConnect.SetNickname.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.MusthHaveNicknameToConnect.Message.Text"
  }, {
    type: 'confirm-delete-message',
    title: "Dialog.ConfirmDeleteMessageDialog.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteMessageDialog.Message.Text"
  }, {
    type: 'confirm-delete-messages',
    title: "Dialog.ConfirmDeleteMessagesDialog.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeleteMessagesDialog.Message.Text"
  }, {
    type: 'confirm-delete-password-generation-history',
    title: "Dialog.ConfirmDeletePasswordGenerationDialog.Heading.Text",
    continueButtonText: "Common.Buttons.Delete.Text",
    continueButtonTitle: "Common.Buttons.Delete.Text",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmDeletePasswordGenerationDialog.Message.Text"
  }, {
    type: 'confirm-launch-urls',
    title: "Dialog.ConfirmLaunchUrlsDialog.Heading.Text",
    continueButtonText: "Dialog.ConfirmLaunchUrlsDialog.Launch.Text",
    continueButtonTitle: "Dialog.ConfirmLaunchUrlsDialog.Launch.Title",
    cancelButtonText: "Common.Buttons.Cancel.Text",
    cancelButtonTitle: "Common.Buttons.Cancel.Title",
    message: "Dialog.ConfirmLaunchUrlsDialog.Message.Text"
  }];
  _exports.default = _default;
});