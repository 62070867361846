define("web-client/verifyreset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2bo6gQ+p",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[29,\"verify-password-reset-dialog\",null,[[\"success\",\"goToLogin\",\"resetPassword\"],[[25,[\"reduxStore\",\"state\",\"verifyPasswordResetSuccess\"]],[29,\"service-action\",[\"global-actions\",\"goToLogin\"],null],[29,\"service-action\",[\"global-actions\",\"resetPassword\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/verifyreset/template.hbs"
    }
  });

  _exports.default = _default;
});