define("web-client/components/label-dialog/component", ["exports", "web-client/api/get-labels", "ui-core/mixins/dialog-base", "lodash-es/has", "lodash-es/isNumber", "web-client/api", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/utils/color-options", "lodash-es/clone", "lodash-es/find"], function (_exports, _getLabels, _dialogBase, _has, _isNumber, _api, _emberRedux, _emberConcurrency, _emberCpValidations, _colorOptions, _clone, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    value: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Label can't be blank"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-2',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    loading: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    value: Ember.computed.alias('editLabel.label'),
    color: Ember.computed.alias('editLabel.color'),
    colorOptions: _colorOptions.colorOptions,
    hasChanged: Ember.computed('editLabel.{label,color}', function () {
      return this.get('editLabel.label') !== this.originalValue || this.get('editLabel.color') !== this.originalColor;
    }),
    showErrors: false,
    showValueErrors: Ember.computed('showErrors', 'value', function () {
      return this.showErrors || this.value && this.value.length;
    }),
    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),
    changeColorOnly: Ember.computed('editLabel.{label,color}', function () {
      return this.get('editLabel.label') === this.originalValue && this.get('editLabel.color') !== this.originalColor || this.get('editLabel.color') !== this.originalColor && this.get('editLabel.label') !== this.originalValue;
    }),
    setColor: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.standardDialogs.showDialog('color-picker-dialog', {
        complete: deferred
      }, "small");
      let colorOption = yield deferred.promise;

      if (colorOption) {
        this.set('editLabel.color', colorOption.value);
      }
    }).drop(),

    init() {
      this._super.apply(this, arguments);

      if (this.label) {
        this.set('isEditMode', true);
        this.set('editLabel', (0, _clone.default)(this.label));
        this.set('originalValue', this.get('label.label'));
        this.set('originalColor', this.get('label.color'));
      } else {
        if (this.newLabel) {
          this.set('isEditMode', false);
          this.set('editLabel', Ember.Object.extend({
            id: '',
            label: this.newLabel,
            color: 'green-500'
          }).create());
          this.set('originalValue', '');
          this.set('originalColor', 'green-500');
        } else {
          this.set('isEditMode', false);
          this.set('editLabel', Ember.Object.extend({
            id: '',
            label: '',
            color: 'green-500'
          }).create());
          this.set('originalValue', '');
          this.set('originalColor', 'green-500');
        }
      }
    },

    saveLabel: (0, _emberConcurrency.task)(function* () {
      let self = this;
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          if (self.get('isEditMode')) {
            return (0, _api.updateLabel)(self.get('editLabel.id'), self.get('editLabel.label'), self.get('editLabel.color'), self.get('changeColorOnly')).then(label => {
              (0, _getLabels.getLabels)().then(data => {
                self.reduxStore.dispatch({
                  type: 'UPDATE_LABELS',
                  labels: data
                });
                let found = (0, _find.default)(data, item => {
                  return item.id === self.editLabel.id;
                });
                deferred.resolve();
                self.send('remove');

                if (self.onComplete) {
                  self.onComplete.resolve(found);
                }
              });
            }).catch(function (data) {
              if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
                self.get('notifications').show(self.get("i18n").t('Common.Errors.UnexpectedConnectionFailure.Text'));
              } else {
                self.get('notifications').show(self.get("i18n").t((0, _api.getLanguageErrorKey)(data.status.code)));
              }

              self.set('showErrors', true);
              deferred.resolve();

              if (self.onComplete) {
                self.onComplete.resolve();
              }
            });
          } else {
            (0, _api.createLabel)(self.get('value'), self.get('color')).then(data => {
              if (self.newLabel) {
                self.currentLabels.pushObject({
                  label: self.get('value'),
                  color: self.get('color'),
                  id: data.id
                });
                self.focusBack.value = '';
                self.focusTrap.deactivate();
                self.parentFocusTrap.activate();
                self.focusBack.focus();
              }

              self.send('remove');

              if (self.onComplete) {
                self.onComplete.resolve({
                  label: self.get('value'),
                  color: self.get('color'),
                  id: data.id
                });
              }
            }).catch(function (data) {
              if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
                self.get('notifications').show(self.get("i18n").t('Common.Errors.UnexpectedConnectionFailure.Text'));
              } else {
                self.get('notifications').show(self.get("i18n").t((0, _api.getLanguageErrorKey)(data.status.code)));
              }

              self.set('showErrors', true);
              deferred.resolve();
            });
          }
        } else {
          self.set('showErrors', true);
          deferred.resolve();
        }
      });
      yield deferred.promise;
    }).drop(),
    deleteLabel: (0, _emberConcurrency.task)(function* () {
      let self = this;
      let itemId = this.get('label.id');
      let deferred = Ember.RSVP.defer();
      this.loading.show('Deleting label');
      (0, _api.deleteLabel)(itemId).then(() => {
        this.reduxStore.state.data.labels.selected.removeObject(itemId);
        (0, _getLabels.getLabels)().then(data => {
          self.reduxStore.dispatch({
            type: 'UPDATE_LABELS',
            labels: data
          });
          this.loading.dismiss();
          deferred.resolve();
          self.send('remove');

          if (self.onComplete) {
            self.onComplete.resolve('deleted');
          }
        });
      }).catch(data => {
        this.loading.dismiss();

        if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
          self.get('notifications').show(self.get("i18n").t('Common.Errors.UnexpectedConnectionFailure.Text'));
        } else {
          self.get('notifications').show(self.get("i18n").t((0, _api.getLanguageErrorKey)(data.status.code)));
        }

        deferred.resolve();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      onSelectColor: function onSelectColor(color) {
        this.set('editLabel.color', color);
      },

      saveLabel() {
        this.saveLabel.perform();
      },

      cancelDialog: function cancelDialog() {
        let self = this;

        if (this.newLabel) {
          setTimeout(() => {
            this.focusTrap.deactivate();
            this.parentFocusTrap.activate();
          }, 100);
          this.send('remove');

          if (self.onComplete) {
            self.onComplete.resolve();
          }
        } else {
          this.send('remove');

          if (self.onComplete) {
            self.onComplete.resolve();
          }
        }
      },

      discardChanges() {
        this.set('editLabel.label', this.originalValue);
        this.focusFirst();
      }

    }
  });

  _exports.default = _default;
});