define("web-client/components/passwords-sidebar-menu/ion-treeview-teams-group-item/component", ["exports", "lodash-es/filter", "lodash-es/map"], function (_exports, _filter, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ion-treeview-teams-group-item'],
    layoutValue: "icon-start",
    button: false,
    attributeBindings: ['layoutValue:layout', 'button'],
    classNameBindings: ['expandedClass', 'searching:searching'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    expanded: false,
    item: null,
    filterText: "",
    loaded: false,

    init() {
      this._super.apply(this, arguments);

      setTimeout(() => {
        if (!this.isDestroyed) {
          this.set('loaded', true);
        }
      }, 500);
    },

    searching: Ember.computed('filterText', function () {
      return this.filterText ? true : false;
    }),
    expandedClass: Ember.computed('expanded', 'filterText', function () {
      return this.expanded || this.filterText ? 'open' : '';
    }),
    theirTeams: Ember.computed(function () {
      var data = this.get('reduxStore.state.data.theirTeams'); //     console.log("DATA: " + JSON.stringify(data));
      // Ember does not handle a period in the key so we need to convert the hash to an array.

      return (0, _map.default)(data, function (value, key) {
        return {
          key: key,
          value: value
        };
      });
    }),

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});