define("web-client/login/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/unauthed-route"], function (_exports, _baseRoute, _unauthedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    initApp: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    queryParams: {
      code: {},
      featureCode: {}
    },

    model(params) {
      const t = Ember.Object.create(params);
      this.set('queryParams', t);
    },

    setupController(controller, _model) {
      this._super.apply(this, arguments); // Here we "define" the queryParams in the controller;


      let queryParameters = this.get("queryParams");
      controller.set('queryParameters', queryParameters);
    },

    _updateQueryParameter(paramName, paramValue) {
      // The following will ensure the query params are updated in the url
      // https://guides.emberjs.com/v2.15.0/routing/query-params/
      Ember.set(this, 'controller.${paramName}', paramValue); // Might want to refresh the model here
    },

    actions: {
      updateQueryParameter() {
        this._updateQueryParameter.apply(this, arguments);
      }

    },

    renderTemplate() {
      this.render('login', {
        into: 'application',
        outlet: 'content'
      });
    }

  });

  _exports.default = _default;
});