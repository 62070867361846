define("web-client/instance-initializers/previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let previous = {
      path: null,
      url: null,
      route: null
    };
    appInstance.register('previous:main', previous, {
      instantiate: false
    });
    appInstance.inject('router', 'previous', 'previous:main');
    appInstance.inject('service:global-actions', 'previous', 'previous:main');
  }

  var _default = {
    name: 'previous',
    initialize
  };
  _exports.default = _default;
});