define("web-client/platform-specific/tests/addon.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | addon');
  QUnit.test('addon/actions/global-actions/show-edit-password-dialog.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/actions/global-actions/show-edit-password-dialog.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/extra-field-dialog/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/extra-field-dialog/component.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/extra-fields-list-header/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/components/extra-fields-list-header/component.js should pass ESLint\n\n');
  });
  QUnit.test('addon/components/extra-fields-list-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/components/extra-fields-list-item/component.js should pass ESLint\n\n2:10 - \'once\' is defined but never used. (no-unused-vars)\n7:10 - \'computed\' is defined but never used. (no-unused-vars)\n7:20 - \'observer\' is defined but never used. (no-unused-vars)\n78:34 - \'e\' is not defined. (no-undef)');
  });
  QUnit.test('addon/components/history-list-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/components/history-list-item/component.js should pass ESLint\n\n2:10 - \'once\' is defined but never used. (no-unused-vars)\n4:23 - \'computed\' is defined but never used. (no-unused-vars)\n8:11 - \'observer\' is defined but never used. (no-unused-vars)\n51:7 - Unexpected console statement. (no-console)');
  });
  QUnit.test('addon/components/password-dialog/component.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/components/password-dialog/component.js should pass ESLint\n\n15:16 - \'scheduleOnce\' is defined but never used. (no-unused-vars)\n47:5 - \'baseUrl\' is assigned a value but never used. (no-unused-vars)\n71:10 - \'allSelectedTest\' is defined but never used. (no-unused-vars)\n639:11 - Don\'t introduce side-effects in computed properties (ember/no-side-effects)\n639:11 - \'Ember\' is not defined. (no-undef)\n639:11 - Use import { set } from \'@ember/object\'; instead of using Ember.set (ember/new-module-imports)\n641:8 - Unexpected console statement. (no-console)\n661:11 - \'teamId\' is assigned a value but never used. (no-unused-vars)\n713:27 - Unexpected console statement. (no-console)\n719:137 - \'e\' is defined but never used. (no-unused-vars)\n742:11 - \'loader\' is not defined. (no-undef)\n773:42 - \'event\' is defined but never used. (no-unused-vars)');
  });
  QUnit.test('addon/initialize-app/platform-init.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'addon/initialize-app/platform-init.js should pass ESLint\n\n');
  });
  QUnit.test('addon/ipc/ipc-client.js', function (assert) {
    assert.expect(1);
    assert.ok(false, 'addon/ipc/ipc-client.js should pass ESLint\n\n17:10 - \'getGUID\' is defined but never used. (no-unused-vars)\n26:19 - \'navigator\' is not defined. (no-undef)\n27:19 - \'navigator\' is not defined. (no-undef)\n28:7 - \'iOS\' is assigned a value but never used. (no-unused-vars)\n28:40 - \'navigator\' is not defined. (no-undef)\n60:17 - \'w\' is defined but never used. (no-unused-vars)\n111:37 - Empty block statement. (no-empty)\n189:15 - Unexpected console statement. (no-console)\n217:15 - Unexpected console statement. (no-console)\n354:23 - \'MessageChannel\' is not defined. (no-undef)');
  });
});