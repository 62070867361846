define("web-client/components/ion-treeview-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2+OjrSn5",
    "block": "{\"symbols\":[\"extraAction\"],\"statements\":[[4,\"if\",[[25,[\"showExpander\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ion-buttons\"],[11,\"class\",\"ion-treeview-buttons\"],[9],[0,\"\\n    \"],[7,\"ion-button\"],[11,\"class\",\"expander icon-only\"],[11,\"shape\",\"round\"],[9],[0,\"\\n      \"],[7,\"ion-icon\"],[12,\"name\",[30,[[23,\"expandedIndicator\"]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"ion-buttons\"],[11,\"class\",\"ion-treeview-buttons\"],[9],[0,\"\\n    \"],[7,\"ion-button\"],[11,\"class\",\"icon-only\"],[11,\"disabled\",\"\"],[11,\"shape\",\"round\"],[9],[0,\"\\n\"],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[25,[\"selectedIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ion-icon\"],[12,\"class\",[30,[\"selected-indicator \",[23,\"selectedClass\"]]]],[12,\"name\",[30,[[23,\"selectedIcon\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ion-icon\"],[12,\"class\",[30,[\"item-icon  \",[23,\"iconColor\"]]]],[12,\"name\",[30,[[23,\"icon\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ion-label\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n\\n\"],[7,\"ion-badge\"],[9],[1,[23,\"badge\"],false],[10],[0,\"\\n\"],[7,\"ion-buttons\"],[11,\"class\",\"ion-treeview-buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"extraActions\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/ion-treeview-item/template.hbs"
    }
  });

  _exports.default = _default;
});