define("web-client/dashboard/security-stats/controller", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    connectionStats: null,
    teamStats: null,
    passwordStats: null,

    getPasswordStats() {
      (0, _api.getPasswordStats)().then(data => {
        this.set('passwordStats', [{
          cat: "Yours",
          val: data.totalOwnedPasswords,
          color: '#001c53'
        }, {
          cat: "Others",
          val: data.totalSharedToYou,
          color: '#FFA726'
        }]);
      });
    },

    getConnectionStats() {
      (0, _api.getAllConnectionStats)().then(data => {
        this.set('connectionStats', [{
          cat: "Active",
          val: data.totalActiveConnections,
          color: '#001c53'
        }, {
          cat: "Inactive",
          val: data.totalInactiveConnections,
          color: '#FFA726'
        }]);
      });
    },

    getTeamStats() {
      (0, _api.getAllTeamStats)().then(data => {
        this.set('teamStats', [{
          cat: "Yours",
          val: data.totalYourTeams,
          color: '#001c53'
        }, {
          cat: "Theirs",
          val: data.totalTheirTeams,
          color: '#FFA726'
        }]);
      });
    },

    /*
       yoursAgeStats:data[0].ageStatCount,
          othersAgeStats:data[1].ageStatCount,
          yoursScoreStats:data[0].scoreStatCount,
          othersScoreStats:data[1].scoreStatCount
     */
    getAllPasswordAgeStats() {
      (0, _api.getAllPasswordAgeStats)().then(data => {
        this.set('passwordAgeStats', [{
          segment: 'Yours',
          freq: data.yoursAgeStats
        }, {
          segment: 'Others',
          freq: data.othersAgeStats
        }]);
        this.set('securityStats', [{
          segment: 'Yours',
          freq: data.yoursScoreStats
        }, {
          segment: 'Others',
          freq: data.othersScoreStats
        }]);
      });
    },

    passwordAgeStats: [],
    securityStats: [],
    securityStatsSchema: [{
      name: "Very Weak",
      color: "#B71C1C"
    }, {
      name: "Weak",
      color: "#F57C00"
    }, {
      name: "Medium",
      color: "#F58A30"
    }, {
      name: "Strong",
      color: "#43A047"
    }, {
      name: "Very Strong",
      color: "#2E7D32"
    }],
    passwordAgeStatsSchema: [{
      name: "Last 30 days",
      color: '#1B5E20'
    }, {
      name: "30 to 60  days",
      color: '#388E3C'
    }, {
      name: "60 to 90 days",
      color: '#43A047'
    }, {
      name: "90 to 180 days",
      color: '#F9A825'
    }, {
      name: "Over 180 days",
      color: '#F57F17'
    }]
  });

  _exports.default = _default;
});