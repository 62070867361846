define("web-client/components/password-list-gauge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    score: 0,
    crack_time_display: "",
    styleClass: "",
    classNames: ['password-list-score-gauge'],
    classNameBindings: 'styleClass',
    attributeBindings: ['title'],
    title: Ember.computed('score', 'crack_time_display', function () {
      var score = this.score;

      switch (score) {
        case 0:
          return "Very weak. The estimated time to crack this password is: " + this.crack_time_display;

        case 1:
          return "Very weak. The estimated time to crack this password is: " + this.crack_time_display;

        case 2:
          return "Weak. The estimated time to crack this password is: " + this.crack_time_display;

        case 3:
          return "Strong. The estimated time to crack this password is: " + this.crack_time_display;

        case 4:
          return "Very strong. The estimated time to crack this password is: " + this.crack_time_display;
      }
    }),
    scoreDidChange: Ember.observer('score', 'crack_time_display', function () {
      this.element.classList.remove('score-0,score-1,score-2,score-3,score-4,');
      this.element.classList.add('score-' + this.score);
    }),
    didInsertElement: function didInsertElement() {
      this.element.classList.remove('score-0,score-1,score-2,score-3,score-4,');
      this.element.classList.add('score-' + this.score);
    }
  });

  _exports.default = _default;
});