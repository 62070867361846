define("web-client/api/get-add-license-estimate", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAddLicenseEstimate = void 0;

  /**
   * params
  
   */
  const getAddLicenseEstimate = _ipcClient.ipc.makeRequestFunction('getAddLicenseEstimate');

  _exports.getAddLicenseEstimate = getAddLicenseEstimate;
});