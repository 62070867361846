define("web-client/icons/config", [], function () {
  "use strict";

  /* eslint-env node */
  //const COLORS = require('../../node_modules/ui-core/addon/utils/node-colors');
  module.exports = {
    icons: [{
      name: 'circle-medium',
      colors: []
    }, {
      name: 'alpha-w-box',
      colors: []
    }, {
      name: 'alpha-w-box-outline',
      colors: []
    }, {
      name: 'alpha-d-box',
      colors: []
    }, {
      name: 'alpha-d-box-outline',
      colors: []
    }, {
      name: 'alpha-r-box',
      colors: []
    }, {
      name: 'alpha-r-box-outline',
      colors: []
    }, {
      name: 'information-outline',
      colors: []
    }, {
      name: 'menu',
      colors: []
    }, {
      name: 'sort-up',
      colors: []
    }, {
      name: 'sort-up-down',
      colors: []
    }, {
      name: 'sort-down',
      colors: []
    }, {
      name: 'plus-circle-multiple-outline',
      colors: []
    }, {
      name: 'menu-down',
      colors: []
    }, {
      name: 'menu-right',
      colors: []
    }, {
      name: 'sort',
      colors: []
    }, {
      name: 'link-variant',
      colors: []
    }, {
      name: 'wechat',
      colors: []
    }, {
      name: 'home',
      colors: []
    }, {
      name: 'help',
      colors: []
    }, {
      name: 'puzzle',
      colors: []
    }, {
      name: 'newspaper',
      colors: []
    }, {
      name: 'shield',
      colors: []
    }, {
      name: 'download',
      colors: []
    }, {
      name: 'menu-down',
      colors: []
    }, {
      name: 'refresh',
      colors: []
    }, {
      name: 'cloud-download',
      colors: []
    }, {
      name: 'view-sequential',
      colors: []
    }, {
      name: 'view-stream',
      colors: []
    }, {
      name: 'school',
      colors: []
    }, {
      name: 'textbox-password',
      colors: []
    }, {
      name: 'check',
      colors: []
    }, {
      name: 'check-all',
      colors: []
    }, {
      name: 'minus-box',
      colors: []
    }, {
      name: 'minus-circle',
      colors: []
    }, {
      name: 'plus-circle',
      colors: []
    }, {
      name: 'open-in-new',
      colors: []
    }, {
      name: 'linux',
      colors: []
    }, {
      name: 'windows',
      colors: []
    }, {
      name: 'android',
      colors: []
    }, {
      name: 'ubuntu',
      colors: []
    }, {
      name: 'apple-ios',
      colors: []
    }, {
      name: 'google-chrome',
      colors: []
    }, {
      name: 'apple-safari',
      colors: []
    }, {
      name: 'firefox',
      colors: []
    }, {
      name: 'edge',
      colors: []
    }, {
      name: 'desktop-mac',
      colors: []
    }, {
      name: 'cellphone',
      colors: []
    }, {
      name: 'thumb-up',
      colors: []
    }, {
      name: 'thumb-down',
      colors: []
    }, {
      name: 'close',
      colors: []
    }, {
      name: 'credit-card',
      colors: []
    }, {
      name: 'alphabetical',
      colors: []
    }, {
      name: 'history',
      colors: []
    }, {
      name: 'wrench',
      colors: []
    }, {
      name: 'details',
      colors: []
    }, {
      name: 'content-copy',
      colors: []
    }, {
      name: 'file-import',
      colors: []
    }, {
      name: 'file-export',
      colors: []
    }, {
      name: 'database',
      colors: []
    }, {
      name: 'shuffle-variant',
      colors: []
    }, {
      name: 'star',
      colors: []
    }, {
      name: 'dots-vertical',
      colors: []
    }, {
      name: 'undo',
      colors: []
    }, {
      name: 'pencil',
      colors: []
    }, {
      name: 'delete',
      colors: []
    }, {
      name: 'pp-icon',
      colors: [{
        color: "#ffffff",
        name: "white"
      }]
    }, {
      name: 'eye',
      colors: []
    }, {
      name: 'filter-variant',
      colors: []
    }, {
      name: 'magnify',
      colors: []
    }, {
      name: 'shuffle',
      colors: []
    }, {
      name: 'filter',
      colors: []
    }, {
      name: 'eye-off',
      colors: []
    }, {
      name: 'view-dashboard',
      colors: []
    }, {
      name: 'clipboard-arrow-down',
      colors: []
    }, {
      name: 'arrow-expand',
      colors: []
    }, {
      name: 'view-list',
      colors: []
    }, {
      name: 'key',
      colors: []
    }, {
      name: "plus",
      colors: []
    }, {
      name: 'key-plus',
      colors: []
    }, {
      name: 'folder-account',
      colors: []
    }, {
      name: 'account',
      colors: []
    }, {
      name: 'folder-multiple',
      colors: []
    }, {
      name: 'label',
      colors: []
    }, {
      name: 'checkbox-blank-circle',
      colors: []
    }, {
      name: 'checkbox-blank-circle-outline',
      colors: []
    }, {
      name: 'email-secure',
      colors: []
    }, {
      name: 'email',
      colors: []
    }, {
      name: 'contact-mail',
      colors: []
    }, {
      name: 'alert-box',
      colors: []
    }, {
      name: 'settings',
      colors: []
    }, {
      name: 'lock',
      colors: []
    }, {
      name: 'lock-unlocked',
      colors: []
    }, {
      name: 'login-variant',
      colors: []
    }, {
      name: 'logout-variant',
      colors: []
    }, {
      name: 'window-restore',
      colors: []
    }, {
      name: 'window-maximize',
      colors: []
    }]
  };
});