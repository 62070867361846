define("web-client/components/connection-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/find", "lodash-es/filter", "lodash-es/map", "lodash-es/intersection", "web-client/api", "ember-concurrency", "ember-get-config", "lodash-es/includes", "lodash-es/difference", "xxhashjs", "lodash-es/reduce", "lodash-es/sortBy", "lodash-es/cloneDeep", "lodash-es/compact", "lodash-es/concat", "lodash-es/forEach"], function (_exports, _dialogBase, _find, _filter, _map, _intersection, _api, _emberConcurrency, _emberGetConfig, _includes, _difference, _xxhashjs, _reduce, _sortBy, _cloneDeep, _compact, _concat, _forEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function allSelectedTest(itemArray, selectedArray) {
    if (itemArray.length === 0) {
      return false;
    }

    let count = (0, _difference.default)(itemArray, selectedArray).length;

    if (count === 0) {
      return true;
    }

    if (count > 0 && count < itemArray.length) {
      return undefined;
    }

    return false;
  }

  function selectAll(allSelected, availableArray, selectedArray) {
    if (allSelected) {
      let removeThese = (0, _intersection.default)(availableArray, selectedArray);
      selectedArray.removeObjects(removeThese);
    } else {
      let addThese = (0, _difference.default)(availableArray, selectedArray);
      selectedArray.pushObjects(addThese);
    }
  }

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-4',
    classNames: ['connection-dialog'],
    loading: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    data: Ember.computed.alias('reduxStore.state.data'),
    originalContent: "",
    originalTeams: null,
    isEditMode: false,
    name: Ember.computed.alias('editTeam.name'),
    escAction: "cancelDialog",
    defaultAction: "cancelDialog",
    standardDialogs: Ember.inject.service(),
    profileImageURL: Ember.computed('request', function () {
      return _emberGetConfig.default.apiHost + '/portal/v1/secure/avatar/' + this.connection.id;
    }),
    managingTeams: false,
    teamsListWidth: Ember.computed('managingTeams', function () {
      return this.get('managingTeams') ? 5 : 12;
    }),

    onShow() {
      this.get('reduxStore').dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.get('reduxStore').dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    permissionChanges: Ember.computed('stats.teams', 'stats.teams.[]', 'originalTeams', function () {
      //[{"otherId":"adf4117dfd6c4f9bb82c55bf5848caa1","groupId":"95d4d6a755554074988924807ff014c1","role":4}]
      let newConnectionIds = (0, _difference.default)((0, _map.default)(this.get('stats.teams'), 'id'), (0, _map.default)(this.get('originalTeams'), 'id'));
      let newConnectionChanges = (0, _compact.default)((0, _map.default)(this.stats.teams, team => {
        if ((0, _includes.default)(newConnectionIds, team.id)) {
          return {
            otherId: this.connection.id,
            "groupId": team.id,
            role: team.perm
          };
        }
      }));
      let existingConnectionChanges = (0, _compact.default)((0, _map.default)(this.stats.teams, team => {
        let found = (0, _find.default)(this.originalTeams, foundTeam => {
          if (foundTeam.id === team.id) {
            return foundTeam;
          }
        });

        if (found && found.perm !== team.perm) {
          return {
            otherId: this.connection.id,
            groupId: team.id,
            role: team.perm
          };
        }
      }));
      return (0, _concat.default)(newConnectionChanges, existingConnectionChanges);
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('connection', this.get('cn'));
      this.set('active', this.connection.active);
      this.set('stats', (0, _cloneDeep.default)(this.get('st')));
      this.set('originalTeams', Ember.A((0, _cloneDeep.default)(this.stats.teams)));
    },

    currentTeams: Ember.computed('stats.teams', 'stats.teams.[]', function () {
      return (0, _filter.default)(this.get("stats.teams"), function (team) {
        return team.name !== 'Default' && !team.individual;
      });
    }),
    availableTeams: Ember.computed('reduxStore.state.data.teams.{content,content.[]}', 'stats.teams', 'stats.teams.[]', function () {
      return (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
        return team.name !== 'Default' && !team.individual && team.owner === this.get('reduxStore.state.username') && !(0, _includes.default)((0, _map.default)(this.get('stats.teams'), "id"), team.id);
      });
    }),
    selectedAssignedTeams: Ember.A([]),
    selectedAvailableTeams: Ember.A([]),
    allAssignedTeamsSelected: Ember.computed('selectedAssignedTeams', 'selectedAssignedTeams.[]', 'currentTeams', 'currentTeams.[]', function () {
      return allSelectedTest((0, _map.default)(this.get('currentTeams'), 'id'), this.get('selectedAssignedTeams'));
    }),
    allAssignedIndividualTeamsSelected: Ember.computed('selectedAssignedTeams', 'selectedAssignedTeams.[]', 'currentIndividualTeams', 'currentIndividualTeams.[]', function () {
      return allSelectedTest((0, _map.default)(this.get('currentIndividualTeams'), 'id'), this.get('selectedAssignedTeams'));
    }),
    allAvailableTeamsSelected: Ember.computed('selectedAvailableTeams', 'selectedAvailableTeams.[]', 'availableTeams', 'availableTeams.[]', function () {
      return allSelectedTest((0, _map.default)(this.get('availableTeams'), 'id'), this.get('selectedAvailableTeams'));
    }),
    allAvailableIndividualTeamsSelected: Ember.computed('selectedAvailableTeams', 'selectedAvailableTeams.[]', 'availableIndividualTeams', 'availableIndividualTeams.[]', function () {
      return allSelectedTest((0, _map.default)(this.get('availableIndividualTeams'), 'id'), this.get('selectedAvailableTeams'));
    }),
    undoTeamChanges: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.set('stats', (0, _cloneDeep.default)(this.get('st')));
      deferred.resolve();
      yield deferred.promise;
    }).drop(),
    active: false,
    selectTeamToAdd: (0, _emberConcurrency.task)(function* (team) {
      let deferred = Ember.RSVP.defer();

      if (!(0, _includes.default)(this.get('selectedAvailableTeams'), team.id)) {
        this.get('selectedAvailableTeams').pushObject(team.id);
        deferred.resolve();
      } else {
        this.get('selectedAvailableTeams').removeObject(team.id);
        deferred.resolve();
      }

      yield deferred.promise;
    }).drop(),
    originalTeamsHash: Ember.computed('originalTeams', 'originalTeams.{[],@each}', function () {
      if (this.originalTeams && this.originalTeams.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.originalTeams, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id, 1) + acc;
          return acc;
        }, 0), 0).toString();
      } else {
        return "0";
      }
    }),
    teamsHash: Ember.computed('stats', 'stats.teams', 'stats.teams.{[],@each}', function () {
      if (this.stats.teams && this.stats.teams.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.stats.teams, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id, 1) + acc;
          return acc;
        }, 0), 0).toString();
      } else {
        return "0";
      }
    }),
    hasChanged: Ember.computed('originalTeamsHash', 'teamsHash', 'permissionChanges', 'permissionChanges.[]', function () {
      return this.teamsHash !== this.originalTeamsHash || this.permissionChanges.length;
    }),
    saveTeamChanges: (0, _emberConcurrency.task)(function* () {
      this.loading.show('Saving Team Changes');
      let teamChanges = {
        removeFromThese: (0, _difference.default)((0, _map.default)(this.get('originalTeams'), 'id'), (0, _map.default)(this.get('stats.teams'), 'id')),
        addToThese: (0, _difference.default)((0, _map.default)(this.get('stats.teams'), 'id'), (0, _map.default)(this.get('originalTeams'), 'id')),
        connectionId: this.get('connection.id'),
        permissionChanges: this.permissionChanges
      };
      yield (0, _api.updateConnectionTeams)(teamChanges);
      this.loading.dismiss();
      this.send('remove');
    }),
    manageTeams: (0, _emberConcurrency.task)(function* () {
      let filtered = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
        return team.name !== 'Default' && !team.individual && team.owner === this.get('reduxStore.state.username');
      });
      let currentIds = (0, _map.default)(this.stats.teams, 'id');
      let items = (0, _sortBy.default)((0, _map.default)(filtered, function (item) {
        return {
          name: item.name,
          value: item.id,
          group: item.individual ? "Connections" : "Teams",
          selected: (0, _includes.default)(currentIds, item.id),
          color: item.color,
          icon: item.individual ? "contact" : "folder"
        };
      }), item => {
        return item.name.toLowerCase();
      });
      let multiSelectOptions = {
        title: 'Manage teams for connection',
        size: 'size-3',
        okText: 'Save',
        cancelText: 'Cancel',
        listItems: items,
        addNewTask: this.addNewTeam,
        grouping: true
      };
      let newItems = yield this.standardDialogs.showMultiSelectDialog(multiSelectOptions);
      let newTeams = (0, _filter.default)(this.reduxStore.state.data.teams.content, function (item) {
        return (0, _includes.default)(newItems, item.id);
      });
      let mappedToPerms = (0, _map.default)(newTeams, newTeam => {
        let found = (0, _find.default)(this.stats.teams, team => {
          return team.id === newTeam.id;
        });

        if (found) {
          Ember.set(newTeam, 'perm', found.perm);
        } else {
          Ember.set(newTeam, 'perm', 0);
        }

        return newTeam;
      });
      this.set('stats.teams', mappedToPerms);
    }).drop(),
    sortedTeams: Ember.computed('stats.teams', function () {
      return (0, _sortBy.default)(this.stats.teams, function (team) {
        return team.name.toLowerCase();
      });
    }),
    currentTab: "details",
    actions: {
      setTab(tab) {
        this.set('currentTab', tab);
      },

      togglePermission(team, perm) {
        Ember.set(team, "perm", perm);
        this.notifyPropertyChange('stats.teams');
      },

      toggleManageTeams() {
        this.set("managingTeams", !this.managingTeams);
      },

      selectTeamToRemove(team) {
        if (!(0, _includes.default)(this.get('selectedAssignedTeams'), team.id)) {
          this.get('selectedAssignedTeams').pushObject(team.id);
        } else {
          this.get('selectedAssignedTeams').removeObject(team.id);
        }
      },

      selectAllAvailableTeams() {
        selectAll(this.get('allAvailableTeamsSelected'), (0, _map.default)(this.get('availableTeams'), 'id'), this.get('selectedAvailableTeams'));
      },

      selectAllAssignedTeams() {
        selectAll(this.get('allAssignedTeamsSelected'), (0, _map.default)((0, _filter.default)(this.get('currentTeams'), team => {
          return team.ownerId !== this.get('connection.id');
        }), 'id'), this.get('selectedAssignedTeams'));
      },

      removeSelectedTeams() {
        let removeThese = (0, _filter.default)(this.get('stats.teams'), team => {
          return (0, _includes.default)(this.get('selectedAssignedTeams'), team.id);
        });
        this.get('stats.teams').removeObjects(removeThese);
        this.selectedAssignedTeams.clear();
      },

      addSelectedTeams() {
        let addThese = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
          return (0, _includes.default)(this.get('selectedAvailableTeams'), team.id);
        });
        (0, _forEach.default)(addThese, con => {
          if (con.perm === undefined) {
            Ember.set(con, "perm", 0);
          }
        });
        this.get('stats.teams').addObjects(addThese);
        this.selectedAvailableTeams.clear();
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});