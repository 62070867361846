define("web-client/dashboard/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    globalActions: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);
    },

    activate() {
      this._super.apply(this, arguments);

      var isLicensed = this.get('reduxStore.state.licenseSummary.licensed');

      if (isLicensed) {
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Dashboard Menu"
        });
        this.reduxStore.state.set('contentClass', 'scroll-content');
        this.reduxStore.state.set('contentSlot', '');
        Ember.run.scheduleOnce('afterRender', () => {
          window.dispatchEvent(new Event('resize'));
        });
      } else {
        this.reduxStore.state.set('contentClass', 'unlicensed-dialog');
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: ""
        });
      }
    },

    deactivate() {
      this._super.apply(this, arguments);

      let sideBarFadeElement = document.getElementsByClassName('fade-sidebar')[0];

      if (sideBarFadeElement) {
        sideBarFadeElement.classList.remove('fade-in');
      }
    },

    setupController(controller) {
      this.globalActions.loadSessionHistory();
      controller.getPasswordStats();
      controller.getConnectionStats();
      controller.getTeamStats();
      controller.getAllPasswordAgeStats();
    },

    actions: {
      didTransition() {
        var isLicensed = this.get('reduxStore.state.licenseSummary.licensed');

        if (isLicensed) {
          this.reduxStore.dispatch({
            type: "SET_SECTION_TITLE",
            sectionTitle: "PASSPACK"
          });
          this.reduxStore.dispatch({
            type: "SET_SIDEBAR_TITLE",
            sidebarTitle: "Usage"
          });
          this.reduxStore.dispatch({
            type: "SET_CURRENT_DASHBOARD_SECTION",
            dashboardSection: "Usage"
          });
          this.reduxStore.dispatch({
            type: "SET_CURRENT_HEADER_BUTTON",
            selectedHeaderButton: "dashboard"
          });
        } else {
          this.reduxStore.state.set('contentClass', 'unlicensed-dialog');
          this.reduxStore.dispatch({
            type: "SET_SIDEBAR_TITLE",
            sidebarTitle: ""
          });
        }
      }

    },

    renderTemplate() {
      // this._super(...arguments);
      this.render('dashboard', {
        outlet: 'content',
        into: 'application'
      });
      this.render('dashboard/left-sidebar', {
        outlet: 'left-sidebar',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});