define("web-client/services/standard-dialogs", ["exports", "lodash-es/merge"], function (_exports, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    reduxStore: Ember.inject.service(),
    dialogManager: Ember.inject.service(),

    ensurePackingKey() {
      let onPackingKey = Ember.RSVP.defer();
      onPackingKey.promise.then(() => {
        this.data.updatePasswords();
      }).catch(function () {});

      if (this.get('reduxStore.state.isLocked')) {
        this.showDialog('packing-key-dialog', Ember.Object.create({
          onPackingKey: onPackingKey
        }));
      } else {
        onPackingKey.resolve();
      }

      return onPackingKey.promise;
    },

    /**
     *
     * @param dialogComponent A component name as a string "some-dialog"
     * @param params an Ember Object or POJO to be passed to the dialog and used for parameters.
     */
    showDialog: function showDialog(dialogComponent, params) {
      let dialogType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "auto-height";
      this.get('dialogManager.actionHandler').send('show', dialogComponent, params, dialogType);
    },
    showInfoDialog: function showInfoDialog(type, action, isProtected, size) {
      var params = Ember.Object.create({
        type: type,
        onConfirm: action,
        size: size
      });

      if (isProtected) {
        this.ensurePackingKey().then(() => {
          this.get('dialogManager.actionHandler').send('show', 'info-dialog', params, 'small');
        }).catch(function () {});
      } else {
        this.get('dialogManager.actionHandler').send('show', 'info-dialog', params, 'small');
      }
    },

    showConfirmDialog(type, isProtected, size) {
      let deferred = Ember.RSVP.defer();
      let aSize = size ? size : 'size-3';
      let params = Ember.Object.create({
        type: type,
        onConfirm: deferred,
        size: aSize
      });
      this.get('dialogManager.actionHandler').send('show', 'confirm-dialog', params, "small");
      return deferred.promise;
    },

    showMultiSelectDialog(multiSelectOptions) {
      let deferred = Ember.RSVP.defer();
      let defaultOptions = {
        title: "",
        onConfirm: deferred,
        size: "size-3",
        okText: "Save",
        cancelText: "Cancel",
        listItems: null,
        addNewTask: null,
        grouping: false
      };
      let options = (0, _merge.default)(defaultOptions, multiSelectOptions);
      let params = Ember.Object.create(options);
      this.get('dialogManager.actionHandler').send('show', 'multi-select-dialog', params);
      return deferred.promise;
    },

    showRadioSelectDialog(radioSelectOptions) {
      let deferred = Ember.RSVP.defer();
      let defaultOptions = {
        title: "",
        onConfirm: deferred,
        size: "size-3",
        okText: "Save",
        cancelText: "Cancel",
        listItems: null,
        addNewTask: null,
        grouping: false
      };
      let options = (0, _merge.default)(defaultOptions, radioSelectOptions);
      let params = Ember.Object.create(options);
      this.get('dialogManager.actionHandler').send('show', 'radio-select-dialog', params);
      return deferred.promise;
    }

  });

  _exports.default = _default;
});