define("web-client/components/company-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ilbMFsE/",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[25,[\"showCompanySelector\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"company-selector\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"float-left company-selector-label\"],[9],[0,\"Working Company\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n      \"],[7,\"select\"],[11,\"id\",\"selectWorkingCompany\"],[11,\"class\",\"select-box\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"selectWorkingCompany\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"companySections\"]]],[[\"key\"],[\"id\"]],{\"statements\":[[4,\"if\",[[24,1,[\"working\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"value\",[30,[[24,1,[\"orgId\"]]]]],[11,\"selected\",\"\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"value\",[30,[[24,1,[\"orgId\"]]]]],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/company-selector/template.hbs"
    }
  });

  _exports.default = _default;
});