define("web-client/reducers/data", ["exports", "lodash-es/map", "lodash-es/every", "lodash-es/some", "lodash-es/groupBy", "lodash-es/filter", "lodash-es/mapValues", "web-client/utils/misc-utils", "lodash-es/find", "lodash-es/concat", "lodash-es/compact", "lodash-es/take", "lodash-es/difference", "lodash-es/intersection", "lodash-es/includes"], function (_exports, _map, _every, _some, _groupBy, _filter, _mapValues, _miscUtils, _find, _concat, _compact, _take, _difference, _intersection, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { inject as service } from '@ember/service';
  //import Component from '@ember/component';
  //import { getOwner } from '@ember/application';
  //import { sort, filter as filterComputed } from '@ember/object/computed';
  //import { later } from '@ember/runloop';
  var _default = Ember.Object.extend({
    generatedPasswords: Ember.ArrayProxy.extend({
      selected: Ember.A([]),
      last20: Ember.computed('content', function () {
        return (0, _take.default)(this.content, 20);
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    messages: Ember.ArrayProxy.extend({
      filterType: "all",
      selected: Ember.A([]),
      totalCount: 0,
      currentPage: 1,
      pageSize: 15000,
      startRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return this.currentPage * this.pageSize - this.pageSize + 1;
      }),
      endRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return Math.min(this.currentPage * this.pageSize, (this.currentPage - 1) * this.pageSize + this.totalCount);
      }),
      selectedState: Ember.computed('selected', 'selected.[]', 'content', 'content.[]', function () {
        if (this.get('selected.length') === this.get('content.length') && this.content.length !== 0) {
          return true;
        }

        if (this.get('selected.length') > 0 && this.get('selected.length') < this.get('content.length')) {
          return null;
        }

        if (this.get('selected.length') === 0) {
          return false;
        }
      }),
      sortOnName: undefined,
      sortOnSubject: undefined,
      sortOnSentDate: true,
      sortDirection: Ember.computed('sortName', function () {
        const sortedOnProperty = (0, _filter.default)([{
          sortName: 'nickname',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'subject',
          name: 'sortOnSubject',
          active: this.sortOnSubject
        }, {
          sortName: 'sentDate',
          name: 'sortOnSentDate',
          active: this.sortOnSentDate
        }], item => {
          return item.active !== undefined;
        })[0].name;

        if (this.get(sortedOnProperty) === true) {
          return 'ASC';
        } else {
          return 'DESC';
        }
      }),
      sortName: Ember.computed('sortOnName', 'sortOnSubject', 'sortOnSentDate', function () {
        return (0, _filter.default)([{
          sortName: 'nickname',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'subject',
          name: 'sortOnSubject',
          active: this.sortOnSubject
        }, {
          sortName: 'sentDate',
          name: 'sortOnSentDate',
          active: this.sortOnSentDate
        }], item => {
          return item.active !== undefined;
        })[0].sortName;
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    labels: Ember.ArrayProxy.extend({
      selected: Ember.A([]),
      selectedState: Ember.computed('selected', 'selected.[]', 'content', 'content.[]', function () {
        if (this.get('selected.length') === this.get('content.length') && this.content.length !== 0) {
          return true;
        }

        if (this.get('selected.length') > 0 && this.get('selected.length') < this.get('content.length')) {
          return null;
        }

        if (this.get('selected.length') === 0) {
          return false;
        }
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    allInvites: Ember.ArrayProxy.extend({
      selected: Ember.A([]),
      selectedState: Ember.computed('selected', 'selected.[]', 'content', 'content.[]', function () {
        if (this.get('selected.length') === this.get('content.length') && this.content.length !== 0) {
          return true;
        }

        if (this.get('selected.length') > 0 && this.get('selected.length') < this.get('content.length')) {
          return null;
        }

        if (this.get('selected.length') === 0) {
          return false;
        }
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    invitationCount: Ember.computed('sentInvites.content.[]', 'receivedInvites.content.[]', function () {
      return this.sentInvites.content.length + this.receivedInvites.content.length;
    }),
    sentInvites: Ember.ArrayProxy.extend({
      selected: Ember.A([]),
      selectedState: Ember.computed('selected', 'selected.[]', 'content', 'content.[]', function () {
        if (this.get('selected.length') === this.get('content.length') && this.content.length !== 0) {
          return true;
        }

        if (this.get('selected.length') > 0 && this.get('selected.length') < this.get('content.length')) {
          return null;
        }

        if (this.get('selected.length') === 0) {
          return false;
        }
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      },

      all: Ember.computed('selected.[]', 'selected', 'content', 'content.[]', function () {
        return this.content;
      })
    }).create({
      content: Ember.A([])
    }),
    receivedInvites: Ember.ArrayProxy.extend({
      selected: Ember.A([]),
      selectedState: Ember.computed('selected', 'selected.[]', 'content', 'content.[]', function () {
        if (this.get('selected.length') === this.get('content.length') && this.content.length !== 0) {
          return true;
        }

        if (this.get('selected.length') > 0 && this.get('selected.length') < this.get('content.length')) {
          return null;
        }

        if (this.get('selected.length') === 0) {
          return false;
        }
      }),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      },

      all: Ember.computed('selected.[]', 'selected', 'content', 'content.[]', function () {
        return this.content;
      })
    }).create({
      content: Ember.A([])
    }),
    passwords: Ember.ArrayProxy.extend({
      filterOnFavorites: false,
      totalCount: 0,
      currentPage: 1,
      pageSize: 15000,
      startRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return this.currentPage * this.pageSize - this.pageSize + 1;
      }),
      endRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return Math.min(this.currentPage * this.pageSize, (this.currentPage - 1) * this.pageSize + this.totalCount);
      }),
      filterText: '',
      selected: null,
      strengthsFilter: null,
      searchResults: '',
      sortDirection: Ember.computed('sortName', function () {
        const sortedOnProperty = (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }, {
          sortName: 'score',
          name: 'sortOnPasswordStrength',
          active: this.sortOnPasswordStrength
        }], item => {
          return item.active !== undefined;
        })[0].name;

        if (this.get(sortedOnProperty) === true) {
          return 'ASC';
        } else {
          return 'DESC';
        }
      }),
      sortName: Ember.computed('sortOnName', 'sortOnURL', 'sortOnLastUpdate', 'sortOnLastPasswordUpdate', 'sortOnPasswordStrength', function () {
        return (0, _filter.default)([{
          sortName: 'name',
          name: 'sortOnName',
          active: this.sortOnName
        }, {
          sortName: 'url',
          name: 'sortOnURL',
          active: this.sortOnURL
        }, {
          sortName: 'actualLastUpdate',
          name: 'sortOnLastUpdate',
          active: this.sortOnLastUpdate
        }, {
          sortName: 'actualPasswordLastUpdate',
          name: 'sortOnLastPasswordUpdate',
          active: this.sortOnLastPasswordUpdate
        }, {
          sortName: 'score',
          name: 'sortOnPasswordStrength',
          active: this.sortOnPasswordStrength
        }], item => {
          return item.active !== undefined;
        })[0].sortName;
      }),
      sortOnName: true,
      sortOnURL: undefined,
      sortOnLastUpdate: undefined,
      sortOnLastPasswordUpdate: undefined,
      sortOnPasswordStrength: undefined,

      init() {
        this.set('selected', Ember.A([]));
        this.set('strengthsFilter', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    teams: Ember.ArrayProxy.extend({
      filterText: '',
      selected: Ember.A([]),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      },

      allTeamsSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const allTeams = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(allTeams, item => {
          return this.selected.includes(item);
        });

        if (allTeams.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(allTeams, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      }),
      allOwnedSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const owned = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.role === 1 && item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (owned.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      }),
      allUnOwnedSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const owned = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.role !== 1 && item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (owned.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      })
    }).create({
      content: Ember.A([])
    }),
    yourTeams: Ember.computed('teams.content', 'teams.[]', 'teams', function () {
      return (0, _filter.default)(this.get('teams.content'), team => {
        return team.role === 1 && team.name !== 'Default' && !team.individual;
      });
    }),
    theirTeams: Ember.computed('teams.content', 'teams.[]', 'teams', function () {
      //      console.log("theirTeams: " + JSON.stringify(this.get('teams.content')));
      const grouped = (0, _groupBy.default)((0, _filter.default)(this.get('teams.content'), team => {
        //console.log("team: " + team.role + " " + team.individual + " " + team.name + " " + team.status);
        return team.role !== 1 && !team.individual;
      }), function (team) {
        return team.ownerNick;
      }); //console.log("grouped: " + JSON.stringify(grouped));

      const rv = (0, _mapValues.default)(grouped, function (o) {
        //          console.log("o: " + JSON.stringify(o));
        var rv2 = Ember.Object.extend({
          teams: o,
          isSelected: Ember.computed('teams.@each.isSelected', function () {
            const all = (0, _every.default)(this.teams, item => {
              return item.get('isSelected');
            });

            if (this.teams.length === 0) {
              return false;
            }

            if (all) {
              return true;
            }

            const x = (0, _some.default)(this.teams, item => {
              return item.get('isSelected');
            });

            if (x) {
              return null;
            }

            return false;
          })
        }).create(); //          console.log("rv2: " + JSON.stringify(rv2));

        return rv2;
      }); //      console.log("rv: " + JSON.stringify(rv));

      return rv;
    }),
    connections: Ember.ArrayProxy.extend({
      filterText: '',
      reduxStore: Ember.inject.service(),
      selected: null,

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      },

      updateAllConnectionsSelected: false,
      allConnectionsSelectedForPasswordFilter: Ember.computed('connectionsForPasswordFilter', 'updateAllConnectionsSelected', 'connectionsForPasswordFilter@each.isSelected', 'connectionsForPasswordFilter@each.allTeams', function () {
        let allSelected = (0, _every.default)(this.connectionsForPasswordFilter, item => {
          return item.get("isSelected");
        }) && !this.connectionsForPasswordFilter.length === 0;

        if (allSelected) {
          return true;
        }

        let someSelected = (0, _some.default)(this.connectionsForPasswordFilter, item => {
          return item.get("isSelected") === true || item.get('isSelected') === null;
        });

        if (someSelected) {
          return null;
        }

        return false;
      }),
      connectionsForPasswordFilter: Ember.computed('activeConnections', 'inactiveConnectionsWithIncomingDirectShareTeam', 'content.[]', 'content', 'selectedTeamsPerConnection.@each.allTeams', function () {
        let self = this;
        return (0, _map.default)((0, _concat.default)(this.get('activeConnections'), this.get('inactiveConnectionsWithIncomingDirectShareTeam')), function (o) {
          return Ember.Object.extend({
            selected: Ember.A([]),

            init() {
              this._super.apply(this, arguments);

              this.set("selected", Ember.A([]));
            },

            allTeams: Ember.computed('connections.content', function () {
              return (0, _compact.default)((0, _concat.default)(this.individualTeamSharedToConnection ? [this.individualTeamSharedToConnection] : null, this.individualTeamSharedFromConnection ? [this.individualTeamSharedFromConnection] : null, (0, _map.default)(this.ownedTeams, "teamId"), (0, _map.default)(this.memberOfTeams, "teamId")));
            }),
            isSelected: Ember.computed('selected.[]', 'selected', function () {
              if ((0, _miscUtils.arraysIdenticalAnyOrder)(this.selected, this.allTeams)) {
                setTimeout(() => {
                  this.connections.notifyPropertyChange('allConnectionsSelectedForPasswordFilter');
                }, 0);
                return true;
              }

              if ((0, _intersection.default)(this.selected, this.allTeams).length) {
                setTimeout(() => {
                  this.connections.notifyPropertyChange('allConnectionsSelectedForPasswordFilter');
                }, 0);
                return null;
              }

              setTimeout(() => {
                this.connections.notifyPropertyChange('allConnectionsSelectedForPasswordFilter');
              }, 0);
              return false;
            }),
            directlySharedToSelected: Ember.computed('selected.[]', 'selected', function () {
              if (!this.individualTeamSharedToConnection) {
                return false;
              }

              return (0, _includes.default)(this.selected, this.individualTeamSharedToConnection);
            }),
            directlySharedFromSelected: Ember.computed('selected.[]', 'selected', function () {
              if (!this.individualTeamSharedFromConnection) {
                return false;
              }

              return (0, _includes.default)(this.selected, this.individualTeamSharedFromConnection);
            }),
            sharedFromTeamsSelected: Ember.computed('selected.[]', 'selected', function () {
              if (this.ownedTeams.length === 0) {
                return false;
              }

              if ((0, _difference.default)((0, _map.default)(this.ownedTeams, "teamId"), this.selected).length === 0) {
                return true;
              }

              return false;
            }),
            sharedToTeamsSelected: Ember.computed('selected', 'selected.[]', function () {
              if (this.memberOfTeams.length === 0) {
                return false;
              }

              if ((0, _difference.default)((0, _map.default)(this.memberOfTeams, "teamId"), this.selected).length === 0) {
                return true;
              }

              return false;
            }),

            selectAllSharedToConnectionViaTeams(select) {
              if (select) {
                this.selected.pushObjects((0, _map.default)(this.memberOfTeams, "teamId"));
              } else {
                this.selected.removeObjects((0, _map.default)(this.memberOfTeams, "teamId"));
              }
            },

            selectAllSharedFromConnectionViaTeams(select) {
              if (select) {
                this.selected.pushObjects((0, _map.default)(this.ownedTeams, "teamId"));
              } else {
                this.selected.removeObjects((0, _map.default)(this.ownedTeams, "teamId"));
              }
            },

            selectAllSharedDirectlyFromConnection(select) {
              if (this.individualTeamSharedFromConnection) {
                if (select) {
                  this.selected.pushObject(this.individualTeamSharedFromConnection);
                } else {
                  this.selected.removeObject(this.individualTeamSharedFromConnection);
                }
              }
            },

            selectAllSharedDirectlyToConnection(select) {
              if (this.individualTeamSharedToConnection) {
                if (select) {
                  this.selected.pushObject(this.individualTeamSharedToConnection);
                } else {
                  this.selected.removeObject(this.individualTeamSharedToConnection);
                }
              }
            },

            connections: self
          }).create(o);
        });
      }),
      connectionsForPasswordTransfer: Ember.computed('activeConnections', 'content.[]', 'content', function () {
        let self = this;
        return (0, _map.default)(this.get('activeConnections'), function (o) {
          return Ember.Object.extend({
            connections: self
          }).create(o);
        });
      }),
      inactiveConnectionsWithIncomingDirectShareTeam: Ember.computed('content.[]', 'content', function () {
        return (0, _filter.default)(this.get('content'), function (item) {
          return item.active === 0 && item.individualTeamSharedToConnection;
        });
      }),
      activeConnections: Ember.computed('content.[]', 'content', function () {
        return (0, _filter.default)(this.get('content'), function (item) {
          return item.active;
        });
      })
    }).create({
      content: Ember.A([])
    }),
    connectionsForManagement: Ember.ArrayProxy.extend({
      totalCount: 0,
      currentPage: 1,
      pageSize: 1509,
      filterOnActive: false,
      filterOnInactive: false,
      startRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return this.currentPage * this.pageSize - this.pageSize + 1;
      }),
      endRecord: Ember.computed('totalCount', 'currentPage', 'pageSize', function () {
        return this.currentPage * this.pageSize;
      }),
      filterText: '',
      selected: null,
      sortDirection: Ember.computed('sortName', function () {
        const sortedOnProperty = (0, _filter.default)([{
          sortName: 'nickname',
          name: 'sortOnNickname',
          active: this.sortOnNickname
        }, {
          sortName: 'active',
          name: 'sortOnActive',
          active: this.sortOnActive
        }], item => {
          return item.active !== undefined;
        })[0].name;

        if (this.get(sortedOnProperty) === true) {
          return 'ASC';
        } else {
          return 'DESC';
        }
      }),
      sortName: Ember.computed('sortOnNickname', 'sortOnActive', function () {
        return (0, _filter.default)([{
          sortName: 'nickname',
          name: 'sortOnNickname',
          active: this.sortOnNickname
        }, {
          sortName: 'active',
          name: 'sortOnActive',
          active: this.sortOnActive
        }], item => {
          return item.active !== undefined;
        })[0].sortName;
      }),
      sortOnNickname: true,
      sortOnActive: undefined,

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      }

    }).create({
      content: Ember.A([])
    }),
    teamsForConnectionManagement: Ember.ArrayProxy.extend({
      filterText: '',
      selected: Ember.A([]),

      init() {
        this.set('selected', Ember.A([]));

        this._super.apply(this, arguments);
      },

      allTeamsSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const allTeams = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(allTeams, item => {
          return this.selected.includes(item);
        });

        if (allTeams.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(allTeams, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      }),
      allOwnedSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const owned = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.role === 1 && item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (owned.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      }),
      allUnOwnedSelected: Ember.computed('content', 'content.[]', 'selected.[]', 'selected', function () {
        const owned = (0, _map.default)((0, _filter.default)(this.content, function (item) {
          return item.role !== 1 && item.name !== 'Default' && !item.individual;
        }), function (item) {
          return item.id;
        });
        const all = (0, _every.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (owned.length === 0) {
          return false;
        }

        if (all) {
          return true;
        }

        const x = (0, _some.default)(owned, item => {
          return this.selected.includes(item);
        });

        if (x) {
          return null;
        }

        return false;
      })
    }).create({
      content: Ember.A([])
    }),
    yourTeamsForConnectionManagement: Ember.computed('teamsForConnectionManagement.{content.[],content}', function () {
      return (0, _filter.default)(this.get('teamsForConnectionManagement.content'), team => {
        return team.role === 1 && team.name !== 'Default' && !team.individual;
      });
    }),
    theirTeamsForConnectionManagement: Ember.computed('teamsForConnectionManagement.{content.[],content}', function () {
      const grouped = (0, _groupBy.default)((0, _filter.default)(this.get('teamsForConnectionManagement.content'), team => {
        return team.role !== 1 && !team.individual;
      }), function (team) {
        return team.ownerNick;
      });
      return (0, _mapValues.default)(grouped, function (o) {
        return Ember.Object.extend({
          teams: o,
          isSelected: Ember.computed('teams.@each.isSelected', function () {
            const all = (0, _every.default)(this.teams, item => {
              return item.get('isSelected');
            });

            if (this.teams.length === 0) {
              return false;
            }

            if (all) {
              return true;
            }

            const x = (0, _some.default)(this.teams, item => {
              return item.get('isSelected');
            });

            if (x) {
              return null;
            }

            return false;
          })
        }).create();
      });
    })
  }).create();

  _exports.default = _default;
});