define("web-client/components/manage-allowed-domains-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/includes", "lodash-es/filter", "lodash-es/sortBy", "ember-concurrency", "web-client/config/environment", "web-client/api", "ember-cp-validations", "lodash-es/has", "lodash-es/isNumber"], function (_exports, _dialogBase, _includes, _filter, _sortBy, _emberConcurrency, _environment, _api, _emberCpValidations, _has, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newDomain: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Label can't be blank"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    selectedItems: null,
    loading: Ember.inject.service(),
    filterText: '',
    mayViewSection: false,
    mayChangeSection: false,
    organizationSettings: {},
    showAddSection: false,
    newDomain: '',
    showErrors: false,

    init() {
      this._super.apply(this, arguments);

      this.set('selectedItems', Ember.A([]));
      this.mayViewSection = this.globalActions.doesPermissionExist('settings', 'company.company-settings', 'ro');
      this.mayChangeSection = this.globalActions.doesPermissionExist('settings', 'company.company-settings', 'rw');
    },

    hasChanged: Ember.computed('newDomain', function () {
      return this.newDomain.length > 3;
    }),
    showNewDomainErrors: Ember.computed('showErrors', 'newDomain', function () {
      return this.showErrors || this.newDomain && this.newDomain.length;
    }),
    showAdd: Ember.computed('reduxStore.state.orgSettings', 'reduxStore.state.orgSettings.allowedDomains', 'reduxStore.state.orgSettings.allowedDomains.[]', function () {
      let domains = this.reduxStore.state.orgSettings.allowedDomains; // Only disable if more than 10

      if (!this.get('mayChangeSection')) {
        return false;
      } else {
        if (domains && domains.length >= 10) {
          return false;
        } else {
          return true;
        }
      }
    }),
    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      return Ember.A((0, _sortBy.default)((0, _filter.default)(this.items, item => {
        return item.domainName.toLowerCase().includes(this.filterText.toLowerCase());
      }), "name"));
    }),
    items: Ember.computed('reduxStore.state.orgSettings', 'reduxStore.state.orgSettings.allowedDomains', 'reduxStore.state.orgSettings.allowedDomains.[]', function () {
      //  console.log("manage verified domains items computed");
      //  console.log(this.reduxStore.state.orgSettings.allowedDomains);
      return this.reduxStore.state.orgSettings.allowedDomains;
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveNewDomain" : "cancelDialog";
    }),
    saveNewDomain: (0, _emberConcurrency.task)(function* () {
      let self = this;
      let added = false;
      let deferred = Ember.RSVP.defer();
      (0, _api.saveOrganizationSetting)("allowedDomains", "add", this.get('newDomain')).then(data => {
        //        console.log("here");
        self.get('notifications').show("Added allowed domain");
        added = true;
        deferred.resolve();
      }).catch(function (data) {
        if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
          self.get('notifications').show(self.get("i18n").t('Common.Errors.UnexpectedConnectionFailure.Text'));
        } else {
          self.get('notifications').show(self.get("i18n").t((0, _api.getLanguageErrorKey)(data.status.code)));
        }

        self.set('showErrors', true);
        deferred.resolve();

        if (self.onComplete) {
          self.onComplete.resolve();
        }
      });
      yield deferred.promise;

      if (added) {
        this.globalActions.updateOrganizationSettings();
        self.resetAddNewDomain();
      }
    }).drop(),
    deleteSelectedAllowedDomains: (0, _emberConcurrency.task)(function* () {
      const deferred = Ember.RSVP.defer();
      let close = false;
      let self = this;
      this.standardDialogs.showConfirmDialog('confirm-delete-allowed-domain').then(() => {
        this.loading.show('Deleting allowed domains');
        let items = this.selectedItems; //      console.log("Selected: " + this.selectedItems);

        let domainsToRemove = "";

        if (items) {
          for (let i = 0; i < items.length; i++) {
            let domain = items[i];
            domainsToRemove = domainsToRemove + "," + domain;
          }
        }

        (0, _api.saveOrganizationSetting)("allowedDomains", "remove", domainsToRemove).then(data => {
          this.globalActions.updateOrganizationSettings();
        }).catch(data => {
          console.log("error with remove: " + data);
        }); // Close the modal dialog so we do not have to reset the checkboxes

        this.send('remove');
        this.complete.resolve();
      }).then(() => {
        self.loading.dismiss();
        deferred.resolve();
        close = true;
      }).catch(data => {
        this.loading.dismiss();

        if (data !== 'cancelled') {}

        deferred.reject();
      });

      if (close) {
        self.send('remove');
        self.complete.resolve();
      }

      yield deferred.promise;
    }),
    resetAddNewDomain: function resetAddNewDomain() {
      this.set('newDomain', '');
      this.set('showAddSection', false);
    },
    actions: {
      setItemSelectedState(itemId, newState) {
        //    console.log("set select " + itemId + " " + newState);
        if (newState) {
          if (!(0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.pushObject(itemId);
          }
        } else {
          if ((0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.removeObject(itemId);
          }
        }
      },

      saveNewDomain: function saveNewDomain() {
        this.saveNewDomain.perform();
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.resolve();
      },
      showAddNewDomain: function showAddNewDomain() {
        this.set('newDomain', '');
        this.set('showAddSection', true);
      },
      resetAddNewDomain: function resetAddNewDomain() {
        this.resetAddNewDomain();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});