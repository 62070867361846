define("web-client/components/passwords-sidebar-menu/ion-treeview-label-group-item/component", ["exports", "lodash-es/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ion-treeview-label-group-item'],
    layoutValue: "icon-start",
    button: false,
    attributeBindings: ['layoutValue:layout', 'button'],
    classNameBindings: ['expandedClass', 'searching:searching'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    expanded: false,
    item: null,
    filterText: "",
    loaded: false,

    init() {
      this._super.apply(this, arguments);

      setTimeout(() => {
        if (!this.isDestroyed) {
          this.set('loaded', true);
        }
      }, 500);
    },

    labels: Ember.computed('reduxStore.state.data.labels.content', 'filterText', function () {
      if (this.filterText) {
        return (0, _filter.default)(this.reduxStore.state.data.labels.content, item => {
          return item.label.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase());
        });
      } else {
        let rv = this.reduxStore.state.data.labels.content;
        return rv;
      }
    }),
    searching: Ember.computed('filterText', function () {
      return this.filterText ? true : false;
    }),
    expandedClass: Ember.computed('expanded', 'filterText', function () {
      return this.expanded || this.filterText ? 'open' : '';
    })
  });

  _exports.default = _default;
});