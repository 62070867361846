define("web-client/api/get-system-messages", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSystemMessages = void 0;

  /**
   * params
   */
  const getSystemMessages = _ipcClient.ipc.makeRequestFunction('getSystemMessages');

  _exports.getSystemMessages = getSystemMessages;
});