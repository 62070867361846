define("web-client/components/ion-nice-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3W8+zV07",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[7,\"ion-item\"],[9],[0,\"\\n\"],[7,\"ion-label\"],[12,\"position\",[30,[[23,\"labelPosition\"]]]],[9],[0,\"\\n  \"],[1,[23,\"label\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[29,\"ion-input\",null,[[\"autocapitalize\",\"autocomplete\",\"autocorrect\",\"autofocus\",\"clearInput\",\"clearOnEdit\",\"color\",\"debounce\",\"disabled\",\"inputmode\",\"max\",\"maxlength\",\"min\",\"minlength\",\"placeholder\",\"readonly\",\"spellcheck\",\"text\",\"value\",\"type\",\"inputElement\"],[[25,[\"autocapitalize\"]],[25,[\"autocomplete\"]],[25,[\"autocorrect\"]],[25,[\"autofocus\"]],[25,[\"clearInput\"]],[25,[\"clearOnEdit\"]],[25,[\"color\"]],[25,[\"debounce\"]],[25,[\"disabled\"]],[25,[\"inputmode\"]],[25,[\"max\"]],[25,[\"maxlength\"]],[25,[\"min\"]],[25,[\"minlength\"]],[25,[\"placeholder\"]],[25,[\"readonly\"]],[25,[\"spellcheck\"]],[25,[\"text\"]],[25,[\"value\"]],[25,[\"type\"]],[25,[\"inputElement\"]]]]],false],[0,\"\\n\"],[7,\"ion-buttons\"],[11,\"slot\",\"end\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"buttons\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[25,[\"copyButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"nb-button\",null,[[\"title\",\"color\",\"icon\",\"on-tap\",\"size\",\"useNativeClick\"],[[25,[\"copyFieldTitle\"]],\"dark\",\"copy\",[29,\"action\",[[24,0,[]],\"copyField\"],null],\"small\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ion-nice-input-error\"],[9],[0,\"\\n  \"],[1,[23,\"errorString\"],false],[0,\" \\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/ion-nice-select/template.hbs"
    }
  });

  _exports.default = _default;
});