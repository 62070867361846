define("web-client/components/registration-dialog/component", ["exports", "web-client/api", "web-client/mixins/registration-base-dialog", "ember-cp-validations"], function (_exports, _api, _registrationBaseDialog, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    emailAddress: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Email address can't be blank"
    }), (0, _emberCpValidations.validator)('email', {
      message: "Must be a valid email address"
    })],
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 500,
      message: "Password must be between 8 and 500 characters"
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your password"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: "You must retype your password exactly as before"
    })],
    company: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Company name can't be blank"
    }), (0, _emberCpValidations.validator)('company', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , . , - , @, ) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 50,
      message: "Company name must be between 3 and 50 characters"
    })],
    userName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    }), (0, _emberCpValidations.validator)('username', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , . , - , @) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 32,
      message: "Username must be between 2 and 32 characters"
    })],
    conditionsAccepted: [(0, _emberCpValidations.validator)('conditionsaccepted', {
      presence: true,
      message: "You must accept the terms of service"
    })]
  });

  var _default = Ember.Component.extend(_registrationBaseDialog.default, Validations, {
    standardDialogs: Ember.inject.service(),
    classNames: ['registration-dialog'],
    classNameBindings: ['currentSize'],
    currentSize: Ember.computed('success', function () {
      return this.success ? 'size-3' : 'size-3';
    }),
    defaultAction: 'registerUser',
    showErrors: false,
    showCompanyErrors: Ember.computed('company', 'showErrors', function () {
      return this.showErrors || this.company.length;
    }),
    showUserNameErrors: Ember.computed('userName', 'showErrors', function () {
      return this.showErrors || this.userName.length;
    }),
    showEmailAddressErrors: Ember.computed('emailAddress', 'showErrors', function () {
      return this.showErrors || this.emailAddress.length;
    }),
    showPasswordErrors: Ember.computed('password', 'showErrors', function () {
      return this.showErrors || this.password.length;
    }),
    showPassword2Errors: Ember.computed('password2', 'showErrors', function () {
      return this.showErrors || this.password2.length;
    }),
    showInvitationCodeErrors: Ember.computed('invitationCode', 'showErrors', function () {
      return this.showErrors || this.invitationCode.length;
    }),
    emailAddress: '',
    userName: '',
    company: '',
    password: '',
    password2: '',
    invitationCode: '',
    conditionsAccepted: false,
    success: false,
    focusedDescription: "",
    passwordStats: Ember.computed("password", "userName", "company", "emailAddress", function () {
      if (!this.liveStatsObj) {
        this.liveStatsObj = Ember.Object.extend({
          password: Ember.Object.extend({
            score: 0,
            crack_time_display: "instant",
            scoreCSS: Ember.computed('score', function () {
              return 'score-' + (this.score ? this.score : 0);
            })
          }).create()
        }).create();
      }

      let self = this;
      (0, _api.getScore)(self.get('password'), [self.get('emailAddress'), self.get('userName')]).then(function (data) {
        if (data !== 'unavailable') {
          self.liveStatsObj.password.set('score', data.score);
          self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
        } else {
          self.liveStatsObj.password.set('score', 0);
          self.liveStatsObj.password.set('crack_time_display', "instant");
        }
      });
      return this.liveStatsObj;
    }),
    actions: {
      blockSubmit()
      /*e*/
      {},

      toggleAcceptConditions: function toggleAcceptConditions(checked) {
        if (checked) {
          let deferred = Ember.RSVP.defer();
          let params = Ember.Object.create({
            onConfirm: deferred
          });
          deferred.promise.then(() => {});
          deferred.promise.catch(() => {
            this.set('conditionsAccepted', false);
          });
          this.standardDialogs.showDialog('accept-terms-dialog', params);
        }
      },
      goToLogin: function goToLogin() {
        // $(this.get('element')).hide();
        this.reduxStore.dispatch({
          type: 'REGISTRATION_SUCCESSFUL_RESET'
        });
        this.goToLogin();
      },
      goToRegister: function goToRegister() {
        this.reduxStore.dispatch({
          type: 'REGISTRATION_SUCCESSFUL_RESET'
        });
        this.goToRegister();
      },

      goToPreviousVersion() {
        window.location.href = "https://www.passpack.com/online";
      },

      goToTransitionInstructions() {
        window.location.href = "https://support.passpack.com/hc/en-us/articles/115005898126";
      },

      registerUser: function registerUser() {
        let self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            this.registerUser(this.company, this.userName, this.password, this.emailAddress, this.invitationCode, this.reduxStore.state.registrationEdition);
          }
        }).finally(function () {
          self.set('showErrors', true);
        });
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});