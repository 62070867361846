define("web-client/components/info-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/components/info-dialog/dialog-types/dialog-types", "lodash-es/merge", "lodash-es/find", "web-client/components/info-dialog/template"], function (_exports, _dialogBase, _dialogTypes, _merge, _find, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    layout: _template.default,
    size: 'size-3',
    escAction: "ok",
    defaultAction: "ok",
    classNames: ['info-dialog', 'centered-dialog-text'],
    reduxStore: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);

      var mergedOptions = (0, _merge.default)((0, _find.default)(_dialogTypes.default, {
        type: this.type
      }));
      this.set('dialogText', Ember.Object.create(mergedOptions));
    },

    actions: {
      ok: function ok() {
        if (this.onConfirm) {
          this.onConfirm.promise.then(() => {
            this.send('remove');
          });
          this.onConfirm.resolve('confirmed');
        } else {
          this.send('remove');
        }
      }
    }
  });

  _exports.default = _default;
});