define("web-client/components/company-settings-sections/sso/component", ["exports", "lodash-es/each", "lodash-es/filter"], function (_exports, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'sso',
    globalActions: Ember.inject.service(),
    classNames: [''],
    settingsNeedSaving: false,

    init() {
      this._super.apply(this, arguments);

      this.set('adminMFAOptions', [{
        value: "none",
        display: 'No Override'
      }, {
        value: "require",
        display: 'Require MFA'
      }, {
        value: "bypass",
        display: 'Allow bypass MFA'
      }]);
    },

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
    },

    getMfaHandling: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.mfaHandling;
    }),
    populateLocalData: function populateLocalData() {
      let orgSettings = this.get('reduxStore.state.orgSettings');

      if (orgSettings) {
        if (orgSettings.sso) {
          try {
            this.set('mfaHandling', orgSettings.sso.mfaHandling);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    actions: {
      setMfaHandling(option) {
        if (this.mayChangeSection) {
          this.saveOrganizationSetting("sso", "mfaHandling", option);
          this.set('settingsNeedSaving', true);
        }
      },

      enforceSettings() {
        if (this.mayChangeSection) {
          this.sendOrganizationCommand("logoutAllUsers");
          this.set('settingsNeedSaving', false);
          this.globalActions.signOut();
        }
      }

    }
  });

  _exports.default = _default;
});