define("web-client/components/override-setting-output/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+eIUJ564",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"override-value\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"override-text\"],[9],[0,\"\\n    \"],[1,[23,\"displayText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"overriddenBy\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"override-by\"],[9],[0,\"\\n      Set by: \"],[1,[23,\"overriddenBy\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/override-setting-output/template.hbs"
    }
  });

  _exports.default = _default;
});