define("web-client/initializers/nb-input-theme-init", ["exports", "ui-core/initializers/nb-input-theme-init"], function (_exports, _nbInputThemeInit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nbInputThemeInit.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _nbInputThemeInit.initialize;
    }
  });
});