define("web-client/locales/en/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    rtl: false,
    pluralForm: true
  };
  _exports.default = _default;
});