define("web-client/router", ["exports", "web-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    router: Ember.inject.service(),
    reduxStore: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);

      this.on('routeWillChange', () => {
        const previous = Ember.getOwner(this).lookup('previous:main');
        Ember.setProperties(previous, {
          'path': this.currentPath,
          'url': this.currentURL,
          'route': this.currentRouteName
        });
        return true;
      });
    },

    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('system-unavailable');
    this.route('oob');
    this.route('resetpw');
    this.route('retrieveuser');
    this.route('verifyreset');
    this.route('locked');
    this.route('register', function () {
      this.route('team');
      this.route('business');
      this.route('invited', {
        path: '/invited/:invitation_id'
      });
      this.route('invited', {
        path: '/invited'
      });
    });
    this.route('createpassphrase');
    this.route('settings', function () {
      this.route('import');
      this.route('license-summary');
      this.route('export');
      this.route('backup');
      this.route('local-data');
      this.route('multi-factor');
      this.route('session-settings');
      this.route('audit-logs');
      this.route('company', function () {
        this.route('billing');
        this.route('company-settings');
        this.route('license-management');
        this.route('subscription');
      });
    });
    this.route('passwords');
    this.route('connections');
    this.route('catch-all', {
      path: "/*wildcard"
    });
    this.route('dashboard', function () {
      this.route('security-stats');
      this.route('system-updates');
    });
  });
  var _default = Router;
  _exports.default = _default;
});