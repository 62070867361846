define("web-client/components/passwords-sidebar-menu/ion-treeview-connection-item/component", ["exports", "lodash-es/filter", "lodash-es/map"], function (_exports, _filter, _map) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ion-treeview-connection-item'],
    layoutValue: "icon-start",
    button: false,
    attributeBindings: ['layoutValue:layout', 'button'],
    classNameBindings: ['expanded:open'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    expanded: false,
    item: null,
    filterText: "",
    loaded: false,

    init() {
      this._super.apply(this, arguments);

      setTimeout(() => {
        if (!this.isDestroyed) {
          this.set('loaded', true);
        }
      }, 500);
    },

    labels: Ember.computed('reduxStore.state.data.labels.content', 'filterText', function () {
      if (this.filterText) {
        return (0, _filter.default)(this.reduxStore.state.data.labels.content, item => {
          return item.label.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase());
        });
      } else {
        return this.reduxStore.state.data.labels.content;
      }
    }),

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    ownedTeams: Ember.computed('item', function () {
      return (0, _map.default)(this.item.ownedTeams, 'teamId');
    }),
    memberOfTeams: Ember.computed('item', function () {
      return (0, _map.default)(this.item.memberOfTeams, 'teamId');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('label-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }
  });

  _exports.default = _default;
});