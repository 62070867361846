define("web-client/verifyreset/route", ["exports", "web-client/mixins/unauthed-route", "web-client/mixins/base-route", "web-client/actions/reset-password"], function (_exports, _unauthedRoute, _baseRoute, _resetPassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    actions: {
      resetPassword: _resetPassword.default
    },
    setupController: function setupController()
    /*controller, model*/
    {//   this.dispatch({ type: 'SET_PASSWORD_RESET_CODE',verifyResetCode:model.verifyResetCode});
    },

    renderTemplate() {
      this.render('verifyreset', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});