define("web-client/components/company-override-setting-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'read-only-input-display',
    classNames: ["setting-item"],
    classNameBindings: ["layoutDirection"],
    lines: "full",
    attributeBindings: ['lines'],
    selected: false,
    showSelected: false,
    gestures: Ember.inject.service(),
    media: Ember.inject.service(),
    actions: {},
    layoutDirection: "horizontal",

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});