define("web-client/components/footer-navigation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-tab-bar',
    classNames: ["footer-nav", "ion-color", "ion-color-primary", "ion-hide-lg-up"],
    classNameBindings: ['sidebarOpen:ion-hide'],
    sidebarOpen: Ember.computed.alias('reduxStore.state.sidebarOpen'),
    color: "primary",
    slot: "bottom",
    selectedTab: "main",
    attributeBindings: ["color", "slot", "selectedTab:selected-tab"],
    reduxStore: Ember.inject.service(),

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('ionTabsDidChange', function () {});
    }
  });

  _exports.default = _default;
});