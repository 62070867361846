define("web-client/api/resend-verification-email", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resendVerificationEmail = void 0;

  const resendVerificationEmail = _ipcClient.ipc.makeRequestFunction('resendVerificationEmail');

  _exports.resendVerificationEmail = resendVerificationEmail;
});