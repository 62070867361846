define("web-client/components/info-dialog/dialog-types/dialog-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    type: 'confirm-open-selected-urls',
    title: "Dialog.ConfirmOpenSelectedURLs.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.ConfirmOpenSelectedURLs.Message.Text"
  }, {
    type: 'updates_available',
    title: "Dialog.ConfirmUpdatesAvailable.Heading.Text",
    continueButtonText: "Dialog.ConfirmUpdatesAvailable.Button.AcceptInvite.Text",
    continueButtonTitle: "Dialog.ConfirmUpdatesAvailable.Button.AcceptInvite.Title",
    message: "Dialog.ConfirmUpdatesAvailableForce.Message.Text"
  }, {
    type: "cannot-add-connection-to-unowned-team",
    title: "Dialog.CannotAddConnectionToUnownedTeam.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.CannotAddConnectionToUnownedTeam.Message.Text"
  }, {
    type: "cannot-add-to-unowned-team",
    title: "Dialog.CannotAddToUnownedTeam.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.CannotAddToUnownedTeam.Message.Text"
  }, {
    type: "info-over-team-limits",
    title: "Dialog.InfoOverTeamLimits.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoOverTeamLimits.Message.Text"
  }, {
    type: "info-over-password-limits",
    title: "Dialog.InfoOverPasswordLimits.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoOverPasswordLimits.Message.Text"
  }, {
    type: "info-import-type-not-found",
    title: "Dialog.InfoImportTypeNotFound.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoImportTypeNotFound.Message.Text"
  }, {
    type: "info-import-file-too-large",
    title: "Dialog.InfoImportFileTooLarge.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoImportFileTooLarge.Message.Text"
  }, {
    type: "info-must-set-nickname",
    title: "Dialog.InfoMustSetNickname.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoMustSetNickname.Message.Text"
  }, {
    type: "must-connect-to-send-message",
    title: "Dialog.InfoMustConnectToSendMessage.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.InfoMustConnectToSendMessage.Message.Text"
  }, {
    type: "application-is-busy",
    title: "Dialog.ApplicationBusyMessage.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.ApplicationBusy.Message.Text"
  }, {
    type: "only-unowned-selected",
    title: "Dialog.OnlyUnownedSelected.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.OnlyUnownedSelected.Message.Text"
  }, {
    type: "only-inactive-selected",
    title: "Dialog.OnlyInactiveConnectionsSelected.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.OnlyInactiveConnectionsSelected.Message.Text"
  }, {
    type: "reached-limit-on-active-connections",
    title: "Dialog.ReachedLimitOnActiveConnections.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.ReachedLimitOnActiveConnections.Message.Text"
  }, {
    type: "reached-limit-cannot-set-active",
    title: "Dialog.ReachedLimitCannotSetActive.Heading.Text",
    continueButtonText: "Common.Buttons.Ok.Text",
    continueButtonTitle: "Common.Buttons.Ok.Title",
    message: "Dialog.ReachedLimitCannotSetActive.Message.Text"
  }];
  _exports.default = _default;
});