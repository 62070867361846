define("web-client/components/about-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/config/environment"], function (_exports, _dialogBase, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});