define("web-client/actions/request-password-reset", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requestPasswordReset;

  function requestPasswordReset(userId) {
    (0, _api.lostPassword)({
      userId: userId
    }).then(() => {
      this.reduxStore.dispatch({
        type: 'SET_PASSWORD_RESET_SUCCESS',
        passwordResetRequestSuccessful: true
      });
    }).catch(data => {
      this.actionUIErrorHandler(data);
    });
  }
});