define("web-client/components/passwords-sidebar-menu/ion-treeview-owned-sub-teams-group-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jEEQhUul",
    "block": "{\"symbols\":[\"team\"],\"statements\":[[4,\"if\",[[29,\"not\",[[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"button\",\"item\",\"tap\",\"selected\",\"showExpander\",\"expanded\"],[[25,[\"text\"]],\"folder\",false,[25,[\"item\"]],[29,\"service-task-action\",[\"global-actions\",\"selectAllOwnedTeamsForPasswordFilter\"],null],[25,[\"selected\"]],true,[25,[\"expanded\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"ion-list\"],[11,\"class\",\"sub-items\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[25,[\"expanded\"]],[25,[\"loaded\"]],[25,[\"filterText\"]]],null]],null,{\"statements\":[[4,\"each\",[[25,[\"filteredTeams\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"    \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"item\",\"icon\",\"iconColor\",\"item\",\"tap\",\"selected\"],[[24,1,[\"name\"]],[24,1,[]],\"folder\",[24,1,[\"color\"]],[24,1,[]],[29,\"service-task-action\",[\"global-actions\",\"selectTeamForPasswordFilter\"],null],[24,1,[\"isSelected\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/passwords-sidebar-menu/ion-treeview-owned-sub-teams-group-item/template.hbs"
    }
  });

  _exports.default = _default;
});