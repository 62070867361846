define("web-client/components/ion-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-toggle',
    slot: "",
    classNames: [],
    disabled: false,
    checked: null,
    checkedObserver: Ember.observer('checked', function () {
      if (this.checked !== this.element.checked) {
        this.element.setAttribute('checked', this.checked);
      }

      this.get('change')(this.element.checked); //}
    }),
    position: "",
    attributeBindings: ["disabled", "slot", "position"],
    gestures: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);
    },

    actions: {},

    willDestroyElement() {
      this.get('element').removeEventListener('ionChange', this.clickEvent);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.clickEvent = e => {
        if (!this.disabled) {
          // this.notifyPropertyChange('checked')
          this.set('checked', e.detail.checked);
        }
      };

      this.get('element').addEventListener('ionChange', this.clickEvent);
      this.notifyPropertyChange('checked');
    }
  });

  _exports.default = _default;
});