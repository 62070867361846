define("web-client/components/ion-fab-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-fab-button',
    size: "",
    classNames: [],
    buttonType: "button",
    disabled: false,
    href: undefined,
    strong: false,
    type: "button",
    icon: '',
    title: "",
    attributeBindings: ["expand", "buttonType", "disabled", "href", "strong", "type", "title"],
    gestures: Ember.inject.service(),
    actions: {},

    willDestroyElement() {
      this.get('gestures').removeEventListener(this.get('element'), 'tap', this.clickEvent);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.clickEvent = () => {
        if (!this.disabled) {
          this.get('tap')();
        }
      };

      this.get('gestures').addEventListener(this.get('element'), 'tap', this.clickEvent);
    }
  });

  _exports.default = _default;
});