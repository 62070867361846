define("web-client/components/connection-list-item/component", ["exports", "ember-drag-drop/components/draggable-object", "web-client/utils/misc-utils", "web-client/config/environment"], function (_exports, _draggableObject, _miscUtils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var baseUrl = _environment.default.apiHost;
  /*const openHilightSpan = "\x3C\x73\x70\x61\x6E\x20\x63\x6C\x61\x73\x73\x3D\x22\x73\x65\x61\x72\x63\x68\x2D\x68\x69\x67\x68\x6C\x69\x67\x68\x74\x22\x3E";// utf8.encode('<span class="search-highlight">');
  const closeHilightSpan = "\x3C\x2F\x73\x70\x61\x6E\x3E";
  */

  var _default = _draggableObject.default.extend({
    reduxStore: Ember.inject.service(),
    classNames: ['connection-list-item'],
    classNameBindings: ['connection::fade-it', 'connection.isSelected:selected', 'hover', 'focused'],
    focused: false,
    selected: true,
    attributeBindings: ["touchAction:touch-action", 'tabindex'],
    tabindex: 0,
    touchAction: "none",
    propName: 'connectionn',
    password: null,
    copyData: '',
    nickNameSize: 150,
    connectionDateSize: 150,
    notifications: Ember.inject.service(),
    showTools: Ember.computed('focused', 'hover', function () {
      return this.focused || this.hover;
    }),
    SizeObserver: Ember.observer('nickNameSize', 'nameElement', 'connectionDateSize', 'urlElement', function () {
      Ember.run.once(this, this.performResize);
    }),
    performResize: function performResize() {
      let self = this;
      requestAnimationFrame(function () {
        self.get('nameElement').style.flexBasis = 'calc(' + self.get('nickNameSize') + 'px)';
        self.get('urlElement').style.flexBasis = 'calc(' + self.get('connectionDateSize') + 'px)';
      });
    },
    profileImageURL: Ember.computed('userId', function () {
      return baseUrl + "/portal/v1/secure/avatar/" + this.connection.id;
    }),
    isSelected: Ember.computed('reduxStore.state.data.connectionsForManagement.{selected.[],selected}', 'connection', function () {
      return this.get('reduxStore.state.data.connectionsForManagement.selected').includes(this.get('connection.id'));
    }),
    displayName: Ember.computed('connection.nickname', 'filterText', function () {
      //  var filterText = self.get('filterText').toUpperCase();
      //  var regex = new RegExp("(" + utf8.encode(escapeRegExp(escapeHtml(filterText))) + ")", "ig");

      /*  if (self.get('filterText') !== '') {
           if (escapeHtml(self.get('password.name')).toUpperCase().indexOf(escapeHtml(filterText).toUpperCase()) !== -1) {
            //  return escapeHtml(self.get('decryptedName')).replace(regex, openHilightSpan + '$1' + closeHilightSpan);
            return utf8.decode(utf8.encode(escapeHtml(self.get('name'))).replace(regex, openHilightSpan + '$1' + closeHilightSpan));
          }
          else {
            return escapeHtml(self.get('password.name'));
          }
        }
        else {
          return escapeHtml(self.get('password.name'));
        }*/
      return (0, _miscUtils.escapeHtml)(this.get('connection.nickname'));
    }),
    filterText: Ember.computed('reduxStore.state.data.connectionsForManagement.filterText', function () {
      return this.get('reduxStore.state.data.connectionsForManagement.filterText');
    }),
    actions: {
      onCopyButtonDown: function onCopyButtonDown(field, e) {
        e.preventDefault();
        e.stopPropagation();

        switch (field) {
          case 'password':
            this.set('copyData', this.password.password);
            break;

          case 'username':
            this.set('copyData', this.password.username);
            break;

          case 'email':
            this.set('copyData', this.password.email);
            break;

          default:
            this.set('copyData', "");
        }

        if (this.copyData) {
          document.getElementById('copyNode').innerText = this.copyData; // "Optional" Select some text

          var range = document.createRange();
          range.selectNodeContents(document.getElementById('copyNode'));
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range); // Use try & catch for unsupported browser

          try {
            // The important part (copy selected text)
            var successful = document.execCommand('copy'); // "Optional" remove selected text
            //     sel.removeAllRanges();

            document.getElementById('copyNode').innerText = "";
            this.set('copyData', "");

            if (successful) {
              this.notifications.show("Field copied to clipboard");
            } else {
              this.notifications.show("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations.", "alert");
            }
          } catch (err) {
            this.notifications.show("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations", "alert");
          }
        } else {
          this.notifications.show("Nothing to copy to clipboard.", "alert");
        }
      },
      selectPassword: function selectPassword(context) {
        if (!this.reduxStore.state.data.passwords.selected.includes(context.id)) {
          this.reduxStore.state.data.passwords.selected.pushObject(context.id);
        }
      }
    },
    dragHandle: '.dragHandle',

    init() {
      this._super.apply(this, arguments);

      this.set('content', this);
    },

    dragStartAction(context, event) {
      let clonedItem = context.get('element').cloneNode(true);
      clonedItem.style.position = "absolute";
      clonedItem.style.background = "#aaa";
      clonedItem.style.top = "-200px";
      clonedItem.style.right = "0px";
      clonedItem.style.height = "36px";
      clonedItem.style.width = "500px";
      clonedItem.style.zIndex = 10000;
      clonedItem.style.opacity = 1;
      document.body.appendChild(clonedItem);
      event.dataTransfer.setDragImage(clonedItem, 0, 0); //  document.body.removeChild(clonedItem);

      context.send('selectPassword', context.get('password')); //   var count = drag.get('data.passwords.selected.content.length');
      //   $('.dragHelper').html("<div class='dragHandle'   ></div><span class='drag-text'>" + count + " passwords selected </span>").addClass('elevation-2dp');
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let element = this.element;
      let self = this;

      this._mouseover = function () {
        if (!self.get('disabled')) {
          self.set('hover', true);
        }
      };

      element.addEventListener('mouseenter', this._mouseover);

      this._mouseout = function () {
        if (!self.get('disabled')) {
          self.set('hover', false);
        }
      };

      element.addEventListener('mouseleave', this._mouseout);
      element.addEventListener('focusin', function ()
      /*e*/
      {
        //  element.focus();
        //  e.target.focus();
        self.set('focused', true);
      });
      element.addEventListener('focus', function () {
        self.set('focused', true);
      });
      element.addEventListener('focusout', function (event) {
        if (!element.contains(event.relatedTarget)) {
          // don't react to this
          self.set('focused', false);
        }
      });
      let nameElement = this.element.querySelector('.name-item');
      this.set('nameElement', nameElement);
      let urlElement = this.element.querySelector('.updated-item');
      this.set('urlElement', urlElement);
    }

  });

  _exports.default = _default;
});