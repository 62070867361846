define("web-client/settings/session-settings/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Session Settings"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "Session Settings"
        });
      }

    },

    setupController(controller) {
      controller.set('timeSpanOptions', [{
        value: 5,
        display: '5 Minutes'
      }, {
        value: 15,
        display: '15 Minutes'
      }, {
        value: 30,
        display: '30 Minutes'
      }, {
        value: 60,
        display: '1 Hour'
      }, {
        value: 1440,
        display: '1 Day'
      }, {
        value: 10080,
        display: '1 Week'
      }]);
      Ember.getOwner(this).lookup('service-action:helpers').compute(['global-actions', 'loadSessionHistory'])();
    },

    renderTemplate() {
      this.render('settings/session-settings', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});