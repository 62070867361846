define("web-client/components/password-generator-dialog/generated-passwords-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cFda77D7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-label\"],[11,\"position\",\"fixed\"],[9],[0,\"\\n\"],[1,[29,\"moment-format\",[[25,[\"generated\",\"ts\"]],\"MM-DD-YYYY HH:mm\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"ion-label\"],[9],[0,\"\\n\"],[1,[25,[\"generated\",\"data\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"ion-buttons\"],[11,\"slot\",\"end\"],[9],[0,\"\\n\\n    \"],[1,[29,\"nb-button\",null,[[\"icon\",\"title\",\"on-tap\",\"useNativeClick\",\"color\",\"size\"],[\"copy\",\"Copy field to clipboard\",[29,\"action\",[[24,0,[]],\"copyField\"],null],true,\"dark\",\"small\"]]],false],[0,\"\\n\\n\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-generator-dialog/generated-passwords-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});