define("web-client/components/passwords-sidebar-menu/ion-treeview-teams-group-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CmRQZ0+z",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[4,\"if\",[[29,\"not\",[[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"button\",\"tap\",\"selected\",\"showExpander\",\"expanded\",\"filterText\",\"extraActions\"],[\"Teams\",\"folder\",false,[29,\"service-task-action\",[\"global-actions\",\"selectAllTeamsForPasswordFilter\"],null],[25,[\"reduxStore\",\"state\",\"data\",\"teams\",\"allTeamsSelected\"]],true,[25,[\"expanded\"]],[25,[\"filterText\"]],[29,\"array\",[[29,\"component\",[\"nb-button\"],[[\"size\",\"text\",\"iconOnly\",\"color\",\"on-tap\"],[\"small\",\"edit\",\"false\",\"dark\",[29,\"service-task-action\",[\"global-actions\",\"showManageTeamsDialog\"],null]]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ion-list\"],[11,\"class\",\"sub-items\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[25,[\"expanded\"]],[25,[\"loaded\"]],[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"\\n\\n    \"],[1,[29,\"passwords-sidebar-menu/ion-treeview-owned-sub-teams-group-item\",null,[[\"text\",\"icon\",\"filterText\",\"teams\",\"selected\"],[\"My Teams\",\"folders\",[25,[\"filterText\"]],[25,[\"reduxStore\",\"state\",\"data\",\"yourTeams\"]],[25,[\"reduxStore\",\"state\",\"data\",\"teams\",\"allOwnedSelected\"]]]]],false],[0,\"\\n\\n\\n\"],[4,\"each\",[[25,[\"theirTeams\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"passwords-sidebar-menu/ion-treeview-sub-teams-group-item\",null,[[\"text\",\"item\",\"icon\",\"teams\",\"selected\",\"filterText\"],[[24,1,[\"key\"]],[24,1,[\"key\"]],\"folders\",[24,1,[\"value\",\"teams\"]],[24,1,[\"value\",\"isSelected\"]],[25,[\"filterText\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/passwords-sidebar-menu/ion-treeview-teams-group-item/template.hbs"
    }
  });

  _exports.default = _default;
});