define("web-client/api/upload-avatar", ["exports", "web-client/api/post-mulit-part-form-data"], function (_exports, _postMulitPartFormData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uploadAvatar = uploadAvatar;

  function uploadAvatar(fd, csrfToken) {
    return (0, _postMulitPartFormData.default)("/portal/v1/secure/avatar/update", fd, csrfToken);
  }
});