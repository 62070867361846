define("web-client/api/remove-verified-domain-memberships", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeVerifiedDomainMemberships = void 0;

  /**
   * params
  
   */
  const removeVerifiedDomainMemberships = _ipcClient.ipc.makeRequestFunction('removeVerifiedDomainMemberships');

  _exports.removeVerifiedDomainMemberships = removeVerifiedDomainMemberships;
});