define("web-client/settings/backup/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {});

  _exports.default = _default;
});