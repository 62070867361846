define("web-client/passwords/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    init() {
      this._super.apply(this, arguments);
    },

    activate() {
      this._super.apply(this, arguments);

      var isLicensed = this.get('reduxStore.state.licenseSummary.licensed');

      if (isLicensed) {
        this.reduxStore.state.set('contentClass', 'fixed-canvas');
        this.reduxStore.state.set('contentSlot', 'fixed');
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Password Filters"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_HEADER_BUTTON",
          selectedHeaderButton: "passwords"
        });
        Ember.run.scheduleOnce('afterRender', () => {
          window.dispatchEvent(new Event('resize'));
        });
      } else {
        this.reduxStore.state.set('contentClass', 'unlicensed-dialog');
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: ""
        });
      }
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    setupController()
    /*controller*/
    {//  this.send('loadSessionHistory');
    },

    actions: {},

    renderTemplate() {
      //  this._super(...arguments);
      this.render('passwords', {
        outlet: 'content',
        into: 'application'
      });
      this.render('passwords/left-sidebar', {
        outlet: 'left-sidebar',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});