define("web-client/dashboard/security-stats/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Security Stats"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_DASHBOARD_SECTION",
          dashboardSection: "Security Statistics"
        });
      }

    },

    setupController(controller) {
      controller.getPasswordStats();
      controller.getConnectionStats();
      controller.getTeamStats();
      controller.getAllPasswordAgeStats();
    },

    renderTemplate() {
      this.render('dashboard/security-stats', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});