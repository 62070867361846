define("web-client/api/get-billing-history", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getBillingHistory = void 0;

  /**
   * params
   *query
   *
   */
  const getBillingHistory = _ipcClient.ipc.makeRequestFunction('getBillingHistory');

  _exports.getBillingHistory = getBillingHistory;
});