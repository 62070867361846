define("web-client/api/generate-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generatePassword = generatePassword;

  function pick(array, min, max) {
    var n,
        chars = [];

    if (typeof max === 'undefined') {
      n = min;
    } else {
      n = min + Math.floor(random() * (max - min + 1));
    }

    for (var i = 0; i < n; i++) {
      chars.push(array[Math.floor(random() * array.length)]);
    }

    return chars;
  }

  function random() {
    var arr = new Uint32Array(1);
    window.crypto.getRandomValues(arr);
    return arr[0] / (0xffffffff + 1);
  }

  function shuffle(charArray) {
    var array = charArray;
    var tmp,
        current,
        top = array.length;
    if (top) while (--top) {
      current = Math.floor(random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
    return array;
  }

  function generatePassword(useUpper, useLower, useNumbers, useSymbols, length, includeChars) {
    var pool = [];
    var password = [];
    var specials = '!$%@#'.split('');
    var lowercase = 'abcdefghijklmnopqrstuvwxyz'.split('');
    var uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    var numbers = '0123456789'.split('');

    if (includeChars) {
      pool.push(includeChars);
      password = password.concat(pick(includeChars, 1));
    }

    if (useLower) {
      pool.push(lowercase);
      password = password.concat(pick(lowercase, 1));
    }

    if (useUpper) {
      pool.push(uppercase);
      password = password.concat(pick(uppercase, 1));
    }

    if (useSymbols) {
      pool.push(specials);
      password = password.concat(pick(specials, 1));
    }

    if (useNumbers) {
      pool.push(numbers);
    }

    var all = [].concat.apply([], pool);
    password = password.concat(pick(all, length, length));
    password = shuffle(password);
    return password.slice(0, length).join('');
  }
});