define("web-client/actions/reset-password", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resetPassword;

  function resetPassword(verifyResetCode, password) {
    (0, _api.resetPassword)(verifyResetCode, password).then(() => {
      this.reduxStore.dispatch({
        type: 'VERIFY_PASSWORD_RESET_SUCCESS'
      });
      this.set('success', true);
    }).catch(data => {
      this.actionUIErrorHandler(data);
    });
  }
});