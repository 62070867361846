define("web-client/settings/export/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SESIixs5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-list\"],[11,\"class\",\"settings-list\"],[9],[0,\"\\n  \"],[7,\"ion-list-header\"],[9],[0,\"\\n    \"],[7,\"ion-label\"],[9],[0,\"    \"],[1,[29,\"t\",[\"Settings.Export.Heading.Text\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"ion-item-group\"],[9],[0,\"\\n    \"],[7,\"ion-item\"],[11,\"class\",\"setting-item\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"inner-setting-wrapper media-media-sm \"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"key-value-wrapper\"],[9],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"\\n              \"],[1,[29,\"t\",[\"Settings.Export.Instructions.Text\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"\\n              Only Passwords owned by you will be exported, passwords shared to you can't be exported.\\n            \"],[10],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"\\n              \"],[1,[29,\"t\",[\"Settings.Export.Instructions3.Text\"],null],false],[0,\"\\n            \"],[10],[0,\"\\n\\n\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[1,[29,\"ion-setting-item\",null,[[\"title\",\"value\",\"settingAction\"],[\"Save to CSV\",\"For import into Passpack or other applications\",[29,\"array\",[[29,\"component\",[\"nb-button\"],[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[\"Common.Buttons.Download.Text\"],null],[29,\"t\",[\"Common.Buttons.Download.Title\"],null],[29,\"service-action\",[\"global-actions\",\"exportPasswords\"],null]]]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/settings/export/template.hbs"
    }
  });

  _exports.default = _default;
});