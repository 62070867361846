define("web-client/api/process-import-file", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.performImport = _exports.parseImportFile = _exports.checkImportType = void 0;

  /**
   * params
   *params
   *
   */
  const checkImportType = _ipcClient.ipc.makeRequestFunction('checkImportType');

  _exports.checkImportType = checkImportType;

  const parseImportFile = _ipcClient.ipc.makeRequestFunction('parseImportFile');

  _exports.parseImportFile = parseImportFile;

  const performImport = _ipcClient.ipc.makeRequestFunction("performImport");

  _exports.performImport = performImport;
});