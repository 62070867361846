define("web-client/components/passwords-searchbar/component", ["exports", "lodash-es/merge", "platform-specific/ipc/ipc-client", "ember-concurrency", "web-client/api", "ui-core/components/nb-form/component"], function (_exports, _merge, _ipcClient, _emberConcurrency, _api, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    reduxStore: Ember.inject.service(),
    tagName: "ion-searchbar",
    classNames: ['passwords-searchbar'],
    //  slot:"secondary",
    attributeBindings: ["slot"],
    search: (0, _emberConcurrency.task)(function* (term) {
      this.set('reduxStore.state.waitingOnSearch', true);
      this.set('reduxStore.state.data.passwords.searchResults', []);
      term = term.trim();

      if (term === "" || term.length < 3) {
        //  this.set('reduxStore.state.waitingOnSearch',false);
        return [];
      } // Pause here for DEBOUNCE_MS milliseconds. Because this
      // task is `restartable`, if the user starts typing again,
      // the current search will be canceled at this point and
      // start over from the beginning. This is the
      // ember-concurrency way of debouncing a task.
      //  yield timeout(200);
      //    debounce(this, this.searchTerm,term, 500);


      let results = yield (0, _api.search)(term); //.replace(/-\s/g, '\\$&'));
      //    let results = yield  search(term);

      this.set('reduxStore.state.data.passwords.searchResults', results);
      this.set('reduxStore.state.waitingOnSearch', false);
    }).keepLatest(),
    actions: {},
    filterText: '',

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      this.element.addEventListener('ionChange', function () {
        self.set('filterText', self.element.value);
        self.search.perform(self.element.value);
      });

      _ipcClient.ipc.addListener('searchIndexUpdated', function () {
        if (self.filterText) {
          self.search.perform(self.element.value);
        }
      });

      window.dispatchEvent(new Event('resize'));
    }

  });

  _exports.default = _default;
});