define("web-client/components/change-name-dialog/component", ["exports", "ember-concurrency", "ui-core/mixins/dialog-base", "lodash-es/merge", "ember-cp-validations", "web-client/api"], function (_exports, _emberConcurrency, _dialogBase, _merge, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newFirstName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "First name can't be blank"
    }), (0, _emberCpValidations.validator)('username', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , . , - , @ ) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 32,
      message: "First name must be between 1 and 32 characters"
    })],
    newLastName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Last name can't be blank"
    }), (0, _emberCpValidations.validator)('username', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , . , - , @ ) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 32,
      message: "Last name must be between 1 and 32 characters"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, {
    size: 'size-3',
    name: Ember.computed.alias('reduxStore.state.name'),
    firstName: Ember.computed.alias('reduxStore.state.firstName'),
    lastName: Ember.computed.alias('reduxStore.state.lastName'),
    newFirstName: '',
    newLastName: '',
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    hasChanged: Ember.computed('firstName', 'newFirstName', 'lastName', 'newLastName', function () {
      return this.firstName !== this.newFirstName || this.lastName !== this.newLastName;
    }),
    showErrors: false,
    showNewFirstNameErrors: Ember.computed('showErrors', 'newFirstName', function () {
      return this.showErrors || this.newFirstName.length;
    }),
    showNewLastNameErrors: Ember.computed('showErrors', 'newLastName', function () {
      return this.showErrors || this.newLastName.length;
    }),
    saveName: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          return (0, _api.updateName)(this.newFirstName, this.newLastName);
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        return (0, _api.getAccountDetails)().then(data => {
          this.reduxStore.dispatch({
            type: 'SET_ACCOUNT_DETAILS',
            data: (0, _merge.default)({
              loggedIn: true,
              oobValid: true
            }, data)
          }); //deferred.resolve();

          this.send('remove');
        });
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('name', this.get('reduxStore.state.name'));
      this.set('firstName', this.get('reduxStore.state.firstName'));
      this.set('lastName', this.get('reduxStore.state.lastName'));
      this.set('newFirstName', this.get('reduxStore.state.firstName'));
      this.set('newLastName', this.get('reduxStore.state.lastName'));
    },
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});