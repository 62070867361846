define("web-client/components/password-history-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api"], function (_exports, _dialogBase, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-2',
    classNames: ['password-history-dialog'],
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    passwordId: '',

    onShow() {
      this.get('reduxStore').dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.get('reduxStore').dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    actions: {
      copyField(value, fieldName) {
        if (value) {
          let copyNode = document.getElementById('copyNode');
          let pwId = this.get('passwordId');

          if (pwId) {
            var params = {
              "pid": "" + pwId,
              "field": fieldName,
              "date": this.get('historyEntry.changedDate')
            };
            (0, _api.actionUpdate)("passwordHistoryDataCopied", params);
          }

          copyNode.textContent = value; // "Optional" Select some text

          let range = document.createRange();
          range.selectNodeContents(document.getElementById('copyNode'));
          let sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range); // Use try & catch for unsupported browser

          try {
            // The important part (copy selected text)
            let successful = document.execCommand('copy'); // "Optional" remove selected text
            //     sel.removeAllRanges();
            //   copyNode.innerText = '';

            if (successful) {
              this.notifications.show("Field copied to clipboard");
            } else {
              this.notifications.show("Error copying to clipboard");
            }
          } catch (err) {
            this.notifications.show("Error copying to clipboard");
          }
        } else {
          this.notifications.show("Nothing to copy to clipboard."); //showSnackbar("Nothing to copy to clipboard.", "alert");
        }
      },

      cancelDialog() {
        this.send('remove');
      }

    }
  });

  _exports.default = _default;
});