define("web-client/components/file-import/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    initialized: false,
    standardDialogs: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['file-import-button', 'nb-button-like', 'nb-button', 'theme-context-primary-dialog-button', 'size-3', 'flat-text'],
    attributeBindings: ['for', 'tabindex'],
    tabindex: 0,
    for: "fileImport",
    tagname: 'label',

    willDestroyElement() {
      this._super.apply(this, arguments);

      document.getElementById('fileImport').removeEventListener('change', this._handleFileSelect);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var element = this.element;

      this._handleFileSelect = function handleFileSelect(evt) {
        evt.preventDefault();
        evt.stopPropagation(); //  fileDragHover(evt);

        var files = evt.target.files || evt.dataTransfer.files; // FileList object

        var f = files[0];
        var reader = new FileReader();

        if (f.size > 5512000) {
          self.get('standardDialogs').showInfoDialog('info-import-file-too-large', null, true);
          document.getElementById('fileImport').value = '';
        } else {
          reader.onload = function (e) {
            self.sendAction('parseText', e.target.result);
            var fileImportSelector = document.getElementById('fileImport');
            fileImportSelector.removeEventListener('change', self._handleFileSelect);
            element.querySelector('form').reset(); //  fileImportSelector.wrap('<form>').closest('form').get(0).reset();
            //  fileImportSelector.unwrap();

            fileImportSelector.addEventListener('change', self._handleFileSelect);
          };

          setTimeout(function () {
            reader.readAsText(f);
          }, 500);
        }
      };

      document.getElementById('fileImport').addEventListener('change', this._handleFileSelect);
    }
  });

  _exports.default = _default;
});