define("web-client/components/passwords-sidebar-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'ion-list',
    globalActions: Ember.inject.service(),
    classNames: ['fade-me-in'],
    filterText: '',

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('label-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }

      Ember.run.scheduleOnce('afterRender', () => {
        if (this.element) {
          this.element.classList.add('fade-in');
        }
      });
    }

  });

  _exports.default = _default;
});