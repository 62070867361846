define("web-client/settings/license-summary/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    classNames: ["license-management"],
    currencyFormatter: {},

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    },

    addOneYear: function addOneYear(date) {
      // Making a copy with the Date() constructor
      const dateCopy = new Date(date);
      dateCopy.setFullYear(dateCopy.getFullYear() + 1);
      return dateCopy.format('mm/dd/yy');
    },
    subscriptionDescription: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return originalDescription;
      } else {
        // Legacy subscription types
        return originalDescription + ' (legacy) plan';
      }
    }),
    amtPerLicense: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let sku = this.get('reduxStore.state.subscriptionSummary.subscriptionSku');

      if (sku === 'team' || sku === 'business') {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amountPerUser')) + '  per user/year';
      } else {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      }
    }),
    planOverviewExtra: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';

        try {
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate'); //        console.log('next: ' + nextBillDateOrig);

          const date = new Date(nextBillDateOrig);
          conversionDate = this.addOneYear(date); //        console.log('converted: ' + conversionDate);
        } catch (error) {}

        return 'Important! You will be billed one more time at the existing ' + originalDescription + ' plan.  Unless you convert earlier you will be automatically converted to the Team Plan on ' + conversionDate + '.';
      }
    }),
    nextInvoiceAmount: Ember.computed('reduxStore.state.nextSubscription', function () {
      return this.currencyFormatter.format(this.get('reduxStore.state.nextSubscription.amount'));
    }),
    otherLicenseCount: Ember.computed('reduxStore.state.licenseSummary', function () {
      let licenseData = this.get('reduxStore.state.licenseSummary.licenseData');

      if (null === licenseData) {
        return 0;
      } else {
        return licenseData.length;
      }
    }),
    legacyLicenseInfo: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';

        try {
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate');
          const date = new Date(nextBillDateOrig);
          conversionDate = this.addOneYear(date);
        } catch (error) {
          console.log(error);
        }

        return 'Note: You have been grandfathered into your existing ' + originalDescription + ' plan for one more billing cycle.  We\'ve allocated some licenses free of charge during this conversion period.  Please note that your account will converted to the Team Plan on ' + conversionDate + ' and you will be charged for any licenses you will be using on that date.  To avoid any surprises we\'ll email you a reminder prior to this conversion.';
      }
    }),
    legacyConvertSubscriptionNextPayment: Ember.computed('reduxStore.state.subscriptionSummary', 'reduxStore.state.nextSubscription', 'reduxStore.state.subscriptionSummary.licensesUsed', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';
        let assigned = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
        let numLicenses = assigned;
        let action = this.get('reduxStore.state.nextSubscription.action');

        if (action === 'cancel') {
          numLicenses = 0;
        } else if (action === 'auto-renew') {
          let count = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
          numLicenses = count;
        } else if (action === 'downgrade-team') {
          let count = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
          numLicenses = count;
        } else if (action === 'fewer') {
          let count = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
          numLicenses = count;
        }

        let estimatedBill = this.currencyFormatter.format(18 * numLicenses);

        try {
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate');
          const date = new Date(nextBillDateOrig);
          conversionDate = this.addOneYear(date);
        } catch (error) {
          console.log(error);
        }

        return 'Note: You have been grandfathered into your existing ' + originalDescription + ' plan for one more billing cycle.  We\'ve allocated some licenses free of charge during this conversion period and set the renewal to only bill for those in use on ' + conversionDate + '. To avoid any surprises we\'ll email you a reminder prior to this conversion. Your estimated bill on ' + conversionDate + ' is ' + estimatedBill + '.';
      }
    }),
    actions: {}
  });

  _exports.default = _default;
});