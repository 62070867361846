define("web-client/api/immediately-renew-my-expired-subscription", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.immediatelyRenewMyExpiredSubscription = void 0;

  const immediatelyRenewMyExpiredSubscription = _ipcClient.ipc.makeRequestFunction('immediatelyRenewMyExpiredSubscription');

  _exports.immediatelyRenewMyExpiredSubscription = immediatelyRenewMyExpiredSubscription;
});