define("web-client/instance-initializers/global-actions", ["exports", "lodash-es/clone", "lodash-es/includes", "lodash-es/remove", "lodash-es/map", "lodash-es/fromPairs", "lodash-es/merge", "lodash-es/union", "lodash-es/without", "lodash-es/take", "web-client/helpers/merge-deep", "web-client/actions/global-actions/select-all-unowned-teams-for-password-filter", "lodash-es/some", "web-client/actions/global-actions/is-connection-team-selected", "web-client/actions/global-actions/select-individual-team-for-password-filter", "web-client/actions/global-actions/show-delete-passwords-dialog", "web-client/actions/global-actions/login-to-site", "web-client/api", "web-client/api/get-passwords", "web-client/api/get-connections-for-management", "web-client/api/get-labels", "ember-concurrency", "web-client/actions/remove-packing-key", "web-client/actions/action-ui-error-handler", "web-client/api/get-teams", "lodash-es/trim", "lodash-es/compact", "lodash-es/find", "ua-parser-js", "web-client/utils/default-app-settings", "lodash-es/has", "idle-timer", "web-client/api/idle-timer", "debug", "lodash-es/flatten", "lodash-es/uniq", "lodash-es/difference", "lodash-es/isEmpty", "file-saver", "lodash-es/filter", "lodash-es/forEach", "lodash-es/pull", "lodash-es/concat", "lodash-es/every", "web-client/api/get-connection-stats", "neo-async", "lodash-es/isNumber"], function (_exports, _clone, _includes, _remove, _map, _fromPairs, _merge, _union, _without, _take, _mergeDeep, _selectAllUnownedTeamsForPasswordFilter, _some, _isConnectionTeamSelected, _selectIndividualTeamForPasswordFilter, _showDeletePasswordsDialog, _loginToSite, _api, _getPasswords, _getConnectionsForManagement, _getLabels, _emberConcurrency, _removePackingKey, _actionUiErrorHandler, _getTeams, _trim, _compact, _find, _uaParserJs, _defaultAppSettings, _has, _idleTimer, _idleTimer2, _debug, _flatten, _uniq, _difference, _isEmpty, _fileSaver, _filter, _forEach, _pull, _concat, _every, _getConnectionStats, _neoAsync, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  const log = (0, _debug.default)('global-actions');

  function initialize(appInstance) {
    let actions = Ember.Object.extend({
      router: Ember.inject.service(),
      reduxStore: Ember.inject.service(),
      standardDialogs: Ember.inject.service(),
      loading: Ember.inject.service(),
      dialogManager: Ember.inject.service(),
      initApp: Ember.inject.service(),
      notifications: Ember.inject.service(),
      i18n: Ember.inject.service(),
      actionUIErrorHandler: _actionUiErrorHandler.default,
      selectAllUnOwnedTeamForPasswordFilter: _selectAllUnownedTeamsForPasswordFilter.default,
      selectIndividualTeamForPasswordFilter: _selectIndividualTeamForPasswordFilter.default,
      isConnectionTeamSelected: _isConnectionTeamSelected.default,
      loginToSite: _loginToSite.default,
      removePackingKey: _removePackingKey.default,
      showDeletePasswordsDialog: _showDeletePasswordsDialog.default,

      goToPreviousRoute() {
        if (this.get('previous.route') && this.get('previous.route') !== 'locked' && this.get('previous.route') !== 'oob' && this.get('previous.route') !== 'index' && this.get('previous.route') !== 'login') {
          this.router.replaceWith(this.get('previous.route'));
        } else {
          this.router.replaceWith('passwords');
        }
      },

      showMainMenuDialog() {
        this.standardDialogs.showDialog('main-application-menu', {});
      },

      showTeamDialogMenu(args) {
        this.standardDialogs.showDialog('team-dialog-menu', args);
      },

      showPasswordDialogMenu(args) {
        this.standardDialogs.showDialog('password-dialog-menu', args);
      },

      showConnectionSelectionMenu(args) {
        this.standardDialogs.showDialog('connection-selection-menu', args);
      },

      showPasswordSearchSelectionMenu(args) {
        this.standardDialogs.showDialog('password-search-selection-menu', args);
      },

      showPasswordSelectionMenu(args) {
        this.standardDialogs.showDialog('password-selection-menu', args);
      },

      showMessageSelectionMenu(args) {
        this.standardDialogs.showDialog('message-selection-menu', args);
      },

      showChangeNameDialog() {
        this.standardDialogs.showDialog('change-name-dialog', {});
      },

      showUsernameDialog() {
        this.standardDialogs.showDialog('username-dialog', {});
      },

      showChangeCompanyNameDialog() {
        this.standardDialogs.showDialog('change-company-name-dialog', {});
      },

      showChangeCompanyBillingEmailDialog() {
        this.standardDialogs.showDialog('change-company-billing-email-dialog', {});
      },

      showChangeCompanyNotificationEmailDialog() {
        this.standardDialogs.showDialog('change-company-notification-email-dialog', {});
      },

      updateSubscriptionData() {
        (0, _api.getSubscriptionSummary)().then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_SUBSCRIPTION_SUMMARY',
            nextSubscription: data.next,
            subscriptionSummary: data.subscriptionSummary
          });
        });
      },

      updateOrganizationSettings() {
        //    console.log("update org settings");
        (0, _api.getOrganizationSettings)().then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_ORGANIZATION_SETTINGS',
            data: data
          });
        });
      },

      updateOrganizationLicenses() {
        (0, _api.getOrganizationLicenses)().then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_ORGANIZATION_LICENSES',
            licenses: data.licenses,
            notLicensed: data.notLicensed
          });
        });
      },

      deleteLocalDatabase() {
        (0, _api.deleteLocalDatabase)().then(function () {
          session.set('localDataReset', true);
          (0, _api.actionUpdate)('localDataReset');
          window.location.reload();
        });
      },

      resendVerificationEmail(username) {
        this.loading.show('Resending Verification Email');
        let self = this;
        (0, _api.resendVerificationEmail)(username).then(() => {
          self.loading.dismiss();
          this.reduxStore.dispatch({
            type: 'EMAIL_VERIFICATION_SENT'
          });
        }).catch(data => {
          self.loading.dismiss();
          this.actionUIErrorHandler(data);
        });
      },

      showEmergencyAccessCodes() {
        (0, _api.getBackupSecurityTokens)().then(data => {
          if (data.codes.length > 0) {
            this.reduxStore.dispatch({
              type: 'SET_GA_BACKUP_TOKENS',
              gaBackupTokens: data.codes
            });
            var downloadFile = "Emergency Access Codes:\n" + (0, _map.default)(data.codes, function (code) {
              return code;
            }).join('\r\n');
            var dateString = new Date().format('mm-dd-yy-hh-MM-ss');
            var filename = "passpack-access-codes-" + dateString + '.txt';
            var file = new File([downloadFile], filename, {
              type: "text/plain;charset=utf-8"
            });

            _fileSaver.default.saveAs(file);
          } else {// Do something if no more codes.
          }
        });
      },

      resetBackupSecurityTokens() {
        (0, _api.resetBackupSecurityTokens)().then(data => {
          if (data.codes.length > 0) {
            this.reduxStore.dispatch({
              type: 'SET_GA_BACKUP_TOKENS',
              gaBackupTokens: data.codes
            });
          } else {// Do something if no more codes.
          }
        });
      },

      showManageAllowedDomainsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('manage-allowed-domains-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),
      showManageVerifiedDomainsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('manage-verified-domains-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),
      showManageLabelsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('manage-labels-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),
      showManageTeamsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('manage-teams-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),
      showEditPasswordDialog: (0, _emberConcurrency.task)(function* (password) {
        let deferred = Ember.RSVP.defer();
        this.get('standardDialogs').showDialog('password-dialog', {
          pw: password,
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),

      processActionFeed() {
        //console.log("Processing Action Feed");
        (0, _api.getActionFeed)().then(data => {
          //console.log("data: " + JSON.stringify(data));
          if (data.entries) {
            for (let i = 0; i < data.entries.length; i++) {
              console.log(data.entries[i]);
              let entry = data.entries[i];

              switch (entry.action) {
                case 'localDataReset':
                  console.log("Perform local data reset");
                  this.deleteLocalDatabase();
                  break;

                default:
                  console.log("unknown action: " + entry.action);
              }
            }
          }
        });
      },

      showCreateConnectionDialog() {
        if (this.get('reduxStore.state.nickname')) {
          this.standardDialogs.showDialog('create-connection-dialog', {});
        } else {
          let mustHaveNicknamePromise = Ember.RSVP.defer();
          mustHaveNicknamePromise.promise.then(() => {
            this.send('showNicknameDialog');
          }).catch(() => {// do nothing
          });
          this.standardDialogs.showConfirmDialog('must-have-nickname-to-connect', mustHaveNicknamePromise, true);
        }
      },

      activateYubikey() {
        this.standardDialogs.showDialog('yubikey-setup-dialog', {});
      },

      inactivateYubikey(deviceId) {
        this.standardDialogs.showConfirmDialog('confirm-remove-yubikey', true).then(() => {
          return (0, _api.removeYubikey)(deviceId);
        }).then(() => {
          return (0, _api.getOOBOptions)();
        }).then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_OOB_OPTIONS_SUCCESS',
            oobPreferences: Ember.Object.create(data)
          });
          return (0, _api.getGoogleAuthBackupTokens)();
        }).catch(data => {
          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      activateGoogleAuthenticator() {
        this.standardDialogs.showDialog('google-auth-setup-dialog', {});
      },

      inactivateGoogleAuthenticator() {
        this.standardDialogs.showConfirmDialog('confirm-remove-google-auth', true).then(() => {
          return (0, _api.resetAndRemoveGoogleAuthSetup)();
        }).then(() => {
          return signOut(); //getOOBOptions();
        }) //        .then((data) => {
        //          this.reduxStore.dispatch({
        //            type: 'INITIALIZE_OOB_OPTIONS_SUCCESS',
        //            oobPreferences: EmberObject.create(data)
        //          });
        //          return getGoogleAuthBackupTokens();
        //        })
        .catch(data => {
          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      activateMicrosoftAuthenticator() {
        this.standardDialogs.showDialog('microsoft-auth-setup-dialog', {});
      },

      inactivateMicrosoftAuthenticator() {
        this.standardDialogs.showConfirmDialog('confirm-remove-microsoft-auth', true).then(() => {
          return (0, _api.resetAndRemoveMicrosoftAuthSetup)();
        }).then(() => {
          (0, _api.logout)(); //getOOBOptions();
        }) //        .then((data) => {
        //          this.reduxStore.dispatch({
        //            type: 'INITIALIZE_OOB_OPTIONS_SUCCESS',
        //            oobPreferences: EmberObject.create(data)
        //          });
        //          return getGoogleAuthBackupTokens();
        //        })
        .catch(data => {
          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      submitToken(securityToken) {
        (0, _api.submitToken)({
          token: securityToken
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'OOB_STATE',
            oobValid: true
          });
          this.initApp.setup().then(() => {
            this.router.transitionTo("locked");
          });
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      setConnectionsForManagementSortOrder: (0, _emberConcurrency.task)(function* (sortOn) {
        if (this.get('reduxStore.state.data.connectionsForManagement.' + sortOn) !== undefined) {
          this.set('reduxStore.state.data.connectionsForManagement.' + sortOn, !this.get('reduxStore.state.data.connectionsForManagement.' + sortOn));
        } else {
          this.set('reduxStore.state.data.connectionsForManagement.' + sortOn, true);
          let updates = (0, _fromPairs.default)((0, _map.default)((0, _without.default)(['sortOnNickname', 'sortOnActive'], sortOn), item => {
            return [item, this.set('reduxStore.state.data.connectionsForManagement.' + item, undefined)];
          }));
          this.get('reduxStore.state.data.connectionsForManagement').setProperties(updates);
        }

        const connections = yield this.getConnectionsForManagement();

        if (connections) {
          this.reduxStore.dispatch({
            type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
            connections: connections
          });
        }
      }).restartable(),
      setSortOrder: (0, _emberConcurrency.task)(function* (sortOn) {
        if (this.get('reduxStore.state.data.passwords.' + sortOn) !== undefined) {
          this.set('reduxStore.state.data.passwords.' + sortOn, !this.get('reduxStore.state.data.passwords.' + sortOn));
        } else {
          this.set('reduxStore.state.data.passwords.' + sortOn, true);
          let updates = (0, _fromPairs.default)((0, _map.default)((0, _without.default)(['sortOnName', 'sortOnURL', 'sortOnLastUpdate', 'sortOnLastPasswordUpdate', 'sortOnPasswordStrength'], sortOn), item => {
            return [item, this.set('reduxStore.state.data.passwords.' + item, undefined)];
          }));
          this.get('reduxStore.state.data.passwords').setProperties(updates);
        }

        const passwords = yield this.getPasswords();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }
      }).restartable(),
      getPasswordsTask: (0, _emberConcurrency.task)(function* (page) {
        let offset;

        if (page) {
          offset = page * this.reduxStore.state.data.passwords.pageSize - this.reduxStore.state.data.passwords.pageSize;
        } else {
          offset = this.reduxStore.state.data.passwords.currentPage * this.reduxStore.state.data.passwords.pageSize - this.reduxStore.state.data.passwords.pageSize;
        }

        let allTeams = this.reduxStore.state.data.teams.selected;
        return yield (0, _getPasswords.getPasswords)({
          limit: this.reduxStore.state.data.passwords.pageSize,
          offset: offset,
          pageSize: this.reduxStore.state.data.passwords.pageSize,
          labels: this.reduxStore.state.data.labels.selected,
          teams: allTeams,
          sortName: this.reduxStore.state.data.passwords.sortName,
          sortDirection: this.reduxStore.state.data.passwords.sortDirection,
          isFavorite: this.reduxStore.state.data.passwords.filterOnFavorites,
          strengths: this.reduxStore.state.data.passwords.strengthsFilter
        });
      }).restartable(),

      getPasswords(page) {
        let offset;

        if (page) {
          offset = page * this.reduxStore.state.data.passwords.pageSize - this.reduxStore.state.data.passwords.pageSize;
        } else {
          offset = this.reduxStore.state.data.passwords.currentPage * this.reduxStore.state.data.passwords.pageSize - this.reduxStore.state.data.passwords.pageSize;
        }

        let allTeams = this.reduxStore.state.data.teams.selected;
        return (0, _getPasswords.getPasswords)({
          limit: this.reduxStore.state.data.passwords.pageSize,
          offset: offset,
          pageSize: this.reduxStore.state.data.passwords.pageSize,
          labels: this.reduxStore.state.data.labels.selected,
          teams: allTeams,
          sortName: this.reduxStore.state.data.passwords.sortName,
          sortDirection: this.reduxStore.state.data.passwords.sortDirection,
          isFavorite: this.reduxStore.state.data.passwords.filterOnFavorites,
          strengths: this.reduxStore.state.data.passwords.strengthsFilter
        });
      },

      getConnections(page) {
        let offset;

        if (page) {
          offset = page * this.reduxStore.state.data.connectionsForManagement.pageSize - this.reduxStore.state.data.connectionsForManagement.pageSize;
        } else {
          offset = this.reduxStore.state.data.connectionsForManagement.currentPage * this.reduxStore.state.data.connectionsForManagement.pageSize - this.reduxStore.state.data.connectionsForManagement.pageSize;
        }

        return (0, _getPasswords.getPasswords)({
          limit: this.reduxStore.state.data.passwords.pageSize,
          offset: offset,
          pageSize: this.reduxStore.state.data.passwords.pageSize,
          sortName: this.reduxStore.state.data.connectionsForManagement.sortName,
          sortDirection: this.reduxStore.state.data.connectionsForManagement.sortDirection
        });
      },

      getConnectionsForManagement(page) {
        let offset;

        if (page) {
          offset = page * this.reduxStore.state.data.connectionsForManagement.pageSize - this.reduxStore.state.data.connectionsForManagement.pageSize;
        } else {
          offset = this.reduxStore.state.data.connectionsForManagement.currentPage * this.reduxStore.state.data.connectionsForManagement.pageSize - this.reduxStore.state.data.connectionsForManagement.pageSize;
        }

        return (0, _getConnectionsForManagement.getConnectionsForManagement)({
          limit: this.reduxStore.state.data.connectionsForManagement.pageSize,
          offset: offset,
          pageSize: this.reduxStore.state.data.connectionsForManagement.pageSize,
          filterOnActive: this.reduxStore.state.data.connectionsForManagement.filterOnActive,
          filterOnInactive: this.reduxStore.state.data.connectionsForManagement.filterOnInactive,
          teams: this.reduxStore.state.data.teamsForConnectionManagement.selected,
          sortName: this.reduxStore.state.data.connectionsForManagement.sortName,
          sortDirection: this.reduxStore.state.data.connectionsForManagement.sortDirection
        });
      },

      removeTeamsFilter() {//alert('removeTeamsFilter')
      },

      addSelectedPasswordsToTeam() {// alert('addSelectedPasswordsToTeam')
      },

      selectAllSharedToIndividualsForPasswordFilter() {// alert('selectAllSharedToIndividualsForPasswordFilter')
      },

      selectAllSharedFromIndividualsForPasswordFilter() {//   alert('selectAllSharedFromIndividualsForPasswordFilter')
      },

      goToPasswords() {
        this.router.transitionTo('passwords');
      },

      goToConnections() {
        this.router.transitionTo('connections');
      },

      //    getPendingRequests(page) {
      //      let offset;
      //      if (page) {
      //        offset = (page * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      } else {
      //        offset = (this.reduxStore.state.data.passwords.currentPage * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      }
      //      return getPendingConnectionRequests(
      //        {
      //          limit: this.reduxStore.state.data.passwords.pageSize,
      //          offset: offset,
      //          pageSize: this.reduxStore.state.data.passwords.pageSize,
      //          labels: this.reduxStore.state.data.labels.selected,
      //          teams: this.reduxStore.state.data.teams.selected,
      //          sortName: this.reduxStore.state.data.passwords.sortName,
      //          sortDirection: this.reduxStore.state.data.passwords.sortDirection,
      //          isFavorite: this.reduxStore.state.data.passwords.filterOnFavorites
      //        })
      //    },
      //    getPendingSentConnectionRequests(page) {
      //      let offset;
      //      if (page) {
      //        offset = (page * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      } else {
      //        offset = (this.reduxStore.state.data.passwords.currentPage * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      }
      //      return getPendingSentConnectionRequests(
      //        {
      //          limit: this.reduxStore.state.data.passwords.pageSize,
      //          offset: offset,
      //          pageSize: this.reduxStore.state.data.passwords.pageSize,
      //          labels: this.reduxStore.state.data.labels.selected,
      //          teams: this.reduxStore.state.data.teams.selected,
      //          sortName: this.reduxStore.state.data.passwords.sortName,
      //          sortDirection: this.reduxStore.state.data.passwords.sortDirection,
      //          isFavorite: this.reduxStore.state.data.passwords.filterOnFavorites
      //        })
      //    },
      //    getPendingReceivedConnectionRequests(page) {
      //      let offset;
      //      if (page) {
      //        offset = (page * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      } else {
      //        offset = (this.reduxStore.state.data.passwords.currentPage * this.reduxStore.state.data.passwords.pageSize) - this.reduxStore.state.data.passwords.pageSize;
      //      }
      //      return getPendingReceivedConnectionRequests(
      //        {
      //          limit: this.reduxStore.state.data.passwords.pageSize,
      //          offset: offset,
      //          pageSize: this.reduxStore.state.data.passwords.pageSize,
      //          labels: this.reduxStore.state.data.labels.selected,
      //          teams: this.reduxStore.state.data.teams.selected,
      //          sortName: this.reduxStore.state.data.passwords.sortName,
      //          sortDirection: this.reduxStore.state.data.passwords.sortDirection,
      //          isFavorite: this.reduxStore.state.data.passwords.filterOnFavorites
      //        })
      //    },
      //    setPendingConnectionsView() {
      //      this.getPendingRequests().then((pendingConnectionRequests) => {
      //        this.reduxStore.dispatch({
      //          type: 'UPDATE_PENDING_CONNECTION_REQUESTS',
      //          pendingConnectionRequests: pendingConnectionRequests
      //        });
      //        this.reduxStore.dispatch({
      //          type: 'SET_CONNECTIONS_GRID_VIEW',
      //          connectionGridView: 'pending-connections'
      //        })
      //      });
      //    },
      //    setPendingSentConnectionRequestsView() {
      //      this.getPendingSentConnectionRequests().then((pendingConnectionRequests) => {
      //        this.reduxStore.dispatch({
      //          type: 'UPDATE_PENDING_CONNECTION_REQUESTS',
      //          pendingConnectionRequests: pendingConnectionRequests
      //        });
      //        this.reduxStore.dispatch({
      //          type: 'SET_CONNECTIONS_GRID_VIEW',
      //          connectionGridView: 'pending-sent-connections'
      //        })
      //      });
      //    },
      //    setPendingReceivedConnectionRequestsView() {
      //
      //      this.getPendingReceivedConnectionRequests().then((pendingConnectionRequests) => {
      //        this.reduxStore.dispatch({
      //          type: 'UPDATE_PENDING_CONNECTION_REQUESTS',
      //          pendingConnectionRequests: pendingConnectionRequests
      //        });
      //        this.reduxStore.dispatch({
      //          type: 'SET_CONNECTIONS_GRID_VIEW',
      //          connectionGridView: 'pending-received-connections'
      //        })
      //      });
      //
      //
      //    },
      clearAllConnectionFilters: (0, _emberConcurrency.task)(function* () {
        this.reduxStore.dispatch({
          type: 'SET_CONNECTIONS_GRID_VIEW',
          connectionGridView: 'connections'
        });
        this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
        this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);
        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
          teams: []
        });
        const connections = yield this.getConnectionsForManagement();

        if (connections) {
          this.reduxStore.dispatch({
            type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
            connections: connections
          });
        }

        if (this.connectionGrid) {
          this.connectionGrid.setVScrollValue(0);
        }
      }).restartable(),

      //    toggleActiveConnectionsFilter: task(function* () {
      //      this.reduxStore.dispatch({
      //        type: 'SET_CONNECTIONS_GRID_VIEW',
      //        connectionGridView: 'connections'
      //      })
      //      this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);
      //
      //      this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', !this.reduxStore.state.data.connectionsForManagement.filterOnActive);
      //      this.reduxStore.dispatch({
      //        type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
      //        teams: []
      //      });
      //      const connections = yield this.getConnectionsForManagement();
      //      if (connections) {
      //        this.reduxStore.dispatch({
      //          type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
      //          connections: connections
      //        });
      //      }
      //      if (this.connectionGrid) {
      //        this.connectionGrid.setVScrollValue(0);
      //      }
      //
      //    }).restartable(),
      //    toggleInactiveConnectionsFilter: task(function* () {
      //      this.reduxStore.dispatch({
      //        type: 'SET_CONNECTIONS_GRID_VIEW',
      //        connectionGridView: 'connections'
      //      })
      //      this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
      //      this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', !this.reduxStore.state.data.connectionsForManagement.filterOnInactive);
      //      this.reduxStore.dispatch({
      //        type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
      //        teams: []
      //      });
      //      const connections = yield this.getConnectionsForManagement();
      //      if (connections) {
      //        this.reduxStore.dispatch({
      //          type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
      //          connections: connections
      //        });
      //      }
      //      if (this.connectionGrid) {
      //        this.connectionGrid.setVScrollValue(0);
      //      }
      //    }).restartable(),
      openSelectedURLS() {
        let self = this;
        let stuff = (0, _take.default)((0, _filter.default)(self.get("reduxStore.state.data.passwords.content"), function (item) {
          return (0, _includes.default)(self.get('reduxStore.state.data.passwords.selected'), item.id) && item.url;
        }), 10);
        let someNotOpened = false;
        (0, _forEach.default)(stuff, function (item) {
          let w = window.open(item.url, '_blank');

          if (!w) {
            someNotOpened = true;
          }
        });

        if (self.get('reduxStore.state.data.passwords.selected').length > 10 || someNotOpened) {
          this.get('standardDialogs').showInfoDialog('confirm-open-selected-urls');
        }
      },

      //
      //    bulkDeclineInvites() {
      //
      //      let selectedIds = this.reduxStore.state.data.receivedInvites.selected;
      //      let selected;
      //      this.standardDialogs.showConfirmDialog('confirm-delete-received-invites')
      //        .then(() => {
      //          let deferred = defer();
      //          this.loading.show('Declining Invitation');
      //          selected = filter(this.reduxStore.state.data.receivedInvites.content, (item) => {
      //            return includes(selectedIds, item.id);
      //          });
      //          async.eachSeries(selected, function (item, callback) {
      //            return rejectInvite(item.fromId)
      //              .then(() => {
      //                deleteInvite(item.fromId).then(() => {
      //                  callback()
      //                })
      //                  .catch(() => {
      //                    callback()
      //                  });
      //
      //              })
      //              .catch(() => {
      //                callback()
      //              });
      //          }, function () {
      //            deferred.resolve();
      //          })
      //
      //          return deferred.promise;
      //        })
      //
      //        .then(() => {
      //          this.reduxStore.state.data.receivedInvites.selected.removeObjects(selectedIds);
      //          return getPendingReceivedConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_RECEIVED_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //        })
      //
      //
      //        .catch(() => {
      //          this.loading.dismiss();
      //
      //        });
      //    },
      //    bulkAcceptInvites() {
      //      let selected;
      //      let selectedIds = this.reduxStore.state.data.receivedInvites.selected;
      //      this.standardDialogs.showConfirmDialog('confirm-accept-received-invites').then(() => {
      //
      //        let deferred = defer();
      //        this.loading.show( 'Accepting Invitations');
      //        selected = filter(this.reduxStore.state.data.receivedInvites.content, (item) => {
      //          return includes(selectedIds, item.id);
      //        });
      //        async.eachSeries(selected, function (item, callback) {
      //          acceptInvite(item.fromId)
      //            .then(() => {
      //
      //              callback()
      //
      //
      //            })
      //            .catch(() => {
      //              callback()
      //            });
      //        }, function () {
      //          deferred.resolve();
      //        })
      //
      //        return deferred.promise;
      //
      //      })
      //        .then(() => {
      //          this.reduxStore.state.data.receivedInvites.selected.removeObjects(selectedIds);
      //
      //          return getPendingReceivedConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_RECEIVED_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //
      //
      //        })
      //
      //
      //        .catch(() => {
      //          this.loading.dismiss();
      //
      //
      //        });
      //    },
      //    bulkCancelInvites() {
      //
      //      let selected;
      //      let selectedIds = this.reduxStore.state.data.sentInvites.selected;
      //      this.standardDialogs.showConfirmDialog('confirm-cancel-sent-invites').then(() => {
      //        let deferred = defer();
      //        this.loading.show('Canceling Invitations');
      //
      //        selected = filter(this.reduxStore.state.data.sentInvites.content, (item) => {
      //          return includes(selectedIds, item.id);
      //        });
      //        async.eachSeries(selected, function (item, callback) {
      //          cancelSentInvite(item.toId)
      //            .then(() => {
      //              deleteSentInvite(item.toId).then(() => {
      //                callback()
      //              })
      //                .catch(() => {
      //                  callback()
      //                });
      //
      //            })
      //            .catch(() => {
      //              callback()
      //            });
      //        }, function () {
      //          deferred.resolve();
      //        })
      //
      //        return deferred.promise;
      //
      //      })
      //
      //        .then(() => {
      //          this.reduxStore.state.data.sentInvites.selected.removeObjects(selectedIds);
      //
      //          return getPendingSentConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_SENT_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //
      //
      //        })
      //
      //
      //        .catch((e) => {
      //          this.loading.dismiss();
      //        });
      //    },
      //
      //
      //    declineInvite(sender) {
      //
      //
      //      this.standardDialogs.showConfirmDialog('confirm-delete-received-invite')
      //        .then(() => {
      //          this.loading.show('Declining Invitation');
      //
      //          return rejectInvite(sender.fromId);
      //        })
      //        .then(() => {
      //
      //          return deleteInvite(sender.fromId);
      //        })
      //        .then(() => {
      //          this.reduxStore.state.data.receivedInvites.selected.removeObject(sender.id);
      //
      //          return getPendingReceivedConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_RECEIVED_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //        })
      //
      //
      //        .catch(() => {
      //          this.loading.dismiss();
      //
      //        });
      //    },
      //    acceptInvite(sender) {
      //
      //      this.standardDialogs.showConfirmDialog('confirm-accept-received-invite').then(() => {
      //        this.loading.show( 'Accepting Invitation');
      //
      //        return acceptInvite(sender.fromId);
      //      })
      //        .then(() => {
      //          this.reduxStore.state.data.receivedInvites.selected.removeObject(sender.id);
      //
      //          return getPendingReceivedConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_RECEIVED_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //
      //
      //        })
      //
      //
      //        .catch(() => {
      //          this.loading.dismiss();
      //
      //
      //        });
      //    },
      //    cancelInvite(recipient) {
      //
      //
      //      this.standardDialogs.showConfirmDialog('confirm-cancel-sent-invite').then(() => {
      //        this.loading.show('Canceling Invitation');
      //
      //        return cancelSentInvite(recipient.toId);
      //      })
      //        .then(() => {
      //
      //          return deleteSentInvite(recipient.toId);
      //        })
      //        .then(() => {
      //          this.reduxStore.state.data.sentInvites.selected.removeObject(recipient.id);
      //
      //          return getPendingSentConnectionRequests();
      //
      //        })
      //        .then((data) => {
      //          this.reduxStore.dispatch({
      //            type: 'UPDATE_SENT_INVITES',
      //            sentInvites: data
      //          });
      //          this.loading.dismiss();
      //
      //
      //        })
      //
      //
      //        .catch((e) => {
      //          this.loading.dismiss();
      //        });
      //    },
      //    selectAllReceivedInvites(grid) {
      //      const all = map(this.get('reduxStore.state.data.receivedInvites.content'), (item) => {
      //        return item.id
      //      });
      //      this.reduxStore.state.data.receivedInvites.selected.clear();
      //      this.reduxStore.state.data.receivedInvites.selected.pushObjects(all);
      //      grid.canvas.paintNow();
      //
      //
      //    },
      //    deselectAllReceivedInvites(grid) {
      //      this.set('reduxStore.state.data.receivedInvites.selected', []);
      //      grid.canvas.paintNow();
      //    },
      //    selectAllSentInvites(grid) {
      //      const all = map(this.get('reduxStore.state.data.sentInvites.content'), (item) => {
      //        return item.id
      //      });
      //
      //      this.reduxStore.state.data.sentInvites.selected.clear();
      //      this.reduxStore.state.data.sentInvites.selected.pushObjects(all);
      //      grid.canvas.paintNow();
      //
      //    },
      //    deselectAllSentInvites(grid) {
      //      this.set('reduxStore.state.data.sentInvites.selected', []);
      //      grid.canvas.paintNow();
      //    },
      //    showSentInviteDialog(request) {
      //      var args = EmberObject.create({
      //        request: request
      //
      //      });
      //      this.standardDialogs.showDialog('sent-invite-dialog', args);
      //
      //
      //    },
      //    showSentReceivedDialog(request) {
      //      var args = EmberObject.create({
      //        request: request,
      //
      //      });
      //      this.standardDialogs.showDialog('received-invite-dialog', args);
      //
      //
      //    },
      signOut() {
        (0, _api.logout)().then(() =>
        /*e */
        {
          try {
            storage.remove('sessionToken');
            this.reduxStore.dispatch({
              type: 'RESET'
            });
            this.router.transitionTo('login');
            this.initApp.setup();
          } catch (e) {
            log(e);
          }
        }).catch(e => {
          console.log(e);
          log(e);
        });
      },

      showEditTeamDialog(team, showEditMembersTab, event) {
        (0, _api.getConnectionsForTeam)(team.id).then(connections => {
          this.standardDialogs.showDialog('edit-team-dialog', {
            tm: team,
            cn: connections,
            showEditMembersTab: showEditMembersTab === true ? true : false
          });
        });
      },

      refreshAccountDetails() {
        (0, _api.getAccountDetails)().then(data => {
          this.reduxStore.dispatch({
            type: 'SET_ACCOUNT_DETAILS',
            data: (0, _merge.default)({
              loggedIn: true,
              oobValid: true
            }, data)
          });
        });
      },

      goToSettings() {
        this.router.transitionTo('settings');
      },

      goToLicenseSummary() {
        this.router.transitionTo('settings.license-summary');
      },

      goToSettingsReplace() {
        this.router.replaceWith('settings');
      },

      goToSettingsCompanyDetails() {
        this.router.transitionTo('settings.company');
      },

      goToSettingsCompanyBilling() {
        this.router.transitionTo("settings.company.billing");
      },

      goToSettingsCompanySettings() {
        this.router.transitionTo("settings.company.company-settings");
      },

      goToSettingsCompanySubscription() {
        this.router.transitionTo("settings.company.subscription");
      },

      goToSettingsCompanyLicenseManagement() {
        this.router.transitionTo("settings.company.license-management");
      },

      goToSystemUpdates() {
        this.router.transitionTo("dashboard.system-updates");
      },

      goToUserManual() {
        window.open("https://downloads.passpack.com/Passpack_User_Guide.pdf");
      },

      goToOOB() {
        this.router.transitionTo('oob');
      },

      goToDashboard() {
        this.router.transitionTo('dashboard');
      },

      goToSecurityStats() {
        this.router.transitionTo('dashboard.security-stats');
      },

      goToResetPW() {
        this.router.transitionTo('resetpw');
      },

      goToPasswordResetVerification() {
        this.router.transitionTo("verifyreset");
      },

      goToLogin() {
        this.router.transitionTo('login');
      },

      goToBusinessRegistration() {
        this.router.transitionTo('register.business');
      },

      goToTeamRegistration() {
        this.router.transitionTo('register.team');
      },

      goToRetrieveUserID() {
        this.router.transitionTo('retrieveuser');
      },

      goToRegister() {
        this.router.transitionTo('register');
      },

      getAccountLicenseSummary() {
        (0, _api.getAccountLicenseSummary)().then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_ACCOUNT_LICENSE_SUMMARY',
            licenseSummary: data.licenseSummary
          });
        });
      },

      registerUser(company, username, password, email, ic, edition) {
        (0, _api.registerUser)({
          company: company,
          username: username,
          password: password,
          email: email,
          ic: ic,
          edition: edition
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'REGISTRATION_SUCCESSFUL'
          });
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      registerUserInvite(username, password, flowId) {
        (0, _api.registerUserInvite)({
          flowId: flowId,
          password: password
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'REGISTRATION_SUCCESSFUL'
          });
          this.loginToSite(username, password);
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      acceptUserInvite(flowId) {
        (0, _api.acceptUserInvite)({
          flowId: flowId
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'REGISTRATION_SUCCESSFUL'
          }); //          this.loginToSite(username,password);
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      goToSessionSettings() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.session-settings');
      },

      //    let mayViewBilling = this.globalActions.doesPermissionExist('settings','company.billing','ro');
      doesPermissionExist(category, section, desiredPermission) {
        let permissionGranted = false;

        try {
          let permissions = this.get('reduxStore.state.companyPermissions');

          if (null != permissions && '' !== permissions && !(0, _isEmpty.default)(permissions)) {
            var companyPermissions = new Map(permissions); //          console.log("doesPermissionExist: " + category + " " + section);

            var sectionPermission = companyPermissions.get(category + "." + section);

            if (null != sectionPermission) {
              if (desiredPermission === 'rw' && sectionPermission === 'rw') {
                permissionGranted = true;
              } else if (desiredPermission === 'ro' && (sectionPermission === 'rw' || sectionPermission === 'ro')) {
                permissionGranted = true;
              }
            }
          }
        } catch (e) {
          console.log(e);
        }

        return permissionGranted;
      },

      requestPasswordReset(userId) {
        (0, _api.lostPassword)({
          userId: userId
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'SET_PASSWORD_RESET_SUCCESS',
            passwordResetRequestSuccessful: true
          });
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      retrieveUserID(emailAddress) {
        (0, _api.lostUserID)({
          email: emailAddress
        }).then(() => {
          this.reduxStore.dispatch({
            type: 'RETRIEVE_USER_SUCCESS'
          });
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      resetPassword(verifyResetCode, password) {
        (0, _api.resetPassword)(verifyResetCode, password).then(() => {
          this.reduxStore.dispatch({
            type: 'VERIFY_PASSWORD_RESET_SUCCESS'
          });
          this.set('success', true);
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      submitPassphrase(passphrase) {
        var self = this;
        self.set('generatingKeys', true);
        (0, _api.updateKeyData)({
          passphrase: passphrase
        }).then(data => {
          // Set generatingKeys flag so we won't be re-enter generation.
          self.reduxStore.dispatch({
            type: 'SET_PASSPHRASE',
            passphrase: passphrase
          });
          self.reduxStore.dispatch({
            type: 'SET_PASSPHRASE_CHECK',
            passphraseCheck: data.data
          });
          self.reduxStore.dispatch({
            type: 'SET_ENCRYPTED_PRIVATE_KEY',
            encryptedPrivateKey: data.privateKey
          });
          self.reduxStore.dispatch({
            type: 'SET_PUBLIC_KEY',
            publicKey: data.publicKey
          });
          this.initApp.login();
        }).catch(function ()
        /*data*/
        {
          self.set('generatingKeys', true);
        });
      },

      cancelKeyGeneration() {
        var self = this;
        (0, _api.logout)().then(function () {
          self.get('reduxStore').dispatch({
            type: 'RESET'
          });
          self.get('router').transitionTo("login");
        }).catch(function () {
          self.get('reduxStore').dispatch({
            type: 'RESET'
          });
          self.get('router').transitionTo("login");
        });
      },

      goToSessionHistory() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.session-history');
      },

      goToLocalData() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.local-data');
      },

      goToMultiFactor() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.multi-factor');
      },

      goToImport() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.import');
      },

      showEditEmailAddressDialog() {
        this.standardDialogs.showDialog('email-address-dialog', {});
      },

      showNicknameDialog() {
        this.standardDialogs.showDialog('nickname-dialog', {});
      },

      showConfirmEmailAddressDialog() {
        this.standardDialogs.showDialog('validate-email-change-dialog', {});
      },

      toggleAccountSearchable() {
        if (this.get('reduxStore.state.searchable')) {
          this.standardDialogs.showConfirmDialog('confirm-turn-off-searchable').then(() => {
            (0, _api.updatePersonalInfo)({
              email: this.get('reduxStore.state.emailAddress'),
              nickname: this.get('reduxStore.state.nickname'),
              searchable: false
            }).then(() => {
              return (0, _api.getAccountDetails)();
            }).then(data => {
              this.reduxStore.dispatch({
                type: 'SET_ACCOUNT_DETAILS',
                data: (0, _merge.default)({
                  loggedIn: true,
                  oobValid: true
                }, data)
              });
              this.notifications.show("Hidden status changed");
            }).catch(data => {
              this.actionUIErrorHandler(data);
            });
          }).catch(() => {//this.send("showSnackbar", "Action cancelled");
          });
        } else {
          if (this.get('reduxStore.state.nickname')) {
            this.standardDialogs.showConfirmDialog('confirm-turn-on-searchable').then(() => {
              (0, _api.updatePersonalInfo)({
                email: this.get('reduxStore.state.emailAddress'),
                nickname: this.get('reduxStore.state.nickname'),
                searchable: true
              }).then(() => {
                return (0, _api.getAccountDetails)();
              }).then(data => {
                this.reduxStore.dispatch({
                  type: 'SET_ACCOUNT_DETAILS',
                  data: (0, _merge.default)({
                    loggedIn: true,
                    oobValid: true
                  }, data)
                });
              }).catch(data => {
                this.actionUIErrorHandler(data);
              });
            }).catch(() => {// this.send("showSnackbar", "Action cancelled");
            });
          } else {
            this.standardDialogs.showConfirmDialog('must-have-nickname').then(() => {
              this.send('showNicknameDialog');
            }).catch(() => {//  this.send("showSnackbar", "Action cancelled");
            });
          }
        }
      },

      deleteAccount() {
        this.standardDialogs.showConfirmDialog('confirm-delete-account').then(() => {
          this.loading.show('Deleting Account');
          return (0, _api.deleteAccount)(this.get('reduxStore.state.username'));
        }).then(() => {
          //  this.get('notifications').error(this.get('i18n').t("Dialog.EditUsername.Message.DeletingAccount.Text"));
          setTimeout(function () {
            window.location.reload(true);
          }, 1000); //     this.send('remove');
        }).catch(data => {
          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      showUpdateUserPasswordDialog() {
        this.standardDialogs.showDialog('update-user-password-dialog', {});
      },

      showAboutDialog() {
        this.standardDialogs.showDialog('about-dialog', {});
      },

      showChangePackingKeyDialog() {
        this.standardDialogs.showDialog('change-packing-key-dialog', {});
      },

      showBackupPackingKeyDialog() {
        this.standardDialogs.showDialog('backup-packing-key-dialog', {});
      },

      showSendInviteDialog(request) {
        let args = Ember.Object.create({
          request: request
        });
        this.standardDialogs.showDialog('send-invite-dialog', args);
      },

      openLink(link) {
        window.open(link);
      },

      openTermsOfUse() {
        window.open('https://passpack.com/terms/');
      },

      openSupportPage() {
        window.open('https://support.passpack.com');
      },

      openStatusPage() {
        window.open('https://status.passpack.com');
      },

      goToGeneratedPasswordHistory() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('collected.password-history');
      },

      goToExport() {
        if (this.get('media.isMobile') || this.get('media.isTablet')) {
          this.reduxStore.dispatch({
            type: 'TOGGLE_LEFT_SIDEBAR',
            leftSidebarShowing: false
          });
        }

        this.router.transitionTo('settings.export');
      },

      ensurePackingKey() {
        var onPackingKey = Ember.RSVP.defer();
        onPackingKey.promise.then(() => {
          this.data.updatePasswords();
        }).catch(function () {});

        if (this.get('reduxStore.state.isLocked')) {
          this.router.transitionTo('locked');
        } else {
          onPackingKey.resolve();
        }

        return onPackingKey.promise;
      },

      exportPasswords() {
        (0, _api.getExportData)().then(data => {
          var dateString = new Date().format('mm-dd-yy-hh-MM-ss');
          var filename = "passpack-export-" + dateString + '.csv';
          var file = new File([data], filename, {
            type: "text/plain;charset=utf-8"
          });

          _fileSaver.default.saveAs(file);
        });
      },

      showAvatarDialog() {
        this.standardDialogs.showDialog('avatar-dialog', {});
      },

      setPasswordGrid(grid) {
        this.passwordGrid = grid;
      },

      setConnectionGrid(grid) {
        this.connectionGrid = grid;
      },

      togglePasswordSelectionState(passwordId, ctrlKey, shiftKey) {
        //      console.log("toggle password selection state " + passwordId + " " + ctrlKey + " " + shiftKey);
        var currentPasswords = (0, _clone.default)(this.get('reduxStore.state.data.passwords.selected')); // This currently doesn't do anything when holding shift, need to fix this to select range.

        if (shiftKey) {
          currentPasswords.clear();
          currentPasswords.push(passwordId);
        } else {
          // Assume control key functionality is desired
          ctrlKey = true;
          var context = passwordId;

          if (ctrlKey) {
            if (!(0, _includes.default)(currentPasswords, context)) {
              currentPasswords.push(context);
            } else {
              (0, _remove.default)(currentPasswords, function (item) {
                return item === context;
              });
            }
          } else {
            if (!(0, _includes.default)(currentPasswords, context) || currentPasswords.length > 1) {
              currentPasswords.clear();
              currentPasswords.push(context);
            } else {
              currentPasswords.clear();
            }
          }
        }

        this.reduxStore.dispatch({
          type: 'SELECT_PASSWORDS',
          passwords: currentPasswords
        });
      },

      toggleReceivedInviteSelectionState(inviteId, ctrlKey, shiftKey) {
        var current = (0, _clone.default)(this.get('reduxStore.state.data.receivedInvites.selected')); // This currently doesn't do anything when holding shift, need to fix this to select range.

        if (shiftKey) {
          current.clear();
          current.push(inviteId);
        } else {
          var context = inviteId;

          if (ctrlKey) {
            if (!(0, _includes.default)(current, context)) {
              current.push(context);
            } else {
              (0, _remove.default)(current, function (item) {
                return item === context;
              });
            }
          } else {
            if (!(0, _includes.default)(current, context) || current.length > 1) {
              current.clear();
              current.push(context);
            } else {
              current.clear();
            }
          }
        }

        this.reduxStore.dispatch({
          type: 'SELECT_RECEIVED_INVITES',
          invites: current
        });
      },

      toggleSentInviteSelectionState(connectionId, ctrlKey, shiftKey) {
        var current = (0, _clone.default)(this.get('reduxStore.state.data.sentInvites.selected')); // This currently doesn't do anything when holding shift, need to fix this to select range.

        if (shiftKey) {
          current.clear();
          current.push(connectionId);
        } else {
          var context = connectionId;

          if (ctrlKey) {
            if (!(0, _includes.default)(current, context)) {
              current.push(context);
            } else {
              (0, _remove.default)(current, function (item) {
                return item === context;
              });
            }
          } else {
            if (!(0, _includes.default)(current, context) || current.length > 1) {
              current.clear();
              current.push(context);
            } else {
              current.clear();
            }
          }
        }

        this.reduxStore.dispatch({
          type: 'SELECT_SENT_INVITES',
          invites: current
        });
      },

      toggleConnectionSelectionState(connectionId, ctrlKey, shiftKey) {
        var currentConnections = (0, _clone.default)(this.get('reduxStore.state.data.connectionsForManagement.selected')); // This currently doesn't do anything when holding shift, need to fix this to select range.

        if (shiftKey) {
          currentConnections.clear();
          currentConnections.push(connectionId);
        } else {
          var context = connectionId;

          if (ctrlKey) {
            if (!(0, _includes.default)(currentConnections, context)) {
              currentConnections.push(context);
            } else {
              (0, _remove.default)(currentConnections, function (item) {
                return item === context;
              });
            }
          } else {
            if (!(0, _includes.default)(currentConnections, context) || currentConnections.length > 1) {
              currentConnections.clear();
              currentConnections.push(context);
            } else {
              currentConnections.clear();
            }
          }
        }

        this.reduxStore.dispatch({
          type: 'SELECT_CONNECTIONS_FOR_MANAGEMENT',
          connections: currentConnections
        });
      },

      selectTeamForConnectionFilter: (0, _emberConcurrency.task)(function* (ids, ctrlKey, shiftKey) {
        this.reduxStore.dispatch({
          type: 'SET_CONNECTIONS_GRID_VIEW',
          connectionGridView: 'connections'
        });
        this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
        this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);
        let currentGroups = storage.get('groups', []);

        if (shiftKey) {
          currentGroups.clear();
          currentGroups.push(ids.id);
        } else {
          let context = ids.id;

          if (ctrlKey) {
            if (!(0, _includes.default)(currentGroups, context)) {
              currentGroups.push(context);
            } else {
              (0, _remove.default)(currentGroups, function (item) {
                return item === context;
              });
            }
          } else {
            if (!(0, _includes.default)(currentGroups, context) || currentGroups.length > 1) {
              currentGroups.clear();
              currentGroups.push(context);
            } else {
              currentGroups.clear();
            }
          }
        }

        storage.set('groups', currentGroups);
        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
          teams: currentGroups
        });
        const connections = yield this.getConnectionsForManagement();

        if (connections) {
          this.reduxStore.dispatch({
            type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
            connections: connections
          });
        }

        this.connectionGrid.setVScrollValue(0);
      }),
      selectIndividualAllPasswordsForPasswordFilter: (0, _emberConcurrency.task)(function* (connection) {
        if ((0, _some.default)(connection.allTeams, team => {
          return (0, _includes.default)(this.reduxStore.state.data.teams.selected, team);
        })) {
          this.reduxStore.state.data.teams.selected.removeObjects(connection.allTeams);
        } else {
          this.reduxStore.state.data.teams.selected.pushObjects(connection.allTeams);
        }

        const passwords = yield this.getPasswords();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }).restartable(),
      selectAllConnectionTeamsForPasswordFilter: (0, _emberConcurrency.task)(function* (connectionNick, ctrlKey, shiftKey) {
        try {
          let teams = [];
          let connection = (0, _find.default)(this.get('reduxStore.state.data.connections.content'), connection => {
            return connection.nickname === connectionNick;
          });

          if (connection) {
            teams = connection.ownedTeams;
          }

          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)((0, _map.default)(teams, 'teamId'), item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), (0, _map.default)(teams, 'teamId'));
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = (0, _map.default)(teams, team => {
                return team.teamId;
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswords();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }).restartable(),
      selectAllConnectionTeamsForConnectionFilter: (0, _emberConcurrency.task)(function* (connectionNick) {
        this.reduxStore.dispatch({
          type: 'SET_CONNECTIONS_GRID_VIEW',
          connectionGridView: 'connections'
        });
        this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
        this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);
        let teams = (0, _filter.default)(this.get('reduxStore.state.data.teamsForConnectionManagement.content'), team => {
          return team.ownerNick === connectionNick && !team.individual;
        });
        let all = (0, _every.default)(teams, item => {
          return item.get('isSelected');
        });

        if (teams.length === 0) {// this.send('updatePasswordRouteState');
        }

        if (all) {
          let currentGroups = []; // storage.get('groups', []);

          (0, _forEach.default)(teams, function (group) {
            if ((0, _includes.default)(currentGroups, group.id)) {
              (0, _pull.default)(currentGroups, group.id);
            }
          });
          storage.set('groups', currentGroups); //   this.send('updatePasswordRouteState');

          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          this.connectionGrid.setVScrollValue(0);
        } else {
          let currentGroups = []; // storage.get('groups', []);

          (0, _forEach.default)(teams, function (group) {
            if (!(0, _includes.default)(currentGroups, group.id)) {
              currentGroups.push(group.id);
            }
          });
          storage.set('groups', currentGroups); //this.send('updatePasswordRouteState');

          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          this.connectionGrid.setVScrollValue(0);
        }
      }).restartable(),
      selectAllOwnedTeamsForConnectionManagementFilter: (0, _emberConcurrency.task)(function* ()
      /* args, ctrlKey, shiftKey */
      {
        this.reduxStore.dispatch({
          type: 'SET_CONNECTIONS_GRID_VIEW',
          connectionGridView: 'connections'
        });
        this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
        this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);

        if (this.get('reduxStore.state.data.teamsForConnectionManagement.allOwnedSelected')) {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teamsForConnectionManagement.content'), function (item) {
            return item.role === 1 && item.name !== 'Default' && !item.individual;
          });
          let currentGroups = []; // storage.get('groups', []);

          (0, _forEach.default)(owned, function (group) {
            if ((0, _includes.default)(currentGroups, group.id)) {
              (0, _pull.default)(currentGroups, group.id);
            }
          });
          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          if (this.connectionGrid) {
            this.connectionGrid.setVScrollValue(0);
          }
        } else {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teamsForConnectionManagement.content'), function (item) {
            return item.role === 1 && item.name !== 'Default' && !item.individual;
          });
          let currentGroups = []; // storage.get('groups', []);

          (0, _forEach.default)(owned, function (group) {
            if (!(0, _includes.default)(currentGroups, group.id) || currentGroups.length > 1) {
              currentGroups.push(group.id);
            }
          });
          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          if (this.connectionGrid) {
            this.connectionGrid.setVScrollValue(0);
          }
        }
      }).restartable(),
      selectAllTeamsForConnectionManagementFilter: (0, _emberConcurrency.task)(function* ()
      /* args, ctrlKey, shiftKey */
      {
        this.reduxStore.dispatch({
          type: 'SET_CONNECTIONS_GRID_VIEW',
          connectionGridView: 'connections'
        });
        this.reduxStore.set('state.data.connectionsForManagement.filterOnActive', false);
        this.reduxStore.set('state.data.connectionsForManagement.filterOnInactive', false);

        if (this.get('reduxStore.state.data.teamsForConnectionManagement.allTeamsSelected')) {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teamsForConnectionManagement.content'), function (item) {
            return item.name !== 'Default' && !item.individual;
          });
          let currentGroups = []; // storage.get('groups', []);

          (0, _forEach.default)(owned, function (group) {
            if ((0, _includes.default)(currentGroups, group.id)) {
              (0, _pull.default)(currentGroups, group.id);
            }
          });
          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          if (this.connectionGrid) {
            this.connectionGrid.setVScrollValue(0);
          }
        } else {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teamsForConnectionManagement.content'), function (item) {
            return item.name !== 'Default' && !item.individual;
          });
          let currentGroups = []; //storage.get('groups', []);

          (0, _forEach.default)(owned, function (group) {
            if (!(0, _includes.default)(currentGroups, group.id)) {
              currentGroups.push(group.id);
            }
          });
          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT',
            teams: currentGroups
          });
          const connections = yield this.getConnectionsForManagement();

          if (connections) {
            this.reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: connections
            });
          }

          if (this.connectionGrid) {
            this.connectionGrid.setVScrollValue(0);
          }
        }
      }).restartable(),
      selectAllTeamsForPasswordFilter: (0, _emberConcurrency.task)(function* ()
      /* args, ctrlKey, shiftKey */
      {
        if (this.get('reduxStore.state.data.teams.allTeamsSelected')) {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
            return item.name !== 'Default' && !item.individual;
          });
          let currentGroups = storage.get('groups', []);
          (0, _forEach.default)(owned, function (group) {
            if ((0, _includes.default)(currentGroups, group.id)) {
              (0, _pull.default)(currentGroups, group.id);
            }
          });
          storage.set('groups', currentGroups); //this.send('updatePasswordRouteState');

          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: currentGroups
          });
          const passwords = yield this.getPasswords();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } else {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
            return item.name !== 'Default' && !item.individual;
          });
          let currentGroups = storage.get('groups', []);
          (0, _forEach.default)(owned, function (group) {
            if (!(0, _includes.default)(currentGroups, group.id)) {
              currentGroups.push(group.id);
            }
          });
          storage.set('groups', currentGroups); //   this.send('updatePasswordRouteState');

          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: currentGroups
          });
          const passwords = yield this.getPasswords();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        }
      }).restartable(),

      showSnackbar() {//  alert('showSnackbar')
      },

      copyToClipboard() {//  alert('copyToClipboard')
      },

      onCopyButtonDown() {//  alert('onCopyButtonDown')
      },

      addFavoriteToSelectedPasswords() {/// alert('addFavoriteToSelectedPasswords')
      },

      addLabelToSelectedPasswords() {//  alert('addLabelToSelectedPasswords')
      },

      showBulkManageLabelsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('bulk-manage-labels-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),
      showBulkManageTeamsDialog: (0, _emberConcurrency.task)(function* () {
        let deferred = Ember.RSVP.defer();
        this.standardDialogs.showDialog('bulk-manage-teams-dialog', {
          complete: deferred
        });
        yield deferred.promise;
      }).drop(),

      setPasswordViewType() {// alert('setPasswordViewType');
      },

      showQuickCopyDialog(password) {
        this.standardDialogs.showDialog('quick-copy-dialog', {
          pw: password
        });
      },

      openURL(url) {
        window.open(url, '_blank');
      },

      togglePasswordFavorite(password) {
        let newFavoriteState = !password.favorite;
        let item = [{
          id: password.id,
          fav: newFavoriteState
        }];
        this.loading.show('Updating Favorites');
        (0, _api.setPasswordFavoriteStatus)(item).then(() =>
        /* data */
        {
          //  grid.canvas.paintNow();
          this.loading.dismiss();
        }).catch(data => {
          this.actionUIErrorHandler(data);
        });
      },

      showTransferOwnershipDialog() {// alert('showTransferOwnershipDialog');
      },

      showPasswordTeamsEditDialog() {// alert('showPasswordTeamsEditDialog')
      },

      selectAllVisiblePasswords(grid) {
        let all = (0, _union.default)((0, _map.default)(this.get('reduxStore.state.data.passwords.content'), 'id'), this.get('reduxStore.state.data.passwords.selected'));
        this.set('reduxStore.state.data.passwords.selected', all);
        grid.canvas.paintNow();
      },

      selectAllVisibleSearchPasswords(grid) {
        let all = (0, _union.default)((0, _map.default)(this.get('reduxStore.state.data.passwords.searchResults'), 'id'), this.get('reduxStore.state.data.passwords.selected'));
        this.set('reduxStore.state.data.passwords.selected', all);
        grid.canvas.paintNow();
      },

      selectAllFilteredPasswords(grid) {
        (0, _api.getAllPasswordIds)().then(ids => {
          this.set('reduxStore.state.data.passwords.selected', ids);
          grid.canvas.paintNow();
        });
      },

      deselectAllPasswords(grid) {
        this.set('reduxStore.state.data.passwords.selected', []);
        grid.canvas.paintNow();
      },

      selectInverseSearchPasswords(grid) {
        let inverse = (0, _difference.default)((0, _map.default)(this.get('reduxStore.state.data.passwords.searchResults'), 'id'), this.get('reduxStore.state.data.passwords.selected'));
        this.set('reduxStore.state.data.passwords.selected', inverse);
        grid.canvas.paintNow();
      },

      selectInversePasswords(grid) {
        let inverse = (0, _difference.default)((0, _map.default)(this.get('reduxStore.state.data.passwords.content'), 'id'), this.get('reduxStore.state.data.passwords.selected'));
        this.set('reduxStore.state.data.passwords.selected', inverse);
        grid.canvas.paintNow();
      },

      selectAllVisibleConnections(grid) {
        let all = (0, _union.default)((0, _map.default)(this.get('reduxStore.state.data.connectionsForManagement.content'), 'id'), this.get('reduxStore.state.data.connectionsForManagement.selected'));
        this.set('reduxStore.state.data.connectionsForManagement.selected', all);
        grid.canvas.paintNow();
      },

      selectAllFilteredConnections(grid) {
        (0, _api.getAllConnectionIds)().then(ids => {
          this.set('reduxStore.state.data.connectionsForManagement.selected', ids);
          grid.canvas.paintNow();
        });
      },

      deselectAllConnections(grid) {
        this.set('reduxStore.state.data.connectionsForManagement.selected', []);
        grid.canvas.paintNow();
      },

      selectInverseConnections(grid) {
        (0, _api.getAllConnectionIds)().then(ids => {
          let inverse = (0, _difference.default)(ids, this.get('reduxStore.state.data.connectionsForManagement.selected'));
          this.set('reduxStore.state.data.connectionsForManagement.selected', inverse);
          grid.canvas.paintNow();
        });
        grid.canvas.paintNow();
      },

      showEditLabelDialog(label) {
        let args = Ember.Object.create({
          label: label
        });
        this.standardDialogs.showDialog('label-dialog', args);
      },

      showCreateTeamDialog() {
        let args = Ember.Object.create({});
        this.standardDialogs.showDialog('create-team-dialog', args);
      },

      showSessionDialog(session) {
        var args = Ember.Object.create({
          session: session
        });
        this.standardDialogs.showDialog('session-item-dialog', args);
      },

      deleteTeam(teamId) {
        var _this = this;

        return _asyncToGenerator(function* () {
          let self = _this;

          _this.standardDialogs.showConfirmDialog('confirm-delete-team', true).then(() => {
            _this.loading.show('Deleting Team');

            (0, _api.deleteTeams)([teamId]).then(() => {
              _this.reduxStore.state.data.teams.selected.removeObject(teamId);

              return (0, _getTeams.getTeams)();
            }).then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_TEAMS',
                teams: data
              });
              return _this.getPasswords();
            }).then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_PASSWORDS',
                passwords: data
              });
              self.loading.dismiss();
            }).catch(data => {
              _this.loading.dismiss();

              if (data !== 'cancelled') {
                _this.actionUIErrorHandler(data);
              }
            });
          }).catch(() => {});
        })();
      },

      deleteLabel(labelId) {
        let self = this;
        this.standardDialogs.showConfirmDialog('confirm-delete-label').then(() => {
          this.loading.show('Deleting label');
          return (0, _api.deleteLabel)(labelId);
        }).then(() => {
          this.reduxStore.state.data.labels.selected.removeObject(labelId);
          return (0, _getLabels.getLabels)();
        }).then(data => {
          self.reduxStore.dispatch({
            type: 'UPDATE_LABELS',
            labels: data
          });
          self.loading.dismiss();
        }).catch(data => {
          this.loading.dismiss();

          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      showEditInvoiceExtraInfoDialog() {
        this.standardDialogs.showDialog('set-extra-invoice-info-dialog', {});
      },

      showPasswordGeneratorDialog() {
        this.standardDialogs.showDialog('password-generator-dialog', {});
      },

      showConnectionDialog(connection, e) {
        if (e) {
          e.stopPropagation();
        }

        (0, _getConnectionStats.getConnectionStats)(connection.id).then(stats => {
          let args = {
            // Silly workaround naming things like "cn" then assigning them to the actual property name in init to avoid ember readonly bug
            cn: connection,
            st: stats
          };
          this.standardDialogs.showDialog('connection-dialog', args);
        });
      },

      killSession(sessionId) {
        this.standardDialogs.showConfirmDialog('confirm-kill-session').then(() => {
          (0, _api.killSession)(sessionId);
        }).then(() => {
          this.notifications.show("Other Session signed out");
          this.loadSessionHistory();
        }).catch(data => {
          if (data !== 'cancelled') {
            this.actionUIErrorHandler(data);
          }
        });
      },

      setUserMfaHandling(option) {
        //this.reduxStore.dispatch({type: 'SET_LOGOUTTIME', logoutTime: time});
        (0, _api.updateSettingsData)('login.mfaHandling', option).then(() => {
          (0, _api.getSettingsData)().then(data => {
            if ((0, _has.default)(data, "data")) {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                settings: (0, _mergeDeep.mergeDeep)(_defaultAppSettings.default, JSON.parse(data.data))
              });
            } else {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                settings: (0, _merge.default)(_defaultAppSettings.default, {})
              });
            }
          });
        }).catch(function () {// don't know
        });
      },

      setLogoutTime(time) {
        this.reduxStore.dispatch({
          type: 'SET_LOGOUTTIME',
          logoutTime: time
        }); //updateSettingsData(thi//s.get('reduxStore.state.settings')).then(() => {

        (0, _api.updateSettingsData)('logoutTime', time).then(() => {
          (0, _api.getSettingsData)().then(data => {
            if ((0, _has.default)(data, "data")) {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                settings: (0, _mergeDeep.mergeDeep)(_defaultAppSettings.default, JSON.parse(data.data))
              });
            } else {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                settings: (0, _merge.default)(_defaultAppSettings.default, {})
              });
            }

            (0, _api.setSessionTimeout)(this.get('reduxStore.state.settings.logoutTime.effective'));
          });
        }).catch(function () {// don't know
        });
      },

      setLockTime(time) {
        this.reduxStore.dispatch({
          type: 'SET_LOCKTIME',
          lockTime: time
        });
        (0, _api.updateSettingsData)('lockTime', time).then(() => {
          (0, _api.getSettingsData)().then(data => {
            if ((0, _has.default)(data, "data")) {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                //              settings: JSON.parse(data.data)
                settings: (0, _mergeDeep.mergeDeep)(_defaultAppSettings.default, JSON.parse(data.data))
              });
            } else {
              this.reduxStore.dispatch({
                type: 'UPDATE_SETTINGS',
                settings: (0, _merge.default)(_defaultAppSettings.default, {})
              });
            } // set how long before deciding it is idle


            if ((0, _idleTimer2.getTimer)()) {
              (0, _idleTimer2.getTimer)().destroy();
              (0, _idleTimer2.setTimer)(null);
            }

            (0, _idleTimer2.setTimer)((0, _idleTimer.default)({
              idleTime: this.get('reduxStore.state.settings.lockTime.effective') * 60 * 1000,
              callback: function callback() {
                // Lock it up
                if (storage.get('sessionToken')) {
                  storage.remove('sessionToken');
                  session.set('timedout', true);
                  window.location.reload(true);
                }
              },
              activeCallback: function activeCallback() {}
            }));
          });
        }).catch(function () {// don't know
        });
      },

      loadSessionHistory() {
        let parser = new _uaParserJs.default(); // by default it takes ua string from current browser's window.navigator.userAgent
        //  var result = parser.getResult();

        (0, _api.getSessions)().then(sessions => {
          (0, _api.getLoginHistory)().then(data => {
            if (data && data.logins) {
              var cleanedData = (0, _map.default)(data.logins, function (element) {
                parser.setUA(element.ua);
                var uaInfo = parser.getResult();
                var newData = {
                  id: element.sessionId,
                  ipaddress: element.ip,
                  useragent: element.ua,
                  os: uaInfo.os,
                  device: uaInfo.device,
                  cpu: uaInfo.cpu,
                  browser: uaInfo.browser,
                  engine: uaInfo.engine,
                  uaInfo: uaInfo,
                  logintime: moment(element.loginTime.replace(/([.])\w+/, ""), "MM/DD/YYYY hh:mm:ss.SSS").format("MM/DD/YYYY h:mm:ss A"),
                  success: !element.failure,
                  logintimeformatted: moment(element.loginTime.replace(/([.])\w+/, ""), "MM/DD/YYYY hh:mm:ss.SSS").format("MM/DD/YYYY h:mm:ss A"),
                  geo: element.geo,
                  locationFormatted: (0, _trim.default)((0, _compact.default)((0, _map.default)([element.geo.city, element.geo.stateProvince, element.geo.countryCode], function (item) {
                    if (item) {
                      return item;
                    }
                  })).join(", ")),

                  /*     active: function () {
                    return !isUndefined(this.get('activeSession'))
                   }.property('activeSession'),*/
                  active: !!(0, _find.default)(sessions.entries, {
                    session: element.sessionId
                  }),
                  activeSession: (0, _find.default)(sessions.entries, {
                    session: element.sessionId
                  }),
                  isCurrentSession: Ember.computed('activeSession', function () {
                    return this.active ? this.get('activeSession.currentSession') ? true : false : false;
                  }),
                  mapURL: Ember.computed("geo.{latitude,longitude}", function () {
                    /*
                                        let encoded = "";
                                          let radiusColor = this.get('geo.accuracyRadius') <= 5 ? "1daa2880" : this.get('geo.accuracyRadius') <= 10 ? "dce107b3"  : "F40707";
                                        encoded += "&path=fillcolor:0x" + radiusColor + "|color:0x" + radiusColor + "|enc:" + GMapCircle(this.get('geo.latitude'), this.get('geo.longitude'), this.get('geo.accuracyRadius'), 8);
                                          return "https://maps.google.com/maps/api/staticmap?markers=" + this.get("geo.latitude") + "," + this.get("geo.longitude") + "&size=500x300&maptype=roadmap" + encoded;
                     */
                  })
                };
                return Ember.Object.extend(newData).create();
              });
              this.reduxStore.dispatch({
                type: 'SET_ACTIVE_SESSIONS',
                activeSessions: cleanedData
              });
            }
          });
        });
      },

      /* Password filter actions */
      clearAllFilters: (0, _emberConcurrency.task)(function* () {
        this.reduxStore.dispatch({
          type: 'SELECT_LABELS',
          labels: []
        });
        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS',
          teams: []
        });
        this.reduxStore.dispatch({
          type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
        }); // this.reduxStore.state.data.connections.selectedTeamsPerConnection.content.clear();

        const passwords = yield this.getPasswords();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }).restartable(),
      getPasswordPage: (0, _emberConcurrency.task)(function* (pageNumber) {
        let passwords = yield this.getPasswords(pageNumber);
        this.reduxStore.dispatch({
          type: 'UPDATE_CURRENT_PASSWORD_PAGE',
          currentPage: pageNumber
        });

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }
      }).restartable(),
      togglePasswordsFavoritesFilter: (0, _emberConcurrency.task)(function* (fave, ctrl, shift) {
        if (!ctrl) {
          this.reduxStore.dispatch({
            type: 'SELECT_LABELS',
            labels: []
          });
          this.reduxStore.dispatch({
            type: 'SELECT_STRENGTHS',
            strengths: []
          });
          this.reduxStore.dispatch({
            type: 'SELECT_LABELS',
            labels: []
          });
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: []
          });
        }

        this.reduxStore.dispatch({
          type: 'TOGGLE_PASSWORDS_FAVORITE_FILTER'
        });
        const passwords = yield this.getPasswordsTask.perform();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }).restartable(),
      selectLabelForPasswordFilter: (0, _emberConcurrency.task)(function* (labelId, ctrlKey, shiftKey) {
        var currentLabels = (0, _clone.default)(this.get('reduxStore.state.data.labels.selected')); // This currently doesn't do anything when holding shift, need to fix this to select range.

        if (shiftKey) {
          currentLabels.clear();
          currentLabels.push(labelId.id);
        } else {
          var context = labelId.id;

          if (ctrlKey) {
            if (!(0, _includes.default)(currentLabels, context)) {
              currentLabels.push(context);
            } else {
              (0, _remove.default)(currentLabels, function (item) {
                return item === context;
              });
            }
          } else {
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });

            if (!(0, _includes.default)(currentLabels, context) || currentLabels.length > 1) {
              currentLabels.clear();
              currentLabels.push(context);
            } else {
              currentLabels.clear();
            }
          }
        }

        this.reduxStore.dispatch({
          type: 'SELECT_LABELS',
          labels: currentLabels
        });
        const passwords = yield this.getPasswordsTask.perform();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }).restartable(),
      toggleLabelsFilter: (0, _emberConcurrency.task)(function* (labels, ctrl, shift) {
        if (!ctrl) {
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: []
          });
          this.reduxStore.dispatch({
            type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
          });
        }

        this.reduxStore.dispatch({
          type: 'SELECT_LABELS',
          labels: this.reduxStore.state.data.labels.selectedState === false ? (0, _map.default)(this.reduxStore.state.data.labels.content, 'id') : []
        });
        const passwords = yield this.getPasswordsTask.perform();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }).restartable(),
      selectTeamForPasswordFilter: (0, _emberConcurrency.task)(function* (ids, ctrlKey, shiftKey) {
        let currentGroups = storage.get('groups', []);

        if (shiftKey) {
          currentGroups.clear();
          currentGroups.push(ids.id);
        } else {
          let context = ids.id;

          if (ctrlKey) {
            if (!(0, _includes.default)(currentGroups, context)) {
              currentGroups.push(context);
            } else {
              (0, _remove.default)(currentGroups, function (item) {
                return item === context;
              });
            }
          } else {
            this.reduxStore.dispatch({
              type: 'SELECT_LABELS',
              labels: []
            });
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });

            if (!(0, _includes.default)(currentGroups, context) || currentGroups.length > 1) {
              currentGroups.clear();
              currentGroups.push(context);
            } else {
              currentGroups.clear();
            }
          }
        }

        storage.set('groups', currentGroups);
        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS',
          teams: currentGroups
        });
        const passwords = yield this.getPasswordsTask.perform();

        if (passwords) {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        }

        this.passwordGrid.setVScrollValue(0);
      }),
      selectAllOwnedTeamsForPasswordFilter: (0, _emberConcurrency.task)(function* (args, ctrlKey, shiftKey) {
        if (this.get('reduxStore.state.data.teams.allOwnedSelected')) {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
            return item.role === 1 && item.name !== 'Default' && !item.individual;
          });
          let currentGroups = storage.get('groups', []);
          (0, _forEach.default)(owned, function (group) {
            if ((0, _includes.default)(currentGroups, group.id)) {
              (0, _pull.default)(currentGroups, group.id);
            }
          });

          if (!ctrlKey) {
            this.reduxStore.dispatch({
              type: 'SELECT_LABELS',
              labels: []
            });
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });
          }

          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: currentGroups
          });
          const passwords = yield this.getPasswords();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } else {
          let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
            return item.role === 1 && item.name !== 'Default' && !item.individual;
          });
          let currentGroups = storage.get('groups', []);
          (0, _forEach.default)(owned, function (group) {
            if (!(0, _includes.default)(currentGroups, group.id) || currentGroups.length > 1) {
              currentGroups.push(group.id);
            }
          });

          if (!ctrlKey) {
            this.reduxStore.dispatch({
              type: 'SELECT_LABELS',
              labels: []
            });
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });
          }

          storage.set('groups', currentGroups);
          this.reduxStore.dispatch({
            type: 'SET_SELECTED_TEAMS',
            teams: currentGroups
          });
          const passwords = yield this.getPasswords();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        }
      }).restartable(),
      selectConnectionSharedToViaTeams: (0, _emberConcurrency.task)(function* (connection, ctrlKey, shiftKey) {
        try {
          let teams = [];
          teams = connection.memberOfTeams;
          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)((0, _map.default)(teams, 'teamId'), item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), (0, _map.default)(teams, 'teamId'));
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = (0, _map.default)(teams, team => {
                return team.teamId;
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }),
      selectConnectionSharedFromViaTeams: (0, _emberConcurrency.task)(function* (connection, ctrlKey, shiftKey) {
        try {
          let teams = [];
          teams = connection.ownedTeams;
          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team.teamId);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)((0, _map.default)(teams, 'teamId'), item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), (0, _map.default)(teams, 'teamId'));
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = (0, _map.default)(teams, team => {
                return team.teamId;
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }),
      selectConnectionIndividualSharedFromTeam: (0, _emberConcurrency.task)(function* (connection, ctrlKey, shiftKey) {
        try {
          let teams = [];
          teams = [connection.individualTeamSharedFromConnection];
          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)(teams, item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), teams);
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = teams;
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }).restartable(),
      selectConnectionIndividualSharedToTeam: (0, _emberConcurrency.task)(function* (connection, ctrlKey, shiftKey) {
        try {
          let teams = [];
          teams = [connection.individualTeamSharedToConnection];
          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)(teams, item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), teams);
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = teams;
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }),
      selectAllStrengthsForPasswordFilter: (0, _emberConcurrency.task)(function* (strength, ctrlKey, shiftKey) {
        try {
          let strengths = [];

          if (ctrlKey) {
            if ((0, _some.default)(this.reduxStore.state.data.passwords.strengthsFilter, function (item) {
              return (0, _includes.default)([0, 1, 2, 3, 4], item);
            })) {
              strengths = [];
            } else {
              strengths = [0, 1, 2, 3, 4];
            }

            this.reduxStore.dispatch({
              type: 'SELECT_STRENGTHS',
              strengths: strengths
            });
          } else {
            if ((0, _some.default)(this.reduxStore.state.data.passwords.strengthsFilter, function (item) {
              return (0, _includes.default)([0, 1, 2, 3, 4], item);
            })) {
              strengths = [];
            } else {
              strengths = [0, 1, 2, 3, 4];
            }

            this.reduxStore.dispatch({
              type: 'SELECT_LABELS',
              labels: []
            });
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });
            this.reduxStore.dispatch({
              type: 'SELECT_STRENGTHS',
              strengths: strengths
            });
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }).restartable(),
      selectStrengthForPasswordFilter: (0, _emberConcurrency.task)(function* (strength, ctrlKey, shiftKey) {
        try {
          let strengths = [];

          if (ctrlKey) {
            if ((0, _includes.default)(this.reduxStore.state.data.passwords.strengthsFilter, strength)) {
              strengths = (0, _filter.default)(this.reduxStore.state.data.passwords.strengthsFilter, pwStrength => {
                return pwStrength !== strength;
              });
            } else {
              strengths = (0, _concat.default)(this.reduxStore.state.data.passwords.strengthsFilter, strength);
            }

            this.reduxStore.dispatch({
              type: 'SELECT_STRENGTHS',
              strengths: strengths
            });
          } else {
            if ((0, _includes.default)(this.reduxStore.state.data.passwords.strengthsFilter, strength)) {
              strengths = [];
            } else {
              strengths = [strength];
            }

            this.reduxStore.dispatch({
              type: 'SELECT_LABELS',
              labels: []
            });
            this.reduxStore.dispatch({
              type: 'SET_SELECTED_TEAMS',
              teams: []
            });
            this.reduxStore.dispatch({
              type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
            });
            this.reduxStore.dispatch({
              type: 'SELECT_STRENGTHS',
              strengths: strengths
            });
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }).restartable(),
      selectAllConnectionsForPasswordFilter: (0, _emberConcurrency.task)(function* (allConnectionsTeams, ctrlKey, shiftKey) {
        try {
          let teams = [];
          teams = allConnectionsTeams;
          let all = (0, _every.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });
          let someSelected = (0, _some.default)(teams, team => {
            return (0, _includes.default)(this.get('reduxStore.state.data.teams.selected'), team);
          });

          if (ctrlKey) {
            if (all || someSelected) {
              let newTeams = (0, _filter.default)(this.get('reduxStore.state.data.teams.selected'), item => {
                return !(0, _includes.default)(teams, item);
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            } else {
              let newTeams = (0, _concat.default)(this.get('reduxStore.state.data.teams.selected'), teams);
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          } else {
            if (all || someSelected) {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
            } else {
              this.reduxStore.dispatch({
                type: 'SELECT_LABELS',
                labels: []
              });
              this.reduxStore.dispatch({
                type: 'CLEAR_PASSWORDS_FAVORITE_FILTER'
              });
              let newTeams = teams;
              this.reduxStore.dispatch({
                type: 'SET_SELECTED_TEAMS',
                teams: newTeams
              });
            }
          }

          const passwords = yield this.getPasswordsTask.perform();

          if (passwords) {
            this.reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: passwords
            });
          }

          this.passwordGrid.setVScrollValue(0);
        } catch (e) {
          console.log(e);
        }
      }).restartable()
      /* End password filter actions */

    });
    appInstance.register('service:global-actions', actions, {
      instantiate: true
    });
    appInstance.inject('service:init-app', 'globalActions', 'service:global-actions');
  }

  var _default = {
    name: 'global-actions',
    initialize
  };
  _exports.default = _default;
});