define("web-client/components/confirm-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UUY9UvBm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"\\n  \"],[1,[29,\"nb-dialog-header\",null,[[\"small\",\"title\",\"cancelDialog\"],[true,[29,\"t\",[[25,[\"dialogText\",\"title\"]]],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\\n\\n\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"ion-grid\"],[9],[0,\"\\n      \"],[7,\"ion-row\"],[9],[0,\"\\n        \"],[7,\"ion-col\"],[11,\"col-12\",\"\"],[9],[0,\"\\n          \"],[1,[29,\"t\",[[25,[\"dialogText\",\"message\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"dialogText\",\"message2\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ion-row\"],[9],[0,\"\\n          \"],[7,\"ion-col\"],[11,\"col-12\",\"\"],[9],[0,\"\\n            \"],[1,[29,\"t\",[[25,[\"dialogText\",\"message2\"]]],null],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,[[\"small\"],[true]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"left-buttons\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[[25,[\"dialogText\",\"cancelButtonText\"]]],null],[29,\"t\",[[25,[\"dialogText\",\"cancelButtonTitle\"]]],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[[25,[\"dialogText\",\"continueButtonText\"]]],null],[29,\"t\",[[25,[\"dialogText\",\"continueButtonTitle\"]]],null],[29,\"action\",[[24,0,[]],\"ok\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/confirm-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});