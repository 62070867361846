define("web-client/settings/company/license-management/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    router: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Company License Management"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "CompanyLicenseManagement"
        });
      }

    },

    activate() {
      this._super.apply(this, arguments); // if the user is not allowed redirect them elsewhere.


      let mayView = this.globalActions.doesPermissionExist('settings', 'company.licenseManagement', 'ro');

      if (!mayView) {
        this.router.transitionTo('settings');
      }

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      }); //      // Subscription Summary Data
      // These can be removed after handling in feeds

      this.globalActions.updateSubscriptionData();
      this.globalActions.updateOrganizationLicenses();
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('rootURL', this.router.rootURL);
    },

    renderTemplate() {
      this.render('settings/company/license-management', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});