define("web-client/services/init-app-utils/get-organization-list", ["exports", "web-client/api", "lodash-es/eq", "lodash-es/get"], function (_exports, _api, _eq, _get) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getOrganizationList;

  function getOrganizationList() {
    let reduxStore = this.reduxStore;
    return function getOrganizationList(callback) {
      (0, _api.startupLog)({
        actionText: "Loading Company List"
      });
      (0, _api.getOrganizationList)().then(function (data) {
        reduxStore.dispatch({
          type: 'UPDATE_ORGANIZATION_LIST',
          companyData: data.companyData
        });
        callback();
      }).catch(function (data) {
        callback('Failed getOrganizationList');
      });
    };
  }
});