define("web-client/settings/company/company-settings/controller", ["exports", "lodash-es/each", "lodash-es/filter", "lodash-es/has", "lodash-es/isNumber", "web-client/api"], function (_exports, _each, _filter, _has, _isNumber, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    notifications: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    isUpdating: false,
    mayViewSection: false,
    mayChangeSection: false,
    organizationSettings: {},
    unlockMessageHeader: "Input Disabled",
    unlockMessage: "Business Plan Feature",

    init() {
      this._super.apply(this, arguments);

      console.log("controller initializing");
      this.mayViewSection = this.globalActions.doesPermissionExist('settings', 'company.company-settings', 'ro');
      this.mayChangeSection = this.globalActions.doesPermissionExist('settings', 'company.company-settings', 'rw');
    },

    isBusinessPlan: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let businessPlan = false; // Determine if this is a business plan or not

      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Business Plan') {
        businessPlan = true;
      }

      return businessPlan;
    }),
    saveOrganizationSetting: function saveOrganizationSetting(section, key, value) {
      let self = this; //    let deferred = defer();

      (0, _api.saveOrganizationSetting)(section, key, value).then(() => {
        self.globalActions.updateOrganizationSettings(); //      deferred.resolve();
      }).catch(data => {
        if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
          self.notifications.show(self.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'), 'alert');
        } else {
          self.notifications.show(self.get("i18n").t((0, _api.getLanguageErrorKey)(data.status.code)), 'alert');
        } //       deferred.reject(data);

      });
    },
    sendOrganizationCommand: function sendOrganizationCommand(command) {
      let deferred = Ember.RSVP.defer();
      (0, _api.sendOrganizationCommand)(command).then(() => {
        this.globalActions.updateOrganizationSettings();
        deferred.resolve();
      }).catch(error => {
        console.log(error);
        deferred.reject();
      });
    },
    actions: {}
  });

  _exports.default = _default;
});