define("web-client/components/process-payment-default-card-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P5ItjyPp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"nb-dialog\",null,[[\"tabindex\"],[0]],{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[\"Upgrade your plan?\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\\n\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n\"],[4,\"nb-layout-row\",null,null,{\"statements\":[[4,\"nb-layout-column\",null,[[\"col-xs\"],[12]],{\"statements\":[[0,\"\\n                  \"],[7,\"p\"],[9],[0,\"Upgrade your plan to \"],[7,\"strong\"],[9],[1,[23,\"description\"],false],[10],[0,\"? You will be charged a prorated amount of \"],[7,\"strong\"],[9],[1,[29,\"format-currency\",[[25,[\"amount\"]]],null],false],[10],[0,\" immediately.\"],[10],[7,\"p\"],[9],[0,\" Future charges will occur on your plan renewal date \"],[7,\"strong\"],[9],[1,[29,\"passpack-date\",[[25,[\"reduxStore\",\"state\",\"subscriptionSummary\",\"subscriptionRenewalDate\"]]],null],false],[10],[0,\".\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,null,{\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"left-buttons\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[\"Common.Buttons.Cancel.Title\"],null],[29,\"t\",[\"Common.Buttons.Cancel.Title\"],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n    \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Purchase Now\",\"Purchase now\",[29,\"action\",[[24,0,[]],\"purchase\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/process-payment-default-card-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});