define("web-client/components/read-only-input-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMESRohA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"read-only-input-display\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"read-only-input-display-header\"],[9],[0,\"\\n    \"],[1,[23,\"displayHeader\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"read-only-input-display-text\"],[9],[0,\"\\n    \"],[1,[23,\"displayText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/read-only-input-display/template.hbs"
    }
  });

  _exports.default = _default;
});