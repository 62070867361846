define("web-client/api/rsa-decrypt", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsaDecrypt = void 0;

  /**
   * params
   *data, encryptedPrivateKey
   *
   */
  const rsaDecrypt = _ipcClient.ipc.makeRequestFunction('rsaDecrypt');

  _exports.rsaDecrypt = rsaDecrypt;
});