define("web-client/themes/default/theme", ["exports", "ui-core/utils/colors"], function (_exports, _colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'nb-basic-layout': [{
      context: 'default',
      'header-background-color': {
        "standard": "#001c53"
      },
      'header-height': '92px',
      'left-sidebar-background-color': _colors.default.WHITE,
      'left-sidebar-width': "100%"
    }],
    'nb-input': [{
      context: 'default',
      'focused-label-color': '#111',
      'label-color': '#222',
      'error-label-color': _colors.default.RED_700,
      'focused-error-label-color': _colors.default.RED_700,
      'text-color': '#222',
      'error-text-color': _colors.default.RED_700,
      'hint-text-color': _colors.default.GREY_500,
      'border-color': _colors.default.GREY_500,
      'focused-border-color': _colors.default.GREY_700
    }, {
      context: 'auth',
      'focused-label-color': _colors.default.WHITE,
      'label-color': _colors.default.WHITE,
      'error-label-color': _colors.default.WHITE,
      'focused-error-label-color': _colors.default.WHITE,
      'text-color': '#222',
      'error-text-color': _colors.default.WHITE,
      'hint-text-color': _colors.default.GREY_500
    }],
    'nb-color-select': [{
      context: 'default',
      'focused-label-color': _colors.default.GREY_900,
      'label-color': _colors.default.GREY_600,
      'error-label-color': _colors.default.RED_700,
      'focused-error-label-color': _colors.default.RED_700,
      'text-color': '#222',
      'error-text-color': _colors.default.RED_700,
      'hint-text-color': _colors.default.GREY_500,
      'border-color': _colors.default.GREY_500,
      'focused-border-color': _colors.default.GREY_800
    }],
    'nb-textarea': [{
      context: 'default',
      'focused-border-color': _colors.default.GREY_800,
      'border-color': _colors.default.GREY_500,
      'border-error-color': _colors.default.RED_700,
      'focused-border-error-color': _colors.default.RED_700,
      'focused-label-color': _colors.default.GREY_900,
      'label-color': _colors.default.GREY_600,
      'error-label-color': _colors.default.RED_700,
      'focused-error-label-color': _colors.default.RED_700,
      'text-color': '#222',
      'error-text-color': _colors.default.RED_700,
      'hint-text-color': _colors.default.GREY_500
    }],
    'nb-select': [{
      context: 'default',
      'focused-underline-color': _colors.default.GREY_9000,
      'underline-color': _colors.default.GREY_300,
      'focused-label-color': _colors.default.GREY_900,
      'label-color': _colors.default.GREY_600,
      'error-label-color': _colors.default.RED_700,
      'focused-error-label-color': _colors.default.RED_700,
      'text-color': '#222',
      'error-text-color': _colors.default.RED_700,
      'hint-text-color': _colors.default.GREY_500,
      'focused-border-color': _colors.default.GREY_800
    }],
    'nb-checkbox': [],
    'nb-fab': [{
      context: 'default',
      'background-color': {
        "mini": _colors.default.RED_700,
        "normal": _colors.default.RED_700,
        "large": _colors.default.RED_700
      },
      'focused-text-color': {
        "flat-text": _colors.default.BLACK,
        "raised-text": _colors.default.WHITE,
        'flat-icon': _colors.default.WHITE,
        'raised-icon': _colors.default.WHITE
      },
      'text-color': {
        "flat-text": _colors.default.BLACK,
        "raised-text": _colors.default.WHITE,
        'flat-icon': _colors.default.WHITE,
        'raised-icon': _colors.default.WHITE
      },
      'focused-background-color': {
        "flat-text": _colors.default.GREY,
        "raised-text": _colors.default.BLUE_A700,
        'flat-icon': _colors.default.BLUE_A700,
        'raised-icon': _colors.default.BLUE_A700
      },
      'pressed-background-color': {
        "flat-text": _colors.default.GREY,
        "raised-text": _colors.default.BLUE_A700,
        'flat-icon': _colors.default.BLUE_A700,
        'raised-icon': _colors.default.BLUE_A700
      },
      'pressed-text-color': {
        "flat-text": _colors.default.BLACK,
        "raised-text": _colors.default.WHITE,
        'flat-icon': _colors.default.WHITE,
        'raised-icon': _colors.default.WHITE
      }
    }],
    'nb-button': [{
      context: 'default',
      "text-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.WHITE
      },
      "border": {
        "flat-text": "1px solid transparent"
      },
      "focused-border": {
        "flat-text": "1px solid #111"
      },
      "background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": '#444'
      },
      "pressed-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": '#333'
      },
      "focused-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": "#333"
      }
    }, {
      context: 'auth',
      "text-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.WHITE
      },
      "border": {
        "flat-text": "1px solid #222"
      },
      "focused-border": {
        "flat-text": "1px solid #111"
      },
      "background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": '#444'
      },
      "pressed-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": '#333'
      },
      "focused-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": "#333"
      }
    }, {
      context: 'secondary-dialog-button',
      "text-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.WHITE
      },
      "background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_500
      },
      "pressed-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_300
      },
      "focused-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_700
      }
    }, {
      context: 'primary-dialog-button',
      "text-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.WHITE
      },
      "background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_800
      },
      "pressed-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_900
      },
      "focused-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_700
      }
    }, {
      context: 'auxillary-dialog-button',
      "text-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.WHITE
      },
      "background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_400
      },
      "pressed-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_600
      },
      "focused-background-color": {
        "raised-text": _colors.default.GREEN_700,
        "flat-text": _colors.default.GREY_700
      }
    }, {
      context: 'header',
      'background-color': {
        "flat-text": "transparent",
        "raised-text": _colors.default.BLUE_500,
        'flat-icon': "transparent",
        'raised-icon': _colors.default.BLUE_500
      },
      "text-color": {
        "flat-text": _colors.default.WHITE
      },
      'focused-background-color': {
        'flat-icon': "rgba(255,255,255,.2)",
        'flat-text': "rgba(255,255,255,.2)"
      },
      'pressed-background-color': {
        'flat-icon': "rgba(255,255,255,.2)",
        'flat-text': "rgba(255,255,255,.2)"
      },
      'ink-color': {
        'flat-icon': "rgba(255,255,255,.3)",
        'flat-text': "rgba(255,255,255,.2)"
      }
    }, {
      context: 'toolbar',
      "text-color": {
        "flat-text": _colors.default.WHITE
      },
      'background-color': {
        'flat-icon': "transparent"
      },
      'focused-background-color': {
        'flat-icon': "rgba(255,255,255,.2)"
      },
      'pressed-background-color': {
        'flat-icon': "rgba(255,255,255,.2)"
      },
      'ink-color': {
        'flat-icon': "rgba(255,255,255,.3)"
      }
    }]
  };
  _exports.default = _default;
});