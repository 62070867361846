define("web-client/components/transfer-ownership-dialog/team-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KN5CPBkw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-icon\"],[12,\"name\",[30,[[29,\"if\",[[25,[\"item\",\"individual\"]],\"contact\",\"folder\"],null]]]],[12,\"class\",[30,[\"item-icon \",[25,[\"item\",\"color\"]]]]],[9],[10],[0,\"\\n\"],[7,\"ion-label\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n\"],[7,\"ion-radio\"],[11,\"slot\",\"start\"],[9],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/transfer-ownership-dialog/team-item/template.hbs"
    }
  });

  _exports.default = _default;
});