define("web-client/settings/session-settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    classNames: ["license-management"],
    currencyFormatter: {},

    init() {
      this._super.apply(this, arguments);
    },

    convertMinToDisplay: function convertMinToDisplay(inputMin) {
      if (5 === inputMin) {
        return "5 Minutes";
      } else if (15 === inputMin) {
        return "15 Minutes";
      } else if (30 === inputMin) {
        return "30 Minutes";
      } else if (60 === inputMin) {
        return "1 Hour";
      } else if (1440 === inputMin) {
        return "1 Day";
      } else if (10080 === inputMin) {
        return "1 Week";
      } else {
        return inputMin + " Minutes";
      }
    },
    overrideSessionTimeoutDescription: Ember.computed('reduxStore.state.settings.logoutTime', function () {
      let value = this.get('reduxStore.state.settings.logoutTime.effective');
      let convertedVal = this.convertMinToDisplay(value);
      return convertedVal;
    }),
    overrideIdleLockDescription: Ember.computed('reduxStore.state.settings.lockTime', function () {
      let value = this.get('reduxStore.state.settings.lockTime.effective');
      let convertedVal = this.convertMinToDisplay(value);
      return convertedVal;
    }),
    actions: {}
  });

  _exports.default = _default;
});