define("web-client/components/company-settings-sections/membership-controls/component", ["exports", "lodash-es/each", "lodash-es/filter"], function (_exports, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tagName: 'sso',
    globalActions: Ember.inject.service(),
    classNames: [''],

    init() {
      this._super.apply(this, arguments);

      this.set('adminExternalConnectionOptions', [{
        value: "disabled",
        display: 'Disable External'
      }, {
        value: "external_any",
        display: 'Any Company'
      }]);
      this.set('adminCompanyMembershipOptions', [{
        value: "verified",
        display: 'Verified Domain Only'
      }, {
        value: "verified_allowed",
        display: 'Verified + Allowed'
      }, {
        value: "any",
        display: 'Any Domain Allowed'
      }]);
    },

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
    },

    getExternalConnectionOptionValue: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.get("externalConnection");
    }),
    getCompanyMembershipOptionValue: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.get("companyMembership");
    }),
    populateLocalData: function populateLocalData() {
      let orgSettings = this.get('reduxStore.state.orgSettings');

      if (orgSettings) {
        if (orgSettings.membership) {
          try {
            this.set('externalConnection', orgSettings.membership.externalConnection);
            this.set('companyMembership', orgSettings.membership.companyMembership);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    actions: {
      setExternalConnectionOption(option) {
        if (this.mayChangeSection) {
          this.saveOrganizationSetting("membership", "externalConnection", option);
        }
      },

      setCompanyMembershipOption(option) {
        if (this.mayChangeSection) {
          this.saveOrganizationSetting("membership", "companyMembership", option);
        }
      }

    }
  });

  _exports.default = _default;
});