define("web-client/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    globalActions: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);

      this.set('userMFAOptions', [{
        value: "none",
        display: 'Use MFA'
      }, {
        value: "bypass",
        display: 'Bypass MFA'
      }]);
    },

    layoutDirection: Ember.computed('media.classNames', function () {
      return !(this.media.get('isMediaLG') || this.media.get('isMediaXL') || this.media.get('isMediaMD')) ? "vertical" : "horizontal";
    }),
    //
    //  ssoEnabled: computed('reduxStore.state.sso', function() {
    //   let sso = this.get('reduxStore.state.sso');
    //   console.log("sso: " + JSON.stringify(sso));
    //   if (sso && sso.enabledForAccount===true) {
    //    return true;
    //   } else {
    //    return false;
    //   }
    //  }),
    getUserMfaHandling: Ember.computed('reduxStore.state.settings.login', function () {
      let value = this.get('reduxStore.state.settings.login.mfaHandling.original');

      if (value) {
        return value;
      } else {
        return "none";
      }
    }),
    getMfaHandlingBusinessHeader: Ember.computed('reduxStore.state.settings.login.mfaHandling', function () {
      let value = this.get('reduxStore.state.settings.login.mfaHandling.overridden');

      if (value && value === true) {
        return "Company Settings";
      } else {
        return "";
      }
    }),
    getMfaHandlingBusinessText: Ember.computed('reduxStore.state.settings.login.mfaHandling', function () {
      let overridden = this.get('reduxStore.state.settings.login.mfaHandling.overridden');
      let effectiveValue = this.get('reduxStore.state.settings.login.mfaHandling.effective');
      let originalValue = this.get('reduxStore.state.settings.login.mfaHandling.original');

      if (overridden && effectiveValue === 'require') {
        return "You belong to an organization which requires MFA during SSO logins.";
      }

      if (overridden && effectiveValue === 'bypass') {
        return "You belong to an organization which allows you to bypass MFA during SSO logins.";
      } // default


      return "";
    }),
    getMfaHandlingBusinessEffect: Ember.computed('reduxStore.state.settings.login.mfaHandling', function () {
      let overridden = this.get('reduxStore.state.settings.login.mfaHandling.overridden');
      let effectiveValue = this.get('reduxStore.state.settings.login.mfaHandling.effective');
      let companyDefault = this.get('reduxStore.state.settings.login.mfaHandling.companyDefault');
      let originalValue = this.get('reduxStore.state.settings.login.mfaHandling.original');

      if (overridden && effectiveValue === 'require') {
        return "Complete MFA during SSO login";
      }

      if (overridden && effectiveValue === 'bypass') {
        let response = "";

        if (originalValue && originalValue === 'bypass') {
          response = "Bypass MFA during SSO login";
        } else if (originalValue && originalValue === 'none') {
          response = "If configured, complete MFA during SSO login";
        }

        return response;
      } // No overrides here


      if (originalValue && originalValue === 'none') {
        return "If configured, complete MFA during SSO login";
      } else {
        return "Bypass MFA during SSO login";
      }
    }),
    isBusinessPlan: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let businessPlan = false; // Determine if this is a business plan or not

      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Business Plan') {
        businessPlan = true;
      }

      return businessPlan;
    }),
    actions: {
      setMfaHandling(option) {
        console.log("Setting mfa handling: " + option);
        this.globalActions.setUserMfaHandling(option);
      }

    }
  });

  _exports.default = _default;
});