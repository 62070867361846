define("web-client/helpers/url-to-host-url", ["exports", "urijs"], function (_exports, _urijs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.urlToHostURL = urlToHostURL;
  _exports.default = void 0;

  function urlToHostURL(params) {
    let value = params[0];

    try {
      var uri = new _urijs.default(value); //  return uri.protocol() + "//" + uri.hostname();

      return _urijs.default.build({
        protocol: uri.protocol(),
        hostname: uri.hostname(),
        port: uri.port()
      });
    } catch (e) {
      return "";
    }
  }

  var _default = Ember.Helper.helper(urlToHostURL);

  _exports.default = _default;
});