define("web-client/components/email-address-dialog/component", ["exports", "ui-core/mixins/dialog-base", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/api", "lodash-es/merge"], function (_exports, _dialogBase, _emberRedux, _emberConcurrency, _emberCpValidations, _api, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newEmailAddress: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Email address can't be blank"
    }), (0, _emberCpValidations.validator)('email', {
      message: "Must be a valid email address"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-3',
    newEmailAddress: '',
    notifications: Ember.inject.service(),
    emailAddress: Ember.computed.alias('reduxStore.state.emailAddress'),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    validator: null,
    nextAction: null,

    init() {
      this._super.apply(this, arguments);

      this.set('newEmailAddress', this.get('reduxStore.state.emailAddress'));
    },

    hasChanged: Ember.computed('emailAddress', 'newEmailAddress', function () {
      return this.emailAddress !== this.newEmailAddress;
    }),
    showErrors: false,
    showEmailAddressErrors: Ember.computed('showErrors', 'newEmailAddress', function () {
      return this.showErrors || this.newEmailAddress.length;
    }),
    saveEmailAddress: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          return (0, _api.updateEmailAddress)(this.newEmailAddress);
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        //console.log(result);
        if (result !== 'validation-failed') {
          this.notifications.show(this.i18n.t("Dialog.EmailAddressDialog.MustVerify.Text"));
          (0, _api.getAccountDetails)().then(data => {
            this.reduxStore.dispatch({
              type: 'SET_ACCOUNT_DETAILS',
              data: (0, _merge.default)({
                loggedIn: true,
                oobValid: true
              }, data)
            });
            deferred.resolve();
            this.send('remove');
          });
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
        deferred.reject();
      }).finally(() => {
        this.set('showErrors', true);
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog() {
        this.send('remove');
      }

    },

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    }

  });

  _exports.default = _default;
});