define("web-client/settings/company/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route", "web-client/api"], function (_exports, _baseRoute, _authedRoute, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    router: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Company"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "CompanyDetails"
        });
      }

    },

    activate() {
      this._super.apply(this, arguments); // if the user is not allowed redirect them elsewhere.


      let companySettingRight = this.globalActions.doesPermissionExist('settings', 'company', 'ro');
      let sponsoredOnly = this.get('reduxStore.state.licenseSummary.sponsoredOnly');
      let mayViewCompany = !sponsoredOnly && companySettingRight;

      if (!mayViewCompany) {
        this.router.transitionTo('settings');
      }

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('rootURL', this.router.rootURL); // OrganizationDetails

      (0, _api.getOrganizationDetails)().then(data => {
        this.reduxStore.dispatch({
          type: 'INITIALIZE_ORGANIZATION_DETAILS',
          details: data
        });
      }).catch(() => {//console.log('no extra data')
      });
    },

    renderTemplate() {
      this.render('settings/company', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});