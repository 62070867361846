define("web-client/components/dashboard-sidebar-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7a87gtyi",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"tap\",\"selected\"],[\"Usage\",\"stats\",[29,\"service-action\",[\"global-actions\",\"goToDashboard\"],null],[29,\"eq\",[[25,[\"reduxStore\",\"state\",\"dashboardSection\"]],\"Usage\"],null]]]],false],[0,\"\\n  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"tap\",\"selected\"],[\"Security Statistics\",\"contacts\",[29,\"service-action\",[\"global-actions\",\"goToSecurityStats\"],null],[29,\"eq\",[[25,[\"reduxStore\",\"state\",\"dashboardSection\"]],\"Security Statistics\"],null]]]],false],[0,\"\\n\"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"tap\",\"selected\"],[\"System Updates\",\"paper\",[29,\"service-action\",[\"global-actions\",\"goToSystemUpdates\"],null],[29,\"eq\",[[25,[\"reduxStore\",\"state\",\"dashboardSection\"]],\"System Updates\"],null]]]],false],[0,\"\\n\\n\\n\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/dashboard-sidebar-menu/template.hbs"
    }
  });

  _exports.default = _default;
});