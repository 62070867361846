define("web-client/components/add-user-licenses-dialog/component", ["exports", "ui-core/mixins/dialog-base", "debug", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/utils/color-options", "lodash-es/clone", "web-client/api", "lodash-es/find"], function (_exports, _dialogBase, _debug, _emberRedux, _emberConcurrency, _emberCpValidations, _colorOptions, _clone, _api, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const log = (0, _debug.default)("");
  const Validations = (0, _emberCpValidations.buildValidations)({
    additionalLicenses: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Must have at least 1 license to add"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    classNames: ['add-licenses'],
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    currencyFormatter: {},
    computing: false,
    additionalLicenses: 0,
    planAdditionalMin: 0,
    renewalAmountToday: 0,
    renewalAmountNextCycle: 0,
    chargeButtonAmount: "",
    planAdditionalLicensesAllowed: Ember.computed('reduxStore.state.{subscriptionSummary}', function () {
      //     if (this.get('reduxStore.state.currentSubscription.sku')==='team'){
      //      let allowed = parseInt(this.get('reduxStore.state.currentSubscription.allowedLicenseQuantity'));
      //      let currentPurchased = parseInt(this.get('reduxStore.state.subscriptionSummary.totalLicenseCount'));
      //      return allowed-currentPurchased;
      //     } else if (this.get('reduxStore.state.currentSubscription.sku')==='business'){
      //      return 9999;
      //     } else {
      //      let allowed = this.get('reduxStore.state.currentSubscription.allowedLicenseQuantity');
      //      let currentPurchased = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
      //      return allowed-currentPurchased;
      //     }
      return 9999;
    }),
    totalLicensesAfterPurchase: Ember.computed('reduxStore.state.{subscriptionSummary}', 'additionalLicenses', function () {
      let total = parseInt(this.additionalLicenses);

      if (isNaN(total)) {
        total = 0;
      } // Check to see if they are trying to add too many


      if (total > this.planAdditionalLicensesAllowed) {
        total = this.planAdditionalLicensesAllowed;
        this.additionalLicenses = this.planAdditionalLicensesAllowed;
      }

      total += parseInt(this.get('reduxStore.state.subscriptionSummary.totalLicenseCount'));
      return total;
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    chargeButtonDisabled: Ember.computed('additionalLicenses', 'computing', function () {
      let disabled = this.get('computing');
      let validAmount = false;
      let total = parseInt(this.additionalLicenses);

      if (isNaN(total)) {
        total = 0;
      }

      validAmount = 0 === total ? false : true; // The return should indicate true for disabled, false for enabled.

      return disabled || !validAmount;
    }),
    showErrors: false,
    showAdditionalLicensesErrors: Ember.computed('showErrors', 'additionalLicenses', function () {
      return this.showErrors;
    }),

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      this.set('renewalAmountNextCycle', this.get('reduxStore.state.nextSubscription.amount'));
    },

    renewalAmountInternalCompute: function renewalAmountInternalCompute() {
      const deferred = Ember.RSVP.defer();
      this.set('computing', true);

      if (this.additionalLicenses > 0) {
        (0, _api.getAddLicenseEstimate)(this.additionalLicenses).then(data => {
          this.set('renewalAmountToday', data.proratedAmount);
          this.set('chargeButtonAmount', this.currencyFormatter.format(data.proratedAmount));
          this.set('renewalAmountNextCycle', data.totalSubscriptionRenewalAmount);
          deferred.resolve();
        }).catch(e => {
          deferred.reject(e);
        });
      } else {
        this.set('renewalAmountToday', 0);
        deferred.resolve();
      }

      this.set('computing', false);
      return deferred.promise;
    },
    renewalAmountCompute: Ember.observer('additionalLicenses', function () {
      this.renewalAmountInternalCompute();
    }),
    renewalAmount: Ember.computed('renewalAmountNextCycle', function () {
      return this.currencyFormatter.format(this.renewalAmountNextCycle);
    }),
    proratedChargeAmount: Ember.computed('renewalAmountToday', function () {
      return this.currencyFormatter.format(this.renewalAmountToday);
    }),
    chargeButtonAmountText: Ember.computed('chargeButtonAmount', 'additionalLicenses', 'computing', function () {
      if (this.additionalLicenses > 0) {
        if (this.renewalAmountToday > 0) {
          return "Charge " + this.chargeButtonAmount + " to my card";
        } else {
          return "Add Licenses";
        }
      } else {
        return "Please Select Additional Licenses";
      }
    }),
    immediatelyAddLicenses: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          this.loading.show('Adding additional licenses');
          (0, _api.addAddtionalLicensesToSubscription)(this.additionalLicenses).then(result => {
            //this.globalActions.updateSubscriptionData();
            self.loading.dismiss();
            self.send('remove');
            deferred.resolve();
          }).catch(e => {
            self.loading.dismiss();
            this.globalActions.actionUIErrorHandler(e);
            deferred.reject(e);
          });
        } else {
          self.loading.dismiss();
          self.set('showErrors', true);
          deferred.resolve();
        }
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      }
    },

    afterShow() {
      this._super.apply(this, arguments);

      let input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    }

  });

  _exports.default = _default;
});