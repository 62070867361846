define("web-client/components/packing-key-dialog/component", ["exports", "ember-concurrency", "web-client/mixins/auth-base-dialog", "web-client/api", "lodash-es/isEmpty", "ember-cp-validations"], function (_exports, _emberConcurrency, _authBaseDialog, _api, _isEmpty, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    passphrase: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Packing Key can't be blank"
    }), (0, _emberCpValidations.validator)('inline', {
      validate(value, options, model) {
        if (!model.get("correctPackingKey") && model.get('submittedPackingKey') === value) {
          return options.message;
        } else {
          return true;
        }
      },

      message: "You have entered the wrong Packing Key",
      dependentKeys: ['model.correctPackingKey', 'model.submittedPackingKey']
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    classNames: ['size-3'],
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service(),

    /* Holds Username */
    passphrase: '',
    submittedPackingKey: '',
    correctPackingKey: true,
    initApp: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: 'submitPassphrase',
    showErrors: false,
    showPassphraseErrors: Ember.computed('showErrors', 'passphrase', function () {
      return this.showErrors || this.passphrase.length;
    }),
    nextAction: null,
    escAction: "cancelDialog",
    submitPassphrase: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let self = this;
      let check = '';
      let decryptedPrivateKey = '';
      let decryptedDbKey = '';
      let keyData = '';

      try {
        let validResult = yield this.validate();

        if (validResult.validations.isValid) {
          keyData = yield (0, _api.getKeyData)();

          if (!(0, _isEmpty.default)(keyData.data)) {
            check = yield (0, _api.aesDecrypt)(keyData.data, self.get('passphrase'));

            if (check === "c42963b150bf47ad") {
              (0, _api.actionUpdate)('packingKeyEntered');

              if (keyData.privateKey) {
                decryptedPrivateKey = yield (0, _api.aesDecrypt)(keyData.privateKey, self.get('passphrase'));
                let encryptedPassphrase = yield (0, _api.aesEncrypt)(self.get('passphrase'), self.get('reduxStore.state.statusToken'));

                if (encryptedPassphrase) {
                  storage.set('sessionToken', encryptedPassphrase);
                }

                if (!self.reduxStore.get('state.decryptedDbKey')) {
                  decryptedDbKey = yield (0, _api.rsaDecrypt)(self.reduxStore.get('state.settings.dbKey'), decryptedPrivateKey);
                  self.reduxStore.dispatch({
                    type: 'SET_LOGIN_KEYS',
                    keys: {
                      decryptedDbKey: decryptedDbKey,
                      passphrase: self.get('passphrase'),
                      passphraseCheck: keyData.data,
                      publicKey: keyData.publicKey,
                      decryptedPrivateKey: decryptedPrivateKey
                    }
                  });
                  /*  self.reduxStore.dispatch({
                      type: 'SET_DECRYPTED_DB_KEY',
                      decryptedDbKey: decryptedDbKey
                    });
                     self.get('reduxStore').dispatch({
                      type: 'SET_PASSPHRASE',
                      passphrase: self.get('passphrase')
                    });
                    self.get('reduxStore').dispatch({
                      type: 'SET_PASSPHRASE_CHECK',
                      passphraseCheck: keyData.data
                    });
                     if (keyData.publicKey) {
                      self.get('reduxStore').dispatch({
                        type: 'SET_PUBLIC_KEY',
                        publicKey: keyData.publicKey
                      });
                    }
                      self.get('reduxStore').dispatch({
                      type: 'SET_DECRYPTED_PRIVATE_KEY',
                      decryptedPrivateKey: decryptedPrivateKey
                    });
                  */

                  deferred.resolve();
                  self.get('initApp').reInitDatabase();
                  window.location.reload();
                  self.get('goToPreviousRoute')();
                } else {
                  self.reduxStore.dispatch({
                    type: 'SET_LOGIN_KEYS',
                    keys: {
                      decryptedDbKey: self.reduxStore.get('state.decryptedDbKey'),
                      passphrase: self.get('passphrase'),
                      passphraseCheck: keyData.data,
                      publicKey: keyData.publicKey,
                      decryptedPrivateKey: decryptedPrivateKey
                    }
                  });
                  /*    self.get('reduxStore').dispatch({
                        type: 'SET_PASSPHRASE',
                        passphrase: self.get('passphrase')
                      });
                      self.get('reduxStore').dispatch({
                        type: 'SET_PASSPHRASE_CHECK',
                        passphraseCheck: keyData.data
                      });
                       if (keyData.publicKey) {
                        self.get('reduxStore').dispatch({
                          type: 'SET_PUBLIC_KEY',
                          publicKey: keyData.publicKey
                        });
                      }
                        self.get('reduxStore').dispatch({
                        type: 'SET_DECRYPTED_PRIVATE_KEY',
                        decryptedPrivateKey: decryptedPrivateKey
                      });
                    */

                  deferred.resolve();
                  window.location.reload();
                  self.get('goToPreviousRoute')();
                }
              } else {
                self.set('submittedPackingKey', self.get('passphrase'));
                self.set('correctPackingKey', false);
                deferred.resolve();
              }
            } else {
              self.set('submittedPackingKey', self.get('passphrase'));
              self.set('correctPackingKey', false);
              self.get('reduxStore').dispatch({
                type: 'SET_PASSPHRASE',
                passphrase: ''
              });
              deferred.resolve();
            }
          }
        } else {
          deferred.resolve();
          this.set('showErrors', true);
        }
      } catch (e) {
        deferred.resolve();
        this.set('showErrors', true);
      }

      yield deferred.promise;
    }).drop(),
    actions: {
      blockSubmit()
      /*e*/
      {},

      submitPassphrase() {
        this.submitPassphrase.perform();
      },

      cancelDialog: function cancelDialog() {
        this.set('passphrase', '');
        this.set('submittedPackingKey', "");
        this.set('correctPackingKey', true);
        this.signOut();
      }
    }
  });

  _exports.default = _default;
});