define("web-client/utils/misc-utils", ["exports", "lodash-es/toArray", "lodash-es/tail", "lodash-es/first", "lodash-es/sortBy", "lodash-es/max", "lodash-es/map", "lodash-es/includes", "lodash-es/flatten"], function (_exports, _toArray, _tail, _first, _sortBy, _max, _map, _includes, _flatten) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.existy = existy;
  _exports.cat = cat;
  _exports.construct = construct;
  _exports.processSearchParams = processSearchParams;
  _exports.dispatch = dispatch;
  _exports.eachSeries = eachSeries;
  _exports.isValidURL = isValidURL;
  _exports.arraysIdentical = arraysIdentical;
  _exports.arraysIdenticalAnyOrder = arraysIdenticalAnyOrder;
  _exports.escapeHtml = escapeHtml;
  _exports.regexEscape = regexEscape;
  _exports.displaySize = displaySize;
  _exports.secondsToString = secondsToString;
  _exports.escapeRegExp = escapeRegExp;
  _exports.topZIndex = topZIndex;
  _exports.maxZIndex = maxZIndex;
  _exports.randString = randString;
  _exports.uniqID = _exports.getURIInfo = void 0;

  /**
   * Utility Functions
   *
   */
  function existy(x) {
    return x != null;
  }
  /**
   *
   * @returns {*}
   */


  function cat() {
    var head = (0, _first.default)(arguments);

    if (existy(head)) {
      return head.concat.apply(head, (0, _tail.default)(arguments));
    } else {
      return [];
    }
  }
  /**
   *
   * @param head
   * @param tail
   * @returns {*}
   */


  function construct(head, tail) {
    return cat([head], (0, _toArray.default)(tail));
  }

  function processSearchParams(search, preserveDuplicates) {
    //  option to preserve duplicate keys (e.g. 'sort=name&sort=age')
    preserveDuplicates = preserveDuplicates || false; //  disabled by default

    var outputNoDupes = {}; //  sanity check

    if (!search) {
      throw new Error('processSearchParams: expecting "search" input parameter');
    } //  remove ? separator (?foo=1&bar=2 -> 'foo=1&bar=2')


    search = search.split('?')[1]; //  split apart keys into an array ('foo=1&bar=2' -> ['foo=1', 'bar=2'])

    search = search.split('&'); //  separate keys from values (['foo=1', 'bar=2'] -> [{foo:1}, {bar:2}])
    //  also construct simplified outputObj

    var outputWithDupes = search.map(function (keyval) {
      var out = {};
      keyval = keyval.split('=');
      out[keyval[0]] = keyval[1];
      outputNoDupes[keyval[0]] = keyval[1]; //  might as well do the no-dupe work too while we're in the loop

      return out;
    });
    return preserveDuplicates ? outputWithDupes : outputNoDupes;
  }

  var getURIInfo = (() => {
    var link = document.createElement('a');
    return function (path) {
      //  create a link in the DOM and set its href
      link.setAttribute('href', path); //  return an easy-to-use object that breaks apart the path

      return {
        host: link.hostname,
        //  'example.com'
        port: link.port,
        //  12345
        search: link.search !== "" ? processSearchParams(link.search) : "",
        //  {startIndex: 1, pageSize: 10}
        path: link.pathname,
        //  '/blog/foo/bar'
        protocol: link.protocol //  'http:'

      };
    };
  })();
  /**
   *
   * @returns {Function}
   *
   * Executes each function passed as an argument, returns value of the first one that returns something besides undefined,
   * Order of execution is from left to right.
   * Put your Default function to the far right if you have one.
   */


  _exports.getURIInfo = getURIInfo;

  function dispatch()
  /* funs */
  {
    var funs = (0, _toArray.default)(arguments);
    var size = funs.length;
    return function (target
    /*, args */
    ) {
      var ret;
      var args = (0, _tail.default)(arguments);

      for (var funIndex = 0; funIndex < size; funIndex++) {
        var fun = funs[funIndex];
        ret = fun.apply(fun, construct(target, args));

        if (existy(ret)) {
          return ret;
        }
      }

      return ret;
    };
  }

  function eachSeries(arr, iterator, callback) {
    callback = callback || function () {};

    if (!arr.length) {
      return callback();
    }

    var completed = 0;

    var iterate = function iterate() {
      iterator(arr[completed], function (err) {
        if (err) {
          callback(err);

          callback = function callback() {};
        } else {
          completed += 1;

          if (completed >= arr.length) {
            callback();
          } else {
            iterate();
          }
        }
      });
    };

    iterate();
  }
  /**
   *
   */


  function isValidURL(url) {
    try {
      decodeURI(url);
      /* eslint-disable no-useless-escape */

      return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
    } catch (e) {
      return false;
    }
  }
  /**
   * arraysIdentical
   * Test if arrays are the same length and all values match in order.
   * @param a
   * @param b
   * @returns {boolean}
   */


  function arraysIdentical(a, b) {
    var i = a.length;

    if (i !== b.length) {
      return false;
    }

    while (i--) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  function arraysIdenticalAnyOrder(a, b) {
    var i = a.length;

    if (i !== b.length) {
      return false;
    }

    var sortedA = (0, _sortBy.default)(a);
    var sortedB = (0, _sortBy.default)(b);

    while (i--) {
      if (sortedA[i] !== sortedB[i]) {
        return false;
      }
    }

    return true;
  }

  function escapeHtml(str) {
    var div = document.createElement('div');
    div.appendChild(document.createTextNode(str));
    return div.innerHTML;
  }

  function regexEscape(s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  function displaySize(bytes, si) {
    var thresh = si ? 1000 : 1024;

    if (bytes < thresh) {
      return bytes + ' B';
    }

    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; //var units = si ? ['kB','MB','GB','TB','PB','EB','ZB','YB'] : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];

    var u = -1;

    do {
      bytes /= thresh;
      ++u;
    } while (bytes >= thresh);

    return bytes.toFixed(2) + ' ' + units[u];
  }

  function secondsToString(seconds) {
    function n(n) {
      return n > 9 ? "" + n : "0" + n;
    } //var numyears = Math.floor(seconds / 31536000);
    //var numdays = Math.floor((seconds % 31536000) / 86400);


    var numhours = n(Math.floor(seconds % 31536000 % 86400 / 3600));
    var numminutes = n(Math.floor(seconds % 31536000 % 86400 % 3600 / 60));
    var numseconds = n(Math.floor(seconds % 31536000 % 86400 % 3600 % 60));
    var timeString = numhours + ":" + numminutes + ":" + numseconds + "";
    return timeString;
  } // Referring to the table here:
  // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/regexp
  // these characters should be escaped
  // \ ^ $ * + ? . ( ) | { } [ ]
  // These characters only have special meaning inside of brackets
  // they do not need to be escaped, but they MAY be escaped
  // without any adverse effects (to the best of my knowledge and casual testing)
  // : ! , =
  // my test "~!@#$%^&*(){}[]`/=?+\|-_;:'\",<.>".match(/[\#]/g)


  var specials = [// order matters for these
  "-", "[", "]", // order doesn't matter for any of these
  "/", "{", "}", "(", ")", "*", "+", "?", ".", "\\", "^", "$", "|"];
  var regex = new RegExp('[' + specials.join('\\') + ']', 'g');

  function escapeRegExp(str) {
    return str.replace(regex, "\\$&");
  }

  var uniqID = {
    counter: 0,
    get: function get(prefix) {
      if (!prefix) {
        prefix = "uniqid";
      }

      var id = prefix + "" + uniqID.counter++;

      if (!document.getElementById(id)) {
        return id;
      } else {
        return uniqID.get();
      }
    }
  };
  _exports.uniqID = uniqID;

  function topZIndex() {
    return (0, _max.default)((0, _map.default)(document.querySelectorAll('*'), function (el) {
      return parseInt(getComputedStyle(el, null).getPropertyValue('z-index'));
    }));
  }
  /**
   *
   * @returns integer Highest z-index
   */


  function mapZIndexes(nodes) {
    return (0, _max.default)((0, _flatten.default)((0, _map.default)(nodes, function (item) {
      var computedStyle = window.getComputedStyle(item);
      return [parseInt(computedStyle.getPropertyValue("z-index")), !(0, _includes.default)(['relative', 'absolute'], computedStyle.getPropertyValue("position")) ? mapZIndexes(item.children) : 0];
    })));
  }

  function maxZIndex() {
    return mapZIndexes(document.body.children);
  }

  function randString(x) {
    var s = "";

    while (s.length < x && x > 0) {
      var r = Math.random();
      s += r < 0.1 ? Math.floor(r * 100) : String.fromCharCode(Math.floor(r * 26) + (r > 0.5 ? 97 : 65));
    }

    return s;
  }
});