define("web-client/services/init-app-utils/get-subscription-summary", ["exports", "web-client/api", "lodash-es/eq", "lodash-es/get"], function (_exports, _api, _eq, _get) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSubscriptionSummary;

  function getSubscriptionSummary() {
    let reduxStore = this.reduxStore;
    return function getAccountLicenseSummary(callback) {
      (0, _api.startupLog)({
        actionText: "Loading Subscription Summary"
      });
      (0, _api.getSubscriptionSummary)().then(function (data) {
        reduxStore.dispatch({
          type: 'INITIALIZE_SUBSCRIPTION_SUMMARY',
          nextSubscription: data.next,
          subscriptionSummary: data.subscriptionSummary
        });
        callback();
      }).catch(function (data) {
        callback('Failed getSubscriptionSummary');
      });
    };
  }
});