define("web-client/components/ion-nice-textarea/component", ["exports", "lodash-es/isString", "lodash-es/isArray", "lodash-es/join"], function (_exports, _isString, _isArray, _join) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ["ion-nice-textarea"],
    label: "",
    accept: undefined,
    autocapitalize: "off",
    autocomplete: "off",
    autocorrect: "off",
    autofocus: false,
    clearInput: false,
    clearOnEdit: false,
    color: undefined,
    debounce: 0,
    disabled: false,
    inputmode: undefined,
    max: undefined,
    maxlength: undefined,
    min: undefined,
    minlength: undefined,
    placeholder: undefined,
    readonly: false,
    spellcheck: "false",
    type: "text",
    value: '',
    labelPosition: "stacked",
    buttons: null,
    rows: 1,
    attributeBindings: [],
    error: false,
    errorString: Ember.computed('error', 'showErrors', function () {
      if (this.get("showErrors")) {
        if ((0, _isString.default)(this.get('error'))) {
          return this.get('error');
        }

        if ((0, _isArray.default)(this.get('error'))) {
          return (0, _join.default)(this.get('error'), ', ');
        }
      }

      return "";
    }),
    hasError: Ember.computed('error', 'showErrors', function () {
      return this.get('error') && this.get('error').length && this.get('showErrors');
    }),
    actions: {
      togglePasswordVisible() {
        let element = this.get('inputElement');
        let input = element;

        if (input.type === 'text') {
          this.set('passwordVisible', false);
          input.type = 'password';
        } else {
          input.type = 'text';
          this.set('passwordVisible', true);
        }

        input.focus();
      },

      copyField(e) {
        let element = this.get('inputElement');
        let copyNode = document.getElementById('copyNode');
        e.preventDefault();
        e.stopPropagation();
        copyNode.textContent = this.get('value'); // "Optional" Select some text

        let range = document.createRange();
        range.selectNodeContents(document.getElementById('copyNode'));
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range); // Use try & catch for unsupported browser

        try {
          // The important part (copy selected text)
          let successful = document.execCommand('copy'); // "Optional" remove selected text
          //     sel.removeAllRanges();
          //   copyNode.innerText = '';

          if (successful) {
            this.get('attrs.on-copied')('success');
            /*  self.notifications.info(self.get('i18n').t("Common.Messages.FieldCopied"), {
             autoClear: true,
             clearDuration: 3000
             });*/
          } else {
            this.get('attrs.on-copied')('failed');
            /*self.notifications.show(self.get('i18n').t("Common.Messages.FieldNotCopied"), {
             autoClear: true,
             clearDuration: 3000
             });*/
          }
        } catch (err) {
          this.get('attrs.on-copied')('failed');
          /*
           self.notifications.info(self.get('i18n').t('Common.Messages.ManualCopyInstructions'), {
           autoClear: true,
           clearDuration: 3000
           });
           */
        }
      }

    },

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});