define("web-client/components/multi-select-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/map", "lodash-es/compact", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/groupBy", "ember-concurrency", "web-client/config/environment"], function (_exports, _dialogBase, _map, _compact, _filter, _sortBy, _groupBy, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    filterText: '',
    items: Ember.A([]),
    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      if (this.grouping) {
        return Ember.A((0, _groupBy.default)((0, _sortBy.default)((0, _filter.default)(this.items, item => {
          return item.name.toLowerCase().includes(this.filterText.toLowerCase());
        }), item => {
          return item.name.toLowerCase();
        }), 'group'));
      } else {
        return Ember.A((0, _sortBy.default)((0, _filter.default)(this.items, item => {
          return item.name.toLowerCase().includes(this.filterText.toLowerCase());
        }), item => {
          return item.name.toLowerCase();
        }));
      }
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('items', Ember.A(this.listItems));
    },

    addNew: (0, _emberConcurrency.task)(function* () {
      let newItems = yield this.addNewTask.perform(this.items);
      this.items.clear();
      this.items.pushObjects(newItems);
      this.notifyPropertyChange('items');
    }).drop(),
    saveChanges: (0, _emberConcurrency.task)(function* () {
      this.send('remove');
      let selectedItems = (0, _compact.default)((0, _map.default)(this.items, function (item) {
        return item.selected ? item.value : null;
      }));
      yield this.onConfirm.resolve(selectedItems);
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.onConfirm.reject();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});