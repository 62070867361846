define("web-client/components/bulk-manage-teams-dialog/team-item/component", ["exports", "@ionic/core", "lodash-es/isBoolean"], function (_exports, _core, _isBoolean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //ios="ios-remove-circle" md="md-remove-circle"
  // <ion-icon ios="ios-checkmark-circle" md="md-checkmark-circle">
  var _default = Ember.Component.extend({
    tagName: 'ion-item',
    layoutValue: "icon-start",
    classNames: ['multi-select-item'],
    disabled: false,
    checked: Ember.computed('item', 'selected', function () {
      return this.selected;
    }),
    button: false,
    attributeBindings: ['layoutValue:layout', 'disabled', 'button'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),

    willDestroyElement() {},

    init() {
      this._super.apply(this, arguments); //  console.log(isPlatform(window, 'ios'));

    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      let checkbox = this.element.querySelector('ion-checkbox');
      checkbox.addEventListener('ionChange', e => {
        Ember.set(this.item, 'selected', e.detail.checked); //  this.setItemSelectedState(this.item.id,e.detail.checked);

        e.stopPropagation();
      });
    }
  });

  _exports.default = _default;
});