define("web-client/mixins/progress-handler", ["exports", "lodash-es/bind", "lodash-es/isNumber", "web-client/utils/misc-utils"], function (_exports, _bind, _isNumber, _miscUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProgressHandler = Ember.Object.extend({
    title: "Dialog.Progress.Heading.Text",
    startTime: 0,
    _elapsed: 0,
    _stopTimer: false,
    totalWork: 0,
    workComplete: 0,
    status: '',
    aborted: false,
    forceComplete: false,
    _doTimer: function _doTimer() {
      if (!this._stopTimer) {
        this.set('_elapsed', Date.now() - this.startTime);
        setTimeout((0, _bind.default)(this._doTimer, this), 1000);
      }
    },
    _remaining: Ember.computed('totalWork', 'workComplete', 'forceComplete', function () {
      if (this.forceComplete || this.workComplete === 0 && this.totalWork === 0) {
        return 100;
      } else {
        return this._elapsed / this.workComplete * (this.totalWork - this.workComplete);
      }
    }),
    completed: Ember.computed('totalWork', 'workComplete', 'aborted', 'forceComplete', 'enableClose', function () {
      let complete = this.totalWork >= 0 && this.totalWork === this.workComplete || this.aborted || this.forceComplete;
      let ret = false;

      if (complete && this.explicitClose) {
        ret = this.enableClose;
      } else {
        ret = complete;
      }

      return ret;
    }),
    explicitClose: false,
    completedObserver: Ember.observer('totalWork', 'workComplete', 'aborted', 'forceComplete', function () {
      if (this.totalWork >= 0 && this.totalWork === this.workComplete || this.aborted || this.forceComplete) {
        this._stopTimer = true;
      }
    }),
    timeRemaining: Ember.computed('_remaining', function () {
      return (0, _miscUtils.secondsToString)(this._remaining > 0 ? (0, _isNumber.default)(this._remaining / 1000) ? this._remaining / 1000 : 0 : 0);
    }),
    timeElapsed: Ember.computed('_elapsed', function () {
      return (0, _miscUtils.secondsToString)(this._elapsed > 0 ? (0, _isNumber.default)(this._elapsed / 1000) ? this._elapsed / 1000 : 0 : 0);
    }),
    startTimer: function startTimer() {
      this._stopTimer = false;
      this.set('aborted', false);
      this.set('_elapsed', 0);
      this.set('startTime', Date.now());
      this.set('workComplete', 0);
      setTimeout((0, _bind.default)(this._doTimer, this), 1000);
    },
    stopTimer: function stopTimer() {
      this._stopTimer = true;
    },
    show: function show() {
      this.set('aborted', false);
      var args = Ember.Object.create({
        progressHandler: this
      });
      this.get('parent.dialogManager.actionHandler').send('show', 'progress-dialog', args);
    }
  });

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('progressHandler', ProgressHandler.create({
        parent: this
      }));
    },
    progressHandler: null
  });

  _exports.default = _default;
});