define("web-client/helpers/array-contains", ["exports", "lodash-es/includes"], function (_exports, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContains = arrayContains;
  _exports.default = void 0;

  function arrayContains(params) {
    let value = params[0];
    let array = params[1];
    return (0, _includes.default)(array, value);
  }

  var _default = Ember.Helper.helper(arrayContains);

  _exports.default = _default;
});