define("web-client/register/invited/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/unauthed-route"], function (_exports, _baseRoute, _unauthedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    token: null,
    queryParams: {
      // definition as per:
      // https://emberjs.com/api/ember/2.15/classes/Ember.Route/properties/queryParams?anchor=queryParams
      token: {}
    },

    init() {
      this._super.apply(this, arguments);
    },

    model(params) {
      //console.log('This is the dynamic segment data: ' + params.invitation_id);
      // Sign out
      this.reduxStore.dispatch({
        type: 'RESET'
      });
      this.reduxStore.dispatch({
        type: "SET_REGISTRATION_FLOW_ID",
        registrationFlowId: params.invitation_id
      });
      const t = Ember.Object.create(params);
      this.set('token', t);
    },

    setupController(controller, _model) {
      this._super.apply(this, arguments); // Here we "define" the queryParams in the controller


      controller.set('queryParameters', this.get('token'));
    },

    _updateQueryParameter(paramName, paramValue) {
      // The following will ensure the query params are updated in the url
      // https://guides.emberjs.com/v2.15.0/routing/query-params/
      Ember.set(this, 'controller.${paramName}', paramValue); // Might want to refresh the model here
    },

    actions: {
      updateQueryParameter() {
        this._updateQueryParameter.apply(this, arguments);
      }

    },

    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.dispatch({
        type: "SET_REGISTRATION_EDITION",
        registrationEdition: "Invited"
      }); //console.log('registration flow id:'+this.get('reduxStore.state.registrationFlowId'));
    },

    renderTemplate() {
      this.render('register/invited', {
        into: 'application',
        outlet: 'content'
      });
    }

  });

  _exports.default = _default;
});