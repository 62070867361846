define("web-client/api/idle-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTimer = getTimer;
  _exports.setTimer = setTimer;
  let timer = null;

  function getTimer() {
    return timer;
  }

  function setTimer(newTimer) {
    timer = newTimer;
  }
});