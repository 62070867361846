define("web-client/components/connection-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'img',
    buttonClasses: '',
    classNames: ['site-icon'],
    gestures: Ember.inject.service(),
    buttonIcon: '',
    buttonText: 'Default Text',
    attributeBindings: ['dataToggle:data-toggle', 'touchAction:touch-action', 'tabindex', 'title:title', 'imgSource:src'],
    tabindex: -1,
    imageServerDown: false,
    imgSource: Ember.computed('imageSource', 'imageServerDown', function () {
      return this.imageSource;
    }),
    title: Ember.computed('isSelected', function () {
      return "";
    }),
    imageSource: '',
    dataToggle: '',
    touchAction: "pan",

    willDestroyElement() {
      this.element.removeEventListener('error', this._error);
    },

    didInsertElement: function didInsertElement() {
      var self = this;

      this._super();

      this._error = function () {
        Ember.run.next(function () {
          self.set('imageServerDown', true);
        });
      };

      this.element.addEventListener('error', this._error);
    }
  });

  _exports.default = _default;
});