define("web-client/components/change-company-name-dialog/component", ["exports", "ember-concurrency", "ui-core/mixins/dialog-base", "ember-cp-validations", "web-client/api"], function (_exports, _emberConcurrency, _dialogBase, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newCompanyName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Company name can't be blank"
    }), (0, _emberCpValidations.validator)('company', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , . , - , @, ) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 50,
      message: "Company name must be between 3 and 50 characters"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, {
    size: 'size-3',
    companyName: Ember.computed.alias('reduxStore.state.orgDetails.name'),
    newCompanyName: '',
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    hasChanged: Ember.computed('companyName', 'newCompanyName', function () {
      return this.companyName !== this.newCompanyName;
    }),
    showErrors: false,
    showCompanyNameErrors: Ember.computed('showErrors', 'newCompanyName', function () {
      return this.showErrors || this.newCompanyName.length;
    }),
    saveCompanyName: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          return (0, _api.updateCompanyName)(this.newCompanyName);
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        if (result !== 'validation-failed') {
          // OrganizationDetails
          (0, _api.getOrganizationDetails)().then(data => {
            this.reduxStore.dispatch({
              type: 'INITIALIZE_ORGANIZATION_DETAILS',
              details: data
            });
          }).catch(() => {//console.log('no extra data')
          });
          this.send('remove');
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newCompanyName', this.get('reduxStore.state.orgDetails.name'));
    },
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});