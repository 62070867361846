define("web-client/components/connection-management-row/component", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: [''],
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    item: {},
    mayChange: {},

    init() {
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    filteredTeamsSharedToMe: Ember.computed('item', function () {
      // Return the original list
      return this.filterTeamsBySize(this.item.ownedTeams);
    }),
    filteredTeamsSharedByMe: Ember.computed('item', function () {
      // Return the original list
      return this.filterTeamsBySize(this.item.memberOfTeams);
    }),
    filterTeamsBySize: function filterTeamsBySize(data) {
      if (null != data && data.length > 6) {
        let data2 = data.slice(0, 5);
        data2 = data2.concat([JSON.parse('{"teamName":"....."}')]);
        console.log(data2);
        data = data2;
      }

      return data;
    },
    actions: {
      showConnectionDialog: function showConnectionDialog(connection) {
        this.globalActions.showConnectionDialog(connection);
      },
      removeConnection: function removeConnection(customerId) {
        //      console.log("deleting connection to " + customerId);
        let deferred = Ember.RSVP.defer();
        this.get('standardDialogs').showConfirmDialog('confirm-delete-connection').then(() => {
          this.loading.show('Deleting Connection');
          (0, _api.deleteConnection)(customerId).then(() => {
            this.loading.dismiss();
            deferred.resolve(); // Subscription Summary Data

            this.globalActions.getAccountLicenseSummary(); //                 this.globalActions.updateSubscriptionData();
            //                 this.globalActions.updateOrganizationLicenses();

            this.send('remove');
          }).catch(error => {
            //console.log("error" + error);
            this.loading.dismiss();
            deferred.resolve();
          });
        }).catch(error => {//console.log("dialog error" + error);
        });
        return deferred.promise;
      }
    }
  });

  _exports.default = _default;
});