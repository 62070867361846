define("web-client/register/team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3q4Di/3",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n      \"],[1,[29,\"registration-dialog\",null,[[\"goToLogin\",\"goToRegister\",\"registerUser\",\"success\"],[[29,\"service-action\",[\"global-actions\",\"goToLogin\"],null],[29,\"service-action\",[\"global-actions\",\"goToRegister\"],null],[29,\"service-action\",[\"global-actions\",\"registerUser\"],null],[25,[\"reduxStore\",\"state\",\"registrationSuccessful\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/register/team/template.hbs"
    }
  });

  _exports.default = _default;
});