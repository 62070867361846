define("web-client/components/session-history-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //<li id="pwRow-{{unbound password.id}}" {{action goToPasswordEdit password bubbles=false }} >
    //</li>
    tagName: 'div',
    attributeBindings: ['tabindex', 'touchAction:touch-action'],
    classNames: ["session-history-list-item"],
    touchAction: "",
    tabindex: 0,
    clipName: null,
    classNameBindings: ['selected', 'login:fade-in:fade-out', 'login.activeSession:active:inactive'],
    actions: {
      viewSessionDetails: function viewSessionDetails() {
        this.parentView.send('viewSessionDetails', this.session);
      }
    }
  });

  _exports.default = _default;
});