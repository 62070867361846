define("web-client/createpassphrase/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOSl7AiN",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n      \"],[1,[29,\"create-passphrase-dialog\",null,[[\"goToApplication\",\"submitPassphrase\",\"submitPassphraseAndContinue\",\"cancelKeyGeneration\",\"sessionisKeySet\",\"sessionPassphrase\"],[[29,\"service-action\",[\"globalActions\",\"goToApplication\"],null],[29,\"service-action\",[\"globalActions\",\"submitPassphrase\"],null],[29,\"service-action\",[\"globalActions\",\"submitPassphraseAndContinue\"],null],[29,\"service-action\",[\"globalActions\",\"cancelKeyGeneration\"],null],[25,[\"reduxStore\",\"state\",\"isKeySet\"]],[25,[\"reduxStore\",\"state\",\"passphrase\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/createpassphrase/template.hbs"
    }
  });

  _exports.default = _default;
});