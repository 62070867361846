define("web-client/components/multi-select-dialog/multi-select-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CsgcHYo8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-icon\"],[12,\"name\",[30,[[25,[\"item\",\"icon\"]]]]],[12,\"class\",[30,[\"item-icon \",[25,[\"item\",\"color\"]]]]],[9],[10],[0,\"\\n\"],[7,\"ion-label\"],[9],[1,[25,[\"item\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"ion-checkbox\"],[11,\"slot\",\"start\"],[12,\"value\",[30,[[25,[\"item\",\"value\"]]]]],[12,\"checked\",[30,[[25,[\"item\",\"selected\"]]]]],[9],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/multi-select-dialog/multi-select-item/template.hbs"
    }
  });

  _exports.default = _default;
});