define("web-client/components/bulk-manage-teams-dialog/team-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pF1x5J+7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-icon\"],[11,\"name\",\"folder\"],[12,\"class\",[30,[\"item-icon \",[25,[\"item\",\"color\"]]]]],[9],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[7,\"ion-label\"],[9],[1,[25,[\"item\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"ion-checkbox\"],[11,\"slot\",\"start\"],[12,\"value\",[30,[[25,[\"item\",\"value\"]]]]],[12,\"checked\",[30,[[25,[\"item\",\"selected\"]]]]],[12,\"indeterminate\",[30,[[29,\"if\",[[29,\"or\",[[29,\"eq\",[[25,[\"item\",\"selected\"]],false],null],[29,\"eq\",[[25,[\"item\",\"selected\"]],true],null]],null],false,true],null]]]],[9],[10],[0,\"\\n\\n\"],[7,\"ion-buttons\"],[11,\"class\",\"manage-label-buttons\"],[9],[0,\"\\n\\n  \"],[7,\"ion-badge\"],[12,\"color\",[30,[[29,\"if\",[[29,\"eq\",[[25,[\"item\",\"originalSelection\"]],[25,[\"item\",\"selected\"]]],null],\"grey-500\",\"red-500\"],null]]]],[9],[1,[29,\"if\",[[29,\"eq\",[[25,[\"item\",\"originalSelection\"]],[25,[\"item\",\"selected\"]]],null],[25,[\"item\",\"teamCount\"]],[29,\"if\",[[25,[\"item\",\"selected\"]],[25,[\"total\"]],\"0\"],null]],null],false],[10],[0,\"\\n\\n  \"],[1,[29,\"nb-button\",null,[[\"icon\",\"shape\",\"size\",\"color\",\"on-tap\"],[\"trash\",\"round\",\"small\",\"dark\",[29,\"perform\",[[25,[\"deleteTeam\"]],[25,[\"item\",\"id\"]]],null]]]],false],[0,\"\\n  \"],[1,[29,\"nb-button\",null,[[\"icon\",\"shape\",\"size\",\"color\",\"on-tap\"],[\"create\",\"round\",\"small\",\"dark\",[29,\"perform\",[[25,[\"showEditTeamDialog\"]],[25,[\"item\"]]],null]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/bulk-manage-teams-dialog/team-item/template.hbs"
    }
  });

  _exports.default = _default;
});