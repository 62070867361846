define("web-client/helpers/array-contains-array", ["exports", "lodash-es/difference", "lodash-es/isArray", "lodash-es/compact"], function (_exports, _difference, _isArray, _compact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayContainsArray = arrayContainsArray;
  _exports.default = void 0;

  /*
  @developper89 you probably have missed the arguments order: first is subset and second is superset.
  So _.difference([], [16, 28, 35]).length == 0 means that [] is a subset of
  [16, 28, 35], or [16, 28, 35] includes [], and that is really true
  */

  /**
   *
   * @param params
   * First param is the superset that you want to check the existance of the subset in
   * @returns {boolean}
   */
  function arrayContainsArray(params) {
    let subset = (0, _isArray.default)(params[1]) ? params[1] : (0, _compact.default)([params[1]]);
    let superset = params[0];

    if (subset.length === 0) {
      return false;
    }

    let diff = (0, _difference.default)(subset, superset).length;

    if (diff == 0) {
      return true;
    }

    if (diff < subset.length) {
      return null;
    }

    if (diff === subset.length) {
      return false;
    }
  }

  var _default = Ember.Helper.helper(arrayContainsArray);

  _exports.default = _default;
});