define("web-client/validators/email", ["exports", "lodash-es/trim", "lodash-es/isEmpty", "ember-cp-validations/validators/base"], function (_exports, _trim, _isEmpty, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Email = _base.default.extend({
    validate(value, options
    /*, model, attribute*/
    ) {
      var trimmedValue = (0, _trim.default)(value);
      /* eslint-disable no-useless-escape */

      if (!(0, _isEmpty.default)(trimmedValue) && !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(trimmedValue) || (0, _isEmpty.default)(trimmedValue) && options.allowBlank !== true) {
        return options.message; // this.errors.pushObject(this.get('i18n').t("errors.email"));
      } else {
        return true;
      }
    }

  });

  var _default = Email;
  _exports.default = _default;
});