define("web-client/oob/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/unauthed-route", "web-client/actions/submit-token"], function (_exports, _baseRoute, _unauthedRoute, _submitToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    actions: {
      submitToken: _submitToken.default
    },

    renderTemplate() {
      this.render('oob', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});