define("web-client/api/change-packing-key", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changePackingKey = void 0;

  const changePackingKey = _ipcClient.ipc.makeRequestFunction('changePackingKey');

  _exports.changePackingKey = changePackingKey;
});