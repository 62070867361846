define("web-client/connections/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    classNames: ["connection-management"],
    nickNameSize: 100,
    connectionDateSize: 100,
    actions: {
      goToDashboard: function goToDashboard() {
        this.globalActions.goToSettingsReplace();
      }
    }
  });

  _exports.default = _default;
});