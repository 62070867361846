define("web-client/dashboard/system-updates/controller", ["exports", "lodash-es/each", "lodash-es/filter", "lodash-es/sortBy", "web-client/config/environment", "web-client/api"], function (_exports, _each, _filter, _sortBy, _environment, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    classNames: ["system-updates"],
    version: _environment.default.clientVersion,
    systemUpdates: {},

    init() {
      this._super.apply(this, arguments);

      (0, _api.getSystemUpdates)().then(data => {
        this.set('systemUpdates', data.entries);
      }).catch(() => {//this.set('isUpdating', false);
      });
    },

    actions: {}
  });

  _exports.default = _default;
});