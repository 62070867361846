define("web-client/application/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    sidebarTitle: Ember.computed.alias('reduxStore.state.sidebarTitle'),
    isSmall: Ember.computed('media.classNames', function () {
      return !(this.media.get('isMediaLG') || this.media.get('isMediaXL') || this.media.get('isMediaMD'));
    }),
    actions: {
      blockSubmit() {}

    }
  });

  _exports.default = _default;
});