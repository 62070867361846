define("web-client/components/tools-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ixHkM5Iy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-menu-bar-menu\",null,[[\"tether\",\"options\"],[[25,[\"tether\"]],[25,[\"options\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"nb-menu-item\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\"],[\"Password Generator\",\"Generate a secure password\",[29,\"build-svg-icon\",[\"textbox-password\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"textbox-password\",\"white\"],null],[29,\"service-action\",[\"global-actions\",\"showPasswordGeneratorDialog\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/tools-menu/template.hbs"
    }
  });

  _exports.default = _default;
});