define("web-client/components/change-account-renewal-settings-dialog/component", ["exports", "ui-core/mixins/dialog-base", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "web-client/api"], function (_exports, _dialogBase, _emberRedux, _emberConcurrency, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    classNames: ['change-account-renewal-settings'],
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    computing: false,
    autoRenewSetting: 'cancel',
    currentSelected: '',

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);

      this.set('autoRenewSetting', this.get('reduxStore.state.nextSubscription.action'));
      this.set('currentSelected', this.get('reduxStore.state.nextSubscription.action'));
    },

    changeRenewalSetting: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      console.log(this.currentSelected);
      this.loading.show('Updating Next Subscription');
      (0, _api.updateCompanyNextSubscriptionBehavior)(this.currentSelected).then(result => {
        this.globalActions.updateSubscriptionData();
        self.loading.dismiss();
        self.send('remove');
        deferred.resolve();
      }).catch(e => {
        self.loading.dismiss();
        this.globalActions.actionUIErrorHandler(e);
        deferred.reject(e);
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      }
    },

    afterShow() {
      this._super.apply(this, arguments);

      let input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let checkbox = this.element.querySelector('ion-radio-group');
      checkbox.addEventListener('ionChange', e => {
        this.set('currentSelected', checkbox.value);
      });
    }

  });

  _exports.default = _default;
});