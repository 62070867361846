define("web-client/api/bulk-update-labels-for-passwords", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bulkUpdateLabelsForPasswords2 = _exports.bulkUpdateLabelsForPasswords = void 0;

  const bulkUpdateLabelsForPasswords = _ipcClient.ipc.makeRequestFunction('bulkUpdateLabelsForPasswords');

  _exports.bulkUpdateLabelsForPasswords = bulkUpdateLabelsForPasswords;

  const bulkUpdateLabelsForPasswords2 = _ipcClient.ipc.makeRequestFunction('bulkUpdateLabelsForPasswords2');

  _exports.bulkUpdateLabelsForPasswords2 = bulkUpdateLabelsForPasswords2;
});