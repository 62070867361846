define("web-client/services/redux-store", ["exports", "redux", "redux-thunk", "web-client/reducers"], function (_exports, _redux, _reduxThunk, _reducers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const createStoreWithMiddleware = middleware => _redux.applyMiddleware.apply(null, middleware)(_redux.createStore);

  const logger = () => next => action => {
    return next(action);
  };

  var _default = Ember.Service.extend({
    reducer: _reducers.default,
    // Provided by the host app's app/reducers/index.js via app/services/redux-store
    state: Ember.computed.readOnly('_state'),

    init() {
      this._super.apply(this, arguments);

      this.middleware = [_reduxThunk.default, logger];
      this._store = createStoreWithMiddleware(this.middleware)(this.reducer);

      this._store.subscribe(() => {
        this.set('_state', this._store.getState());
        this.notifyPropertyChange('_state');
      });

      this.set('_state', this._store.getState());
    },

    dispatch(action) {
      this._store.dispatch(action);
    }

  });

  _exports.default = _default;
});