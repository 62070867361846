define("web-client/components/registration-dialog-invite/component", ["exports", "web-client/api", "web-client/mixins/registration-base-dialog", "ember-cp-validations", "lodash-es/has", "web-client/config/environment"], function (_exports, _api, _registrationBaseDialog, _emberCpValidations, _has, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 500,
      message: "Password must be between 8 and 500 characters"
    })],
    password2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your password"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: "You must retype your password exactly as before"
    })],
    conditionsAccepted: [(0, _emberCpValidations.validator)('conditionsaccepted', {
      presence: true,
      message: "You must accept the terms of service"
    })]
  });

  var _default = Ember.Component.extend(_registrationBaseDialog.default, Validations, {
    standardDialogs: Ember.inject.service(),
    classNames: ['registration-dialog'],
    classNameBindings: ['currentSize'],
    invitationFlowData: '',
    reduxStore: Ember.inject.service(),
    validFlowData: true,
    errorCode: null,
    password: '',
    password2: '',
    conditionsAccepted: false,
    success: false,
    flowData: null,
    invalidFlow: false,
    defaultAction: 'registerUser',
    showErrors: false,
    queryParameters: null,
    token: '',

    init() {
      this._super.apply(this, arguments); //    console.log("token");
      //    console.log(this.queryParameters);


      let token = this.get('reduxStore.state.registrationFlowId'); //    console.log("token2");
      //    console.log(token);

      try {
        if (this.queryParameters) {
          let queryToken = this.queryParameters.get('token');

          if (queryToken && queryToken.length > 10) {
            token = queryToken;
          }
        }
      } catch (_unused) {
        console.log("error getting token from " + this.queryParameters);
      }

      this.set('token', token); //    console.log(token);
      // Get the data for the invitation flow

      (0, _api.getInvitationFlow)(token).then(data => {
        if (data.status.code === 0) {
          this.set('invitationFlowData', data);
        } else {
          this.set('validFlowData', false);
        }
      }).catch(error => {
        this.set('validFlowData', false);

        if ((0, _has.default)(error, 'status.code')) {
          this.set('errorCode', error.status.code);
        }
      }).finally(function () {//self.set('showErrors', true);
      });
    },

    currentSize: Ember.computed('success', function () {
      return this.success ? 'size-3' : 'size-3';
    }),
    showPasswordErrors: Ember.computed('password', 'showErrors', function () {
      return this.showErrors || this.password.length;
    }),
    showPassword2Errors: Ember.computed('password2', 'showErrors', function () {
      return this.showErrors || this.password2.length;
    }),
    ssoRequired: Ember.computed("invitationFlowData.edition", function () {
      let edition = this.invitationFlowData.edition;
      let sso = this.invitationFlowData.sso;

      if ("Business Plan" === edition && "require" === sso) {
        return true;
      } else {
        return false;
      }
    }),
    usernamePasswordAllowed: Ember.computed('invitationFlowData.edition', 'invitationFlowData.sso', function () {
      let edition = this.invitationFlowData.edition;
      let sso = this.invitationFlowData.sso;

      if ("Business Plan" === edition && ("username" === sso || "any" === sso)) {
        return true;
      } else if ("Business Plan" === edition && "require" === sso) {
        return false;
      } else if ("Team Plan" === edition) {
        return true;
      } else {
        // legacy
        return true;
      }
    }),
    ssoAllowed: Ember.computed('invitationFlowData.edition', 'invitationFlowData.sso', function () {
      let edition = this.invitationFlowData.edition;
      let sso = this.invitationFlowData.sso;

      if ("Team Plan" === edition) {
        return false;
      } else if ("Business Plan" === edition && ("require" === sso || "any" === sso)) {
        return true;
      } else {
        return false;
      }
    }),
    passwordStats: Ember.computed("password", function () {
      if (!this.liveStatsObj) {
        this.liveStatsObj = Ember.Object.extend({
          password: Ember.Object.extend({
            score: 0,
            crack_time_display: "instant",
            scoreCSS: Ember.computed('score', function () {
              return 'score-' + (this.score ? this.score : 0);
            })
          }).create()
        }).create();
      }

      let self = this;
      (0, _api.getScore)(self.get('password'), self.get('username'), self.get('email')).then(function (data) {
        if (data !== 'unavailable') {
          self.liveStatsObj.password.set('score', data.score);
          self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
        } else {
          self.liveStatsObj.password.set('score', 0);
          self.liveStatsObj.password.set('crack_time_display', "instant");
        }
      });
      return this.liveStatsObj;
    }),
    actions: {
      blockSubmit()
      /*e*/
      {},

      goToLogin: function goToLogin() {
        // $(this.get('element')).hide();
        this.reduxStore.dispatch({
          type: 'REGISTRATION_SUCCESSFUL_RESET'
        });
        this.goToLogin();
      },
      goToSettings: function goToSettings() {
        this.goToSettings();
      },
      toggleAcceptConditions: function toggleAcceptConditions(checked) {
        if (checked) {
          let deferred = Ember.RSVP.defer();
          let params = Ember.Object.create({
            onConfirm: deferred
          });
          deferred.promise.then(() => {});
          deferred.promise.catch(() => {
            this.set('conditionsAccepted', false);
          });
          this.standardDialogs.showDialog('accept-terms-dialog', params);
        }
      },
      acceptInvitation: function acceptInvitation() {
        let self = this;
        this.acceptUserInvite(this.token);
      },
      registerWithGoogle: function registerWithGoogle() {
        var baseUrl = _environment.default.apiHost;
        window.location.href = baseUrl + '/portal/v1/register/google/' + this.invitationFlowData.invitationId;
      },
      registerUser: function registerUser() {
        let self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            console.log("register invited user");
            this.registerUserInvite(this.invitationFlowData.email, this.password, this.token);
          }
        }).finally(function () {
          self.set('showErrors', true);
        });
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});