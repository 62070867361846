define("web-client/actions/global-actions/show-delete-passwords-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = showDeletePasswordsDialog;

  function showDeletePasswordsDialog(password) {
    var args = Ember.Object.create({
      password: password instanceof Event ? null : password
    });
    this.standardDialogs.showDialog('delete-passwords-dialog', args, 'small');
  }
});