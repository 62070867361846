define("web-client/components/transfer-ownership-dialog-moved/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api", "ember-concurrency", "lodash-es/filter", "lodash-es/includes", "lodash-es/groupBy", "lodash-es/sortBy"], function (_exports, _dialogBase, _api, _emberConcurrency, _filter, _includes, _groupBy, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    classNames: ["transfer-ownership-dialog"],
    notifications: Ember.inject.service(),
    group: null,
    loading: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    filterText: '',
    availableTeams: Ember.computed('reduxStore.state.data.teams.{content,content.[]}', 'teams', 'teams.[]', function () {
      return (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
        return team.name !== 'Default' && team.role === 3 && team.owner !== this.get('reduxStore.state.username');
      });
    }),
    filteredItems: Ember.computed('availableTeams.[]', 'availableTeams.@each', 'filterText', function () {
      return Ember.A((0, _groupBy.default)((0, _sortBy.default)((0, _filter.default)(this.availableTeams, item => {
        let testText = item.individual ? item.ownerNick : item.name;
        return testText.toLowerCase().includes(this.filterText.toLowerCase());
      }), "name"), function (item) {
        return item.individual ? "Connection" : "Team";
      }));
    }),
    currentSelected: null,
    showErrors: false,

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.deferred.reject();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});