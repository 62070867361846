define("web-client/settings/export/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route", "web-client/mixins/progress-handler", "lodash-es/isString", "lodash-es/flatten", "lodash-es/map", "ui-core/neo-async-es/series", "ui-core/neo-async-es/parallel", "ui-core/neo-async-es/mapLimit", "file-saver", "web-client/api"], function (_exports, _baseRoute, _authedRoute, _progressHandler, _isString, _flatten, _map, _series, _parallel, _mapLimit, _fileSaver, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //var splitRegex = /\r\n|\r|\n/g;
  const escapeAndQuoteField = function escapeAndQuoteField(item) {
    if ((0, _isString.default)(item)) {
      /* eslint-disable no-useless-escape */
      return '"' + item.replace(/"/g, '\""') + '"';
    } else {
      /* eslint-disable no-useless-escape */
      return '"' + "".replace(/"/g, '\""') + '"';
    }
  };

  const escapeAndNoQuoteField = function escapeAndNoQuoteField(item) {
    if ((0, _isString.default)(item)) {
      return item;
    } else {
      return "";
    }
  };

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, _progressHandler.default, {
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),

    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Export"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "Export"
        });
      },

      exportPasswords() {
        const ensurePackingKey = Ember.getOwner(this).lookup('service-action:helpers').compute(['global-actions', 'ensurePackingKey']);
        const self = this;
        ensurePackingKey().then(function () {
          self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Initializing"));
          const abortHandler = Ember.RSVP.defer();
          abortHandler.promise.then(function () {
            self.set('data.pauseUpdates', false);
            self.set('abort', true);
          });
          const closeHandler = Ember.RSVP.defer();
          closeHandler.promise.then(function () {
            self.set('abort', false);
            self.set('data.pauseUpdates', false);
          });
          self.set('progressHandler.abortPromise', abortHandler);
          self.set('progressHandler.closePromise', closeHandler);
          self.get('progressHandler').startTimer();
          self.set('progressHandler.totalWork', self.get('data.passwords.unfiltered.length') + 30);
          self.get('progressHandler').show();
          (0, _mapLimit.default)(self.get('data.passwords.unfiltered'), 5, function (item, callback) {
            if (self.get('abort')) {
              callback('abort', 'abort');
            } else {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Exporting"));
              (0, _api.getPasswordById)(item.id).then(function (xdata) {
                const data = xdata.password;
                (0, _api.getTeamById)(data.groups[0]["id"]).then(function (groupData) {
                  if (groupData.owner === self.get('reduxStore.state.username')) {
                    self.set('editable', true);
                  }

                  (0, _api.rsaDecrypt)(groupData.encryptedGroupKey, self.get('reduxStore.state.privateKey')).then(function (groupKey) {
                    let pwKey = null;
                    (0, _series.default)([function (callback) {
                      (0, _api.aesDecrypt)(data.key, groupKey).then(function (data) {
                        pwKey = data;
                        callback(null, data);
                      });
                    }, function (callback) {
                      (0, _parallel.default)([function (callbackx) {
                        (0, _api.aesDecrypt)(data.data, pwKey).then(function (data) {
                          callbackx(null, JSON.parse(data));
                        });
                      }, function (callbackx) {
                        if (data.notes) {
                          (0, _api.aesDecrypt)(data.notes, pwKey).then(function (data) {
                            callbackx(null, data);
                          });
                        } else {
                          callbackx(null, '');
                        }
                      }, callback => {
                        (0, _api.getTeamMetaKey)(data.groups[0]["id"], false, self.get('reduxStore.state.privateKey')).then(metaKey => {
                          const name = (0, _api.bfDecryptInline)(data.name, metaKey);
                          let url = '';

                          if (data.url) {
                            url = (0, _api.bfDecryptInline)(data.url, metaKey);
                          }

                          callback(null, [name, url]);
                        }).catch(() => {
                          callback(null, ["", ""]);
                        });
                      }], function (err, results) {
                        callback(null, (0, _flatten.default)(results));
                      });
                    }], function (err, results) {
                      const resultsx = (0, _flatten.default)(results);
                      const payload = resultsx[1];
                      const extra = (0, _map.default)(payload.extra, function (item) {
                        return JSON.stringify({
                          name: item.name,
                          type: item.type,
                          data: item.data
                        });
                      });
                      const extraArray = JSON.stringify({
                        extraFields: extra
                      });
                      const tags = (0, _map.default)(data.tags, function (tag) {
                        return JSON.stringify({
                          tag: tag.tag
                        });
                      });
                      const tagArray = JSON.stringify({
                        tags: tags
                      });
                      const row = [escapeAndQuoteField(resultsx[3]), escapeAndQuoteField(resultsx[4]), escapeAndQuoteField(payload.username), escapeAndQuoteField(payload.password), escapeAndQuoteField(resultsx[2]), escapeAndQuoteField(payload.sharedNotes), escapeAndQuoteField(tagArray), escapeAndQuoteField(extraArray), escapeAndQuoteField(payload.email)].join(',');

                      if (self.get('abort')) {
                        self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Aborted"));
                      }

                      self.set('progressHandler.workComplete', self.get('progressHandler.workComplete') + 1);
                      callback(null, row);
                    });
                  });
                }).catch(function ()
                /*e*/
                {});
              });
            }
          }, function (err, results) {
            if (self.get('abort')) {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Aborted"));
            } else {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.WritingFile"));
              const downloadFile = '"Entry Name","URL","User ID","Password","Notes","Shared Notes","Tags","Extra Fields"' + "\r\n" + results.join('\r\n');
              const dateString = new Date().format('mm-dd-yy-hh-MM-ss');
              const filename = "passpack-export-" + dateString + '.csv';
              const file = new File([downloadFile], filename, {
                type: "text/plain;charset=utf-8"
              });

              _fileSaver.default.saveAs(file);

              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Complete"));
              self.set('progressHandler.workComplete', self.get('progressHandler.workComplete') + 30);
            }
          });
        });
      },

      exportPasswordsToPDF() {
        let ensurePackingKey = Ember.getOwner(this).lookup('service-action:helpers').compute(['global-actions', 'ensurePackingKey']);
        const self = this;
        ensurePackingKey().then(function () {
          self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Initializing"));
          const abortHandler = Ember.RSVP.defer();
          abortHandler.promise.then(function () {
            self.set('data.pauseUpdates', false);
            self.set('abort', true);
          });
          const closeHandler = Ember.RSVP.defer();
          closeHandler.promise.then(function () {
            self.set('abort', false);
            self.set('data.pauseUpdates', false);
          });
          self.set('progressHandler.abortPromise', abortHandler);
          self.set('progressHandler.closePromise', closeHandler);
          self.get('progressHandler').startTimer();
          self.set('progressHandler.totalWork', self.get('data.passwords.unfiltered.length') + 30);
          self.get('progressHandler').show();
          (0, _mapLimit.default)(self.get('data.passwords.unfiltered'), 5, function (item, callback) {
            if (self.get('abort')) {
              callback('abort', 'abort');
            } else {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Exporting"));
              (0, _api.getPasswordById)(item.id).then(function (xdata) {
                var data = xdata.password;
                (0, _api.getTeamById)(data.groups[0]["id"]).then(function (groupData) {
                  if (groupData.owner === self.get('reduxStore.state.username')) {
                    self.set('editable', true);
                  }

                  (0, _api.rsaDecrypt)(groupData.encryptedGroupKey, self.get('reduxStore.state.privateKey')).then(function (groupKey) {
                    var pwKey = null;
                    (0, _series.default)([function (callback) {
                      (0, _api.aesDecrypt)(data.key, groupKey).then(function (data) {
                        pwKey = data;
                        callback(null, data);
                      });
                    }, function (callback) {
                      (0, _parallel.default)([function (callbackx) {
                        (0, _api.aesDecrypt)(data.data, pwKey).then(function (data) {
                          callbackx(null, JSON.parse(data));
                        });
                      }, function (callbackx) {
                        if (data.notes) {
                          (0, _api.aesDecrypt)(data.notes, pwKey).then(function (data) {
                            callbackx(null, data);
                          });
                        } else {
                          callbackx(null, '');
                        }
                      }, callback => {
                        (0, _api.getTeamMetaKey)(data.groups[0]["id"], false, self.get('reduxStore.state.privateKey')).then(metaKey => {
                          const name = (0, _api.bfDecryptInline)(data.name, metaKey);
                          let url = '';

                          if (data.url) {
                            url = (0, _api.bfDecryptInline)(data.url, metaKey);
                          }

                          callback(null, [name, url]);
                        }).catch(() => {
                          callback(null, ["", ""]);
                        });
                      }], function (err, results) {
                        callback(null, (0, _flatten.default)(results));
                      });
                    }], function (err, results) {
                      const resultsx = (0, _flatten.default)(results);
                      const payload = resultsx[1];
                      const extra = (0, _map.default)(payload.extra, function (item) {
                        return JSON.stringify({
                          name: item.name,
                          type: item.type,
                          data: item.data
                        });
                      });
                      const extraArray = JSON.stringify({
                        extraFields: extra
                      });
                      const tags = (0, _map.default)(data.tags, function (tag) {
                        return JSON.stringify({
                          tag: tag.tag
                        });
                      });
                      const tagArray = JSON.stringify({
                        tags: tags
                      });
                      const row = [escapeAndNoQuoteField(resultsx[3]), escapeAndNoQuoteField(resultsx[4]), escapeAndNoQuoteField(payload.username), escapeAndNoQuoteField(payload.password), escapeAndNoQuoteField(resultsx[2]), escapeAndNoQuoteField(payload.sharedNotes), escapeAndNoQuoteField(tagArray), escapeAndNoQuoteField(extraArray), escapeAndNoQuoteField(payload.email)];

                      if (self.get('abort')) {
                        self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Aborted"));
                      }

                      self.set('progressHandler.workComplete', self.get('progressHandler.workComplete') + 1);
                      callback(null, row);
                    });
                  });
                }).catch(function ()
                /*e*/
                {});
              });
            }
          }, function ()
          /*err, results*/
          {
            if (self.get('abort')) {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Aborted"));
            } else {
              self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.WritingFile"));
              /*  var doc = new jsPDF({ unit: 'pt' });
                //var downloadFile = '"Entry Name","URL","User ID","Password","Notes","Shared Notes","Tags","Extra Fields"' + "\r\n" + results.join('\r\n');
                let cumulativeLinePos = 40;
                //   let lineHeight  = 4;
                 let left = 20;
                 let printItem = (name, value) => {
                  if (value) {
                     doc.setFontStyle('bold');
                    cumulativeLinePos = doc.text(name, left, cumulativeLinePos).getTextDimensions(name).h + cumulativeLinePos;
                    doc.setFontStyle('normal');
                    cumulativeLinePos = (doc.text(doc.splitTextToSize(value, doc.internal.pageSize.width - 100), left, cumulativeLinePos).getTextDimensions(value).h * (doc.splitTextToSize(value, doc.internal.pageSize.width - 100).length + 1)) + cumulativeLinePos;
                   }
                }
                 let getRecordHeight = (record) => {
                  return sum(map(record, function (item) {
                     return doc.getTextDimensions(item).h * (doc.splitTextToSize(item, doc.internal.pageSize.width - 100).length + 1);
                  }));
                 }
                each(sortBy(results, function (record) { return record[0].toLowerCase(); }), function (record) {
                    let name = record[0];
                  let url = record[1];
                  let userID = record[2];
                  let password = record[3];
                  let notes = record[4];
                  let sharedNotes = record[5];
                  let extraFields = record[7];
                  let email = record[8];
                  if (cumulativeLinePos + getRecordHeight(record) > doc.internal.pageSize.height - 40) {
                    doc.addPage();
                    cumulativeLinePos = 40;
                  }
                  doc.setFontSize(8);
                  printItem("Name", name);
                  printItem("URL", url);
                  printItem("User ID", userID);
                  printItem("Password", password);
                  printItem("Email", email);
                  printItem("Notes", notes);
                  printItem("Shared Notes", sharedNotes);
                  let extra = JSON.parse(extraFields);
                  each(extra.extraFields, (field) => {
                    let parsedField = JSON.parse(field);
                    printItem(parsedField.name, parsedField.data);
                  });
                  cumulativeLinePos = cumulativeLinePos + 10;
                  doc.line(20, cumulativeLinePos, doc.internal.pageSize.width - 40, cumulativeLinePos);
                  cumulativeLinePos = cumulativeLinePos + 30;
                 });
                  var dateString = new Date().format('mm-dd-yy-hh-MM-ss');
                var filename = "passpack-export-" + dateString;
                doc.save(filename);
                 self.set('progressHandler.currentAction', self.get('i18n').t("Common.ProgressStatus.Complete"));
                self.set('progressHandler.workComplete', self.get('progressHandler.workComplete') + 30);
              */
            }
          });
        });
      }

    },

    renderTemplate() {
      this.render('settings/export', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});