define("web-client/components/ion-list-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AHMgL1H1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-checkbox\"],[12,\"checked\",[30,[[23,\"selected\"]]]],[9],[10],[0,\"\\n\"],[7,\"ion-label\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"icon\"]]],null,{\"statements\":[[7,\"ion-icon\"],[12,\"name\",[30,[[23,\"icon\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ion-badge\"],[9],[1,[23,\"badge\"],false],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/ion-list-button/template.hbs"
    }
  });

  _exports.default = _default;
});