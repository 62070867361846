define("web-client/utils/default-app-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Default application settings
   */
  var _default = {
    lockTime: {
      value: 10080,
      overriddenBy: '',
      overridden: false
    },
    logoutTime: {
      value: 10080,
      overriddenBy: '',
      overridden: false
    },
    hasSystemDefaultImage: true,
    tours: {
      autoShow: {
        dashboard: true,
        passwords: true,
        connections: true,
        settings: true
      }
    }
  };
  _exports.default = _default;
});