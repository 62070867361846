define("web-client/validators/matchesproperty", ["exports", "ember-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    init: function init() {
      // this call is necessary, don't forget it!
      this._super.apply(this, arguments);

      this.dependentValidationKeys.pushObject(this.options.matchesProperty);
    },
    call: function call() {
      let a = this.model.get(this.property);
      let b = this.model.get(this.options.matchesProperty);

      if (a !== b) {
        this.errors.pushObject(this.i18n.t(this.options.message));
      }
    }
  });

  _exports.default = _default;
});