define("web-client/components/x-tabs-tabs", ["exports", "ember-x-tabs/components/x-tabs-tabs"], function (_exports, _xTabsTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xTabsTabs.default;
    }
  });
});