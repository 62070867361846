define("web-client/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    classNames: ['registration-dialog'],
    actions: {
      blockSubmit()
      /*e*/
      {},

      registerUser: function registerUser() {
        let self = this;
      }
    }
  });

  _exports.default = _default;
});