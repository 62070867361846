define("web-client/components/company-settings-sections/session/component", ["exports", "lodash-es/each", "lodash-es/filter"], function (_exports, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'company-settings-session',
    globalActions: Ember.inject.service(),
    classNames: [''],
    sessionTimeoutValue: -1,
    lockTimeValue: -1,
    sessionSettingsNeedSaving: false,

    init() {
      this._super.apply(this, arguments);

      this.set('adminTimeSpanOptions', [{
        value: -1,
        display: 'No Override'
      }, {
        value: 5,
        display: '5 Minutes'
      }, {
        value: 15,
        display: '15 Minutes'
      }, {
        value: 30,
        display: '30 Minutes'
      }, {
        value: 60,
        display: '1 Hour'
      }, {
        value: 1440,
        display: '1 Day'
      }, {
        value: 10080,
        display: '1 Week'
      }]);
    },

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
    },

    getSessionTimeoutValue: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.sessionTimeoutValue;
    }),
    getLockTimeValue: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.lockTimeValue;
    }),
    populateLocalData: function populateLocalData() {
      let orgSettings = this.get('reduxStore.state.orgSettings');

      if (orgSettings) {
        if (orgSettings.session) {
          try {
            this.set('sessionTimeoutValue', Number(orgSettings.session.sessionTimeoutInMinutes));
            this.set('lockTimeValue', Number(orgSettings.session.sessionLockTimeInMinutes));
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    actions: {
      setLockTime(timeout) {
        if (this.mayChangeSection) {
          this.set('lockTimeValue', timeout);
          this.saveOrganizationSetting("session", "lockTimeValue", timeout);
          this.set('sessionSettingsNeedSaving', true);
        }
      },

      setLogoutTime(timeout) {
        if (this.mayChangeSection) {
          this.saveOrganizationSetting("session", "sessionTimeoutValue", timeout);
          this.set('sessionTimeoutValue', timeout);
          this.set('sessionSettingsNeedSaving', true);
        }
      },

      enforceSubscriptionSettings() {
        if (this.mayChangeSection) {
          this.sendOrganizationCommand("logoutAllUsers");
          this.set('sessionSettingsNeedSaving', false);
          this.globalActions.signOut();
        }
      }

    }
  });

  _exports.default = _default;
});