define("web-client/helpers/merge-deep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mergeDeep = mergeDeep;
  const logConsole = false;

  function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  function mergeDeep(target) {
    for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    if (!sources.length) return target;
    const source = sources.shift();

    if (logConsole) {
      console.log("target: " + target);
      console.log("source: " + source);
    }

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (logConsole) console.log("reviewing key " + key);

        if (isObject(source[key])) {
          if (logConsole) console.log("key " + key + " is object");

          if (!target[key]) {
            if (logConsole) console.log("assign key " + key);
            Object.assign(target, {
              [key]: {}
            });
          } else {
            if (logConsole) console.log("no set on key " + key);
          }

          mergeDeep(target[key], source[key]);
        } else {
          //Object.assign(target, {[key]: source[key]});
          try {
            if (logConsole) console.log("merge key: " + key + " -->" + source[key] + "<-");

            if ("null" !== source[key]) {
              Ember.set(target, key, source[key]);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    }

    return mergeDeep.apply(void 0, [target].concat(sources));
  }
});