define("web-client/components/password-generator-dialog/generated-passwords-list-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49DhRVNI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"table\"],[11,\"id\",\"generated-passwords-list-resizer\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"generated-item\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"sort-holder\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sort-name\"],[9],[0,\"\\n            Password\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"date-item\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"sort-holder\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sort-name\"],[9],[0,\"\\n            Date Generated\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-generator-dialog/generated-passwords-list-header/template.hbs"
    }
  });

  _exports.default = _default;
});