define("web-client/components/loading-dialog/component", ["exports", "web-client/components/loading-dialog/dialog-types/dialog-types", "ui-core/mixins/dialog-base", "platform-specific/ipc/ipc-client", "lodash-es/merge", "lodash-es/find"], function (_exports, _dialogTypes, _dialogBase, _ipcClient, _merge, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    parentComponent: null,
    nextAction: null,
    size: "size-3",
    options: Ember.Object.create({
      nextAction: null,
      title: "",
      continueButtonText: "",
      cancelButtonText: "",
      continueButtonTitle: "",
      cancelButtonTitle: ""
    }),
    message: "",
    updateMessage: "",
    _message: Ember.computed('message', 'updateMessage', function () {
      return this.updateMessage ? this.updateMessage : this.message;
    }),
    reduxStore: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      if (this.progressListenerHandle) {
        _ipcClient.ipc.removeEventListener(this.progressListenerHandle);
      }

      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init: function init() {
      this._super.apply(this, arguments);

      if (this.progressListenerHandle) {
        _ipcClient.ipc.addListener(this.progressListenerHandle, message => {
          this.set('updateMessage', message);
        });
      }

      var mergedOptions = (0, _merge.default)((0, _find.default)(_dialogTypes.default, {
        type: this.type
      }));
      this.set('dialogText', Ember.Object.create(mergedOptions));
    }
  });

  _exports.default = _default;
});