define("web-client/api/search", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search = void 0;

  /**
   * params
   * data
   * password
   */
  const search = _ipcClient.ipc.makeRequestFunction('search');

  _exports.search = search;
});