define("web-client/dashboard/controller", ["exports", "web-client/api", "lodash-es/countBy", "lodash-es/sortBy", "lodash-es/map", "lodash-es/uniq", "lodash-es/filter", "lodash-es/merge", "lodash-es/toPairs", "lodash-es/each"], function (_exports, _api, _countBy, _sortBy, _map, _uniq, _filter, _merge, _toPairs, _each) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    connectionStats: null,
    teamStats: null,
    passwordStats: null,

    getPasswordStats() {
      (0, _api.getPasswordStats)().then(data => {
        this.set('passwordStats', [{
          cat: "Yours",
          val: data.totalOwnedPasswords,
          color: '#001c53'
        }, {
          cat: "Others",
          val: data.totalSharedToYou,
          color: '#FFA726'
        }]);
      });
    },

    getConnectionStats() {
      (0, _api.getAllConnectionStats)().then(data => {
        this.set('connectionStats', [{
          cat: "Active",
          val: data.totalActiveConnections,
          color: '#001c53'
        }, {
          cat: "Inactive",
          val: data.totalInactiveConnections,
          color: '#FFA726'
        }]);
      });
    },

    getTeamStats() {
      (0, _api.getAllTeamStats)().then(data => {
        this.set('teamStats', [{
          cat: "Yours",
          val: data.totalYourTeams,
          color: '#001c53'
        }, {
          cat: "Theirs",
          val: data.totalTheirTeams,
          color: '#FFA726'
        }]);
      });
    },

    /*
       yoursAgeStats:data[0].ageStatCount,
          othersAgeStats:data[1].ageStatCount,
          yoursScoreStats:data[0].scoreStatCount,
          othersScoreStats:data[1].scoreStatCount
     */
    getAllPasswordAgeStats() {
      (0, _api.getAllPasswordAgeStats)().then(data => {
        this.set('passwordAgeStats', [{
          segment: 'Yours',
          freq: data.yoursAgeStats
        }, {
          segment: 'Others',
          freq: data.othersAgeStats
        }]);
        this.set('securityStats', [{
          segment: 'Yours',
          freq: data.yoursScoreStats
        }, {
          segment: 'Others',
          freq: data.othersScoreStats
        }]);
      });
    },

    passwordAgeStats: [],
    securityStats: [],
    securityStatsSchema: [{
      name: "Very Weak",
      color: "#B71C1C"
    }, {
      name: "Weak",
      color: "#F57C00"
    }, {
      name: "Medium",
      color: "#F58A30"
    }, {
      name: "Strong",
      color: "#43A047"
    }, {
      name: "Very Strong",
      color: "#2E7D32"
    }],
    passwordAgeStatsSchema: [{
      name: "Last 30 days",
      color: '#1B5E20'
    }, {
      name: "30 to 60  days",
      color: '#388E3C'
    }, {
      name: "60 to 90 days",
      color: '#43A047'
    }, {
      name: "90 to 180 days",
      color: '#F9A825'
    }, {
      name: "Over 180 days",
      color: '#F57F17'
    }],
    sessionStatsSchema: [],
    sessionStats: Ember.computed('reduxStore.state.sessions', function () {
      let colors = ['#00BCD4', '#03A9F4', '#009688', '#4CAF50', '#F44336', '#EF9A9A', '#AD1457', '#8E24AA', '#AB47BC', '#673AB7', '#1976D2', '#FBC02D', '#F57F17', '#FFD54F', '#795548', '#FFF59D', '#D84315'];
      let ipList = (0, _sortBy.default)((0, _map.default)((0, _uniq.default)((0, _map.default)(this.get("reduxStore.state.sessions"), function (item) {
        return item.ipaddress;
      })), function (item, index) {
        return {
          name: item,
          color: colors[index]
        };
      }), function (item) {
        return item.name;
      });
      this.set('sessionStatsSchema', ipList);
      let masterList = {};
      (0, _each.default)(ipList, function (item) {
        masterList[item.name] = 0;
      });
      let activeIps = (0, _filter.default)(this.get('reduxStore.state.sessions'), function (item) {
        return item.active;
      });
      let ActiveCounts = (0, _countBy.default)(activeIps, function (item) {
        return item.ipaddress;
      });
      let activeMerged = (0, _merge.default)(masterList, ActiveCounts);
      let activeFreq = (0, _map.default)((0, _sortBy.default)((0, _toPairs.default)(activeMerged), function (item) {
        return item[0];
      }), function (item) {
        return item[1];
      });
      let failedIps = (0, _filter.default)(this.get('reduxStore.state.sessions'), function (item) {
        return !item.success;
      });
      let failedCounts = (0, _countBy.default)(failedIps, function (item) {
        return item.ipaddress;
      });
      let failedMerged = (0, _merge.default)(masterList, failedCounts);
      let failedFreq = (0, _map.default)((0, _sortBy.default)((0, _toPairs.default)(failedMerged), function (item) {
        return item[0];
      }), function (item) {
        return item[1];
      });
      let successIps = (0, _filter.default)(this.get('reduxStore.state.sessions'), function (item) {
        return item.success && !item.active;
      });
      let successCounts = (0, _countBy.default)(successIps, function (item) {
        return item.ipaddress;
      });
      let successMerged = (0, _merge.default)(masterList, successCounts);
      let successFreq = (0, _map.default)((0, _sortBy.default)((0, _toPairs.default)(successMerged), function (item) {
        return item[0];
      }), function (item) {
        return item[1];
      });
      return [{
        segment: 'Active',
        freq: activeFreq
      }, {
        segment: 'Success',
        freq: successFreq
      }, {
        segment: 'Failure',
        freq: failedFreq
      }];
    }),
    actions: {
      goToDashboard: function goToDashboard() {
        this.globalActions.goToSettingsReplace();
      }
    }
  });

  _exports.default = _default;
});