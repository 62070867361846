define("web-client/components/system-message-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49Gh/vk+",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[11,\"style\",\"vertical-align:middle\"],[9],[7,\"ion-icon\"],[12,\"class\",[30,[[25,[\"message\",\"iconClass\"]]]]],[12,\"name\",[30,[[25,[\"message\",\"icon\"]]]]],[12,\"size\",[30,[[25,[\"message\",\"iconSize\"]]]]],[9],[10],[10],[0,\"\\n    \"],[7,\"td\"],[11,\"style\",\"vertical-align:middle\"],[12,\"class\",[30,[[25,[\"message\",\"messageClass\"]]]]],[9],[1,[25,[\"message\",\"message\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/system-message-list/template.hbs"
    }
  });

  _exports.default = _default;
});