define("web-client/components/password-selection-menu/component", ["exports", "ui-core/mixins/dialog-base"], function (_exports, _dialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-fit',
    classNames: [''],
    content: null,
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    escAction: "cancelDialog",

    //notifications: service(),
    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      },
      selectAllVisiblePasswords: function selectAllVisiblePasswords(grid) {
        this.globalActions.selectAllVisiblePasswords(grid);
        this.send('remove');
      },
      deselectAllPasswords: function deselectAllPasswords(grid) {
        this.globalActions.deselectAllPasswords(grid);
        this.send('remove');
      },
      selectInversePasswords: function selectInversePasswords(grid) {
        this.globalActions.selectInversePasswords(grid);
        this.send('remove');
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});