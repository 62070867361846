define("web-client/services/init-app-utils/get-language", ["exports", "web-client/lang-fingerprint", "web-client/api", "debug"], function (_exports, _langFingerprint, _api, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getLanguage;

  /**
   * Created by doug on 11/27/16.
   */
  let log = (0, _debug.default)('get-language');

  function getLanguage() {
    var self = this;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: 'Initializing Language Data'
      });
      fetch(_langFingerprint.default["default"].replace('//', '/')).then(function (response) {
        return response.json();
      }).then(function (json) {
        self.get('i18n').addTranslations('en', json);
        callback();
      }).catch(e => {
        log(e);
      });
    };
  }
});