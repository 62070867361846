define("web-client/api/save-organization-setting", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saveOrganizationSetting = void 0;

  /**
   * params
   * label
   * color
   */
  const saveOrganizationSetting = _ipcClient.ipc.makeRequestFunction('saveOrganizationSetting');

  _exports.saveOrganizationSetting = saveOrganizationSetting;
});