define("web-client/settings/import/controller", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  var _default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    router: Ember.inject.service(),
    fileContents: '',
    reduxStore: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    loading: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    subscription: Ember.computed.alias('reduxStore.state.subscription'),
    rows: Ember.computed.alias('reduxStore.state.importData'),
    //  canImport: computed(
    //    'subscription.limits.{maxPasswordEntryCount,usedPasswordEntryCount}',
    //    'rows.selected.content.length',
    //    function () {
    //
    //      return ((this.get("subscription.limits.maxPasswordEntryCount") - this.get('subscription.limits.usedPasswordEntryCount')) >= this.get("rows.selected.content.length"));
    //    }
    //  ),
    //  availableEntries: computed(
    //    'subscription.limits.{maxPasswordEntryCount,usedPasswordEntryCount}',
    //    function () {
    //
    //      return (this.get("subscription.limits.maxPasswordEntryCount") - this.get('subscription.limits.usedPasswordEntryCount'));
    //    }
    //  ),
    //  overLimitCount: computed(
    //    'subscription.limits.{maxPasswordEntryCount,usedPasswordEntryCount}',
    //    'selectedRows.content.length',
    //    function () {
    //      return this.get("selectedRows.content.length") - (this.get("subscription.limits.maxPasswordEntryCount") - this.get('subscription.limits.usedPasswordEntryCount'));
    //    }
    //  ),
    actions: {
      parseInput(inputText) {
        (0, _api.checkImportType)(inputText).then(importTypes => {
          if (importTypes.length === 0) {
            this.loading.dismiss();
            this.standardDialogs.showInfoDialog('info-import-type-not-found', null, true);
          } else {
            let progressListenerHandle = makeid();
            this.loading.show("Importing Passwords");
            (0, _api.parseImportFile)(importTypes[0], progressListenerHandle).then(() =>
            /*e*/
            {
              this.loading.dismiss();
              this.standardDialogs.showConfirmDialog('confirm-import-complete', false).then(() => {
                this.router.transitionTo('passwords');
              }).catch(() =>
              /*data*/
              {});
            });
          }
        }).catch(() =>
        /*input*/
        {// console.log(input)
        });
      }

    }
  });

  _exports.default = _default;
});