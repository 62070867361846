define("web-client/components/security-code-dialog/component", ["exports", "web-client/mixins/auth-base-dialog", "ember-cp-validations", "web-client/api"], function (_exports, _authBaseDialog, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    securityToken: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must enter your security token"
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    securityToken: '',
    classNames: ['size-3'],
    defaultAction: 'submitToken',
    showErrors: false,
    showSecurityTokenErrors: Ember.computed('securityToken', 'showErrors', function () {
      return this.showErrors || this.securityToken.length;
    }),

    didInsertElement() {
      this._super.apply(this, arguments);
    },

    actions: {
      submitToken: function submitToken() {
        let self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.get("submitToken")(self.get("securityToken"));
          }
        }).finally(function () {
          self.set('showErrors', true);
        });
      },
      cancelLogin: function cancelLogin() {
        (0, _api.logout)().then(() => {
          window.location.reload(true);
        });
      }
    }
  });

  _exports.default = _default;
});