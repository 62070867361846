define("web-client/platform-specific/tests/app.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | app');
  QUnit.test('app/actions/global-actions/show-edit-password-dialog.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/actions/global-actions/show-edit-password-dialog.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/edit-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/edit-menu/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/extra-field-dialog/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/extra-field-dialog/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/extra-fields-list-header/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/extra-fields-list-header/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/extra-fields-list-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/extra-fields-list-item/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/file-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/file-menu/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/help-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/help-menu/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/history-list-header/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/history-list-header/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/history-list-item/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/history-list-item/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/password-dialog/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/password-dialog/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/settings-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/settings-menu/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/components/tools-menu/component.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/components/tools-menu/component.js should pass ESLint\n\n');
  });
  QUnit.test('app/initialize-app/platform-init.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/initialize-app/platform-init.js should pass ESLint\n\n');
  });
  QUnit.test('app/ipc/ipc-client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/ipc/ipc-client.js should pass ESLint\n\n');
  });
  QUnit.test('app/themes/default/theme.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'app/themes/default/theme.js should pass ESLint\n\n');
  });
});