define("web-client/api/add-additional-licenses-to-subscription", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addAddtionalLicensesToSubscription = void 0;

  /**
   * params
  
   */
  const addAddtionalLicensesToSubscription = _ipcClient.ipc.makeRequestFunction('addAddtionalLicensesToSubscription');

  _exports.addAddtionalLicensesToSubscription = addAddtionalLicensesToSubscription;
});