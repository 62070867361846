define("web-client/actions/global-actions/select-individual-team-for-password-filter", ["exports", "lodash-es/includes", "lodash-es/remove"], function (_exports, _includes, _remove) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = selectIndividualTeamForPasswordFilter;

  function selectIndividualTeamForPasswordFilter(ids, ctrlKey, shiftKey) {
    Ember.run.later(() => {
      let currentGroups = storage.get('groups', []);

      if (shiftKey) {
        currentGroups.clear();
        currentGroups.push(ids.id);
      } else {
        let context = ids.id;

        if (ctrlKey) {
          if (!(0, _includes.default)(currentGroups, context)) {
            currentGroups.push(context);
          } else {
            (0, _remove.default)(currentGroups, function (item) {
              return item === context;
            });
          }
        } else {
          if (!(0, _includes.default)(currentGroups, context) || currentGroups.length > 1) {
            currentGroups.clear();
            currentGroups.push(context);
          } else {
            currentGroups.clear();
          }
        }
      }

      storage.set('groups', currentGroups);
      this.reduxStore.dispatch({
        type: 'SET_SELECTED_TEAMS',
        teams: currentGroups
      });
      this.getPasswords().then(passwords => {
        this.reduxStore.dispatch({
          type: 'UPDATE_PASSWORDS',
          passwords: passwords
        });
      });
    }, 0);
  }
});