define("web-client/actions/global-actions/is-connection-team-selected", ["exports", "lodash-es/every", "lodash-es/some", "lodash-es/filter"], function (_exports, _every, _some, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isConnectionTeamSelected;

  function isConnectionTeamSelected(checkTeam) {
    let teams = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
      return team.ownerNick === checkTeam.name && !team.individual;
    });
    let all = (0, _every.default)(teams, item => {
      return item.get('isSelected');
    });

    if (teams.length === 0) {
      return false;
    }

    if (all) {
      return true;
    }

    let x = (0, _some.default)(teams, item => {
      return item.get('isSelected');
    });

    if (x) {
      return null;
    }

    return false;
  }
});