define("web-client/components/radio-select-dialog/radio-select-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-item',
    layoutValue: "icon-start",
    classNames: ['multi-select-item'],
    disabled: false,
    button: false,
    attributeBindings: ['layoutValue:layout', 'disabled', 'checked', 'button'],
    actions: {},
    gestures: Ember.inject.service(),
    selected: false,

    willDestroyElement() {},

    init() {
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      let input = this.element.querySelector('ion-radio');
      input.addEventListener('ionSelect', e => {
        Ember.set(this.item, 'selected', e.detail.checked);
        e.stopPropagation();
      });
    }
  });

  _exports.default = _default;
});