define("web-client/api/get-system-updates", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSystemUpdates = void 0;

  /**
   * params
   *
   *
   */
  const getSystemUpdates = _ipcClient.ipc.makeRequestFunction('getSystemUpdates');

  _exports.getSystemUpdates = getSystemUpdates;
});