define("web-client/components/password-generator-dialog/generated-passwords-list-item/component", ["exports", "web-client/components/password-generator-dialog/generated-passwords-list-item/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'ion-item',
    reduxStore: Ember.inject.service(),
    classNameBindings: ['hover'],
    selected: true,
    attributeBindings: ["touchAction:touch-action"],
    password: null,
    copyData: '',
    generatedElement: null,
    notifications: Ember.inject.service(),
    actions: {
      copyField: function copyField() {
        if (this.get('generated.data')) {
          let element = this.get('element');
          let copyNode = document.getElementById('copyNode');
          copyNode.textContent = this.get('generated.data'); // "Optional" Select some text

          let range = document.createRange();
          range.selectNodeContents(document.getElementById('copyNode'));
          let sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range); // Use try & catch for unsupported browser

          try {
            // The important part (copy selected text)
            let successful = document.execCommand('copy'); // "Optional" remove selected text
            //     sel.removeAllRanges();
            //   copyNode.innerText = '';

            if (successful) {
              this.notifications.show("Field copied to clipboard");
            } else {
              this.notifications.show("Error copying to clipboard");
            }
          } catch (err) {
            this.notifications.show("Error copying to clipboard");
          }
        } else {
          this.notifications.show("Nothing to copy to clipboard."); //showSnackbar("Nothing to copy to clipboard.", "alert");
        }
      }
    },
    dragHandle: '.dragHandle',

    init() {
      this._super.apply(this, arguments);

      this.set('content', this);
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});