define("web-client/api/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.elapsedDateFormat = _exports.shortDateFormat = _exports.dateFormat = void 0;

  /**
   *
   *
   */
  const dateFormat = 'mm/dd/yy hh:MM:ss TT Z';
  _exports.dateFormat = dateFormat;
  const shortDateFormat = 'lll';
  _exports.shortDateFormat = shortDateFormat;
  const elapsedDateFormat = 'mmm dd yyyy hh:MM:ss TT';
  _exports.elapsedDateFormat = elapsedDateFormat;
});