define("web-client/utils/icon-server-alias-hash", ["exports", "xxhashjs"], function (_exports, _xxhashjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.iconServerAliasHash = iconServerAliasHash;
  _exports.generateFaviconUrl = generateFaviconUrl;
  let aliases = ["imga", "imgb", "imgc"];

  function iconServerAliasHash(url) {
    //  if ( url ) {
    //    let aliasIndex = (Math.abs(XXH.h32(url, 0xABCD).toNumber()) % 3);
    //
    ////    return htmlSafe("https://" + aliases[ aliasIndex ] + ".passpack.com/favicon/" + url);
    //    return htmlSafe(generateFaviconUrl(url));
    //  }
    //  else {
    ////    return htmlSafe("https://imga.passpack.com/favicon/none");
    //    return htmlSafe(generateFaviconUrl("none"));
    //  }
    return Ember.String.htmlSafe(generateFaviconUrl(url));
  }

  function generateFaviconUrl(url) {
    if (!url) {
      url = "none";
    } else {} //let domain = 'https://' + url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
    // no action
    // Clean up the domain - remove the protocol and remove the path
    // split the domain by the protocol


    let domainParts = url.split('://'); //if the domainParts has more than 1 part, then remove the protocol

    if (domainParts.length > 1) {
      url = domainParts[1];
    } else {
      url = domainParts[0];
    } // Now get rid of the path


    let pathParts = url.split('/');

    if (pathParts.length > 1) {
      url = pathParts[0];
    } // console.log("URL: " + url);


    let encodedDomain = btoa(url).replaceAll("\/", "-");
    return "https://img.passpack.com/favicon/v2/32/" + encodedDomain;
  }
});