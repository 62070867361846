define("web-client/components/invites-selection-menu/component", ["exports", "ui-core/mixins/nb-menu", "web-client/components/invites-selection-menu/template"], function (_exports, _nbMenu, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nbMenu.default, {
    layout: _template.default,
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service()
  });

  _exports.default = _default;
});