define("web-client/components/header-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVejwI/Z",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"nb-button\",null,[[\"icon\",\"fill\",\"shape\",\"color\",\"on-tap\"],[\"more\",\"clear\",\"round\",\"light\",[29,\"action\",[[24,0,[]],\"pushedButton\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/header-actions/template.hbs"
    }
  });

  _exports.default = _default;
});