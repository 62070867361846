define("web-client/components/company-settings-sections/sso/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LcTEpXgU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"ion-card\"],[11,\"class\",\"white-card\"],[9],[0,\"\\n    \"],[7,\"ion-card-header\"],[9],[0,\"\\n      \"],[7,\"ion-card-title\"],[9],[0,\"Single Sign On - SSO\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"ion-card-content\"],[9],[0,\"\\n      Configure special handling for your team's SSO logins.\\n\\n      \"],[7,\"ion-list\"],[11,\"class\",\"settings-list\"],[9],[0,\"\\n        \"],[7,\"ion-item-group\"],[9],[0,\"\\n          \"],[1,[29,\"ion-setting-item\",null,[[\"title\",\"value\",\"settingAction\"],[\"MFA Handling\",\"Allow SSO logins to bypass MFA.  This should only be utilized if your SSO has MFA enabled.\",[29,\"array\",[[29,\"unless\",[[25,[\"isBusinessPlan\"]],[29,\"component\",[\"read-only-input-display\"],[[\"displayText\",\"displayHeader\"],[[25,[\"unlockMessage\"]],[25,[\"unlockMessageHeader\"]]]]],[29,\"component\",[\"nb-select\"],[[\"class\",\"options\",\"label\",\"hint\",\"value\",\"readonly\",\"onSelectOption\"],[\"width-200\",[25,[\"adminMFAOptions\"]],\"MFA Handling\",\"Select option\",[25,[\"getMfaHandling\"]],[29,\"not\",[[25,[\"mayChangeSection\"]]],null],[29,\"action\",[[24,0,[]],\"setMfaHandling\"],null]]]]],null]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"settingsNeedSaving\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"ion-setting-item\",null,[[\"title\",\"value\",\"settingAction\"],[\"Immediately Enforce Settings\",\"Changes to SSO overrides are only applied at login.  You may force all team members to logout immediately.  This step is OPTIONAL and will also log you out.\",[29,\"array\",[[29,\"component\",[\"nb-button\"],[[\"type\",\"text\",\"title\",\"on-tap\"],[\"flat-text\",\"Force Logout\",\"Force Logout\",[29,\"action\",[[24,0,[]],\"enforceSettings\"],null]]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n\\n\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/company-settings-sections/sso/template.hbs"
    }
  });

  _exports.default = _default;
});