define("web-client/components/process-payment-default-card-dialog/component", ["exports", "ui-core/mixins/dialog-base"], function (_exports, _dialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    i18n: Ember.inject.service(),
    notifications: Ember.inject.service(),
    reduxStore: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: 'purchase',
    showErrors: false,
    nextAction: null,
    escAction: "cancelDialog",
    actions: {
      purchase: function purchase() {
        this.onPurchase.resolve();
        this.send("remove");
      },
      cancelDialog: function cancelDialog() {
        this.onPurchase.reject();
        this.send("remove");
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments); //var input = this.get('element').querySelector("* > input:first-of-type");
      //  input.focus();

    }

  });

  _exports.default = _default;
});