define("web-client/components/window-resize-layout-events/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    resize: Ember.inject.service(),

    willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this._resize);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      let mainHeader = document.getElementById('main-header');

      this._resize = function (event) {
        let fixedContent = document.getElementsByClassName('content-placeholder')[0];

        if (mainHeader && fixedContent && fixedContent.classList.contains('fixed-canvas')) {
          fixedContent.style.top = mainHeader.offsetHeight + 'px';
        } else if (fixedContent) {
          fixedContent.style.top = '0px';
        }
      };

      let resizing = false;

      if (mainHeader) {
        this.get('resize').observe(mainHeader);
        mainHeader.addEventListener('resize', function () {
          if (resizing === false) {
            resizing = true;
            window.dispatchEvent(new Event('resize'));
          } else {
            resizing = false;
          }
        });
        window.addEventListener('resize', this._resize);
      }
    }
  });

  _exports.default = _default;
});