define("web-client/dashboard/system-updates/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    router: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "System Updates"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_DASHBOARD_SECTION",
          dashboardSection: "System Updates"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_HEADER_BUTTON",
          selectedHeaderButton: "dashboard"
        });
      }

    },

    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
    },

    renderTemplate() {
      this.render('dashboard/system-updates', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});