define("web-client/components/set-extra-invoice-info-dialog/component", ["exports", "ui-core/mixins/dialog-base", "ember-concurrency", "ember-cp-validations", "web-client/api", "lodash-es/has", "lodash-es/isNumber"], function (_exports, _dialogBase, _emberConcurrency, _emberCpValidations, _api, _has, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newUserID: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Username can't be blank"
    }), (0, _emberCpValidations.validator)('username', {
      message: "Only the characters ( A-Z, a-z,0-9, _ , - , @ , .) are allowed"
    }), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 32,
      message: "Username must be between 2 and 32 characters"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, {
    size: 'size-4',
    classNames: ['send-message-dialog'],
    field1: Ember.computed.alias('reduxStore.state.extraInvoiceInfo.field1'),
    field2: Ember.computed.alias('reduxStore.state.extraInvoiceInfo.field2'),
    newField1: '',
    notifications: Ember.inject.service(),
    newField2: '',
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    canSave: Ember.computed('newField1', 'newField2', function () {
      return false;
    }),
    saveInfo: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      (0, _api.setExtraInvoiceInfo)(this.newField1, this.newField2).then(() =>
      /*e*/
      {
        (0, _api.getExtraInvoiceInfo)().then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_EXTRA_INVOICE_INFO',
            extraInvoiceInfo: {
              field1: data.field1,
              field2: data.field2
            }
          });
          this.send('remove');
          deferred.resolve();
        }).catch(() => {
          deferred.reject();
        });
      }).catch(data => {
        if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
          this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'));
        } else {
          this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
        }

        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newField1', this.field1);
      this.set('newField2', this.field2);
    },
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});