define("web-client/components/session-history-list-item-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "97tQ7hU2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-menu\",null,[[\"tether\"],[[25,[\"tether\"]]]],{\"statements\":[[0,\"    \"],[1,[29,\"nb-menu-header\",null,[[\"text\",\"title\",\"icon\"],[\"Session\",\"Actions for this team\",[29,\"build-svg-icon\",[\"login-variant\",\"grey-800\"],null]]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"role\",\"presentation\"],[11,\"class\",\"nb-menu-item-divider item\"],[9],[10],[0,\"\\n\\n    \"],[1,[29,\"nb-menu-item\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\"],[\"View Details\",\"View session details\",[29,\"build-svg-icon\",[\"details\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"details\",\"white\"],null],[29,\"route-action\",[\"showSessionDialog\",[25,[\"session\"]]],null]]]],false],[0,\"\\n\\n    \"],[1,[29,\"nb-menu-item\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\",\"disabled\"],[\"Sign out session\",\"Sign out session\",[29,\"build-svg-icon\",[\"close\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"close\",\"white\"],null],[29,\"route-action\",[\"killSession\",[25,[\"session\",\"id\"]]],null],[29,\"if\",[[29,\"or\",[[29,\"not\",[[25,[\"session\",\"activeSession\"]],false],null],[25,[\"session\",\"isCurrentSession\"]]],null],true,false],null]]]],false],[0,\"\\n\\n\\n\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/session-history-list-item-menu/template.hbs"
    }
  });

  _exports.default = _default;
});