define("web-client/components/ion-setting-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HMk4dhLb",
    "block": "{\"symbols\":[\"item\",\"item\"],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"layoutDirection\"]],\"horizontal\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"inner-setting-wrapper \",[29,\"media\",[\"classNames\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"key-value-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[7,\"h2\"],[9],[1,[23,\"title\"],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[23,\"value\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"description\"],[9],[1,[23,\"description\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"slot\",\"end\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"settingAction\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inner-setting-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"key-value-wrapper\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[7,\"h2\"],[9],[1,[23,\"title\"],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n        \"],[1,[23,\"value\"],false],[0,\"\\n\\n\\n\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"footer-buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"settingAction\"]]],null,{\"statements\":[[0,\"        \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/ion-setting-item/template.hbs"
    }
  });

  _exports.default = _default;
});