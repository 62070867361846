define("web-client/components/billing-history-item/component", ["exports", "web-client/api", "web-client/config/environment"], function (_exports, _api, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var baseUrl = _environment.default.apiHost;

  var _default = Ember.Component.extend({
    classNames: ["billing-history-item"],
    tagName: 'ion-row',
    media: Ember.inject.service(),
    totalAmount: Ember.computed('billingItem', 'billingItem.amount', function () {
      return this.get('billingItem.amount') * .01;
    }),
    refunded: Ember.computed('billingItem', 'billingItem.amountRefunded', function () {
      return -1 * this.get('billingItem.amountRefunded') * .01;
    }),
    displayLinks: Ember.computed('billingItem', 'billingItem.invoiceId', function () {
      return null != this.get('billingItem.invoiceId') && this.get('billingItem.status') !== 'failed';
    }),
    viewReceiptLink: Ember.computed('billingItem', function () {
      return baseUrl + '/portal/v1/secure/account/billing/receipt/view/' + this.get("billingItem.invoiceId");
    }),
    downloadReceiptLink: Ember.computed('billingItem', function () {
      return baseUrl + '/portal/v1/secure/account/billing/receipt/download/' + this.get("billingItem.invoiceId");
    }),
    actions: {
      download(invoiceId, type) {
        (0, _api.getInvoice)(invoiceId, "download", type);
      },

      view(invoiceId, disposition, type) {
        (0, _api.getInvoice)(invoiceId, "view", type);
      }

    }
  });

  _exports.default = _default;
});