define("web-client/api/update-user-id", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateUserID = void 0;

  /**
   * params
   *params
   *
   */
  const updateUserID = _ipcClient.ipc.makeRequestFunction('updateUserID');

  _exports.updateUserID = updateUserID;
});