define("web-client/components/ion-avatar-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mrGihzoX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-avatar\"],[11,\"slot\",\"end\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[30,[[23,\"profileImageURL\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/ion-avatar-button/template.hbs"
    }
  });

  _exports.default = _default;
});