define("web-client/initializers/auto-import-stencil-collections", ["exports", "@ionic/core/loader"], function (_exports, _loader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  (0, _loader.defineCustomElements)(window);

  function initialize() {// No-op
  }

  ;
  var _default = {
    initialize
  };
  _exports.default = _default;
});