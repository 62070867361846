define("web-client/components/registration-plan-dialog/component", ["exports", "web-client/mixins/registration-base-dialog", "web-client/config/environment"], function (_exports, _registrationBaseDialog, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var baseUrl = _environment.default.apiHost;

  var _default = Ember.Component.extend(_registrationBaseDialog.default, {
    standardDialogs: Ember.inject.service(),
    classNames: ['registration-dialog'],
    classNameBindings: ['currentSize'],
    currentSize: Ember.computed('success', function () {
      return this.success ? 'size-3' : 'size-3';
    }),
    defaultAction: 'registerUser',
    actions: {
      blockSubmit()
      /*e*/
      {},

      goToLogin: function goToLogin() {
        this.reduxStore.dispatch({
          type: 'REGISTRATION_SUCCESSFUL_RESET'
        });
        this.goToLogin();
      },

      goToPreviousVersion() {
        window.location.href = "https://www.passpack.com/online";
      },

      goToTeamRegistration: function goToTeamRegistration() {
        this.goToTeamRegistration();
      },
      goToBusinessRegistration: function goToBusinessRegistration() {
        this.goToBusinessRegistration();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});