define("web-client/services/notifications", ["exports", "lodash-es/isNumber"], function (_exports, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  var _default = Ember.Service.extend({
    /**
     *
     * @param dialogComponent A component name as a string "some-dialog"
     * @param params an Ember Object or POJO to be passed to the dialog and used for parameters.
     */
    show: function () {
      var _show = _asyncToGenerator(function* (message, duration, type) {
        let dur = duration && (0, _isNumber.default)(duration) ? duration : 5000;
        const toastController = document.querySelector('ion-toast-controller');
        yield toastController.componentOnReady();
        const toast = yield toastController.create({
          message: message + "",
          duration: dur,
          position: "top"
        });
        return yield toast.present();
      });

      function show(_x, _x2, _x3) {
        return _show.apply(this, arguments);
      }

      return show;
    }(),
    showSnackbar: function showSnackbar(message, type) {
      switch (type) {
        default:
          this.show(message);
      }
    }
  });

  _exports.default = _default;
});