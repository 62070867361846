define("web-client/components/yubikey-setup-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api", "lodash-es/has", "lodash-es/isNumber", "lodash-es/map", "file-saver"], function (_exports, _dialogBase, _api, _has, _isNumber, _map, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    complete: false,
    gaCode: '',
    size: 'size-3',
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    page: 'instructions',
    classNames: ['yubikey-setup-dialog'],
    codes: Ember.computed.alias('reduxStore.state.gaBackupTokens'),
    defaultAction: 'registerYubikey',

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      registerYubikey() {
        (0, _api.registerYubikey)(this.yubikey).then(() =>
        /*status*/
        {
          return (0, _api.getOOBOptions)().then(data => {
            this.reduxStore.dispatch({
              type: 'INITIALIZE_OOB_OPTIONS_SUCCESS',
              oobPreferences: Ember.Object.create(data)
            });
            return (0, _api.getGoogleAuthBackupTokens)().then(data => {
              this.reduxStore.dispatch({
                type: 'SET_GA_BACKUP_TOKENS',
                gaBackupTokens: data.codes
              });
              this.set('page', 'complete');
              this.set('complete', true);
            });
          });
        }).catch(data => {
          if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
            this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'), 'alert');
          } else {
            this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)), 'alert');
          }
        });
      },

      backupCodesShowDialog: function backupCodesShowDialog() {
        (0, _api.getGoogleAuthBackupTokens)().then(data => {
          if (data.codes.length > 0) {
            this.reduxStore.dispatch({
              type: 'SET_GA_BACKUP_TOKENS',
              gaBackupTokens: data.codes
            });
            var downloadFile = "Emergency Access Codes:\n" + (0, _map.default)(data.codes, function (code) {
              return code;
            }).join('\r\n');
            var dateString = new Date().format('mm-dd-yy-hh-MM-ss');
            var filename = "passpack-access-codes-" + dateString + '.txt';
            var file = new File([downloadFile], filename, {
              type: "text/plain;charset=utf-8"
            });

            _fileSaver.default.saveAs(file);
          } else {// Do something if no more codes.
          }
        });
      },
      downloadSecretKey: function downloadSecretKey() {
        var downloadFile = "Google Authenticator Secret Key:\r\n" + this.get('reduxStore.state.gaData.code');
        var dateString = new Date().format('mm-dd-yy-hh-MM-ss');
        var filename = "passpack-ga-key-" + dateString + '.txt';
        var file = new File([downloadFile], filename, {
          type: "text/plain;charset=utf-8"
        });

        _fileSaver.default.saveAs(file);
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});