define("web-client/components/transfer-ownership-dialog/team-item/component", ["exports", "web-client/components/transfer-ownership-dialog/team-item/template", "tabbable", "lodash-es/indexOf"], function (_exports, _template, _tabbable, _indexOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    gestures: Ember.inject.service(),
    classNames: ['transfer-ownership-item', 'item'],
    tagName: 'ion-item',
    disabled: false,
    attributeBindings: ['tabindex', "title"],
    tabindex: 0,
    useNativeClick: false,
    actions: {},

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let checkbox = this.element.querySelector('ion-radio');
      checkbox.addEventListener('ionSelect', e => {
        this.set('currentSelected', this.item);
      });
    }

  });

  _exports.default = _default;
});