define("web-client/components/color-picker-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/utils/color-options", "web-client/api", "ember-concurrency", "lodash-es/filter", "lodash-es/includes", "lodash-es/groupBy", "lodash-es/sortBy"], function (_exports, _dialogBase, _colorOptions, _api, _emberConcurrency, _filter, _includes, _groupBy, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-2',
    content: null,
    i18n: Ember.inject.service(),
    classNames: ["color-picker-dialog"],
    notifications: Ember.inject.service(),
    group: null,
    loading: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    filterText: '',
    colorOptions: _colorOptions.colorOptions,
    currentSelected: null,
    showErrors: false,

    init() {
      this._super.apply(this, arguments);
    },

    chooseColor: (0, _emberConcurrency.task)(function* () {
      this.send('remove');
      yield this.complete.resolve(this.currentSelected);
    }),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.reject();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});