define("web-client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C1iZwzC1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ember-view\"],[9],[0,\"\\n\"],[0,\"  \"],[5,\"welcome-page\",[],[[],[]]],[0,\"\\n\"],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/templates/application.hbs"
    }
  });

  _exports.default = _default;
});