define("web-client/helpers/effective-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.effectivePermissons = effectivePermissons;
  _exports.default = void 0;

  function effectivePermissons(params) {
    let defaultPerm = params[0];
    let connectionPerm = params[1];
    let asRadableString = params[2];

    if (asRadableString) {
      let effective;

      if (connectionPerm === 0) {
        effective = defaultPerm;
      } else {
        effective = params[1];
      }

      switch (effective) {
        case 1:
          return "owner";

        case 2:
          return "administrator";

        case 3:
          return "read / write";

        case 4:
          return "read only";
      }
    } else {
      if (connectionPerm === 0) {
        return defaultPerm;
      } else {
        return params[1];
      }
    }
  }

  var _default = Ember.Helper.helper(effectivePermissons);

  _exports.default = _default;
});