define("web-client/components/password-generator-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api", "ember-cp-validations"], function (_exports, _dialogBase, _api, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password name can't be blank"
    })],
    url: [(0, _emberCpValidations.validator)('uri', {
      allowBlank: true,
      message: "Must be a valid URL"
    })],
    email: [(0, _emberCpValidations.validator)('email', {
      allowBlank: true,
      message: "Must be a valid email address"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, {
    size: 'size-4',
    classNames: ['password-generator-dialog'],
    content: null,
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    name: Ember.computed.alias('model.name'),
    url: Ember.computed.alias('model.url'),
    email: Ember.computed.alias('model.email'),
    generatedPassword: '',
    passwordLength: 12,
    lowercase: true,
    uppercase: true,
    symbols: true,
    numbers: true,
    activeTab: 'generator',
    nameSize: 320,
    valueSize: 100,
    showAll: false,

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);

      this.set('generatedPassword', (0, _api.generatePassword)(this.uppercase, this.lowercase, this.numbers, this.symbols, this.passwordLength));
    },

    currentTab: "generator",
    actions: {
      onFieldCopied(status) {
        if (status === 'success') {
          this.notifications.show("Field copied to clipboard");
        } else {
          this.notifications.show("Error copying field to clipboard");
        }
      },

      deleteGeneratedPassword(generated) {},

      setPasswordLength(value) {
        this.set('passwordLength', value); //useUpper,useLower,useNumbers,useSymbols,

        this.set('generatedPassword', (0, _api.generatePassword)(this.uppercase, this.lowercase, this.numbers, this.symbols, this.passwordLength));
      },

      generatePassword: function generatePassword() {
        this.set('generatedPassword', (0, _api.generatePassword)(this.uppercase, this.lowercase, this.numbers, this.symbols, this.passwordLength));
      },

      updatePool(segment) {
        this.set(segment, !this.get(segment));
        this.set('generatedPassword', (0, _api.generatePassword)(this.uppercase, this.lowercase, this.numbers, this.symbols, this.passwordLength));
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
      },
      useGenerated: function useGenerated() {
        (0, _api.saveGeneratedPassword)(this.key, this.generatedPassword, this.reduxStore.state.userId).then(() => {
          if (this.isFieldFill) {
            this.focusField.value = this.generatedPassword;
            this.focusTrap.deactivate();
            this.parentFocusTrap.activate();
            setTimeout(() => {
              this.focusField.focus();
              let event = new Event('change', {
                bubbles: true
              });
              this.focusField.dispatchEvent(event);
            }, 1);
          } else {
            if (this.get('generatedPassword')) {
              let element = this.get('element');
              let copyNode = document.getElementById('copyNode');
              copyNode.textContent = this.get('generatedPassword'); // "Optional" Select some text

              let range = document.createRange();
              range.selectNodeContents(document.getElementById('copyNode'));
              let sel = window.getSelection();
              sel.removeAllRanges();
              sel.addRange(range); // Use try & catch for unsupported browser

              try {
                // The important part (copy selected text)
                let successful = document.execCommand('copy'); // "Optional" remove selected text
                //     sel.removeAllRanges();
                //   copyNode.innerText = '';

                if (successful) {
                  this.notifications.show("Field copied to clipboard");
                } else {
                  this.notifications.show("Error copying to clipboard");
                }
              } catch (err) {
                this.notifications.show("Error copying to clipboard");
              }
            } else {
              this.notifications.show("Nothing to copy to clipboard."); //showSnackbar("Nothing to copy to clipboard.", "alert");
            }
          }

          this.send('remove');
        }).catch(e => {
          console.log(e);
        });
      },

      setTab(tab) {
        this.set('currentTab', tab);
      },

      showAllGenerated() {
        if (this.showAll) {
          this.set('showAll', false);
        } else {
          this.set('showAll', true);
        }
      }

    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});