define("web-client/api/startup-log", ["exports", "debug", "lodash-es/merge"], function (_exports, _debug, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startupLog = startupLog;
  let log = (0, _debug.default)('startup-log');
  /*
    getAccountDetails
    getAccountSummary
    getAccountLimits
    getGetLanguage
    getOOBOptions
    getSettingsData
    InitDatabase
  
   */

  let currentProgressMax = 100;

  function startupLog(params) {
    let newParams = {
      actionText: params.actionText,
      remainingTimeText: params.remainingTimeText ? params.remainingTimeText : "",
      increaseProgressMaxBy: params.increaseProgressMaxBy ? params.increaseProgressMaxBy : 0,
      increment: params.increment ? params.increment : 1
    };
    let element = document.getElementsByClassName('loading-step-text')[0];
    let progress = document.getElementsByClassName('loading-progress-bar')[0];
    let remainingTime = document.getElementsByClassName('loading-remaining-time-text')[0];

    if (element) {
      element.textContent = newParams.actionText;
    }

    currentProgressMax = currentProgressMax + newParams.increaseProgressMaxBy;

    if (progress) {
      progress.setAttribute('value', parseInt(progress.getAttribute('value')) + newParams.increment);
      progress.setAttribute('max', currentProgressMax);
    }

    if (remainingTime) remainingTime.textContent = newParams.remainingTimeText;
  }
});