define("web-client/createpassphrase/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/unauthed-route"], function (_exports, _baseRoute, _unauthedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    initApp: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),

    renderTemplate() {
      this.render('createpassphrase', {
        into: 'application',
        outlet: 'content'
      });
    }

  });

  _exports.default = _default;
});