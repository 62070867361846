define("web-client/components/connection-dialog/team-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RANL772t",
    "block": "{\"symbols\":[\"itemAction\",\"itemAction\"],\"statements\":[[7,\"ion-item\"],[9],[0,\"\\n\"],[7,\"ion-icon\"],[11,\"slot\",\"start\"],[12,\"name\",[30,[[29,\"if\",[[25,[\"item\",\"individual\"]],\"contact\",\"folder\"],null]]]],[12,\"color\",[30,[[25,[\"item\",\"color\"]]]]],[9],[10],[0,\"\\n\\n\"],[7,\"ion-label\"],[9],[1,[25,[\"item\",\"name\"]],false],[10],[0,\"\\n\\n\\n\"],[7,\"ion-buttons\"],[11,\"class\",\"manage-label-buttons\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"slide\"]],false],null]],null,{\"statements\":[[4,\"each\",[[25,[\"itemActions\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[24,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"itemActions\",\"0\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"slide\"]]],null,{\"statements\":[[7,\"ion-item-options\"],[11,\"side\",\"end\"],[9],[0,\"\\n  \"],[7,\"ion-item-option\"],[11,\"color\",\"grey-100\"],[9],[0,\"\\n    \"],[7,\"ion-buttons\"],[11,\"class\",\"manage-label-buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"itemActions\"]]],null,{\"statements\":[[0,\"\\n        \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/connection-dialog/team-item/template.hbs"
    }
  });

  _exports.default = _default;
});