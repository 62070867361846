define("web-client/catch-all/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect() {
      if (this.get('previous.route') && this.get('previous.route') !== 'lock') {
        this.transitionTo(this.get('previous.route'));
      } else {
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});