define("web-client/components/company-settings-sections/domains/component", ["exports", "lodash-es/each", "lodash-es/filter"], function (_exports, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'domains',
    globalActions: Ember.inject.service(),
    classNames: [''],
    settingsNeedSaving: false,
    verifiedDomainListing: {},
    allowedDomainListing: {},

    init() {
      this._super.apply(this, arguments);
    },

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
    },

    allowedDomainOptions: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.get('allowedDomainListing');
    }),
    verifiedDomainOptions: Ember.computed('reduxStore.state.orgSettings', function () {
      this.populateLocalData();
      return this.get('verifiedDomainListing');
    }),
    populateLocalData: function populateLocalData() {
      let orgSettings = this.get('reduxStore.state.orgSettings');

      if (orgSettings) {
        if (orgSettings.verifiedDomains) {
          try {
            this.set('verifiedDomainListing', orgSettings.verifiedDomains);
          } catch (e) {
            console.log(e);
          }
        }

        if (orgSettings.allowedDomains) {
          try {
            this.set('allowedDomainListing', orgSettings.allowedDomains);
          } catch (e) {
            console.log(e);
          }
        }
      } //    if (this.organizationSettings && this.organizationSettings.sectionSettings) {
      //      let sectionData = this.organizationSettings.sectionSettings.verifiedDomains;
      //      if (sectionData) {
      //      console.log(sectionData);
      //        try {
      //            let verified = A([]);
      //            for (const [key, value] of Object.entries(sectionData)) {
      //              console.log(key, value);
      //              verified.pushObject({'domain':key,'status':value});
      //            }
      //            this.set('verifiedDomainListing', verified);
      //        } catch (e) {
      //          console.log(e);
      //        }
      //      }
      //    }

    },
    actions: {
      setMfaHandling(option) {
        if (this.mayChangeSection) {
          this.saveOrganizationSetting("sso", "mfaHandling", option);
          this.set('settingsNeedSaving', true);
        }
      },

      enforceSettings() {
        if (this.mayChangeSection) {
          this.sendOrganizationCommand("logoutAllUsers");
          this.set('settingsNeedSaving', false);
          this.globalActions.signOut();
        }
      }

    }
  });

  _exports.default = _default;
});