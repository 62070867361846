define("web-client/components/bulk-manage-teams-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/includes", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/flatten", "lodash-es/countBy", "lodash-es/map", "lodash-es/find", "lodash-es/groupBy", "lodash-es/cloneDeep", "web-client/api", "neo-async", "ember-concurrency", "web-client/config/environment", "xxhashjs", "lodash-es/reduce", "lodash-es/difference", "lodash-es/concat"], function (_exports, _dialogBase, _includes, _filter, _sortBy, _flatten, _countBy, _map, _find, _groupBy, _cloneDeep, _api, _neoAsync, _emberConcurrency, _environment, _xxhashjs, _reduce, _difference, _concat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    selectedItems: null,
    loading: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    filterText: '',
    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      return Ember.A((0, _groupBy.default)((0, _sortBy.default)((0, _filter.default)(this.items, item => {
        return item.name.toLowerCase().includes(this.filterText.toLowerCase());
      }), item => {
        return item.name.toLowerCase();
      }), item => {
        return item.individual ? "Connections" : "Teams";
      }));
    }),
    items: Ember.A([]),
    originalTeams: Ember.A([]),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),
    updateTeamsList: function updateTeamsList() {
      this.loading.show('Loading');
      let self = this;
      this.items.clear();
      this.originalTeams.clear();
      (0, _api.filterOnOwned)(this.reduxStore.state.data.passwords.selected).then(owned => {
        this.set('selectedPasswordsLength', owned.length); //  this.set('selectedPasswords',selectedPasswords);
        // this.set('passwordsToDelete',filtered);
        //  this.set('totalToDelete',filtered.length);
        //  this.set('totalSelected',selectedPasswords.length);

        (0, _api.getTeamsForPasswords)(owned).then(data => {
          this.set('passwordTeams', data);
          let filtered = (0, _filter.default)(data, function (item) {
            return item.teams.length > 0;
          });
          let flattened = (0, _flatten.default)((0, _map.default)(filtered, function (item) {
            return item.teams;
          }));
          let counts = (0, _countBy.default)(flattened, function (item) {
            return item.id;
          });
          let teams = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
            return team.name !== 'Default' && !team.individual && team.owner === this.get('reduxStore.state.username') || team.individual && team.owner === this.get('reduxStore.state.username');
          });
          let items = (0, _map.default)((0, _cloneDeep.default)(teams), item => {
            item.selected = counts[item.id] > 0 ? counts[item.id] === data.length ? true : undefined : false;
            item.teamCount = counts[item.id];
            item.originalSelection = item.selected;
            return item;
          });
          this.items.pushObjects(items);
          this.originalTeams.pushObjects((0, _cloneDeep.default)(items));
          self.loading.dismiss();
        }).catch(e => {
          console.log(e);
        });
      });
    },
    showEditTeamDialog: (0, _emberConcurrency.task)(function* (team) {
      let deferred = Ember.RSVP.defer();
      let args = Ember.Object.create({
        team: team,
        onComplete: deferred
      });
      (0, _api.getConnectionsForTeam)(team.id).then(connections => {
        this.standardDialogs.showDialog('edit-team-dialog', {
          tm: team,
          cn: connections,
          showEditMembersTab: false,
          onComplete: deferred
        });
      });
      deferred.promise.then(data => {
        if (data && data.id) {
          let found = (0, _find.default)(this.items, function (item) {
            return item.id === data.id;
          });
          Ember.set(found, 'name', data.name);
          Ember.set(found, 'color', data.color);
          Ember.set(found, 'description', data.description);
          Ember.set(found, 'defaultRole', data.defaultRole);
          found = (0, _find.default)(this.originalTeams, function (item) {
            return item.id === data.id;
          });
          Ember.set(found, 'name', data.name);
          Ember.set(found, 'color', data.color);
          Ember.set(found, 'description', data.description);
          Ember.set(found, 'defaultRole', data.defaultRole); //    this.originalTeams.removeObject(found);
        } else {
          if (data === 'deleted') {
            let found = (0, _find.default)(this.items, function (item) {
              return item.id === team.id;
            });
            this.items.removeObject(found);
            found = (0, _find.default)(this.originalTeams, function (item) {
              return item.id === team.id;
            });
            this.originalTeams.removeObject(found);
          } else {// console.log('huh',data)
          }
        }
      });
      yield deferred.promise;
    }).drop(),
    deleteTeam: (0, _emberConcurrency.task)(function* (teamId) {
      let deferred = Ember.RSVP.defer();
      let self = this;
      this.standardDialogs.showConfirmDialog('confirm-delete-team').then(() => {
        this.loading.show('Deleting team');
        return (0, _api.deleteTeams)([teamId]);
      }).then(() => {
        this.reduxStore.state.data.teams.selected.removeObject(teamId);
        return (0, _api.getTeams)();
      }).then(data => {
        self.reduxStore.dispatch({
          type: 'UPDATE_TEAMS',
          teams: data
        });
        let found = (0, _find.default)(this.items, function (item) {
          return item.id === teamId;
        });
        this.items.removeObject(found);
        found = (0, _find.default)(this.originalTeams, function (item) {
          return item.id === teamId;
        });
        this.originalTeams.removeObject(found);
        self.loading.dismiss();
        deferred.resolve();
      }).catch(data => {
        this.loading.dismiss();
        deferred.resolve();

        if (data !== 'cancelled') {
          this.globalActions.actionUIErrorHandler(data);
        }
      });
      yield deferred.promise;
    }).drop(),
    originalTeamsHash: Ember.computed('originalTeams', 'originalTeams.{[],@each}', function () {
      if (this.originalTeams && this.originalTeams.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.originalTeams, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id.toString() + (item.selected === undefined ? 'undefined' : item.selected.toString()) + acc, 123).toString(16);
          return acc;
        }, 0), 0).toString(16);
      } else {
        return "0";
      }
    }),
    teamsHash: Ember.computed('items', 'items.{[],@each}', 'items.@each.selected', function () {
      if (this.items && this.items.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.items, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id.toString() + (item.selected === undefined ? 'undefined' : item.selected.toString()) + acc, 123).toString(16);
          return acc;
        }, 0), 0).toString(16);
      } else {
        return "0";
      }
    }),
    hasChanged: Ember.computed('originalTeamsHash', 'teamsHash', 'items', 'items.[]', 'items.@each.selected', function () {
      return this.originalTeamsHash !== this.teamsHash;
    }),
    undoChanges: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.updateTeamsList();
      deferred.resolve();
      return deferred.promise;
    }).drop(),
    saveChanges: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.loading.show('Saving Changes');
      let self = this;
      let selectedTeams = (0, _filter.default)(this.items, function (item) {
        return item.selected;
      });
      let unchangedTeams = (0, _filter.default)(this.items, function (item) {
        return item.selected === undefined;
      });
      let changeRecords = {
        addToThese: [],
        removeFromThese: []
      };

      _neoAsync.default.each(this.passwordTeams, function (data, callback) {
        let teams = (0, _difference.default)((0, _map.default)((0, _filter.default)(data.teams, team => {
          return team.name !== "Default";
        }), 'id'), (0, _map.default)(unchangedTeams, 'id'));
        let addToThese = (0, _difference.default)((0, _map.default)(selectedTeams, 'id'), teams);
        let removeFromThese = (0, _difference.default)(teams, (0, _map.default)(selectedTeams, 'id'));

        if (addToThese.length) {
          let records = (0, _map.default)(addToThese, function (groupId) {
            let password = (0, _find.default)(self.reduxStore.state.data.passwords.content, function (password) {
              return password.id === data.passwordId;
            });
            return {
              id: password.id,
              key: password.key,
              gid: groupId
            };
          });
          changeRecords.addToThese = (0, _concat.default)(changeRecords.addToThese, records);
        }

        if (removeFromThese.length) {
          changeRecords.removeFromThese.push({
            id: data.passwordId,
            gids: removeFromThese
          });
        }

        callback();
      }, function (err, data) {
        (0, _api.bulkUpdateTeamsForPasswords)(changeRecords).then(data => {
          self.loading.dismiss();
          deferred.resolve(data);
          self.send('remove');
          self.complete.resolve();
        }).catch(data => {
          deferred.resolve();
        });
      });

      yield deferred.promise;
      /*let labelChanges = {
        removeFromThese: difference(map(this.get('originalTeams'), 'id'), map(this.get('teams'), 'id')),
        addToThese: difference(map(this.get('teams'), 'id'), map(this.get('originalTeams'), 'id'))
      };*/
    }).drop(),

    init() {
      this._super.apply(this, arguments);

      this.set('selectedItems', Ember.A([]));
      this.updateTeamsList();
    },

    addNewTeam: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let args = Ember.Object.create({
        onComplete: deferred
      });
      this.standardDialogs.showDialog('create-team-dialog', args);
      deferred.promise.then(data => {
        if (data && data.id) {
          this.updateTeamsList();
        }
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      setItemSelectedState(itemId, newState) {
        if (newState) {
          if (!(0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.pushObject(itemId);
          }
        } else {
          if ((0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.removeObject(itemId);
          }
        }
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.resolve();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});