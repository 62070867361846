define("web-client/services/init-app-utils/get-account-details", ["exports", "web-client/api", "lodash-es/merge", "lodash-es/eq", "lodash-es/has", "lodash-es/isNumber"], function (_exports, _api, _merge, _eq, _has, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAccountDetails;

  function getAccountDetails() {
    let reduxStore = this.reduxStore;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: 'Loading Account Details'
      });
      (0, _api.getAccountDetails)().then(function (data) {
        // Check if the data is actually valid or silently caught upstream.
        if (data == null) {
          console.log("null data");
          reduxStore.dispatch({
            type: 'SET_NETWORK_UNAVAILABLE',
            value: true
          });
          callback('Failed getAccountDetails - network error');
        } else {
          reduxStore.dispatch({
            type: 'SET_NETWORK_UNAVAILABLE',
            value: false
          });
          reduxStore.dispatch({
            type: 'SET_ACCOUNT_DETAILS',
            data: (0, _merge.default)(data, {
              loggedIn: true,
              oobValid: true
            })
          });
          reduxStore.dispatch({
            type: 'LOGIN_STATE',
            loggedIn: true
          }); //        trackJs.configure({userId:data.userId+""})

          reduxStore.dispatch({
            type: 'SET_USERNAME',
            username: data.username
          });
          reduxStore.dispatch({
            type: 'OOB_STATE',
            oobValid: true
          });
          callback();
        }
      }).catch(function (data) {
        console.log("init app " + data);

        if (!(0, _isNumber.default)(data) && (0, _has.default)(data, 'status.code')) {
          let mustUpdatePassword = (0, _has.default)(data, 'status.code') ? (0, _eq.default)(data.status.code, 3) : false; // User is not logged in -OR- user needs to enter OOB and Things begin here.

          reduxStore.dispatch({
            type: 'LOGIN_STATE',
            loggedIn: (0, _eq.default)(data.status.code, 99)
          });
          reduxStore.dispatch({
            type: 'OOB_STATE',
            oobValid: !(0, _eq.default)(data.status.code, 99)
          });

          if (mustUpdatePassword) {
            reduxStore.dispatch({
              type: 'LOGIN_STATE',
              loggedIn: true
            });
            reduxStore.dispatch({
              type: 'SET_MUST_UPDATE_PASSWORD',
              mustUpdatePassword: true
            });
          }

          reduxStore.dispatch({
            type: 'SET_ACCOUNT_DETAILS',
            data: (0, _merge.default)({
              loggedIn: (0, _eq.default)(data.status.code, 99) ? true : mustUpdatePassword ? true : false,
              oobValid: !(0, _eq.default)(data.status.code, 99)
            }, data)
          });
        } else {}

        callback('Failed getAccountDetails');
      });
    };
  }
});