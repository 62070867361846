define("web-client/components/edit-team-dialog/connection-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gxiDB04R",
    "block": "{\"symbols\":[\"itemAction\"],\"statements\":[[7,\"ion-avatar\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[23,\"imageSource\"]],[9],[10],[0,\"\\n\"],[10],[7,\"ion-label\"],[9],[1,[25,[\"item\",\"nickname\"]],false],[10],[0,\"\\n\\n\\n\"],[7,\"ion-buttons\"],[11,\"class\",\"manage-label-buttons\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"itemActions\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/edit-team-dialog/connection-item/template.hbs"
    }
  });

  _exports.default = _default;
});