define("web-client/api/get-password-stats", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPasswordStats = void 0;

  /**
   * params
   *
   *
   */
  const getPasswordStats = _ipcClient.ipc.makeRequestFunction('getPasswordStats');

  _exports.getPasswordStats = getPasswordStats;
});