define("web-client/components/application-left-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-menu',
    classNames: [],
    disabled: "false",
    side: "start",
    menuId: "first",
    contentId: "main-content",
    reduxStore: Ember.inject.service(),
    attributeBindings: ["disabled", "side", "menuId:menu-id", "contentId:content-id"],
    gestures: Ember.inject.service(),
    actions: {},

    willDestroyElement() {
      this.element.removeEventListener('ionWillOpen', this.willOpenEvent);
      this.element.removeEventListener('ionDidClose', this.didCloseEvent);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.willOpenEvent = () => {
        this.reduxStore.state.set('sidebarOpen', true);
      };

      this.didCloseEvent = () => {
        this.reduxStore.state.set('sidebarOpen', false);
      };

      this.element.addEventListener('ionWillOpen', this.willOpenEvent);
      this.element.addEventListener('ionDidClose', this.didCloseEvent);
    }
  });

  _exports.default = _default;
});