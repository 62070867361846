define("web-client/components/confirm-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/components/confirm-dialog/dialog-types/dialog-types", "lodash-es/merge", "lodash-es/find", "web-client/components/confirm-dialog/template"], function (_exports, _dialogBase, _dialogTypes, _merge, _find, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    layout: _template.default,
    size: 'size-3',
    reduxStore: Ember.inject.service(),
    escAction: "cancel",
    classNames: ['confirm-dialog', 'centered-dialog-text'],
    defaultAction: Ember.computed('hasChanged', function () {
      return "ok";
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);

      var mergedOptions = (0, _merge.default)((0, _find.default)(_dialogTypes.default, {
        type: this.type
      }));
      this.set('dialogText', Ember.Object.create(mergedOptions));
      this._closePromise = Ember.RSVP.defer();
      let onConfirm = this.onConfirm;

      this._closePromise.promise.then(() => {
        this.send('remove');
        onConfirm.resolve();
      }).catch(data => {
        this.send('remove');
        onConfirm.reject(data);
      });
    },

    actions: {
      ok() {
        this._closePromise.resolve('confirmed');
      },

      cancelDialog() {
        this._closePromise.reject("cancelled");
      }

    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});