define("web-client/icons/nullbase-checkbox/config", [], function () {
  "use strict";

  /* eslint-env node */
  //const COLORS = require('./lib/ui-core/addon/utils/node-colors');
  module.exports = {
    icons: [{
      name: 'checkbox-marked',
      colors: []
    }, {
      name: 'checkbox-blank-outline',
      colors: []
    }, {
      name: 'minus-box',
      colors: []
    }]
  };
});