define("web-client/connections/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    globalActions: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);
    },

    activate() {
      this._super.apply(this, arguments);

      var isLicensed = this.get('reduxStore.state.licenseSummary.licensed');

      if (isLicensed) {
        //this.globalActions.updateSubscriptionData();
        //      this.reduxStore.state.set('contentClass','fixed-canvas');
        //      this.reduxStore.state.set('contentSlot','fixed');
        this.reduxStore.state.set('contentClass', 'scroll-content');
        this.reduxStore.state.set('contentSlot', '');
        this.reduxStore.dispatch({
          type: "SET_CURRENT_HEADER_BUTTON",
          selectedHeaderButton: "connections"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Connections"
        });
        Ember.run.scheduleOnce('afterRender', () => {
          window.dispatchEvent(new Event('resize'));
        });
      } else {
        this.reduxStore.state.set('contentClass', 'unlicensed-dialog');
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: ""
        });
      }
    },

    setupController()
    /*controller*/
    {//  this.send('loadSessionHistory');
    },

    actions: {},

    renderTemplate() {
      this.render('connections', {
        outlet: 'content',
        into: 'application'
      });
      this.render('connections/left-sidebar', {
        outlet: 'left-sidebar',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});