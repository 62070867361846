define("web-client/api/get-score", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getScore = void 0;

  /**
   * params
   *password,
   * extra
   *
   */
  const getScore = _ipcClient.ipc.makeRequestFunction('getScore');

  _exports.getScore = getScore;
});