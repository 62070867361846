define("web-client/api/reset-and-remove-google-auth-setup", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetAndRemoveGoogleAuthSetup = void 0;

  /**
   * params
   *params
   *
   */
  const resetAndRemoveGoogleAuthSetup = _ipcClient.ipc.makeRequestFunction('resetAndRemoveGoogleAuthSetup');

  _exports.resetAndRemoveGoogleAuthSetup = resetAndRemoveGoogleAuthSetup;
});