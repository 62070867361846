define("web-client/components/registration-features-business/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PUcG4X0/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"mainwrap\"],[9],[0,\"\\n\\t\"],[7,\"h3\"],[9],[0,\"Included Features\"],[10],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"All Team Features + Additional Features Below\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Unlimited Users\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"Unlimited Teams\"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"SSO Intergration\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/registration-features-business/template.hbs"
    }
  });

  _exports.default = _default;
});