define("web-client/initializers/theme-service-initializer", ["exports", "ui-core/initializers/theme-service-initializer"], function (_exports, _themeServiceInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _themeServiceInitializer.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _themeServiceInitializer.initialize;
    }
  });
});