define("web-client/components/update-user-password-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/merge", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/api"], function (_exports, _dialogBase, _merge, _emberRedux, _emberConcurrency, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    })],
    newPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 500,
      message: "Password must be between 8 and 500 characters"
    })],
    newPasswordVerify: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your password"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: "You must retype your password exactly as before"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-3',
    password: '',
    newPassword: '',
    newPasswordVerify: '',
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service(),
    showErrors: false,
    showPasswordErrors: Ember.computed('showErrors', 'password', function () {
      return this.showErrors || this.password.length;
    }),
    showNewPasswordErrors: Ember.computed('showErrors', 'newPassword', function () {
      return this.showErrors || this.newPassword.length;
    }),
    showNewPasswordVerifyErrors: Ember.computed('showErrors', 'newPasswordVerify', function () {
      return this.showErrors || this.newPasswordVerify.length;
    }),
    passwordStats: Ember.computed('newPassword', function () {
      if (!this.liveStatsObj) {
        this.liveStatsObj = Ember.Object.extend({
          password: Ember.Object.extend({
            score: 0,
            crack_time_display: "instant",
            scoreCSS: Ember.computed('score', function () {
              return 'score-' + (this.score ? this.score : 0);
            })
          }).create()
        }).create();
      }

      let self = this;
      (0, _api.getScore)(self.get('newPassword'), [self.get('reduxStore.state.emailAddress'), self.get('reduxStore.state.username')]).then(function (data) {
        if (data !== 'unavailable') {
          self.liveStatsObj.password.set('score', data.score);
          self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
        } else {
          self.liveStatsObj.password.set('score', 0);
          self.liveStatsObj.password.set('crack_time_display', "instant");
        }
      });
      return this.liveStatsObj;
    }),
    updatePassword: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          return (0, _api.updateUserPassword)({
            "new": this.newPassword,
            old: this.password
          });
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        if (result !== 'validation-failed') {
          return (0, _api.getAccountDetails)().then(data => {
            this.reduxStore.dispatch({
              type: 'SET_ACCOUNT_DETAILS',
              data: (0, _merge.default)({
                loggedIn: true,
                oobValid: true
              }, data)
            });
            this.notifications.show(this.i18n.t("Dialog.PasswordChange.Success.Message"));
            (0, _api.killSession)('other');
            this.send('remove');
          });
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});