define("web-client/api/sha256-as-hex", ["exports", "js-sha256"], function (_exports, _jsSha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sha256asHex = sha256asHex;

  function sha256asHex(input) {
    return (0, _jsSha.sha256)(input);
  }
});