define("web-client/components/quick-copy-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W1IkdUVb",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[[29,\"concat\",[\"Quick Copy: \",[25,[\"password\",\"name\"]]],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n  \"],[7,\"ion-toolbar\"],[11,\"class\",\"post-header\"],[9],[0,\"\\n    Click on a field to copy it to the clipboard.\\n  \"],[10],[0,\"\\n\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"    \"],[7,\"ion-grid\"],[9],[0,\"\\n      \"],[7,\"ion-row\"],[9],[0,\"\\n        \"],[7,\"ion-col\"],[9],[0,\"\\n          \"],[7,\"ion-list\"],[11,\"lines\",\"full\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"quickCopyFields\"]]],null,{\"statements\":[[0,\"            \"],[1,[29,\"quick-copy-dialog/quick-copy-item\",null,[[\"passwordId\",\"field\"],[[25,[\"password\",\"id\"]],[24,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"left-buttons\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[\"Common.Buttons.Close.Text\"],null],[29,\"t\",[\"Common.Buttons.Close.Text\"],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/quick-copy-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});