define("web-client/api/aes-decrypt", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aesDecrypt = void 0;

  const aesDecrypt = _ipcClient.ipc.makeRequestFunction('aesDecrypt');

  _exports.aesDecrypt = aesDecrypt;
});