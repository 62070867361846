define("web-client/components/site-icon/component", ["exports", "web-client/utils/icon-server-alias-hash"], function (_exports, _iconServerAliasHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'img',
    buttonClasses: '',
    classNames: ['site-img'],
    gestures: Ember.inject.service(),
    buttonIcon: '',
    buttonText: 'Default Text',
    attributeBindings: ['dataToggle:data-toggle', 'touchAction:touch-action', 'tabindex', 'title:title', 'imgSource:src'],
    tabindex: -1,
    imageServerDown: false,
    imgSource: Ember.computed('imageSource', 'imageServerDown', function () {
      if (this.imageServerDown) {
        return "https://app.passpack.com/assets/static-assets/images/default-favicon.png";
      } else {
        return (0, _iconServerAliasHash.default)(this.imageSource);
      }
    }),
    title: Ember.computed('isSelected', function () {
      if (this.isSelected) {
        return "Deselect password";
      } else {
        return "Select password";
      }
    }),
    imageSource: '',
    dataToggle: '',
    touchAction: "pan",
    didInsertElement: function didInsertElement() {
      var self = this;

      this._super();

      this.element.addEventListener('error', function () {
        Ember.run.next(function () {
          self.set('imageServerDown', true);
        });
      });
    }
  });

  _exports.default = _default;
});