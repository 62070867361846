define("web-client/components/settings-sidebar-menu/ion-treeview-company-group-item/component", ["exports", "lodash-es/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalActions: Ember.inject.service(),
    tagName: 'div',
    classNames: ['ion-treeview-teams-group-item'],
    layoutValue: "icon-start",
    button: false,
    attributeBindings: ['layoutValue:layout', 'button'],
    classNameBindings: ['expandedClass', 'searching:searching'],
    gestures: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    item: null,
    mayViewCompany: false,
    mayViewCompanyDetails: false,
    mayViewBilling: false,
    mayViewSubscription: false,
    mayViewLicenseManagement: false,
    filterText: "",
    loaded: false,

    init() {
      this._super.apply(this, arguments);

      setTimeout(() => {
        if (!this.isDestroyed) {
          this.set('loaded', true);
        }
      }, 500); // if the user is not allowed redirect them elsewhere.

      let companySettingRight = this.globalActions.doesPermissionExist('settings', 'company', 'ro');
      let sponsoredOnly = this.get('reduxStore.state.licenseSummary.sponsoredOnly');
      this.mayViewCompany = !sponsoredOnly && companySettingRight;
      this.mayViewCompanyDetails = this.globalActions.doesPermissionExist('settings', 'company.details', 'ro');
      this.mayViewBilling = this.globalActions.doesPermissionExist('settings', 'company.billing', 'ro');
      this.mayViewSubscription = this.globalActions.doesPermissionExist('settings', 'company.subscription', 'ro');
      this.mayViewLicenseManagement = this.globalActions.doesPermissionExist('settings', 'company.licenseManagement', 'ro');
    },

    isRelevantSection() {
      if ("Company" === this.get('reduxStore.state.settingsSection') || "CompanyDetails" === this.get('reduxStore.state.settingsSection') || "CompanyBilling" === this.get('reduxStore.state.settingsSection') || "CompanySettings" === this.get('reduxStore.state.settingsSection') || "CompanySubscription" === this.get('reduxStore.state.settingsSection') || "CompanyLicenseManagement" === this.get('reduxStore.state.settingsSection')) {
        return true;
      } else {
        return false;
      }
    },

    expanded: Ember.computed('reduxStore.state.settingsSection', function () {
      return true; //this.isRelevantSection();
    }),
    selected: Ember.computed('reduxStore.state.settingsSection', function () {
      return this.isRelevantSection();
    }),
    searching: Ember.computed('filterText', function () {
      return this.filterText ? true : false;
    }),
    expandedClass: Ember.computed('expanded', 'filterText', function () {
      return this.expanded || this.filterText ? 'open' : '';
    }),

    willDestroyElement() {
      this._super.apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      goToSettingsCompanyDetails: function goToSettingsCompanyDetails() {
        this.globalActions.goToSettingsCompanyDetails();
      },
      goToSettingsCompanyBilling: function goToSettingsCompanyBilling() {
        this.globalActions.goToSettingsCompanyBilling();
      },
      goToSettingsCompanySettings: function goToSettingsCompanySettings() {
        this.globalActions.goToSettingsCompanySettings();
      },
      goToSettingsCompanySubscription: function goToSettingsCompanySubscription() {
        this.globalActions.goToSettingsCompanySubscription();
      },
      goToSettingsCompanyLicenseManagement: function goToSettingsCompanyLicenseManagement() {
        this.globalActions.goToSettingsCompanyLicenseManagement();
      }
    }
  });

  _exports.default = _default;
});