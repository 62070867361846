define("web-client/components/header-actions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    size: 'size-4',
    classNames: [''],
    globalActions: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      pushedButton: function pushedButton() {
        this.globalActions.showMainMenuDialog();
      }
    }
  });

  _exports.default = _default;
});