define("web-client/api/send-organization-command", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOrganizationCommand = void 0;

  /**
   * params
   * label
   * color
   */
  const sendOrganizationCommand = _ipcClient.ipc.makeRequestFunction('sendOrganizationCommand');

  _exports.sendOrganizationCommand = sendOrganizationCommand;
});