define("web-client/settings/local-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NiHlIhpf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-list\"],[11,\"class\",\"settings-list\"],[9],[0,\"\\n  \"],[7,\"ion-list-header\"],[9],[0,\"\\n    \"],[7,\"ion-label\"],[9],[0,\" \"],[1,[29,\"t\",[\"Settings.LocalData.Heading.Text\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"ion-item-group\"],[9],[0,\"\\n\\n    \"],[1,[29,\"ion-setting-item\",null,[[\"value\",\"settingAction\"],[[29,\"t\",[\"Settings.LocalData.Instructions.Text\"],null],[29,\"array\",[[29,\"component\",[\"nb-button\"],[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[\"Common.Buttons.Reset.Text\"],null],[29,\"t\",[\"Common.Buttons.Reset.Title\"],null],[29,\"service-action\",[\"global-actions\",\"deleteLocalDatabase\"],null]]]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/settings/local-data/template.hbs"
    }
  });

  _exports.default = _default;
});