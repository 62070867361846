define("web-client/components/passwords-sidebar-menu/ion-treeview-label-group-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pgq+a72s",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[4,\"if\",[[29,\"not\",[[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"button\",\"tap\",\"selected\",\"showExpander\",\"expanded\",\"extraActions\"],[\"Labels\",\"pricetags\",false,[29,\"service-task-action\",[\"global-actions\",\"toggleLabelsFilter\"],null],[25,[\"reduxStore\",\"state\",\"data\",\"labels\",\"selectedState\"]],true,[25,[\"expanded\"]],[29,\"array\",[[29,\"component\",[\"nb-button\"],[[\"size\",\"text\",\"iconOnly\",\"color\",\"on-tap\"],[\"small\",\"edit\",\"false\",\"dark\",[29,\"service-task-action\",[\"global-actions\",\"showManageLabelsDialog\"],null]]]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"ion-list\"],[11,\"class\",\"sub-items\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[25,[\"expanded\"]],[25,[\"loaded\"]],[25,[\"filterText\"]]],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"labels\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"      \"],[1,[29,\"ion-treeview-item\",null,[[\"text\",\"icon\",\"iconColor\",\"item\",\"tap\",\"selected\"],[[24,1,[\"label\"]],\"pricetag\",[24,1,[\"color\"]],[24,1,[]],[29,\"service-task-action\",[\"global-actions\",\"selectLabelForPasswordFilter\"],null],[24,1,[\"isSelected\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/passwords-sidebar-menu/ion-treeview-label-group-item/template.hbs"
    }
  });

  _exports.default = _default;
});