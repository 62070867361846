define("web-client/api/get-subscription-summary", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSubscriptionSummary = void 0;

  /**
   * params
  
   */
  const getSubscriptionSummary = _ipcClient.ipc.makeRequestFunction('getSubscriptionSummary');

  _exports.getSubscriptionSummary = getSubscriptionSummary;
});