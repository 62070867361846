define("web-client/ipc/ipc-client", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ipc", {
    enumerable: true,
    get: function get() {
      return _ipcClient.ipc;
    }
  });
});