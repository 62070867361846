define("web-client/application/route", ["exports", "web-client/actions/sign-out", "web-client/actions/show-username-dialog"], function (_exports, _signOut, _showUsernameDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    initApp: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    actions: {
      signOut: _signOut.default,
      showUsernameDialog: _showUsernameDialog.default
    },

    ensurePackingKey() {
      const onPackingKey = Ember.RSVP.defer();
      const wasLocked = this.get('reduxStore.state.isLocked');
      const deferred2 = Ember.RSVP.defer();
      onPackingKey.promise.then(() => {
        if (wasLocked) {
          this.data.updatePasswords().then(() => {
            deferred2.resolve();
          });
        } else {
          deferred2.resolve();
        }
      }).catch(function () {});

      if (this.get('reduxStore.state.isLocked')) {
        this.standardDialogs.showDialog('packing-key-dialog', Ember.Object.create({
          onPackingKey: onPackingKey
        }));
      } else {
        onPackingKey.resolve();
      }

      return deferred2.promise;
    },

    beforeModel() {
      return Ember.RSVP.all([this.initApp.setup()]);
    },

    redirect(model, transition) {
      if (transition.targetName === 'index') {
        this.transitionTo('passwords');
      }
    },

    renderTemplate() {
      this._super.apply(this, arguments);

      this.render('application/header-bar', {
        outlet: 'header-content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});