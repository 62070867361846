define("web-client/components/footer-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "txviyczM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"scrolly\",\"true\"],[11,\"scrollx\",\"true\"],[11,\"color\",\"primary\"],[11,\"class\",\"footer-tab-button-wrapper\"],[9],[0,\"\\n  \"],[1,[29,\"ion-tab-button\",null,[[\"tab\",\"icon\",\"text\",\"tap\"],[\"main\",\"stats\",\"Dashboard\",[29,\"service-action\",[\"global-actions\",\"goToDashboard\"],null]]]],false],[0,\"\\n\\n  \"],[1,[29,\"ion-tab-button\",null,[[\"tab\",\"icon\",\"text\",\"tap\"],[\"main\",\"list-box\",\"Passwords\",[29,\"service-action\",[\"global-actions\",\"goToPasswords\"],null]]]],false],[0,\"\\n  \"],[1,[29,\"ion-tab-button\",null,[[\"tab\",\"icon\",\"text\",\"tap\",\"badge\"],[\"main\",\"contact\",\"Connnections\",[29,\"service-action\",[\"global-actions\",\"goToConnections\"],null],[25,[\"reduxStore\",\"state\",\"data\",\"invitationCount\"]]]]],false],[0,\"\\n  \"],[1,[29,\"ion-tab-button\",null,[[\"tab\",\"icon\",\"text\",\"tap\"],[\"main\",\"settings\",\"Settings\",[29,\"service-action\",[\"global-actions\",\"goToSettings\"],null]]]],false],[0,\"\\n  \"],[1,[29,\"ion-tab-button\",null,[[\"tab\",\"icon\",\"text\",\"tap\"],[\"main\",\"paper\",\"Updates\",[29,\"service-action\",[\"global-actions\",\"goToSystemUpdates\"],null]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/footer-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});