define("web-client/api/get-default-payment", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDefaultPayment = void 0;

  /**
   * params
   *
   *
   */
  const getDefaultPayment = _ipcClient.ipc.makeRequestFunction('getDefaultPayment');

  _exports.getDefaultPayment = getDefaultPayment;
});