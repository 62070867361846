define("web-client/validators/uri", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Uri = _base.default.extend({
    validate(value, options
    /*, model, attribute*/
    ) {
      let uri = null;

      if (value) {
        try {
          uri = new URL(value);
        } catch (e) {//
        }
      }

      if (uri || value == "" && options.allowBlank == true) {
        return true;
      } else {
        return options.message;
      }
    }

  });

  var _default = Uri;
  _exports.default = _default;
});