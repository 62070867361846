define("web-client/actions/global-actions/login-to-site", ["exports", "lodash-es/has", "lodash-es/isNumber", "web-client/api"], function (_exports, _has, _isNumber, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loginToSite;

  function loginToSite(username, password) {
    this.reduxStore.dispatch({
      type: 'LOGIN_START',
      username: username,
      password: password
    });
    (0, _api.login)({
      username: username,
      password: password
    }).then(() =>
    /*data*/
    {
      // let reinitializeEnvironment = getOwner(this).lookup('route-action:helpers').compute([ 'reinitializeEnvironment' ]);
      // return reinitializeEnvironment();
      this.initApp.login();
    }).catch(data => {
      if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
        this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'), 'alert');
      } else {
        if (data.status.code === 481 || data.status.code === 482) {
          this.reduxStore.dispatch({
            type: 'EMAIL_VERIFICATION_REQUIRED'
          });
        } else {
          this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)), 'alert');
        }
      }
    });
  }
});