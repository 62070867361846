define("web-client/components/bulk-manage-labels-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/includes", "web-client/api", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/flatten", "lodash-es/countBy", "lodash-es/map", "lodash-es/find", "lodash-es/cloneDeep", "neo-async", "ember-concurrency", "web-client/config/environment", "xxhashjs", "lodash-es/reduce", "lodash-es/difference"], function (_exports, _dialogBase, _includes, _api, _filter, _sortBy, _flatten, _countBy, _map, _find, _cloneDeep, _neoAsync, _emberConcurrency, _environment, _xxhashjs, _reduce, _difference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    selectedItems: null,
    loading: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    filterText: '',
    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      return Ember.A((0, _sortBy.default)((0, _filter.default)(this.items, item => {
        return item.label.toLowerCase().includes(this.filterText.toLowerCase());
      }), "name"));
    }),
    items: Ember.A([]),
    originalLabels: Ember.A([]),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),
    undoChanges: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.updateLabelsList(deferred);
      return deferred.promise;
    }).drop(),
    updateLabelsList: function updateLabelsList(deferred) {
      this.loading.show('Loading');
      let self = this;
      this.items.clear();
      this.originalLabels.clear();
      (0, _api.getLabelsForPasswords)(this.reduxStore.state.data.passwords.selected).then(data => {
        this.set('passwordLabels', data);
        let filtered = (0, _filter.default)(data, function (item) {
          return item.labels.length > 0;
        });
        let flattened = (0, _flatten.default)((0, _map.default)(filtered, function (item) {
          return item.labels;
        }));
        let counts = (0, _countBy.default)(flattened, function (item) {
          return item.id;
        });
        let items = (0, _map.default)((0, _cloneDeep.default)(this.reduxStore.state.data.labels.content), item => {
          let selected = counts[item.id] > 0;
          item.selected = counts[item.id] > 0 ? counts[item.id] === this.reduxStore.state.data.passwords.selected.length ? true : undefined : false;
          item.originalSelection = item.selected;
          item.labelCount = counts[item.id];
          return item;
        });
        this.items.pushObjects(items);
        this.originalLabels.pushObjects((0, _cloneDeep.default)(items));
        self.loading.dismiss();

        if (deferred) {
          deferred.resolve();
        }
      }).catch(e => {
        console.log(e);

        if (deferred) {
          deferred.resolve();
        }
      });
    },
    showEditLabelDialog: (0, _emberConcurrency.task)(function* (label) {
      let deferred = Ember.RSVP.defer();
      let args = Ember.Object.create({
        label: label,
        onComplete: deferred
      });
      this.standardDialogs.showDialog('label-dialog', args);
      deferred.promise.then(data => {
        if (data && data.id) {
          let found = (0, _find.default)(this.items, function (item) {
            return item.id === data.id;
          });
          Ember.set(found, 'label', data.label);
          Ember.set(found, 'color', data.color);
          found = (0, _find.default)(this.originalLabels, function (item) {
            return item.id === data.id;
          });
          Ember.set(found, 'label', data.label);
          Ember.set(found, 'color', data.color); //    this.originalLabels.removeObject(found);
        } else {
          if (data === 'deleted') {
            let found = (0, _find.default)(this.items, function (item) {
              return item.id === label.id;
            });
            this.items.removeObject(found);
            found = (0, _find.default)(this.originalLabels, function (item) {
              return item.id === label.id;
            });
            this.originalLabels.removeObject(found);
          }
        }
      });
      yield deferred.promise;
    }).drop(),
    deleteLabel: (0, _emberConcurrency.task)(function* (labelId) {
      let deferred = Ember.RSVP.defer();
      let self = this;
      this.standardDialogs.showConfirmDialog('confirm-delete-label').then(() => {
        this.loading.show('Deleting label');
        return (0, _api.deleteLabel)(labelId);
      }).then(() => {
        this.reduxStore.state.data.labels.selected.removeObject(labelId);
        return (0, _api.getLabels)();
      }).then(data => {
        self.reduxStore.dispatch({
          type: 'UPDATE_LABELS',
          labels: data
        });
        let found = (0, _find.default)(this.items, function (item) {
          return item.id === labelId;
        });
        this.items.removeObject(found);
        found = (0, _find.default)(this.originalLabels, function (item) {
          return item.id === labelId;
        });
        this.originalLabels.removeObject(found);
        self.loading.dismiss();
        deferred.resolve();
      }).catch(data => {
        this.loading.dismiss();
        deferred.resolve();

        if (data !== 'cancelled') {
          this.actionUIErrorHandler(data);
        }
      });
      yield deferred.promise;
    }).drop(),
    deleteSelectedLabels: (0, _emberConcurrency.task)(function* () {
      const deferred = Ember.RSVP.defer();
      let self = this;
      this.standardDialogs.showConfirmDialog('confirm-delete-label').then(() => {
        this.loading.show('Deleting label');
        return (0, _api.deleteLabels)(this.selectedItems);
      }).then(() => {
        this.reduxStore.state.data.labels.selected.removeObjects(this.selectedItems);
        return (0, _api.getLabels)();
      }).then(data => {
        self.reduxStore.dispatch({
          type: 'UPDATE_LABELS',
          labels: data
        });
        self.loading.dismiss();
        deferred.resolve();
      }).catch(data => {
        this.loading.dismiss();

        if (data !== 'cancelled') {//  this.actionUIErrorHandler(data);
        }

        deferred.reject();
      });
      yield deferred.promise;
    }),
    originalLabelsHash: Ember.computed('originalLabels', 'originalLabels.{[],@each}', function () {
      if (this.originalLabels && this.originalLabels.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.originalLabels, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id.toString() + (item.selected === undefined ? 'undefined' : item.selected.toString()) + acc, 123).toString(16);
          return acc;
        }, 0), 0).toString(16);
      } else {
        return "0";
      }
    }),
    labelsHash: Ember.computed('items', 'items.{[],@each}', 'items.@each.selected', function () {
      if (this.items && this.items.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.items, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id.toString() + (item.selected === undefined ? 'undefined' : item.selected.toString()) + acc, 123).toString(16);
          return acc;
        }, 0), 0).toString(16);
      } else {
        return "0";
      }
    }),
    hasChanged: Ember.computed('originalLabelsHash', 'labelsHash', 'items', 'items.[]', 'items.@each.selected', function () {
      return this.originalLabelsHash !== this.labelsHash;
    }),
    saveChanges: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.loading.show('Saving Changes');
      let self = this;
      let selectedLabels = (0, _filter.default)(this.items, function (item) {
        return item.selected;
      });
      let unchangedLabels = (0, _filter.default)(this.items, function (item) {
        return item.selected === undefined;
      });
      let changeRecords = {
        addToThese: [],
        removeFromThese: []
      };

      _neoAsync.default.each(this.passwordLabels, function (data, callback) {
        let labels = (0, _difference.default)((0, _map.default)(data.labels, 'id'), (0, _map.default)(unchangedLabels, 'id'));
        let addToThese = (0, _difference.default)((0, _map.default)(selectedLabels, 'id'), labels);
        let removeFromThese = (0, _difference.default)(labels, (0, _map.default)(selectedLabels, 'id'));

        if (addToThese.length) {
          changeRecords.addToThese.push({
            id: data.passwordId,
            tags: addToThese
          });
        }

        if (removeFromThese.length) {
          changeRecords.removeFromThese.push({
            id: data.passwordId,
            tags: removeFromThese
          });
        }

        callback();
      }, function (err, data) {
        (0, _api.bulkUpdateLabelsForPasswords2)(changeRecords).then(data => {
          self.loading.dismiss();
          deferred.resolve(data);
          self.send('remove');
          self.complete.resolve();
        }).catch(data => {
          deferred.resolve();
        });
      });

      yield deferred.promise;
      /*let labelChanges = {
        removeFromThese: difference(map(this.get('originalLabels'), 'id'), map(this.get('labels'), 'id')),
        addToThese: difference(map(this.get('labels'), 'id'), map(this.get('originalLabels'), 'id'))
      };*/
    }).drop(),

    init() {
      this._super.apply(this, arguments);

      this.set('selectedItems', Ember.A([]));
      this.updateLabelsList();
    },

    addNewLabel: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let args = {
        newLabel: "",
        onComplete: deferred
      };
      this.standardDialogs.showDialog('label-dialog', args);
      deferred.promise.then(data => {
        if (data && data.id) {
          data.selected = false;
          data.originalSelection = data.selected;
          data.labelCount = 0;
          this.items.pushObject(data);
          this.originalLabels.pushObject((0, _cloneDeep.default)(data));
        }
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      setItemSelectedState(itemId, newState) {
        if (newState) {
          if (!(0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.pushObject(itemId);
          }
        } else {
          if ((0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.removeObject(itemId);
          }
        }
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.resolve();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});