define("web-client/mixins/auth-base-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    classNames: ['auth-dialog'],
    showErrors: false,
    attributeBindings: ['tabindex'],
    tabindex: 0,
    defaultAction: '',
    keyPress: function keyPress(e) {
      if (this.defaultAction) {
        var key = e.which || e.keyCode;

        if (key === 13 && e.target.nodeName !== 'TEXTAREA') {
          e.stopPropagation();
          e.preventDefault();
          this.send(this.defaultAction);
        }
      }
    },

    focusFirst() {
      const input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      this.focusFirst();
    }

  });

  _exports.default = _default;
});