define("web-client/services/init-app-utils/get-account-limits", ["exports", "web-client/api", "lodash-es/isNumber", "lodash-es/has", "lodash-es/eq", "lodash-es/merge"], function (_exports, _api, _isNumber, _has, _eq, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAccountLimits;

  function getAccountLimits() {
    let reduxStore = this.reduxStore;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: "Loading Account Limits"
      });
      (0, _api.getAccountLimits)().then(function (data) {
        reduxStore.dispatch({
          type: 'SET_ACCOUNT_LIMITS',
          limits: data.limits
        });
        let count = reduxStore.get('state.subscription.limits.usedPasswordEntryCount') + reduxStore.get('state.subscription.limits.usedConnectionsCount') + reduxStore.get('state.subscription.limits.usedGroupsCount');
        (0, _api.startupLog)({
          actionText: "Loading Account Limits",
          increaseProgressMaxBy: count
        });
        callback();
      }).catch(function (data) {
        let mustUpdatePassword = !_isNumber.default && (0, _has.default)(data, 'status.code') ? (0, _eq.default)(data.status.code, 3) : false; // User is not logged in -OR- user needs to enter OOB and Things begin here.

        reduxStore.dispatch({
          type: 'LOGIN_STATE',
          loggedIn: (0, _eq.default)(data, 424)
        });
        reduxStore.dispatch({
          type: 'OOB_STATE',
          oobValid: !(0, _eq.default)(data, 424)
        });

        if (mustUpdatePassword) {
          reduxStore.dispatch({
            type: 'LOGIN_STATE',
            loggedIn: true
          });
          reduxStore.dispatch({
            type: 'SET_MUST_UPDATE_PASSWORD',
            mustUpdatePassword: true
          });
        }

        reduxStore.dispatch({
          type: 'SET_ACCOUNT_DETAILS',
          data: (0, _merge.default)({
            mustUpdatePassword: mustUpdatePassword,
            loggedIn: (0, _eq.default)(data, 424) ? true : mustUpdatePassword ? true : false,
            oobValid: (0, _eq.default)(data, 424)
          }, data)
        });
        callback('Failed getAccountLimits');
      });
    };
  }
});