define("web-client/components/account-recovery-dialog/component", ["exports", "ember-cp-validations", "web-client/mixins/auth-base-dialog"], function (_exports, _emberCpValidations, _authBaseDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    emailAddress: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Email address can't be blank"
    }), (0, _emberCpValidations.validator)('email', {
      message: "Must be a valid email address"
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    classNames: ['size-3'],
    emailAddress: '',
    showErrors: false,
    success: false,
    defaultAction: 'getUserId',
    retrieveUserSuccess: false,
    actions: {
      goToResetPassword: function goToResetPassword() {
        this.goToResetPW();
      },
      getUserId: function getUserId() {
        var self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.get('retrieveUserID')(self.get('emailAddress'));
          }
        }).catch(function () {}).finally(function () {
          self.set('showErrors', true);
        });
      },
      goToLogin: function goToLogin() {
        this.reduxStore.dispatch({
          type: 'RETRIEVE_USER_SUCCESS_RESET'
        });
        this.goToLogin();
      }
    }
  });

  _exports.default = _default;
});