define("web-client/helpers/service-task-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  let ClosureActionModule;

  if ('ember-htmlbars/keywords/closure-action' in Ember.__loader.registry) {
    ClosureActionModule = Ember.__loader.require('ember-htmlbars/keywords/closure-action');
  } else if ('ember-routing-htmlbars/keywords/closure-action' in Ember.__loader.registry) {
    ClosureActionModule = Ember.__loader.require('ember-routing-htmlbars/keywords/closure-action');
  } else {
    ClosureActionModule = {};
  }

  const ACTION = ClosureActionModule.ACTION;
  /*
  function taskHelperClosure(helperName, taskMethod, _args, hash) {
    let task = _args[0];
    let outerArgs = _args.slice(1);
  
    return bind(null, function (...innerArgs) {
      if (!task || typeof task[taskMethod] !== 'function') {
        assert(`The first argument passed to the \`${helperName}\` helper should be a Task object (without quotes); you passed ${task}`, false);
        return;
      }
  
      if (hash && hash.value) {
        let event = innerArgs.pop();
        innerArgs.push(get(event, hash.value));
      }
  
      return task[taskMethod](...outerArgs, ...innerArgs);
    });
  }
  */

  var _default = Ember.Helper.extend({
    /*router: computed(function() {
      return getOwner(this).lookup('router:main');
    }).readOnly(),*/
    router: Ember.inject.service(),

    compute(_ref) {
      let _ref2 = _toArray(_ref),
          serviceName = _ref2[0],
          actionName = _ref2[1],
          params = _ref2.slice(2);

      let service = Ember.getOwner(this).lookup('service:' + serviceName);
      (true && !(service) && Ember.assert('[service-task-action-helper] Unable to lookup service', service));
      /*
          runInDebug(() => {
            let { handler } = getRouteWithAction(router, actionName);
            assert(`[ember-route-action-helper] Unable to find action ${actionName}`, handler);
          });
      */

      let serviceAction = function serviceAction() {
        //let { action, handler } = getRouteWithAction(router, actionName);
        (true && !(service[actionName]) && Ember.assert('[service-task-action-helper] unable to find action [' + actionName + '] on service [' + serviceName + ']', service[actionName]));

        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        let args = params.concat(invocationArgs);

        let wrappedFunction = function wrappedFunction() {
          //  console.log('calling wrapped function',service[ actionName ],...args3)
          Ember.run(function () {
            var _service$actionName;

            (_service$actionName = service[actionName]).perform.apply(_service$actionName, _toConsumableArray(args));
          });
        };

        return wrappedFunction();
      };

      serviceAction[ACTION] = true;
      return serviceAction;
    }

  });

  _exports.default = _default;
});