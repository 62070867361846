define("web-client/services/init-app-utils/get-system-messages", ["exports", "web-client/api", "lodash-es/has", "lodash-es/isNumber"], function (_exports, _api, _has, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSystemMessages;

  //import merge from "lodash-es/merge";
  //import eq from "lodash-es/eq";
  function getSystemMessages() {
    let reduxStore = this.reduxStore;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: 'Getting System Messages'
      });
      (0, _api.getSystemMessages)().then(function (data) {
        //        console.log("done system messages");
        if (!(0, _isNumber.default)(data) && (0, _has.default)(data, 'status.code')) {
          reduxStore.dispatch({
            type: 'SET_SYSTEM_MESSAGES',
            systemMessages: data.entries
          });
        }

        callback();
      }).catch(function (data) {
        callback('Failed getSystemMessages');
      });
    };
  }
});