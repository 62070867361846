define("web-client/components/business-upgrade-trial-dialog/component", ["exports", "ui-core/mixins/dialog-base", "debug", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/utils/color-options", "lodash-es/clone", "web-client/api", "lodash-es/find"], function (_exports, _dialogBase, _debug, _emberRedux, _emberConcurrency, _emberCpValidations, _colorOptions, _clone, _api, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const log = (0, _debug.default)("");
  const Validations = (0, _emberCpValidations.buildValidations)({//  additionalLicenses: validator('presence', {
    //    presence: true,
    //    message: "Must have at least 1 license to add"
    //  })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    classNames: ['business-upgrade-trial'],
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    currencyFormatter: {},
    computing: false,
    upgradeCredit: 0,
    upgradeRenewalAmount: 0,
    upgradeCostPerSeat: 0,
    upgradeTotalDueAfterTrial: 0,
    upgradeTrialEndDate: {},
    upgradeOffer: {},

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    showErrors: false,

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    },

    beginBusinessPlanTrial: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          this.loading.show('Upgrading to Business Plan');
          (0, _api.postBeginBusinessUpgrade)().then(result => {
            self.loading.dismiss();
            self.send('remove');
            deferred.resolve();
            this.globalActions.updateOrganizationLicenses();
            this.globalActions.getAccountLicenseSummary();
          }).catch(e => {
            self.loading.dismiss();
            this.globalActions.actionUIErrorHandler(e);
            deferred.reject(e);
          });
        } else {
          self.loading.dismiss();
          self.set('showErrors', true);
          deferred.resolve();
        }
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      }
    },

    afterShow() {
      this._super.apply(this, arguments);

      let input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    }

  });

  _exports.default = _default;
});