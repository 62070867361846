define("web-client/utils/oboe-promise", ["exports", "oboe"], function (_exports, _oboe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = oboePromise;

  class OboePromise {
    constructor(source) {
      this.node = this.node.bind(this);
      this.on = this.on.bind(this);
      this.path = this.path.bind(this);
      this.run = this.run.bind(this);
      this._cache = [];
      this._source = source;
      return {
        done: this._error('#done is unavailable in Promise; use #then'),
        fail: this._error('#fail is unavailable in Promise; use #catch'),
        node: this.node,
        on: this.on,
        path: this.path,
        run: this.run
      };
    }

    _chain(method, args) {
      this._cache.push(instance => instance[method].apply(instance, args));

      return this;
    }

    _done() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return this._chain('done', args);
    }

    _fail() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return this._chain('fail', args);
    }

    _error(message) {
      return function _stub() {
        throw new Error(message);
      };
    }

    node() {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      return this._chain('node', args);
    }

    on() {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      return this._chain('on', args);
    }

    path() {
      for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        args[_key5] = arguments[_key5];
      }

      return this._chain('path', args);
    }

    run() {
      return new Promise((resolve, reject) => {
        this._done(resolve)._fail(reject);

        const oboeStream = (0, _oboe.default)(this._source);

        for (let unit = 0; unit < this._cache.length; unit++) {
          this._cache[unit](oboeStream);
        }
      });
    }

  }

  function oboePromise(source) {
    return new OboePromise(source);
  }

  oboePromise.drop = _oboe.default.drop;
});