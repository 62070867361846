define("web-client/components/ion-avatar-button/component", ["exports", "web-client/utils/misc-utils", "web-client/config/environment"], function (_exports, _miscUtils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  const baseURL = _environment.default.apiHost;

  var _default = Ember.Component.extend({
    tagName: 'ion-button',
    classNames: ['icon-only', 'avatar-button'],
    reduxStore: Ember.inject.service(),
    userId: Ember.computed.alias('reduxStore.state.userId'),
    slot: "end",
    shape: 'round',
    attributeBindings: ['slot', 'shape'],
    profileImageURL: Ember.computed('userId', 'resetProfileUrl', function () {
      return baseURL + "/portal/v1/secure/avatar/" + this.userId + '?t=' + _miscUtils.uniqID.get("t");
    }),
    actions: {},

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      const popoverController = document.querySelector('ion-popover-controller');
      let anElment = document.getElementsByClassName('account-popover')[0];

      let dismissFunc = function dismissFunc() {
        document.body.appendChild(anElment);
        popoverController.removeEventListener('ionPopoverWillDismiss', dismissFunc);
      };

      function showPopover(_x) {
        return _showPopover.apply(this, arguments);
      } //  this.element.setAttribute('layout',this.get('layout'));


      function _showPopover() {
        _showPopover = _asyncToGenerator(function* (ev) {
          yield popoverController.componentOnReady();
          popoverController.addEventListener('ionPopoverWillDismiss', dismissFunc);
          anElment.style.display = "block";
          const popover = yield popoverController.create({
            component: anElment,
            event: ev,
            translucent: true
          });
          return yield popover.present();
        });
        return _showPopover.apply(this, arguments);
      }

      this.element.addEventListener('click', ev => {
        showPopover(ev);
      });
    }
  });

  _exports.default = _default;
});