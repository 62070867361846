define("web-client/api/reset-backup-security-tokens", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetBackupSecurityTokens = void 0;

  /**
   * params
   *params
   *
   */
  const resetBackupSecurityTokens = _ipcClient.ipc.makeRequestFunction('resetBackupSecurityTokens');

  _exports.resetBackupSecurityTokens = resetBackupSecurityTokens;
});