define("web-client/components/dashboard-sidebar-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reduxStore: Ember.inject.service(),
    tagName: 'ion-list',
    globalActions: Ember.inject.service(),
    classNames: ['fade-me-in'],

    willInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', () => {
        if (this.element) {
          this.element.classList.add('fade-in');
        }
      });
    }

  });

  _exports.default = _default;
});