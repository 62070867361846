define("web-client/api/get-extra-fields-for-password", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getExtraFieldsForPassword = void 0;

  /**
   * params
   *
   *
   */
  const getExtraFieldsForPassword = _ipcClient.ipc.makeRequestFunction('getExtraFieldsForPassword');

  _exports.getExtraFieldsForPassword = getExtraFieldsForPassword;
});