define("web-client/components/password-search-selection-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aKW+Ak8r",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[\"Password Selection Actions\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"menu-replacement\"],[9],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\"],[\"All\",\"Select all visible passwords\",[29,\"build-svg-icon\",[\"check\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"check\",\"white\"],null],[29,\"action\",[[24,0,[]],\"selectAllVisibleSearchPasswords\",[25,[\"owner\",\"grid\"]]],null]]]],false],[0,\"\\n\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"icon\",\"hoverIcon\",\"on-tap\"],[[29,\"t\",[\"Passwords.Toolbar.SelectionCommands.SelectNone.Text\"],null],[29,\"build-svg-icon\",[\"checkbox-blank-outline\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"checkbox-blank-outline\",\"white\"],null],[29,\"action\",[[24,0,[]],\"deselectAllPasswords\",[25,[\"owner\",\"grid\"]]],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"icon\",\"hoverIcon\",\"on-tap\"],[[29,\"t\",[\"Passwords.Toolbar.SelectionCommands.SelectInverse.Text\"],null],[29,\"build-svg-icon\",[\"shuffle-variant\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"shuffle-variant\",\"white\"],null],[29,\"action\",[[24,0,[]],\"selectInverseSearchPasswords\",[25,[\"owner\",\"grid\"]]],null]]]],false],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-search-selection-menu/template.hbs"
    }
  });

  _exports.default = _default;
});