define("web-client/components/initializing-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N5J8O5tt",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"loading-panel-inner\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"loader\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ball-clip-rotate-multiple\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"label\"],[9],[1,[23,\"label\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/initializing-loader/template.hbs"
    }
  });

  _exports.default = _default;
});