define("web-client/settings/company/billing/controller", ["exports", "web-client/api", "lodash-es/each", "lodash-es/filter"], function (_exports, _api, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //var splitRegex = /\r\n|\r|\n/g;
  // Create our number formatter.

  /*var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2
  });*/
  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    phoneNumber: "",
    newPhoneNumber: "",
    mobileNumber: "",
    newMobileNumber: "",
    emailAddress: "",
    isUpdating: false,
    showDefaultCardUpdate: false,
    currencyFormatter: {},
    mayViewBilling: false,
    mayChangeBilling: false,

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      this.mayViewBilling = this.globalActions.doesPermissionExist('settings', 'company.billing', 'ro');
      this.mayChangeBilling = this.globalActions.doesPermissionExist('settings', 'company.billing', 'rw');
    },

    validLicense: Ember.computed('reduxStore.state.{subscriptionSummary}', function () {
      let expired = this.get('reduxStore.state.subscriptionSummary.expired'); //   console.log(expired);

      return !expired;
    }),
    nextInvoiceAmountOriginal: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalAmount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      return originalAmount;
    }),
    nextInvoiceAmount: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalAmount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      let discount = this.get('reduxStore.state.subscriptionSummary.discount');

      if (discount && discount > 0) {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.discountedAmount'));
      } else {
        return originalAmount;
      }
    }),
    discount: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let discount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.discount'));
      return discount;
    }),
    discountName: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let discount = this.get('reduxStore.state.subscriptionSummary.discountName');
      return discount;
    }),

    updatePlanData() {
      return (0, _api.getAccountLimits)().then(data => {
        this.reduxStore.dispatch({
          type: 'SET_ACCOUNT_LIMITS',
          limits: data.limits
        });
        return new Promise(function (resolve) {
          // on success
          (0, _api.getDefaultPayment)().then(data => {
            resolve(data);
          }).catch(data => {
            resolve(data);
          });
        });
      }).then(data => {
        // {"status":{"code":0,"codes":[]},"last4":"4242","expirationMonth":12,"expirationYear":2020,"brand":"Visa"}
        if (data.status.code === 0) {
          this.set('defaultPayment', data);
        } else {
          this.set('defaultPayment', null);
        } // Subscription Summary Data


        this.globalActions.updateSubscriptionData();
        return (0, _api.getBillingHistory)();
      }).then(billingHistory => {
        this.set('billingHistory', billingHistory.rows); //return getPlanCatalog();
      }).then(data => {
        //        this.notifyPropertyChange('plans');
        //        this.notifyPropertyChange('addons');
        this.set('isUpdating', false);
      }).catch(() => {
        this.set('isUpdating', false);
      });
    },

    availableLicenses: Ember.computed('plans', function () {
      const included = this.get('reduxStore.state.subscriptionSummary.baseLicensesIncluded');
      const extra = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
      const used = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
      return included + extra - used;
    }),
    actions: {
      printInvoice(billingItem) {
        this.set('reduxStore.state.printingReceipt', true);
        this.set('reduxStore.state.printReceiptData', billingItem);
        Ember.run.next(() => {
          window.print();
        });
      },

      resetSubscriptionChanges: function resetSubscriptionChanges() {
        this.set('isUpdating', true);
        (0, _api.resetUpcomingBilling)().then(() => {
          this.updatePlanData();
        }).catch(() => {
          this.updatePlanData();
        });
      },
      paymentSourceUpdate: function paymentSourceUpdate(token) {
        this.set('isUpdating', true);
        const self = this;
        (0, _api.updatePaymentSource)(token).then(() => {
          (0, _api.getDefaultPayment)().then(data => {
            // {"status":{"code":0,"codes":[]},"last4":"4242","expirationMonth":12,"expirationYear":2020,"brand":"Visa"}
            if (data.status.code === 0) {
              self.set('defaultPayment', data);
            } else {
              self.set('defaultPayment', null);
            }
          });
          this.updatePlanData();
        });
      },

      showPaymentSourceUpdate() {
        this.set('showDefaultCardUpdate', true);
      },

      onCloseDefaultCardUpdate() {
        this.set('showDefaultCardUpdate', false);
      }

    }
  });

  _exports.default = _default;
});