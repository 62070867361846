define("web-client/connections/left-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VGXRheRw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"reduxStore\",\"state\",\"licenseSummary\",\"licensed\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"connection-sidebar-menu\",null,[[\"setCurrentTab\",\"currentTab\"],[[29,\"service-action\",[\"global-actions\",\"setCurrentPasswordsSidebarMenuTab\"],null],[25,[\"reduxStore\",\"state\",\"passwordsSidebarMenuTab\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/connections/left-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});