define("web-client/helpers/build-svg-icon", ["exports", "ui-core/helpers/build-svg-icon"], function (_exports, _buildSvgIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _buildSvgIcon.default;
    }
  });
  Object.defineProperty(_exports, "buildSVGIcon", {
    enumerable: true,
    get: function get() {
      return _buildSvgIcon.buildSVGIcon;
    }
  });
});