define("web-client/actions/remove-packing-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removePackingKey;

  function removePackingKey() {
    storage.remove('sessionToken');
    this.reduxStore.dispatch({
      type: 'SET_PASSPHRASE',
      passphrase: ''
    }); //  this.get('notifications').showSnackbar('Packing Key unloaded','alert');

    this.router.transitionTo('locked');
  }
});