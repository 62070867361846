define("web-client/components/invite-new-organization-member-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/trim", "lodash-es/isEmpty", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "lodash-es/has", "lodash-es/isNumber", "web-client/api", "lodash-es/range"], function (_exports, _dialogBase, _trim, _isEmpty, _emberRedux, _emberConcurrency, _emberCpValidations, _has, _isNumber, _api, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    additionalLicenses: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Must have at least 1 license to add"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    classNames: ['add-licenses'],
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    computing: false,
    additionalLicenses: 0,
    planAdditionalMin: 0,
    renewalAmountToday: 0,
    renewalAmountNextCycle: 0,
    chargeButtonAmount: "",
    showInviteErrors: false,
    inviteErrors: {},
    inputRows: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let allowed = parseInt(this.get('reduxStore.state.subscriptionSummary.licensesAvailable'));
      let max = 10;
      let size = allowed > max ? max : allowed;
      if (allowed > 0) return (0, _range.default)(1, size + 1, 1);else return (0, _range.default)(0);
    }),
    inviteTeamMembersDisabled: Ember.computed('additionalLicenses', 'computing', function () {
      //    let disabled = this.get('computing');
      //    let validAmount = false;
      //    let total= parseInt(this.additionalLicenses);
      //    if (isNaN(total)) {
      //      total=0;
      //    }
      //    validAmount = 0===total?false:true;
      //
      //    // The return should indicate true for disabled, false for enabled.
      //    return disabled || !validAmount;
      return false;
    }),
    //  showInviteErrors: computed('',function() {
    //  }),
    errorMessage: Ember.computed('', function () {
      return "";
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    validateEmail: function validateEmail(value) {
      var trimmedValue = (0, _trim.default)(value);
      /* eslint-disable no-useless-escape */

      if (!(0, _isEmpty.default)(trimmedValue) && !/^[a-zA-Z0-9_+&-]+(?:\.[a-zA-Z0-9_+&-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,8}$/.test(trimmedValue) || (0, _isEmpty.default)(trimmedValue)) {
        return false;
      } else {
        return true;
      }
    },

    init() {
      this._super.apply(this, arguments);
    },

    isBusinessLicense: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Business Plan') {
        return true;
      } else {
        return false;
      }
    }),
    inviteTeamMembers: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      this.set('inviteErrors', []);
      this.set('showInviteErrors', false);
      let userData = [];
      let businessLicenseSSOPreferenceElement = document.getElementById("businessLicenseSSOPreference");
      let businessLicenseSSOPreference = 'username';

      if (null != businessLicenseSSOPreferenceElement) {
        // Default to username while in validation
        businessLicenseSSOPreference = businessLicenseSSOPreferenceElement.value;
      }

      for (const index of this.inputRows.values()) {
        let email = document.getElementById("email" + index).value; // if any field has a value then validate email

        if (email) {
          let validEmail = this.validateEmail(email);

          if (!validEmail) {
            this.set('showInviteErrors', true);

            if (email) {
              this.inviteErrors.push("Email address \"" + email + "\" is not valid.");
            } else {
              this.inviteErrors.push("An email is required.");
            }
          } else {
            var user = {
              firstName: '',
              lastName: '',
              sso: businessLicenseSSOPreference,
              email: email
            };
            userData.push(user);
          } //        console.log(index + ":" + fname+":" + lname+":" + email+":" + validEmail);

        }
      }

      if (this.get('showInviteErrors')) {
        self.loading.dismiss();
        deferred.resolve();
      } else {
        this.loading.show('Inviting team members');
        var userDataToRetry = [];
        (0, _api.postInviteUsers)(userData).then(data => {
          //            this.globalActions.updateSubscriptionData();
          //            this.globalActions.updateOrganizationLicenses();
          self.loading.dismiss();
          self.send('remove');
          deferred.resolve();
        }).catch(data => {
          if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
            this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'), 'alert');
          } else {
            if (data.status.code === 100) {
              this.set('showInviteErrors', true);
              this.inviteErrors.push("While trying to add your users the server reported a general error.");
            } else if (data.status.code === 1807) {
              this.set('showInviteErrors', true);

              for (const code of data.status.codes) {
                // save off the input data
                userDataToRetry.push(this.retrieveUserInputData(code.id));

                if (code.code === 1806) {
                  this.inviteErrors.push("User not added: " + code.id + ". Email already in company.");
                } else if (code.code === 1800) {
                  this.inviteErrors.push("User not added: " + code.id + ". Invitation has already been sent.");
                } else if (code.code === 1816) {
                  this.inviteErrors.push("User not added: " + code.id + ". Email contains an invalid domain.");
                } else if (code.code === 1817) {
                  this.inviteErrors.push("User not added: " + code.id + ". Email domain is not allowed to join external companies.");
                } else {
                  this.inviteErrors.push("User not added: " + code.id + ". Error: " + code.code);
                }
              }
            }
          }

          self.loading.dismiss();
          this.globalActions.actionUIErrorHandler(data);
          deferred.reject(data);
        });
        deferred.resolve(); // Remove the completed invites from the list

        this.removeCompletedInputs(userDataToRetry);
      }

      yield deferred.promise;
    }).drop(),
    retrieveUserInputData: function retrieveUserInputData(inputEmail) {
      for (const index of this.inputRows.values()) {
        let email = document.getElementById("email" + index).value;

        if (inputEmail === email) {
          return {
            email: email
          };
        }
      }

      return null;
    },
    removeCompletedInputs: function removeCompletedInputs(userDataToRetry) {
      for (const index of this.inputRows.values()) {
        document.getElementById("email" + index).value = '';
      }

      for (let index = 0; index < userDataToRetry.length; i++) {
        let data = userDataToRetry[index];
        document.getElementById("email" + index).value = data.email;
      }
    },
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      }
    },

    afterShow() {
      this._super.apply(this, arguments);

      let input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    }

  });

  _exports.default = _default;
});