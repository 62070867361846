define("web-client/services/init-app-utils/setup-workers", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAccountDetails;

  function getAccountDetails() {
    let reduxStore = this.reduxStore;
    return function (callback) {
      (0, _ipcClient.setupWorkers)().then(() => {
        callback();
      });
    };
  }
});