define("web-client/components/change-packing-key-dialog/component", ["exports", "ui-core/mixins/dialog-base", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/api"], function (_exports, _dialogBase, _emberRedux, _emberConcurrency, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CreatepackingKeyValidations = (0, _emberCpValidations.buildValidations)({
    oldPackingKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must enter your current Packing Key"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'oldPackingKeyConfirm',
      message: "You must enter your current Packing Key"
    })],
    newPackingKey: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Packing Key can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    })],
    newPackingKeyVerify: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your Packing Key"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPackingKey',
      message: "You must retype your Packing Key exactly as before"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, CreatepackingKeyValidations, _emberRedux.default, {
    size: 'size-3',
    oldPackingKey: '',
    newPackingKey: '',
    newPackingKeyVerify: '',
    oldPackingKeyConfirm: Ember.computed.alias('reduxStore.state.passphrase'),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service(),
    publicKey: Ember.computed.alias('reduxStore.state.publicKey'),
    privateKey: Ember.computed.alias('reduxStore.state.decryptedPrivateKey'),
    showErrors: false,
    showOldPackingKeyErrors: Ember.computed('showErrors', 'oldPackingKey', function () {
      return this.showErrors || this.oldPackingKey.length;
    }),
    showNewPackingKeyErrors: Ember.computed('showErrors', 'newPackingKey', function () {
      return this.showErrors || this.newPackingKey.length;
    }),
    showNewPackingKeyVerifyErrors: Ember.computed('showErrors', 'newPackingKeyVerify', function () {
      return this.showErrors || this.newPackingKeyVerify.length;
    }),
    passwordStats: Ember.computed("newPackingKey", function () {
      if (!this.liveStatsObj) {
        this.liveStatsObj = Ember.Object.extend({
          password: Ember.Object.extend({
            score: 0,
            crack_time_display: "instant",
            scoreCSS: Ember.computed('score', function () {
              return 'score-' + (this.score ? this.score : 0);
            })
          }).create()
        }).create();
      }

      let self = this;
      (0, _api.getScore)(self.get('newPackingKey'), [self.get('reduxStore.state.emailAddress'), self.get('reduxStore.state.username')]).then(function (data) {
        if (data !== 'unavailable') {
          self.liveStatsObj.password.set('score', data.score);
          self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
        } else {
          self.liveStatsObj.password.set('score', 0);
          self.liveStatsObj.password.set('crack_time_display', "instant");
        }
      });
      return this.liveStatsObj;
    }),
    updatePackingKey: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let check = null;
      let self = this;
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          let passphrase = this.newPackingKey;
          let check = "c42963b150bf47ad";
          return (0, _api.aesEncrypt)(check, passphrase);
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        if (result !== 'validation-failed') {
          check = result;
          return (0, _api.aesEncrypt)(this.privateKey, this.newPackingKey);
        } else {
          return 'validation-failed';
        }
      }).then(encryptedPrivateKey => {
        if (encryptedPrivateKey !== 'validation-failed') {
          var tempParams = {
            pin: '708616',
            //    passphrase: passphrase,
            publicKey: this.publicKey,
            privateKey: encryptedPrivateKey,
            data: check
          };
          return (0, _api.changePackingKey)(tempParams);
        } else {
          return 'validation-failed';
        }
      }).then(validation => {
        if (validation !== 'validation-failed') {
          self.dispatch({
            type: 'SET_PASSPHRASE',
            passphrase: self.get('newPackingKey')
          });
          self.dispatch({
            type: 'SET_PASSPHRASE_CHECK',
            passphraseCheck: check
          });
          (0, _api.aesEncrypt)(self.get('newPackingKey'), self.get('reduxStore.state.statusToken')).then(function (data) {
            if (data) {
              storage.set('sessionToken', data);
            }

            deferred.resolve();
            self.send('remove');
          });
        } else {
          deferred.reject();
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});