define("web-client/components/file-import/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DPNFpqOI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n    \"],[7,\"div\"],[11,\"class\",\"inner inkMe \"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inner-wrap\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ink\"],[9],[10],[0,\"\\n         \"],[7,\"div\"],[11,\"class\",\"button-text\"],[9],[0,\"\\n           Select a file to import\\n\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n      \"],[7,\"form\"],[9],[0,\"\\n        \"],[7,\"input\"],[11,\"class\",\"inputfile\"],[11,\"id\",\"fileImport\"],[11,\"name\",\"fileImport[]\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/file-import/template.hbs"
    }
  });

  _exports.default = _default;
});