define("web-client/components/help-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZK9ItQTy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-menu-bar-menu\",null,[[\"tether\",\"options\"],[[25,[\"tether\"]],[25,[\"options\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"nb-menu-item\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\"],[\"Support\",\"Visit support\",[29,\"build-svg-icon\",[\"help\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"help\",\"white\"],null],[29,\"service-action\",[\"global-actions\",\"openSupportPage\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"role\",\"presentation\"],[11,\"class\",\"nb-menu-item-divider item\"],[9],[10],[0,\"\\n  \"],[1,[29,\"nb-menu-item\",null,[[\"text\",\"title\",\"icon\",\"hoverIcon\",\"on-tap\"],[\"About Passpack\",\"About Passpack\",[29,\"build-svg-icon\",[\"information-outline\",\"grey-800\"],null],[29,\"build-svg-icon\",[\"information-outline\",\"white\"],null],[29,\"service-action\",[\"global-actions\",\"showAboutDialog\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/help-menu/template.hbs"
    }
  });

  _exports.default = _default;
});