define("web-client/components/ion-tabs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-tabs',
    classNames: [],
    tab: "main",
    slot: "top",
    attributeBindings: ["tab", "slot"],

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('ionTabsDidChange', function () {});
    }
  });

  _exports.default = _default;
});