define("web-client/components/password-list-gauge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eo/DtSSm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inc four\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inc three\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inc two\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inc one\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-list-gauge/template.hbs"
    }
  });

  _exports.default = _default;
});