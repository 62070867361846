define("web-client/components/accept-terms-dialog/component", ["exports", "ui-core/mixins/dialog-base"], function (_exports, _dialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-6',
    classNames: ['accept-terms-dialog'],
    reduxStore: Ember.inject.service(),
    escAction: "cancel",
    defaultAction: Ember.computed('hasChanged', function () {
      return "ok";
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    /*    options: Ember.Object.create({
     nextAction: null,
     title: "",
     continueButtonText: "",
     cancelButtonText: "",
     continueButtonTitle: "",
     cancelButtonTitle: "",
     message: ""
     }),*/
    init() {
      this._super.apply(this, arguments);

      this._closePromise = Ember.RSVP.defer();
      let onConfirm = this.onConfirm;

      this._closePromise.promise.then(() => {
        this.send('remove');
        onConfirm.resolve();
      }).catch(() => {
        this.send('remove');
        onConfirm.reject();
      });
    },

    currentTab: "tos",
    actions: {
      setTab(tab) {
        this.set('currentTab', tab);
      },

      ok() {
        this._closePromise.resolve('confirmed');
      },

      cancelDialog() {
        this._closePromise.reject("cancelled");
      }

    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});