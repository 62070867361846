define("web-client/settings/company/billing/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route", "web-client/api"], function (_exports, _baseRoute, _authedRoute, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    router: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SECTION_TITLE",
          sectionTitle: "PASSPACK"
        });
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Company Billing"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "CompanyBilling"
        });
      }

    },

    activate() {
      this._super.apply(this, arguments); // if the user is not allowed redirect them elsewhere.


      let mayViewBilling = this.globalActions.doesPermissionExist('settings', 'company.billing', 'ro');

      if (!mayViewBilling) {
        this.router.transitionTo('settings');
      }

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('rootURL', this.router.rootURL); // Subscription Summary Data

      this.globalActions.updateSubscriptionData();
      (0, _api.getDefaultPayment)().then(data => {
        // {"status":{"code":0,"codes":[]},"last4":"4242","expirationMonth":12,"expirationYear":2020,"brand":"Visa"}
        if (data.status.code === 0) {
          controller.set('defaultPayment', data);
        } else {
          controller.set('defaultPayment', null);
        }

        (0, _api.getExtraInvoiceInfo)().then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_EXTRA_INVOICE_INFO',
            extraInvoiceInfo: {
              field1: data.field1,
              field2: data.field2
            }
          });
        });
      }).catch(() => {
        controller.set('defaultPayment', null);
        (0, _api.getExtraInvoiceInfo)().then(data => {
          this.reduxStore.dispatch({
            type: 'INITIALIZE_EXTRA_INVOICE_INFO',
            extraInvoiceInfo: {
              field1: data.field1,
              field2: data.field2
            }
          });
        }).catch(() => {//console.log('no extra data')
        });
      }); // Get billing history

      (0, _api.getBillingHistory)().then(data => {
        controller.set('billingHistory', data.rows);
      }).catch(() => {
        controller.set('billingHistory', []);
      });
    },

    renderTemplate() {
      this.render('settings/company/billing', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});