define("web-client/settings/company/license-management/controller", ["exports", "lodash-es/each", "lodash-es/filter", "lodash-es/sortBy"], function (_exports, _each, _filter, _sortBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    classNames: ["license-management"],
    filterUsername: '',
    filterEmail: '',
    filterMfa: '',
    filterName: '',
    filterStatus: 'Any',
    filterRole: 'Any',
    mayViewLicenseManagement: false,
    mayChangeLicenseManagement: false,
    showNotLicensedTable: true,
    currencyFormatter: {},

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      this.mayViewLicenseManagement = this.globalActions.doesPermissionExist('settings', 'company.licenseManagement', 'ro');
      this.mayChangeLicenseManagement = this.globalActions.doesPermissionExist('settings', 'company.licenseManagement', 'rw');
    },

    validLicense: Ember.computed('reduxStore.state.{subscriptionSummary}', function () {
      let expired = this.get('reduxStore.state.subscriptionSummary.expired'); //   console.log(expired);

      return !expired;
    }),
    filteredItems: Ember.computed('licenseData', 'filterUsername', 'filterRole', 'filterEmail', 'filterMfa', 'filterName', 'filterStatus', 'reduxStore.state.orgLicenses', function () {
      let licenseData = this.reduxStore.state.get('orgLicenses');
      let data = (0, _filter.default)(licenseData, item => {
        return item.username.toLowerCase().includes(this.filterUsername.toLowerCase()) && item.email.toLowerCase().includes(this.filterEmail.toLowerCase()) && item.name.toLowerCase().includes(this.filterName.toLowerCase()) && ('Any' === this.filterMfa ? true : item.mfa.toLowerCase().includes(this.filterMfa.toLowerCase())) && ('Any' === this.filterStatus ? true : item.status.toLowerCase().includes(this.filterStatus.toLowerCase())) && ('Any' === this.filterRole ? true : item.roles ? item.roles.includes(this.filterRole.toLowerCase()) : false);
      }); //    console.log("updating filteredItems");

      return (0, _sortBy.default)(data, ["username", "email"]);
    }),
    notLicensedItems: Ember.computed('reduxStore.state.orgNotLicensed', function () {
      let data = this.reduxStore.state.get('orgNotLicensed'); //      console.log(data);

      let rv = (0, _sortBy.default)(data, ["username", "email"]); //      console.log(rv);

      return rv;
    }),
    filter: function filter() {//      console.log("filtering");
      //      console.log("Username " + this.get("filterUsername"));
      //      console.log("Role " + this.get("filterRole"));
      //      console.log("Email " + this.get("filterEmail"));
      //      console.log("Name " + this.get("filterName"));
      //      console.log("Status " + this.get("filterStatus"));
      // The filtering is done by the computed function once the filters are updaated.
    },
    planAdditionalLicensesAllowed: Ember.computed('reduxStore.state.{subscriptionSummary}', function () {
      return 9999; //     if (this.get('reduxStore.state.currentSubscription.sku')==='team'){
      //      let allowed = this.get('reduxStore.state.currentSubscription.allowedLicenseQuantity');
      //      let currentPurchased = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
      //      return allowed-currentPurchased;
      //     } else if (this.get('reduxStore.state.currentSubscription.sku')==='business'){
      //      return 9999;
      //     } else {
      //       let allowed = this.get('reduxStore.state.currentSubscription.allowedLicenseQuantity');
      //      let currentPurchased = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
      //      return allowed-currentPurchased;
      //     }
    }),
    addOneYear: function addOneYear(date) {
      // Making a copy with the Date() constructor
      const dateCopy = new Date(date);
      dateCopy.setFullYear(dateCopy.getFullYear() + 1);
      return this.formatDate(dateCopy);
    },
    formatDate: function formatDate(date) {
      return date.format('mm/dd/yy');
    },
    legacyLicenseInfo: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';

        try {
          let conversionFromLegacyPlan = this.get('reduxStore.state.nextSubscription.conversionFromLegacyPlan');
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate');
          const date = new Date(nextBillDateOrig);

          if (conversionFromLegacyPlan) {
            conversionDate = this.formatDate(date);
          } else {
            conversionDate = this.addOneYear(date);
          }
        } catch (error) {
          console.log(error);
        }

        return 'Note: You have been grandfathered into your existing ' + originalDescription + ' plan for one more billing cycle.  Please note that your account will converted to the Team Plan on ' + conversionDate + ' and you will be charged for any licenses you will be using on that date. ';
      }
    }),
    actions: {
      addUserLicensesDialog: function addUserLicensesDialog() {
        this.standardDialogs.showDialog('add-user-licenses-dialog', {});
      },
      filterByStatus: function filterByStatus(newStatus) {
        this.set('filterStatus', newStatus);
        this.filter();
      },
      filterByRole: function filterByRole(newStatus) {
        this.set('filterRole', newStatus);
        this.filter();
      },
      filterByUsername: function filterByUsername(search) {
        this.set('filterUsername', search);
        this.filter();
      },
      filterByEmail: function filterByEmail(search) {
        this.set('filterEmail', search);
        this.filter();
      },
      filterByMfa: function filterByMfa(search) {
        this.set('filterMfa', search);
        this.filter();
      },
      filterByName: function filterByName(search) {
        this.set('filterName', search);
        this.filter();
      },
      inviteNewOrganizationMember: function inviteNewOrganizationMember() {
        this.standardDialogs.showDialog('invite-new-organization-member-dialog', {});
      },
      resetFilters: function resetFilters() {
        document.getElementById("filterByStatus").value = 'Any';
        document.getElementById("filterByRole").value = 'Any';
        document.getElementById("filterByUsername").value = '';
        document.getElementById("filterByEmail").value = '';
        document.getElementById("filterByName").value = '';
        document.getElementById("filterByMfa").value = 'Any';
        this.set('filterByMfa', 'Any');
        this.set('filterStatus', 'Any');
        this.set('filterRole', 'Any');
        this.set('filterEmail', '');
        this.set('filterUsername', '');
        this.set('filterName', '');
        this.filter();
      }
    }
  });

  _exports.default = _default;
});