define("web-client/components/stat-pie-chart/component", ["exports", "d3-selection", "d3-array", "d3-shape", "d3-transition", "d3-ease"], function (_exports, _d3Selection, _d3Array, _d3Shape, _d3Transition, _d3Ease) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function DonutCharts(element) {
    var charts = (0, _d3Selection.select)(element);
    var chart_m, chart_r;

    var createCenter = function createCenter()
    /*pie*/
    {
      var donuts = charts.selectAll('.donut'); // The circle displaying total data.

      donuts.append("svg:circle").attr("r", chart_r * 0.6).style("fill", "#FFFFFF").on('mouseover', function ()
      /* d, i */
      {
        (0, _d3Selection.select)(this).transition().attr("r", chart_r * 0.65);
      }).on('mouseout', function ()
      /*d, i */
      {
        (0, _d3Selection.select)(this).transition().duration(500).ease(_d3Ease.easeBounce).attr("r", chart_r * 0.6);
      }).on('click', function ()
      /*d, i*/
      {
        var paths = charts.selectAll('.clicked');
        pathAnim(paths, 0);
        paths.classed('clicked', false);
        resetAllCenterText();
      }); // Title

      donuts.append('text').attr('class', 'center-txt type').attr('y', chart_r * -0.18).attr('text-anchor', 'middle').style('font-weight', 'bold').text(function (d
      /*, i */
      ) {
        return d.type;
      }); // Sub Title

      donuts.append('text').attr('class', 'center-txt subtype').attr('y', chart_r * -0.03).attr('text-anchor', 'middle').style('font-weight', 'bold'); // Value

      donuts.append('text').attr('class', 'center-txt value').attr('y', chart_r * 0.18).style('font-weight', 'bold').attr('text-anchor', 'middle'); ///Percentage

      donuts.append('text').attr('class', 'center-txt percentage').attr('y', chart_r * 0.34).attr('text-anchor', 'middle').style('fill', '#A2A2A2');
    };

    var setCenterText = function setCenterText(thisDonut, catText) {
      var sum1 = (0, _d3Array.sum)(thisDonut.selectAll('.clicked').data(), function (d) {
        return d.data.val;
      });
      thisDonut.select('.center-txt.subtype').text(function ()
      /* d */
      {
        if (catText) {
          return catText;
        } else {
          return ""; // d.type;
        }
      });
      /* thisDonut.select('.value')
         .text(function ( d ) {
            return (sum1) ? sum1
             : d.total;
         });*/

      thisDonut.select('.percentage').text(function (d) {
        return sum1 ? (sum1 / d.total * 100).toFixed(2) + '%' : '';
      });
    };

    var resetAllCenterText = function resetAllCenterText() {
      charts.selectAll('.value').text(function (d) {
        return d.total;
      });
      charts.selectAll('.percentage').text('');
    };

    var pathAnim = function pathAnim(path, dir) {
      switch (dir) {
        case 0:
          path.transition().duration(0).ease(_d3Ease.easeBounce).attr('d', (0, _d3Shape.arc)().innerRadius(chart_r * 0.7).outerRadius(chart_r));
          break;

        case 1:
          path.transition().attr('d', (0, _d3Shape.arc)().innerRadius(chart_r * 0.7).outerRadius(chart_r * 1.08));
          break;
      }
    };

    var updateDonut = function updateDonut() {
      var pie1 = (0, _d3Shape.pie)().sort(null).value(function (d) {
        return d.val;
      });
      var arc1 = (0, _d3Shape.arc)().innerRadius(chart_r * 0.7).outerRadius(function () {
        return (0, _d3Selection.select)(this).classed('clicked') ? chart_r * 1.08 : chart_r;
      }); // Start joining data with paths

      var paths = charts.selectAll('.donut').selectAll('path').data(function (d
      /*, i*/
      ) {
        return pie1(d.data);
      });
      paths.transition().duration(0).attr('d', arc1);
      paths.enter().append('svg:path').attr('d', arc1).style('fill', function (d
      /*, i */
      ) {
        return d.data.color;
      }) // .style('stroke', '#FFFFFF')
      .on('mouseover', function (d
      /*, i, j*/
      ) {
        pathAnim((0, _d3Selection.select)(this), 1);
        var thisDonut = charts.select('.type');
        thisDonut.select('.value').text(function ()
        /*donut_d*/
        {
          return d.data.val;
        });
        thisDonut.select('.percentage').text(function (donut_d) {
          return (d.data.val / donut_d.total * 100).toFixed(2) + '%';
        });
        var thisPath = (0, _d3Selection.select)(this);
        let catText = thisPath.data()[0].data.cat;
        setCenterText(thisDonut, catText, thisPath);
      }).on('mouseout', function ()
      /*d, i, j*/
      {
        var thisPath = (0, _d3Selection.select)(this);

        if (!thisPath.classed('clicked')) {
          pathAnim(thisPath, 0);
        }

        var thisDonut = charts.select('.type');

        if (thisPath.classed('clicked')) {
          let catText = thisPath.data()[0].data.cat;
          setCenterText(thisDonut, catText);
        } else {
          let catText;

          if (thisDonut.selectAll('.clicked').size()) {
            catText = thisDonut.selectAll('.clicked').data()[0].data.cat;
          }

          var sum1 = (0, _d3Array.sum)(thisDonut.selectAll('.clicked').data(), function (d) {
            return d.data.val;
          });
          thisDonut.select('.value').text(function (d) {
            return sum1 ? sum1 : d.total;
          });
          setCenterText(thisDonut, catText);
        }
      }).on('click', function ()
      /* d, i, j*/
      {
        var thisDonut = charts.select('.type');
        let self = this;

        if (thisDonut.selectAll('.clicked').size()) {
          thisDonut.selectAll('.clicked').each(function ()
          /*data,index,elements*/
          {
            if (self !== this) {
              let thisPath = (0, _d3Selection.select)(this);
              let clicked = thisPath.classed('clicked');
              pathAnim(thisPath, ~~!clicked);
              thisPath.classed('clicked', !clicked);
            }
          });
        }

        var thisPath = (0, _d3Selection.select)(this);
        var clicked = thisPath.classed('clicked');
        pathAnim(thisPath, ~~!clicked);
        thisPath.classed('clicked', !clicked);
        let catText = thisPath.data()[0].data.cat;
        setCenterText(thisDonut, catText);
      });
      paths.exit().remove();
      resetAllCenterText();
    };

    this.create = function (dataset) {
      var $charts = element;
      chart_m = $charts.clientWidth / dataset.length / 2 * 0.14;
      chart_r = $charts.clientWidth / dataset.length / 2 * 0.85;
      charts.selectAll('.donut').data(dataset).enter().append('svg:svg') // .attr('width', (chart_r + chart_m) * 2)
      // .attr('height', (chart_r + chart_m) * 2)
      .attr('viewBox', '0 0 ' + (chart_r + chart_m) * 2 + " " + (chart_r + chart_m) * 2).attr("preserveAspectRatio", "xMinYMin meet").classed('stat-pie-chart-svg', true).append('svg:g').attr('class', function ()
      /* d, i */
      {
        return 'donut type';
      }).attr('transform', 'translate(' + (chart_r + chart_m) + ',' + (chart_r + chart_m) + ')');
      createCenter();
      updateDonut();
    };

    this.update = function (dataset) {
      charts.selectAll(".donut").data(dataset);
      updateDonut();
    };

    return this;
  }

  function genData(typeText, statData) {
    var type = [typeText];
    var unit = [''];
    var dataset = new Array();
    var total = 0;

    for (var j = 0; j < statData.length; j++) {
      total += statData[j].val;
    }

    dataset.push({
      "type": type[0],
      "unit": unit[0],
      "data": statData,
      "total": total
    });
    return dataset;
  }

  var _default = Ember.Component.extend({
    classNames: ["stat-pie-chart"],

    drawChart() {},

    statData: null,

    init() {
      this._super();
    },

    statsObserver: Ember.on('init', Ember.observer('statData', function () {
      Ember.run.once(this, this.updateStats);
    })),

    updateStats() {
      if (this.get('statData')) {
        if (this.get('donuts')) {
          this.get('donuts').update(genData(this.get('typeName'), this.get('statData')));
        } else {
          var donutData = genData(this.get("typeName"), this.get('statData'));
          var donuts = new DonutCharts(this.get('element'));
          donuts.create(donutData);
          this.set('donuts', donuts);
        }
      }
    },

    donuts: null,

    didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('statData')) {
        var donutData = genData(this.get("typeName"), this.get('statData'));
        var donuts = new DonutCharts(this.get('element'));
        donuts.create(donutData);
        this.set('donuts', donuts);
      }
    }

  });

  _exports.default = _default;
});