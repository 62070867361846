define("web-client/components/ion-textarea/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-textarea',
    classNames: [],
    intersection: Ember.inject.service(),
    accept: undefined,
    autocapitalize: "off",
    autocomplete: "off",
    autocorrect: "off",
    autofocus: false,
    clearInput: false,
    clearOnEdit: false,
    color: undefined,
    debounce: 0,
    disabled: false,
    inputmode: undefined,
    max: undefined,
    maxlength: undefined,
    min: undefined,
    minlength: undefined,
    placeholder: undefined,
    readonly: false,
    spellcheck: "false",
    type: "text",
    value: '',
    inputElement: null,
    autoGrow: false,
    rows: 1,
    attributeBindings: ["tab", "slot", "autocapitalize", "autocomplete", "autocorrect", "autofocus", "clearInput", "clearOnEdit", "color", "debounce", "disabled", "inputmode", "max", "maxlength", "min", "minlength", "placeholder", "readonly", "spellcheck", "text", "value", "type", "autoGrow", "rows"],

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.componentOnReady().then(() => {
        this.element.addEventListener('ionChange', () => {
          this.set('value', this.element.value);
        });
        this.element.getInputElement().then(element => {
          this.get('intersection').observe(element);

          this._inView = function ()
          /*event*/
          {
            element.style.height = element.scrollHeight + 'px';
          };

          element.addEventListener('inview', this._inView);
          this.set('inputElement', element);
          this.set('autoGrow', true);
          element.setAttribute('value', this.element.value);
          element.setAttribute('spellcheck', 'false');
        });
      });
    }
  });

  _exports.default = _default;
});