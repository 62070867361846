define("web-client/api/remove-yubikey", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeYubikey = void 0;

  /**
   * params
   *params
   *
   */
  const removeYubikey = _ipcClient.ipc.makeRequestFunction('removeYubikey');

  _exports.removeYubikey = removeYubikey;
});