define("web-client/components/delete-passwords-dialog/component", ["exports", "web-client/mixins/progress-handler", "ui-core/mixins/dialog-base", "web-client/api", "ember-concurrency"], function (_exports, _progressHandler, _dialogBase, _api, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function makeid() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  var _default = Ember.Component.extend(_dialogBase.default, _progressHandler.default, {
    size: 'size-3',
    totalToDelete: 0,
    totalSelected: 0,
    aborted: false,
    loading: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    escAction: "cancelDialog",
    classNames: ['centered-dialog-text'],
    defaultAction: Ember.computed('hasChanged', function () {
      return "doDelete";
    }),
    someUnownedSelected: Ember.computed('totalToDelete', 'totalSelected', function () {
      return this.totalToDelete < this.totalSelected;
    }),
    deleteLists: null,
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    password: null,

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    init() {
      this._super.apply(this, arguments);

      let selectedPasswords = [];

      if (this.password) {
        selectedPasswords = [this.password.id];
      } else {
        selectedPasswords = this.get('reduxStore.state.data.passwords.selected');
      }

      (0, _api.filterOnOwned)(selectedPasswords).then(filtered => {
        this.set('selectedPasswords', selectedPasswords);
        this.set('passwordsToDelete', filtered);
        this.set('totalToDelete', filtered.length);
        this.set('totalSelected', selectedPasswords.length);
      });
    },

    doDelete: (0, _emberConcurrency.task)(function* () {
      let progressListenerHandle = makeid();
      this.element.classList.remove('show');
      this.loading.show('Deleting Passwords');
      yield (0, _api.deletePasswords)(this.passwordsToDelete, progressListenerHandle);
      this.reduxStore.state.data.passwords.selected.removeObjects(this.passwordsToDelete);
      yield this.globalActions.getPasswords();
      this.loading.dismiss();
      this.send('remove');
    }),
    actions: {
      abortDelete: function abortDelete() {
        this.set('aborted', true);
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});