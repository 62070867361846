define("web-client/components/settings-list-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lclUCCTd",
    "block": "{\"symbols\":[\"treeViewItem\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"item \",[23,\"_uniqueClassName\"],\" pulse\"]]],[12,\"title\",[30,[\"Go to \",[23,\"name\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"indicator-icon icon \",[23,\"expansionIndicatorIcon\"],\" \",[23,\"_uniqueClassName\"]]]],[12,\"title\",[23,\"expandTitle\"]],[9],[10],[0,\"\\n  \"],[1,[29,\"svg-icon\",null,[[\"class\",\"icon\"],[\"icon\",[29,\"if\",[[25,[\"selected\"]],[29,\"build-svg-icon\",[\"check\",\"grey-800\"],null],[25,[\"icon\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"item-label\"],[9],[0,\"\\n    \"],[1,[23,\"name\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"sub-items \",[23,\"_uniqueClassName\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[26,2]],null,{\"statements\":[[0,\"    \"],[15,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[25,[\"treeViewItems\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"component\",[[25,[\"treeViewItemComponent\"]]],[[\"value\",\"display\",\"selected\",\"onSelecttreeViewItem\"],[[24,1,[\"value\"]],[24,1,[\"display\"]],[24,1,[\"selected\"]],[29,\"action\",[[24,0,[]],\"onSelectTreeItem\",[24,1,[\"value\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/settings-list-menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});