define("web-client/components/nb-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["panel", 'elevation-2dp']
  });

  _exports.default = _default;
});