define("web-client/api/add-connection-to-team", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addConnectionToTeam = void 0;

  const addConnectionToTeam = _ipcClient.ipc.makeRequestFunction('addConnectionToTeam');

  _exports.addConnectionToTeam = addConnectionToTeam;
});