define("web-client/components/company-settings-sections/domains/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nm0edVia",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"ion-card\"],[11,\"class\",\"white-card\"],[9],[0,\"\\n    \"],[7,\"ion-card-header\"],[9],[0,\"\\n      \"],[7,\"ion-card-title\"],[9],[0,\"Domains\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"ion-card-content\"],[9],[0,\"\\n      \"],[7,\"ion-list\"],[11,\"class\",\"settings-list\"],[9],[0,\"\\n        \"],[7,\"ion-item-group\"],[9],[0,\"\\n          \"],[1,[29,\"ion-setting-item\",null,[[\"title\",\"value\",\"settingAction\"],[\"Verified Domains\",\"Verify your domains for additional control and security.\",[29,\"array\",[[29,\"unless\",[[25,[\"isBusinessPlan\"]],[29,\"component\",[\"read-only-input-display\"],[[\"displayText\",\"displayHeader\"],[[25,[\"unlockMessage\"]],[25,[\"unlockMessageHeader\"]]]]],[29,\"component\",[\"domain-listing\"],[[\"label\",\"domains\",\"actionName\",\"readonly\"],[\"Verified Domains\",[25,[\"verifiedDomainOptions\"]],\"showManageVerifiedDomainsDialog\",[29,\"not\",[[25,[\"mayChangeSection\"]]],null]]]]],null]],null]]]],false],[0,\"\\n\\n          \"],[1,[29,\"ion-setting-item\",null,[[\"title\",\"value\",\"settingAction\"],[\"Allowed Domains\",\"These domains may be used when limiting connections to your or other companies.\",[29,\"array\",[[29,\"unless\",[[25,[\"isBusinessPlan\"]],[29,\"component\",[\"read-only-input-display\"],[[\"displayText\",\"displayHeader\"],[[25,[\"unlockMessage\"]],[25,[\"unlockMessageHeader\"]]]]],[29,\"component\",[\"domain-listing\"],[[\"label\",\"domains\",\"actionName\",\"readonly\"],[\"Allowed Domains\",[25,[\"allowedDomainOptions\"]],\"showManageAllowedDomainsDialog\",[29,\"not\",[[25,[\"mayChangeSection\"]]],null]]]]],null]],null]]]],false],[0,\"\\n\\n\\n        \"],[10],[0,\"\\n\\n\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/company-settings-sections/domains/template.hbs"
    }
  });

  _exports.default = _default;
});