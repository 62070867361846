define("web-client/api/get-google-auth-setup", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGoogleAuthSetup = void 0;

  /**
   * params
   *
   *
   */
  const getGoogleAuthSetup = _ipcClient.ipc.makeRequestFunction('getGoogleAuthSetup');

  _exports.getGoogleAuthSetup = getGoogleAuthSetup;
});