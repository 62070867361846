define("web-client/components/stat-display-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHFFca7M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"stat-icon \",[23,\"icon\"]]]],[9],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stat-text-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"stat-label\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/stat-display-header/template.hbs"
    }
  });

  _exports.default = _default;
});