define("web-client/components/create-passphrase-dialog/component", ["exports", "web-client/mixins/auth-base-dialog", "web-client/api", "ember-cp-validations"], function (_exports, _authBaseDialog, _api, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CreatePassphraseValidations = (0, _emberCpValidations.buildValidations)({
    passphrase: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Packing Key can't be blank"
    }), (0, _emberCpValidations.validator)('notrailingleadingspace', {
      message: "Can't have spaces at the beginning or end"
    }), (0, _emberCpValidations.validator)('passwordstrength', {
      scoreProperty: 'passwordStats',
      message: "Must score at least 3 bars on the strength meter"
    })],
    passphrase2: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "You must retype your Packing Key"
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'passphrase',
      message: "You must retype your Packing Key exactly as before"
    })]
  });
  const ContinueCreatePassphraseValidations = (0, _emberCpValidations.buildValidations)({
    passphrase: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Packing Key can't be blank"
    })]
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, {
    currentSize: Ember.computed('success', function () {
      return this.success ? 'size-3' : 'size-3';
    }),
    classNameBindings: ['currentSize'],
    classNames: ['create-passphrase-dialog'],
    passphrase: '',
    passphrase2: '',
    reduxStore: Ember.inject.service(),
    defaultAction: Ember.computed('submitPassphraseAndContinue', 'submitPassphrase', function () {
      if (this.sessionisKeySet) {
        if (this.sessionPassphrase) {
          return '';
        } else {
          return "submitPassphraseAndContinue";
        }
      } else {
        return "submitPassphrase";
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var createPassphrase = Ember.Object.extend(CreatePassphraseValidations, {
        i18n: Ember.inject.service(),
        reduxStore: Ember.inject.service(),
        passphrase: '',
        passphrase2: '',
        showErrors: false,
        showPassphraseErrors: Ember.computed('showErrors', 'passphrase', function () {
          return this.showErrors || this.passphrase.length;
        }),
        showPassphrase2Errors: Ember.computed('showErrors', 'passphrase2', 'passphrase', function () {
          return this.showErrors || this.passphrase2.length;
        }),
        passwordStats: Ember.computed("passphrase", "reduxStore.state.{username,emailAddress}", function () {
          if (!this.liveStatsObj) {
            this.liveStatsObj = Ember.Object.extend({
              password: Ember.Object.extend({
                score: 0,
                crack_time_display: "instant",
                scoreCSS: Ember.computed('score', function () {
                  return 'score-' + (this.score ? this.score : 0);
                })
              }).create()
            }).create();
          }

          let self = this;
          (0, _api.getScore)(self.get('passphrase'), [self.get('reduxStore.state.emailAddress') ? self.get('reduxStore.state.emailAddress') : "a", self.get('reduxStore.state.username') ? self.get('reduxStore.state.username') : "a"]).then(function (data) {
            if (data !== 'unavailable') {
              self.liveStatsObj.password.set('score', data.score);
              self.liveStatsObj.password.set('crack_time_display', data.crack_time_display);
            } else {
              self.liveStatsObj.password.set('score', 0);
              self.liveStatsObj.password.set('crack_time_display', "instant");
            }
          });
          return this.liveStatsObj;
        })
      }).create(Ember.getOwner(this).ownerInjection());
      this.set('createPassphrase', createPassphrase);
      var continueCreatePassphrase = Ember.Object.extend(ContinueCreatePassphraseValidations, {
        i18n: Ember.inject.service(),
        passphrase: '',
        showPassphraseErrors: Ember.computed('showErrors', 'passphrase', function () {
          return this.showErrors || this.passphrase.length;
        })
      }).create(Ember.getOwner(this).ownerInjection());
      this.set('continueCreatePassphrase', continueCreatePassphrase);
    },
    keyGenerationType: 'clientSide',
    sessionisKeySet: false,
    sessionPassphrase: false,

    /**
     * actions
     *
     */
    actions: {
      /**
       *
       */
      goToApplication: function goToApplication() {
        this.goToApplication();
      },

      /**
       *
       */
      submitPassphraseAndContinue: function submitPassphraseAndContinue() {
        var self = this;
        this.continueCreatePassphrase.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.submitPassphraseAndContinue(self.get('continueCreatePassphrase.passphrase'), self.get('keyGenerationType'));
          }
        }).finally(function () {
          self.set('continueCreatePassphrase.showErrors', true);
        });
      },

      /**
       *
       */
      cancelKeyGeneration: function cancelKeyGeneration() {
        this.element.style.display = 'none';
        this.get('cancelKeyGeneration')();
      },

      /**
       *
       */
      submitPassphrase: function submitPassphrase() {
        var self = this;
        this.createPassphrase.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.submitPassphrase(self.get('createPassphrase.passphrase'), self.get('keyGenerationType'));
          }
        }).finally(function () {
          self.set('createPassphrase.showErrors', true);
        });
      }
    }
  });

  _exports.default = _default;
});