define("web-client/components/passwords-sidebar-menu/ion-treeview-sub-teams-group-item/component", ["exports", "lodash-es/filter"], function (_exports, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ion-treeview-sub-teams-group-item'],
    layoutValue: "icon-start",
    button: false,
    attributeBindings: ['layoutValue:layout', 'button'],
    classNameBindings: ['expandedClass'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    expanded: false,
    item: null,
    filterText: "",
    loaded: false,

    init() {
      this._super.apply(this, arguments);

      this.set('strenthValues', Ember.A([0, 1, 2, 3, 4]));
      setTimeout(() => {
        if (!this.isDestroyed) {
          this.set('loaded', true);
        }
      }, 500);
    },

    filteredTeams: Ember.computed('teams', 'filterText', function () {
      //  console.log("TEAMS: " + this.teams + " =" + this.filterText);
      if (this.filterText) {
        return (0, _filter.default)(this.teams, item => {
          return item.name.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase());
        });
      } else {
        return this.teams;
      }
    }),
    expandedClass: Ember.computed('expanded', 'filterText', function () {
      return this.expanded || this.filterText ? 'open' : '';
    })
  });

  _exports.default = _default;
});