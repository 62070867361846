define("web-client/reducers/index", ["exports", "lodash-es/isEmpty", "lodash-es/isNull", "lodash-es/flowRight", "lodash-es/find", "lodash-es/partial", "lodash-es/cloneDeep", "lodash-es/map", "web-client/utils/misc-utils", "web-client/utils/default-app-settings", "web-client/reducers/subscription", "web-client/reducers/data", "web-client/config/environment", "lodash-es/filter", "web-client/reducers/import-data", "debug"], function (_exports, _isEmpty, _isNull, _flowRight, _find, _partial, _cloneDeep, _map, _miscUtils, _defaultAppSettings, _subscription, _data, _environment, _filter, _importData, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  const log = (0, _debug.default)('reducer-index');
  const baseURL = _environment.default.apiHost;
  const initialState = Ember.Object.extend({
    data: _data.default,
    settings: Ember.Object.create(_defaultAppSettings.default),
    authed: Ember.computed('loggedIn', 'oobValid', 'isKeySet', 'isPublicKeySet', 'isEncryptedPrivateKeySet', 'isLocked', function () {
      return this.loggedIn && this.oobValid && this.isKeySet && this.isPublicKeySet && this.isEncryptedPrivateKeySet && !this.isLocked;
    }),
    globalFilterText: "",
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    contentClass: 'fixed-canvas',
    sidebarOpen: false,
    companyData: {},
    companyPermissions: {},
    contentSlot: 'fixed',
    loggedIn: false,
    oobValid: true,
    passphraseCheck: '',
    publicKey: null,
    encryptedPrivateKey: null,
    decryptedPrivateKey: null,
    networkUnavailable: false,
    passphrase: null,
    registrationFlowId: null,
    retrieveUserSuccess: false,
    emailAddress: '',
    userId: null,
    searchable: true,
    nickname: '',
    sharingName: '',
    registrationDate: null,
    statusToken: null,
    securityCode: null,
    lastPasswordChangeDate: null,
    pendingEmailChange: false,
    mustUpdatePassword: false,
    isEmailValid: false,
    verifyPasswordResetSuccess: false,
    subscription: _subscription.default,
    licenseSummary: {},
    nextSubscription: {},
    orgDetails: {},
    orgLicenses: {},
    orgNotLicensed: {},
    orgSettings: {},
    subscriptionSummary: {},
    systemMessages: {},
    passwordsSidebarMenuTab: 'labels',
    decryptedDbKey: null,
    importData: _importData.default,
    allowTransitions: 0,
    isInitialized: false,
    nameSize: 200,
    isValidateDialogLoading: false,
    registrationSuccessful: false,
    urlSize: 200,
    updatedSize: 200,
    changedSize: 200,
    gaBackupTokens: null,
    connectionGridView: "connections",
    dashboardSection: "Usage",
    selectedHeaderButton: "dashboard",
    sidebarTitle: "Menu",
    sso: {},
    yubiKeyIsPreferredOOB: Ember.computed('oobPreferences', function () {
      return (0, _filter.default)(this.get('oobPreferences.options'), item => {
        return item.method === 'yubikey';
      }).length > 0;
    }),
    gaIsPreferredOOB: Ember.computed('oobPreferences', function () {
      return (0, _filter.default)(this.get('oobPreferences.options'), item => {
        return item.method === 'ga';
      }).length > 0;
    }),
    maIsPreferredOOB: Ember.computed('oobPreferences', function () {
      return (0, _filter.default)(this.get('oobPreferences.options'), item => {
        return item.method === 'ma';
      }).length > 0;
    }),
    isKeySet: Ember.computed('passphraseCheck', 'passphrase', function () {
      return !(0, _isEmpty.default)(this.passphraseCheck);
    }),
    isLocked: Ember.computed('passphrase', 'isKeySet', function () {
      return this.isKeySet !== true || (0, _isEmpty.default)(this.passphrase) || (0, _isNull.default)(this.passphrase);
    }),
    isPublicKeySet: Ember.computed('publicKey', function () {
      return !(0, _isEmpty.default)(this.publicKey);
    }),
    isEncryptedPrivateKeySet: Ember.computed('encryptedPrivateKey', function () {
      return !(0, _isEmpty.default)(this.encryptedPrivateKey);
    }),
    isDecryptedPrivateKeySet: Ember.computed('decryptedPrivateKey', function () {
      return !(0, _isEmpty.default)(this.decryptedPrivateKey);
    }),
    profileImageURL: Ember.computed('userId', 'resetProfileUrl', function () {
      return baseURL + "/portal/v1/secure/avatar/" + this.userId + '?t=' + _miscUtils.uniqID.get("t");
    })
  }).create();
  window.initstate = initialState;

  function _default() {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    const type = action ? action.type : null;

    switch (type) {
      case 'RESET':
        {
          state.setProperties({
            username: '',
            name: '',
            firstName: '',
            lastName: '',
            loggedIn: false,
            oobValid: true,
            passphraseCheck: '',
            publicKey: null,
            decryptedPrivateKey: null,
            encryptedPrivateKey: null,
            licenseSummary: {},
            networkUnavailable: false,
            passphrase: null,
            registrationFlowId: null,
            retrieveUserSuccess: false,
            emailAddress: '',
            userId: null,
            searchable: true,
            nickname: '',
            companyData: {},
            companyPermissions: {},
            orgDetails: {},
            orgLicenses: {},
            orgNotLicensed: {},
            orgSettings: {},
            subscriptionSummary: {},
            sso: {},
            sharingName: '',
            registrationDate: null,
            statusToken: null,
            securityCode: null,
            lastPasswordChangeDate: null,
            pendingEmailChange: false,
            mustUpdatePassword: false,
            isEmailValid: false,
            verifyPasswordResetSuccess: false,
            isValidateDialogLoading: false,
            decryptedDbKey: null,
            registrationSuccessful: false
          });
          state.data.passwords.set('content', []);
          state.data.passwords.set('totalCount', 0);
          state.data.passwords.set('currentPage', 1);
          state.setProperties({
            isInitialized: false
          });
          state.setProperties({
            settings: _defaultAppSettings.default
          });
          const labels = (0, _map.default)([], item => {
            return Ember.Object.extend({
              state: state,
              isSelected: Ember.computed('state.data.labels.{selected.[],selected}', function () {
                return this.get('state.data.labels.selected').includes(this.id);
              })
            }).create(item);
          });
          state.data.labels.set('content', labels);
          const connections = (0, _map.default)([], item => {
            return Ember.Object.extend({
              state: state,
              isSelectedForPasswords: Ember.computed('state.data.teams.{selected.[],selected}', function () {
                const found = (0, _find.default)(this.get('state.data.teams.content'), team => {
                  return team.ownerId === item.userId && team.individual;
                });

                if (found) {
                  return found.isSelected;
                } else {
                  return false;
                }
              })
            }).create(item);
          });
          state.data.connections.set('content', connections);
          const teams = (0, _map.default)([], item => {
            return Ember.Object.extend({
              state: state,
              isSelected: Ember.computed('state.data.teams.{selected.[],selected}', function () {
                return this.get('state.data.teams.selected').includes(this.id);
              })
            }).create(item);
          });
          state.data.teams.set('content', teams);
          return state;
        }

      case 'ALLOW_TRANSITIONS':
        state.setProperties({
          allowTransitions: state.get('allowTransitions') - 1
        });
        return state;

      case 'SET_EMAIL_VALID':
        state.setProperties({
          isEmailValid: true
        });
        return state;

      case 'BLOCK_TRANSITIONS':
        state.setProperties({
          allowTransitions: state.get('allowTransitions') + 1
        });
        return state;

      case 'SET_IMPORT_DATA':
        state.importData.preImportData.set('content', action.preImportData); // state.importData.passwords.set('totalCount', action.passwords.count);
        //state.setProperties({importData.rows:action.importData});

        return state;

      case 'SET_NETWORK_UNAVAILABLE':
        state.setProperties({
          networkUnavailable: action.value
        });
        console.log("NETWORK UNAVAILABLE: " + state.networkUnavailable);
        return state;

      case 'SET_PASSWORDS_SIDEBAR_MENU_TAB':
        state.setProperties({
          passwordsSidebarMenuTab: action.tab
        });
        return state;

      case 'SET_REGISTRATION_EDITION':
        state.setProperties({
          registrationEdition: action.registrationEdition
        });
        return state;

      case 'SET_REGISTRATION_FLOW_ID':
        state.setProperties({
          registrationFlowId: action.registrationFlowId
        });
        return state;

      case 'SET_SIDEBAR_TITLE':
        state.setProperties({
          sidebarTitle: action.sidebarTitle
        });
        return state;

      case 'SET_IS_INITIALIZED':
        state.setProperties({
          isInitialized: true
        });
        return state;

      case 'SET_CONNECTIONS_GRID_VIEW':
        state.setProperties({
          connectionGridView: action.connectionGridView
        });
        return state;

      case "SET_GADATA":
        state.setProperties({
          gaData: action.gaData
        });
        return state;

      case "SET_MADATA":
        state.setProperties({
          maData: action.maData
        });
        return state;

      case 'TOGGLE_PASSWORDS_FAVORITE_FILTER':
        state.setProperties({
          'data.passwords.filterOnFavorites': !state.get('data.passwords.filterOnFavorites')
        });
        return state;

      case 'CLEAR_PASSWORDS_FAVORITE_FILTER':
        state.setProperties({
          'data.passwords.filterOnFavorites': false
        });
        return state;

      case 'UPDATE_LABELS':
        {
          const labels = (0, _map.default)(action.labels, item => {
            return Ember.Object.extend({
              state: state,
              isSelected: Ember.computed('state.data.labels.{selected.[],selected}', function () {
                return this.get('state.data.labels.selected').includes(this.id);
              })
            }).create(item);
          });
          state.data.labels.set('content', labels);
          return state;
        }

      case 'UPDATE_CONNECTIONS':
        {
          const connections = (0, _map.default)(action.connections, item => {
            return Ember.Object.extend({
              state: state,
              isSelectedForPasswords: Ember.computed('state.data.teams.{selected.[],selected}', function () {
                const found = (0, _find.default)(this.get('state.data.teams.content'), team => {
                  return team.ownerId === item.userId && team.individual;
                });

                if (found) {
                  return found.isSelected;
                } else {
                  return false;
                }
              })
            }).create(item);
          });
          state.data.connections.set('content', connections);
          return state;
        }

      case 'UPDATE_CURRENT_PASSWORD_PAGE':
        state.data.passwords.set('currentPage', action.currentPage);
        return state;

      case 'UPDATE_PASSWORDS':
        state.data.passwords.set('content', action.passwords.data);
        state.data.passwords.set('totalCount', action.passwords.count);
        return state;

      case 'UPDATE_CONNECTIONS_FOR_MANAGEMENT':
        //      console.log("updating connections for management");
        let connectionData = action.connections.data; //console.log(data);

        let teams = state.data.teams.content; //      console.log(teams);
        // Augment the data to include team name

        connectionData.forEach(function (item, index) {
          //        console.log(item, index);
          item.ownedTeams.forEach(function (item2, index2) {
            //         console.log(item2, index2);
            let targetTeam = (0, _filter.default)(teams, team => {
              return team.id === item2.teamId;
            });

            if (targetTeam.length > 0) {
              item2.teamName = targetTeam[0].name;
            } else {
              item2.teamName = "<unk>";
            }
          });
          item.memberOfTeams.forEach(function (item2, index2) {
            //         console.log(item2, index2);
            let targetTeam = (0, _filter.default)(teams, team => {
              return team.id === item2.teamId;
            });

            if (targetTeam.length > 0) {
              item2.teamName = targetTeam[0].name;
            } else {
              item2.teamName = "<unk>";
            }
          });
        });
        state.data.connectionsForManagement.set('content', connectionData);
        state.data.connectionsForManagement.set('totalCount', action.connections.count);
        return state;

      case 'UPDATE_GENERATED_PASSWORDS':
        {
          state.data.generatedPasswords.set('content', action.generatedPasswords);
          return state;
        }

      case 'UPDATE_TEAMS':
        {
          const teams = (0, _map.default)(action.teams, item => {
            return Ember.Object.extend({
              state: state,
              isSelected: Ember.computed('state.data.teams.{selected.[],selected}', function () {
                return this.get('state.data.teams.selected').includes(this.id);
              })
            }).create(item);
          });
          const teamsForConnectionManagement = (0, _map.default)(action.teams, item => {
            return Ember.Object.extend({
              state: state,
              isSelected: Ember.computed('state.data.teamsForConnectionManagement.{selected.[],selected}', function () {
                return this.get('state.data.teamsForConnectionManagement.selected').includes(this.id);
              })
            }).create(item);
          });
          state.data.teamsForConnectionManagement.set('content', teamsForConnectionManagement);
          state.data.teams.set('content', teams);
          return state;
        }

      case 'SELECT_LABELS':
        state.set('data.labels.selected', action.labels);
        return state;

      case 'SELECT_STRENGTHS':
        state.set('data.passwords.strengthsFilter', action.strengths);
        return state;

      case 'SELECT_PASSWORDS':
        state.set('data.passwords.selected', action.passwords);
        return state;

      case 'SELECT_SENT_INVITES':
        state.set('data.sentInvites.selected', action.invites);
        return state;

      case 'SELECT_RECEIVED_INVITES':
        state.set('data.receivedInvites.selected', action.invites);
        return state;

      case 'SELECT_CONNECTIONS_FOR_MANAGEMENT':
        state.set('data.connectionsForManagement.selected', action.connections);
        return state;

      case 'SET_SELECTED_TEAMS':
        state.set('data.teams.selected', action.teams);
        return state;

      case 'SET_SELECTED_TEAMS_FOR_CONNECTION_MANAGEMENT':
        state.set('data.teamsForConnectionManagement.selected', action.teams);
        return state;

      case 'SET_DECRYPTED_DB_KEY':
        state.setProperties({
          decryptedDbKey: action.decryptedDbKey
        });
        return state;

      case 'RESET_PROFILE_URL':
        state.setProperties({
          resetProfileUrl: _miscUtils.uniqID.get("t")
        });
        return state;

      case 'LOGIN_STATE':
        state.setProperties({
          loggedIn: action.loggedIn
        });
        return state;

      case 'OOB_STATE':
        state.setProperties({
          oobValid: action.oobValid
        });
        return state;

      case 'SET_LOGIN_KEYS':
        state.setProperties({
          decryptedDbKey: action.keys.decryptedDbKey,
          passphrase: action.keys.passphrase,
          passphraseCheck: action.keys.passphraseCheck,
          publicKey: action.keys.publicKey,
          decryptedPrivateKey: action.keys.decryptedPrivateKey
        });
        return state;

      case 'SET_PASSPHRASE_CHECK':
        state.setProperties({
          passphraseCheck: action.passphraseCheck
        });
        return state;

      case 'SET_PUBLIC_KEY':
        state.setProperties({
          publicKey: action.publicKey
        });
        return state;

      case 'SET_ENCRYPTED_PRIVATE_KEY':
        state.setProperties({
          encryptedPrivateKey: action.encryptedPrivateKey
        });
        return state;

      case 'SET_DECRYPTED_PRIVATE_KEY':
        state.setProperties({
          decryptedPrivateKey: action.decryptedPrivateKey
        });
        return state;

      case 'REGISTRATION_SUCCESSFUL':
        state.setProperties({
          'registrationSuccessful': true
        });
        return state;

      case 'REGISTRATION_SUCCESSFUL_RESET':
        // state.setPropert
        return state;

      case 'SET_PASSPHRASE':
        state.setProperties({
          passphrase: action.passphrase
        });
        return state;

      case 'SET_USERNAME':
        state.setProperties({
          username: action.username
        });
        return state;

      case 'UPDATE_ORGANIZATION_LIST':
        state.setProperties({
          companyData: action.companyData
        }); // Recompute companyPermissions

        var companyData = Array.from(state.get('companyData')); // Need to process company data

        for (let i = 0; i < companyData.length; i++) {
          //console.log(companyData[i]);
          let thisCompany = companyData[i];

          if (thisCompany.working === true) {
            let perms = thisCompany.permissions;

            if (perms) {
              var permsAsMap = new Map(Object.entries(perms));
              var replacements = new Map();

              for (let _ref of permsAsMap) {
                var _ref2 = _slicedToArray(_ref, 2);

                let key = _ref2[0];
                let value = _ref2[1];
                replacements.set(key, value);
              }

              state.setProperties({
                companyPermissions: replacements
              });
            }
          }
        }

        return state;

      case 'UPDATE_SETTINGS':
        {
          //  let settings = cloneDeep(state.get('settings'));
          //console.log('SETTINGS',action.settings)
          // settings.setProperties(action.settings);
          state.setProperties({
            settings: action.settings
          });
          return state;
        }

      case 'SET_HAS_SYSTEM_DEFAULT_IMAGE':
        {
          const settings = (0, _cloneDeep.default)(state.get('settings'));
          Ember.set(settings, 'hasSystemDefaultImage', action.hasSystemDefaultImage);
          state.setProperties({
            settings: settings
          });
          return state;
        }

      case 'SET_CURRENT_SETTINGS_SECTION':
        {
          state.setProperties({
            settingsSection: action.settingsSection
          });
          return state;
        }

      case 'SET_CURRENT_DASHBOARD_SECTION':
        {
          state.setProperties({
            dashboardSection: action.dashboardSection
          });
          return state;
        }

      case 'SET_CURRENT_HEADER_BUTTON':
        {
          state.setProperties({
            selectedHeaderButton: action.selectedHeaderButton
          }); // testing state.set('data.invitationCount', 10);

          return state;
        }

      case 'LOGIN_START':
        state.setProperties({
          username: action.username,
          password: action.password
        });
        return state;

      case 'SET_ACCOUNT_DETAILS':
        state.setProperties({
          loggedIn: action.data.loggedIn,
          oobValid: action.data.oobValid,
          username: action.data.username,
          name: action.data.name.fullName,
          firstName: action.data.name.firstName,
          lastName: action.data.name.lastName,
          emailAddress: action.data.emailAddress,
          userId: action.data.userId,
          searchable: action.data.searchable,
          nickname: action.data.nickname,
          sharingName: action.data.sharingName,
          registrationDate: action.data.registrationDate,
          sso: action.data.sso,
          statusToken: action.data.statusToken,
          securityCode: action.data.securityCode,
          lastPasswordChangeDate: action.data.lastPasswordChange,
          pendingEmailChange: action.data.pendingEmailChange,
          mustUpdatePassword: action.data.mustUpdatePassword ? true : false
        });
        return state;

      case 'SET_GA_BACKUP_TOKENS':
        state.setProperties({
          gaBackupTokens: action.gaBackupTokens
        });
        return state;

      case 'SET_ACCOUNT_LIMITS':
        {
          const subscriptionItems = state.get('subscription');

          const match = function match(name) {
            return function (item) {
              return item["name"] === name;
            };
          };

          const lookup = (0, _flowRight.default)((0, _partial.default)(_find.default, action.limits), match);
          subscriptionItems.set('limits.maxPasswordEntryCount', lookup("Allowed Passwords").limit);
          subscriptionItems.set('limits.usedPasswordEntryCount', lookup("Allowed Passwords").used);
          subscriptionItems.set('limits.maxConnectionsCount', lookup("Allowed Connections").limit);
          subscriptionItems.set('limits.usedConnectionsCount', lookup("Allowed Connections").used);
          subscriptionItems.set('limits.maxGroupsCount', lookup("Allowed Groups").limit);
          subscriptionItems.set('limits.usedGroupsCount', lookup("Allowed Groups").used);
          subscriptionItems.set('limits.maxNoteSize', lookup("Max Notes Size").limit);
          subscriptionItems.set('limits.maxExtraSize', lookup("Max Extra Size").limit);
          subscriptionItems.set('limits.maxUserUpgradePacks', lookup("Allowed 10 Shared User Upgrade Packs").limit);
          subscriptionItems.set('limits.usedUserUpgradePacks', lookup("Allowed 10 Shared User Upgrade Packs").used);
          return state;
        }

      case 'INITIALIZE_ORGANIZATION_DETAILS':
        state.setProperties({
          orgDetails: action.details
        });
        return state;

      case 'UPDATE_ORGANIZATION_LICENSES':
        state.setProperties({
          orgLicenses: action.licenses,
          orgNotLicensed: action.notLicensed
        });
        return state;

      case 'UPDATE_ORGANIZATION_SETTINGS':
        state.setProperties({
          orgSettings: action.data
        });
        return state;

      case 'INITIALIZE_SUBSCRIPTION_SUMMARY':
        state.setProperties({
          nextSubscription: action.nextSubscription,
          subscriptionSummary: action.subscriptionSummary
        });
        return state;

      case 'INITIALIZE_ACCOUNT_LICENSE_SUMMARY':
        state.setProperties({
          licenseSummary: action.licenseSummary
        });
        return state;

      case 'INITIALIZE_OOB_OPTIONS_SUCCESS':
        state.setProperties({
          oobPreferences: action.oobPreferences
        });
        return state;

      case 'INITIALIZE_OOB_OPTIONS_FAILURE':
        state.setProperties({
          mustUpdatePassword: action.mustUpdatePassword
        });
        return state;

      case 'RETRIEVE_USER_SUCCESS':
        state.setProperties({
          retrieveUserSuccess: true
        });
        return state;

      case 'RETRIEVE_USER_SUCCESS_RESET':
        state.setProperties({
          retrieveUserSuccess: false
        });
        return state;

      case 'SET_EMAIL_INVALID':
        state.setProperties({
          isEmailValid: false
        });
        return state;

      case 'VERIFY_PASSWORD_RESET_SUCCESS':
        state.setProperties({
          verifyPasswordResetSuccess: true
        });
        return state;

      case 'SET_PASSWORD_RESET_SUCCESS':
        state.setProperties({
          passwordResetRequestSuccessful: action.passwordResetRequestSuccessful
        });
        return state;

      case 'RESET_VERIFY_PASSWORD_RESET':
        state.setProperties({
          verifyPasswordResetSuccess: false
        });
        return state;

      case 'EMAIL_VERIFICATION_REQUIRED':
        state.setProperties({
          'emailVerificationRequired': true
        });
        return state;

      case 'EMAIL_VERIFICATION_SENT':
        state.setProperties({
          'emailVerificationSent': true
        });
        return state;

      case 'EMAIL_VERIFICATION_REQUIRED_RESET':
        state.setProperties({
          'emailVerificationRequired': false
        });
        return state;

      case 'EMAIL_VERIFICATION_SENT_RESET':
        state.setProperties({
          'emailVerificationSent': false
        });
        return state;

      case 'SET_LOCKTIME':
        {
          Ember.set(state.settings, 'lockTime.effective', action.lockTime);
          return state;
        }

      case 'SET_SYSTEM_MESSAGES':
        {
          state.setProperties({
            systemMessages: action.systemMessages
          });
          return state;
        }

      case 'INITIALIZE_EXTRA_INVOICE_INFO':
        state.setProperties({
          extraInvoiceInfo: Ember.Object.create(action.extraInvoiceInfo)
        });
        return state;

      case 'SET_LOGOUTTIME':
        {
          Ember.set(state.settings, 'logoutTime.effective', action.logoutTime);
          return state;
        }

      case 'SET_VALIDATE_DIALOG_LOADING':
        state.setProperties({
          isValidateDialogLoading: action.isValidateDialogLoading
        });
        return state;

      case 'SET_ACTIVE_SESSIONS':
        state.setProperties({
          sessions: action.activeSessions
        });
        return state;

      default:
        log('uncaught action', type, action);
        return state;
    }
  }
});