define("web-client/components/security-stat-chart/component", ["exports", "d3-selection", "d3-scale", "d3-interpolate", "d3-array", "d3-collection", "d3-shape", "d3-transition", "d3-format", "d3-axis"], function (_exports, _d3Selection, _d3Scale, _d3Interpolate, _d3Array, _d3Collection, _d3Shape, _d3Transition, _d3Format, _d3Axis) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function dashboard(id, fData, schema) {
    var barColor = '#001c53'; //function segColor(c){ return {vweak:"#B71C1C",weak:"#F57C00", strong:"#43A047",vstrong:"#2E7D32"}[c]; }

    function segColor(index) {
      return schema[index].color;
    } // compute total for each segment.


    fData.forEach(function (d) {
      d.total = (0, _d3Array.sum)(d.freq);
    }); // function to handle histogram.

    function histoGram(fD) {
      var hG = {},
          hGDim = {
        t: 60,
        r: 0,
        b: 30,
        l: 0
      };
      hGDim.w = 200 - hGDim.l - hGDim.r, hGDim.h = 300 - hGDim.t - hGDim.b; //create svg for histogram.

      var hGsvg = (0, _d3Selection.select)(id).append("svg").attr("width", hGDim.w + hGDim.l + hGDim.r).attr("height", hGDim.h + hGDim.t + hGDim.b).append("g").attr("transform", "translate(" + hGDim.l + "," + hGDim.t + ")"); // create function for x-axis mapping.

      var x = _d3Scale.default.scaleBand().rangeRound([0, hGDim.w]).padding([0.1]).domain(fD.map(function (d) {
        return d[0];
      })); // Add x-axis to the histogram svg.


      hGsvg.append("g").attr("class", "x axis").attr('fill', 'white').attr("transform", "translate(0," + hGDim.h + ")").call(_d3Axis.default.axisBottom(x)); // Create function for y-axis map.

      var y = _d3Scale.default.scaleLinear().range([hGDim.h, 0]).domain([0, (0, _d3Array.max)(fD, function (d) {
        return d[1];
      })]); // Create bars for histogram to contain rectangles and freq labels.


      var bars = hGsvg.selectAll(".bar").data(fD).enter().append("g").attr("class", "bar"); //create the rectangles.

      bars.append("rect").attr("x", function (d) {
        return x(d[0]);
      }).attr("y", function (d) {
        return y(d[1]);
      }).attr("width", x.bandwidth()).attr("height", function (d) {
        return hGDim.h - y(d[1]);
      }).attr('fill', barColor).on("mouseover", mouseover) // mouseover is defined below.
      .on("mouseout", mouseout); // mouseout is defined below.
      //Create the frequency labels above the rectangles.

      bars.append("text").text(function (d) {
        return (0, _d3Format.format)(",")(d[1]);
      }).attr("x", function (d) {
        return x(d[0]) + x.bandwidth() / 2;
      }).attr("y", function (d) {
        return y(d[1]) - 5;
      }).attr('fill', 'white').attr("text-anchor", "middle");

      function mouseover(d) {
        // utility function to be called on mouseover.
        // filter for selected segment.
        var st = fData.filter(function (s) {
          return s.segment == d[0];
        })[0],
            nD = (0, _d3Collection.keys)(st.freq).map(function (s) {
          return {
            type: s,
            freq: st.freq[s]
          };
        }); // call update functions of pie-chart and legend.

        pC.update(nD);
        leg.update(nD);
      }

      function mouseout()
      /*d*/
      {
        // utility function to be called on mouseout.
        // reset the pie-chart and legend.
        pC.update(tF);
        leg.update(tF);
      } // create function to update the bars. This will be used by pie-chart.


      hG.update = function (nD, color) {
        // update the domain of the y-axis map to reflect change in frequencies.
        y.domain([0, (0, _d3Array.max)(nD, function (d) {
          return d[1];
        })]); // Attach the new data to the bars.

        var bars = hGsvg.selectAll(".bar").data(nD); // transition the height and color of rectangles.

        bars.select("rect").transition().duration(200).attr("y", function (d) {
          return y(d[1]);
        }).attr("height", function (d) {
          return hGDim.h - y(d[1]);
        }).attr("fill", color); // transition the frequency labels location and change value.

        bars.select("text").transition().duration(200).text(function (d) {
          return (0, _d3Format.format)(",")(d[1]);
        }).attr("y", function (d) {
          return y(d[1]) - 5;
        });
      };

      return hG;
    } // function to handle pieChart.


    function pieChart(pD) {
      var pC = {},
          pieDim = {
        w: 180,
        h: 180
      };
      pieDim.r = Math.min(pieDim.w, pieDim.h) / 2; // create svg for pie chart.

      var piesvg = (0, _d3Selection.select)(id).append("svg").attr("width", pieDim.w).attr("height", pieDim.h).append("g").attr("transform", "translate(" + pieDim.w / 2 + "," + pieDim.h / 2 + ")"); // create function to draw the arcs of the pie slices.

      var arc1 = (0, _d3Shape.arc)().outerRadius(pieDim.r - 10).innerRadius(0); // create a function to compute the pie slice angles.

      var pie1 = (0, _d3Shape.pie)().sort(null).value(function (d) {
        return d.freq;
      }); // Draw the pie slices.

      piesvg.selectAll("path").data(pie1(pD)).enter().append("path").attr("d", arc1).each(function (d) {
        this._current = d;
      }).style("fill", function (d) {
        return segColor(d.data.index);
      }).on("mouseover", mouseover).on("mouseout", mouseout); // create function to update pie-chart. This will be used by histogram.

      pC.update = function (nD) {
        piesvg.selectAll("path").data(pie1(nD)).transition().duration(200).attrTween("d", arcTween);
      }; // Utility function to be called on mouseover a pie slice.


      function mouseover(d) {
        // call the update function of histogram with new data.
        hG.update(fData.map(function (v) {
          return [v.segment, v.freq[d.data.index]];
        }), segColor(d.data.index));
      } //Utility function to be called on mouseout a pie slice.


      function mouseout()
      /*d*/
      {
        // call the update function of histogram with all data.
        hG.update(fData.map(function (v) {
          return [v.segment, v.total];
        }), barColor);
      } // Animating the pie-slice requiring a custom function which specifies
      // how the intermediate paths should be drawn.


      function arcTween(a) {
        var i = (0, _d3Interpolate.interpolate)(this._current, a);
        this._current = i(0);
        return function (t) {
          return arc1(i(t));
        };
      }

      return pC;
    } // function to handle legend.


    function legend(lD) {
      var leg = {}; // create table for legend.

      var legend = (0, _d3Selection.select)(id).append("table").attr('class', 'legend'); // create one row per segment.

      var tr = legend.append("tbody").selectAll("tr").data(lD).enter().append("tr"); // create the first column for each segment.

      tr.append("td").append("svg").attr("width", '16').attr("height", '16').append("rect").attr("width", '16').attr("height", '16').attr("fill", function (d) {
        return segColor(d.index);
      }); // create the second column for each segment.

      tr.append("td").text(function (d) {
        return d.name;
      }); // create the third column for each segment.

      tr.append("td").attr("class", 'legendFreq').text(function (d) {
        return (0, _d3Format.format)(",")(d.freq);
      }); // Utility function to be used to update the legend.

      leg.update = function (nD) {
        // update the data attached to the row elements.
        var l = legend.select("tbody").selectAll("tr").data(nD); // update the frequencies.

        l.select(".legendFreq").text(function (d) {
          return (0, _d3Format.format)(",")(d.freq);
        }); // update the percentage column.

        l.select(".legendPerc").text(function (d) {
          return getLegend(d, nD);
        });
      };

      function getLegend(d, aD) {
        // Utility function to compute percentage.
        return (0, _d3Format.format)("%")(d.freq / (0, _d3Array.sum)(aD.map(function (v) {
          return v.freq;
        })));
      }

      return leg;
    } // calculate total frequency by segment for all segment.


    var tF = schema.map(function (d, index) {
      return {
        index: index,
        name: d.name,
        freq: (0, _d3Array.sum)(fData.map(function (t) {
          return t.freq[index];
        }))
      };
    }); // calculate total frequency by segment for all segment.

    var sF = fData.map(function (d) {
      return [d.segment, d.total];
    });
    var hG = histoGram(sF),
        // create the histogram.
    pC = pieChart(tF),
        // create the pie-chart.
    leg = legend(tF); // create the legend.
  }

  var _default = Ember.Component.extend({
    classNames: ["security-stat-chart"],

    drawChart() {},

    statData: null,

    init() {
      this._super.apply(this, arguments); // this.set('schema',[]);


      this.set('statData', [{
        segment: 'Yours',
        freq: {
          vweak: 0,
          weak: 0,
          strong: 0,
          vstrong: 0
        }
      }]);
    },

    schema: null,
    statsObserver: Ember.on('init', Ember.observer('statData', 'schema', 'schema.[]', function () {
      (0, _d3Selection.select)(this.get('element')).selectAll('*').remove();
      var donutData = this.get('statData');
      var donuts = dashboard(this.get('element'), donutData, this.get('schema'));
      this.set('donuts', donuts);
    })),
    donuts: null,

    didInsertElement() {
      var donutData = this.get('statData');
      var donuts = dashboard(this.get('element'), donutData, this.get('schema'));
      this.set('donuts', donuts);
    }

  });

  _exports.default = _default;
});