define("web-client/components/manage-teams-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/map", "lodash-es/compact", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/groupBy", "lodash-es/toPairs", "lodash-es/pullAt", "lodash-es/concat", "ember-concurrency", "lodash-es/findIndex", "web-client/config/environment", "lodash-es/unionBy", "lodash-es/includes", "web-client/api"], function (_exports, _dialogBase, _map, _compact, _filter, _sortBy, _groupBy, _toPairs, _pullAt, _concat, _emberConcurrency, _findIndex, _environment, _unionBy, _includes, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } }

  function asyncComputed() {
    for (var _len = arguments.length, deps = new Array(_len), _key = 0; _key < _len; _key++) {
      deps[_key] = arguments[_key];
    }

    let taskFn = deps.pop();
    let fn = (0, _emberConcurrency.task)(taskFn).restartable();
    return Ember.computed.apply(void 0, deps.concat([function () {
      let args = deps.map(dep => this.get(dep));
      return fn.perform.apply(fn, _toConsumableArray(args));
    }]));
  }

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    filterText: '',
    selectedItems: null,

    fadeIn(element) {
      element.classList.add('fade-in');
    },

    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      let filteredItems = (0, _filter.default)(this.items, item => {
        return item.name.toLowerCase().includes(this.filterText.toLowerCase());
      });
      let groupedBy = (0, _groupBy.default)(filteredItems, item => {
        return item.role === 1 ? "!!@@My Teams" : item.ownerNick;
      });
      let asObjects = (0, _map.default)(groupedBy, (item, key) => {
        return {
          teamName: key,
          teams: (0, _sortBy.default)(item, "name")
        };
      });
      let myTeams = (0, _compact.default)((0, _pullAt.default)(asObjects, (0, _findIndex.default)(asObjects, teamGroup => {
        return teamGroup.teamName === "!!@@My Teams";
      })));
      let data = myTeams.length ? (0, _concat.default)(myTeams, asObjects) : asObjects;
      return data;
    }),
    items: Ember.computed('reduxStore.state.data.teams.content', 'reduxStore.state.data.teams.content.[]', function () {
      return (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), team => {
        return team.name !== 'Default' && !team.individual;
      });
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('selectedItems', Ember.A([]));
    },

    addNewLabel: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let args = {
        newLabel: "",
        onComplete: deferred
      };
      this.standardDialogs.showDialog('label-dialog', args);
      yield deferred.promise;
    }).drop(),
    addNewTeam: (0, _emberConcurrency.task)(function* (currentValues) {
      let deferred = Ember.RSVP.defer();
      let args = {
        newLabel: "",
        onComplete: deferred
      };
      this.standardDialogs.showDialog('create-team-dialog', args);
      yield deferred.promise;
    }).drop(),
    deleteSelectedTeams: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.standardDialogs.showConfirmDialog('confirm-delete-teams', true).then(() => {
        this.loading.show('Deleting Team');
        (0, _api.deleteTeams)([this.team.id]).then(() => {
          this.reduxStore.state.data.teams.selected.removeObject(this.team.id);
          return (0, _api.getTeams)();
        }).then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_TEAMS',
            teams: data
          });
          return this.globalActions.getPasswords();
        }).then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: data
          });
          this.loading.dismiss();
          deferred.resolve();
          this.send('remove');
        }).catch(data => {
          deferred.resolve();
          this.loading.dismiss();

          if (data !== 'cancelled') {
            deferred.resolve();
            this.globalActions.actionUIErrorHandler(data);
          }
        });
      }).catch(() => {
        deferred.resolve();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      setItemSelectedState(itemId, newState) {
        if (newState) {
          if (!(0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.pushObject(itemId);
          }
        } else {
          if ((0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.removeObject(itemId);
          }
        }
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.resolve();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});