define("web-client/reducers/subscription", ["exports", "lodash-es/parseInt", "lodash-es/isNaN"], function (_exports, _parseInt, _isNaN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    limits: Ember.Object.extend({
      maxPasswordEntryCount: 0,
      usedPasswordEntryCount: 0,
      maxConnectionsCount: 0,
      usedConnectionsCount: 0,
      maxGroupsCount: 0,
      usedGroupsCount: 0,
      maxNoteSize: 0,
      maxExtraSize: 0,
      connectionsUsagePercent: Ember.computed('usedConnectionsCount', 'maxConnectionsCount', function () {
        var result = (0, _parseInt.default)(this.usedConnectionsCount) / (0, _parseInt.default)(this.maxConnectionsCount);
        return (0, _isNaN.default)(result) ? 0 : result <= 1 ? result : 1;
      }),
      passwordEntriesUsagePercent: Ember.computed('usedPasswordEntryCount', 'maxPasswordEntryCount', function () {
        var result = (0, _parseInt.default)(this.usedPasswordEntryCount) / (0, _parseInt.default)(this.maxPasswordEntryCount);
        return (0, _isNaN.default)(result) ? 0 : result <= 1 ? result : 1;
      }),
      groupsUsagePercent: Ember.computed('usedGroupsCount', 'maxGroupsCount', function () {
        var result = (0, _parseInt.default)(this.usedGroupsCount) / (0, _parseInt.default)(this.maxGroupsCount);
        return (0, _isNaN.default)(result) ? 0 : result <= 1 ? result : 1;
      })
    }).create()
  }).create();

  _exports.default = _default;
});