define("web-client/components/packing-key-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFLaJ1+C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"auth-body\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"auth-header\"],[9],[0,\"\\n    \"],[1,[29,\"t\",[\"Dialog.EnterPassphrase.Heading.Text\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"nb-form\",[[29,\"action\",[[24,0,[]],\"blockSubmit\"],[[\"on\"],[\"submit\"]]]],null,{\"statements\":[[0,\"    \"],[7,\"ion-grid\"],[9],[0,\"\\n      \"],[7,\"ion-row\"],[9],[0,\"\\n        \"],[7,\"ion-col\"],[9],[0,\"\\n\\n          \"],[1,[29,\"ion-nice-input\",null,[[\"autocomplete\",\"label\",\"title\",\"hint\",\"type\",\"autocomplete\",\"showErrors\",\"error\",\"value\",\"spellcheck\"],[\"new-password\",[29,\"t\",[\"Dialog.EnterPassphrase.Packingkey.Text\"],null],[29,\"t\",[\"Dialog.EnterPassphrase.Packingkey.Title\"],null],[29,\"t\",[\"Dialog.EnterPassphrase.Packingkey.Title\"],null],\"password\",\"new-password\",[25,[\"showPassphraseErrors\"]],[25,[\"validations\",\"attrs\",\"passphrase\",\"messages\"]],[25,[\"passphrase\"]],\"false\"]]],false],[0,\"\\n\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"ion-row\"],[9],[0,\"\\n        \"],[7,\"ion-col\"],[9],[0,\"\\n          \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\",\"expand\"],[\"Unlock\",\"Unlock\",[29,\"perform\",[[25,[\"submitPassphrase\"]]],null],\"block\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"ion-row\"],[9],[0,\"\\n        \"],[7,\"ion-col\"],[9],[0,\"\\n          \"],[1,[29,\"nb-button\",null,[[\"expand\",\"text\",\"title\",\"on-tap\"],[\"block\",\"Sign Out\",\"Sign Out\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/packing-key-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});