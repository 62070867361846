define("web-client/utils/import/import", ["exports", "lodash-es/isEqual", "lodash-es/first", "lodash-es/has", "lodash-es/toArray", "lodash-es/isObject", "lodash-es/flowRight", "lodash-es/sortBy", "lodash-es/zipObject", "lodash-es/range", "lodash-es/size", "lodash-es/map", "lodash-es/merge", "lodash-es/partialRight", "lodash-es/transform", "lodash-es/includes", "lodash-es/partial", "lodash-es/tail", "lodash-es/isUndefined", "lodash-es/find", "lodash-es/compact", "lodash-es/split", "papaparse", "web-client/utils/misc-utils"], function (_exports, _isEqual, _first, _has, _toArray, _isObject, _flowRight, _sortBy, _zipObject, _range, _size, _map, _merge, _partialRight, _transform, _includes, _partial, _tail, _isUndefined, _find, _compact, _split, _papaparse, _miscUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseInput = _exports.ImportTypes = _exports.checkInputType = void 0;

  function is1Password(firstRow) {
    return (0, _isEqual.default)(firstRow, ["title", "notes", "username", "password", "url"]) ? ["1Password"] : undefined;
  }

  function isTrueKey(firstRow) {
    return (0, _isEqual.default)(firstRow, ["kind", "autologin", "cardholder", "city", "company", "country", "dateOfBirth", "deliveryPlace", "document_content", "email", "expirationDate", "expiryDate", "favorite", "firstName", "gender", "hexColor", "issueDate", "issuedDate", "lastName", "login", "memberId", "memberSince", "memo", "name", "note", "number", "password", "phoneNumber", "protectedWithPassword", "state", "street", "streetNumber", "subdomainOnly", "telephone", "title", "type", "url", "website", "zipCode", "tk_export_version"]) ? ["TrueKey"] : undefined;
  }

  function isLastPass(firstRow) {
    return (0, _isEqual.default)(firstRow, ["url", "username", "password", "extra", "name", "grouping", "fav"]) ? ["Lastpass"] : undefined;
  }

  function isKeepass(firstRow) {
    return (0, _isEqual.default)(firstRow, ["Account", "Login Name", "Password", "Web Site", "Comments"]) ? ["Keepass", "Generic CSV"] : undefined;
  }

  function isNorton(firstRow) {
    return (0, _isEqual.default)(firstRow, ["url", "username", "password", "name", "grouping", "extra"]) ? ["Norton Identity Safe"] : undefined;
  }

  function isPasswordWallet(firstRow) {
    return (0, _isEqual.default)((0, _first.default)(firstRow, 5), ["Account", "Login Name", "Password", "Web Site", "Comments"]) ? ["Password Wallet 4"] : undefined;
  }

  function isDashlane(firstRow) {
    return firstRow.length === 5 && !isKeepass(firstRow) && !is1Password(firstRow) ? ["Dashlane", "Generic CSV"] : undefined;
  }

  function isKeeper(firstRow, delimiter) {
    return firstRow.length === 7 && !isLastPass(firstRow) && delimiter === "\t" ? ["Keeper", "Generic CSV"] : undefined;
  }

  function isPasspackVer7(firstRow, delimiter) {
    return firstRow.length === 7 && !isLastPass(firstRow) && delimiter === "," ? ["Passpack Ver. 7"] : undefined;
  }

  function isPasspackVer8(firstRow
  /*, delimiter*/
  ) {
    return (0, _isEqual.default)(firstRow, ["Entry Name", "URL", "User ID", "Password", "Notes", "Shared Notes", "Tags", "Extra Fields"]) ? ["Passpack Ver. 8"] : undefined;
  }

  function isClipperZ(anObject) {
    return anObject != null && anObject.length >= 1 && anObject[0]['currentVersion'] != null ? ["Clipperz"] : undefined;
  }

  function isFsecure(anObject) {
    return (0, _has.default)(anObject, "data") && (0, _has.default)((0, _toArray.default)(anObject.data)[0], "url") ? ["F-Secure"] : undefined;
  }

  function isGenericCSV(firstRow) {
    return firstRow.length >= 3 ? ["Generic CSV"] : undefined;
  }

  function isNothing()
  /*anObject*/
  {
    return [];
  }

  function JSON(inputText) {
    try {
      var obj = JSON.parse(inputText);

      if ((0, _isObject.default)(obj)) {
        return obj;
      }
    } catch (e) {
      return undefined;
    }
  }

  var checkJSONInputTypes = (0, _flowRight.default)((0, _miscUtils.dispatch)(isClipperZ, isFsecure), JSON);

  var checkCSVInputType = function checkCSVInputType(inputText) {
    var checkType = (0, _miscUtils.dispatch)(isPasspackVer7, isPasspackVer8, isPasswordWallet, isNorton, isKeeper, isDashlane, is1Password, isKeepass, isLastPass, isTrueKey, isGenericCSV, isNothing);

    var parsedContents = _papaparse.default.parse(inputText, {
      header: true,
      skipEmptyLines: true
    });

    return checkType(parsedContents.meta.fields, parsedContents.meta.delimiter);
  };

  var checkInputType = (0, _miscUtils.dispatch)(checkJSONInputTypes, checkCSVInputType);
  _exports.checkInputType = checkInputType;
  var ImportTypes = (0, _sortBy.default)(["Generic CSV", "Passpack Ver. 7", "Passpack Ver. 8", "TrueKey", "Lastpass", "Dashlane", "Keepass", "F-Secure", "1Password", "Clipperz", "Keeper", "Norton Identity Safe", "Password Wallet 4", "mSecure"], function (str) {
    return str.toLocaleLowerCase();
  });
  _exports.ImportTypes = ImportTypes;

  function parseIt(inputText) {
    var rows = [];

    try {
      var parsedContents = _papaparse.default.parse(inputText, {
        header: false,
        skipEmptyLines: true
      }).data;

      return parsedContents;
    } catch (e) {
      return rows; // Handle Error in parsing.
    }
  }

  var normalizeURL = function normalizeURL(url) {
    let newURL = '';

    try {
      newURL = new URL(url).href;
    } catch (e) {
      try {
        newURL = new URL('https://' + url).href;
      } catch (e) {
        newURL = "";
      }
    }

    try {
      decodeURI(newURL);
      return (0, _miscUtils.isValidURL)(newURL) ? newURL : "";
    } catch (e) {
      return encodeURI(unescape(newURL));
    }
  };

  function inputTypeAction(type, action) {
    return function (input) {
      if (type === input.type) {
        return action(input.text);
      }
    };
  }

  function clipperzToCSV(clipperzJSON) {
    try {
      var desiredColumns = ["label", "Web address", "Username or email", "Password", "notes"];

      var blankRecord = function blankRecord() {
        return (0, _zipObject.default)(desiredColumns, (0, _range.default)((0, _size.default)(desiredColumns)).map(function () {
          return '';
        }));
      };

      var createRecord = function createRecord(record) {
        return (0, _merge.default)(blankRecord(), record);
      };

      var getValues = (0, _partialRight.default)(_transform.default, function (result, property) {
        if ((0, _includes.default)(desiredColumns, property["label"])) {
          result[property["label"]] = property["value"];
        }
      });

      var parseEntry = function parseEntry(entry) {
        var mergeRecordAndValues = (0, _flowRight.default)(createRecord, (0, _partial.default)(_merge.default, {
          label: entry["label"],
          notes: entry["data"]["notes"]
        }));
        return mergeRecordAndValues(getValues(entry['currentVersion']["fields"]));
      };

      var records = (0, _map.default)(clipperzJSON, parseEntry);

      var join = function join(item) {
        return item.join(",");
      };

      var quoteItems = (0, _partialRight.default)(_map.default, function (r) {
        return '"' + r + '"';
      });
      var recordToCSV = (0, _flowRight.default)(join, quoteItems, _toArray.default);
      records.unshift(desiredColumns);
      return (0, _map.default)(records, recordToCSV).join("\n");
    } catch (e) {
      return undefined;
    }
  }

  function setupDisplay(columnMapping, deleteFirst, rows) {
    var entries = deleteFirst ? (0, _tail.default)(rows) : rows;
    return (0, _map.default)(entries, function (item) {
      var tags = [];

      if (!(0, _isUndefined.default)((0, _find.default)(columnMapping, {
        columnType: "Tags"
      }))) {
        var extractor = (0, _find.default)(columnMapping, {
          columnType: "Tags"
        }).extractor;
        tags = extractor(item[(0, _find.default)(columnMapping, {
          columnType: "Tags"
        }).column]);
      }

      var extra = [];

      if (!(0, _isUndefined.default)((0, _find.default)(columnMapping, {
        columnType: "Extra"
      }))) {
        var extractorx = (0, _find.default)(columnMapping, {
          columnType: "Extra"
        }).extractor;
        extra = extractorx(item[(0, _find.default)(columnMapping, {
          columnType: "Extra"
        }).column]);
      }

      return {
        name: item[(0, _find.default)(columnMapping, {
          columnType: "Entry Name"
        }).column] ? item[(0, _find.default)(columnMapping, {
          columnType: "Entry Name"
        }).column] : "Unnamed",
        username: item[(0, _find.default)(columnMapping, {
          columnType: "User Name"
        }).column] ? item[(0, _find.default)(columnMapping, {
          columnType: "User Name"
        }).column] : "",
        password: item[(0, _find.default)(columnMapping, {
          columnType: "Password"
        }).column] ? item[(0, _find.default)(columnMapping, {
          columnType: "Password"
        }).column] : "",
        note: item[(0, _find.default)(columnMapping, {
          columnType: "Note"
        }).column] ? item[(0, _find.default)(columnMapping, {
          columnType: "Note"
        }).column] : "",
        url: item[(0, _find.default)(columnMapping, {
          columnType: "URL"
        }).column] ? normalizeURL(item[(0, _find.default)(columnMapping, {
          columnType: "URL"
        }).column]) : "",
        email: (0, _find.default)(columnMapping, {
          columnType: "Email"
        }) ? item[(0, _find.default)(columnMapping, {
          columnType: "Email"
        }).column] ? item[(0, _find.default)(columnMapping, {
          columnType: "Email"
        }).column] : "" : "",
        tags: tags,
        extra: extra
      };
    });
  }

  var lpColumnMapping = [{
    column: 0,
    columnType: 'URL'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 3,
    columnType: 'Note'
  }, {
    column: 4,
    columnType: 'Entry Name'
  }];
  var setColumnsLastPass = (0, _partial.default)(setupDisplay, lpColumnMapping, true);
  var trueKeyColumnMapping = [
  /*"kind", 0
  "autologin", 1
  "cardholder", 2
  "city", 3
  "company", 4
  "country", 5
  "dateOfBirth", 6
  "deliveryPlace", 7
  "document_content", 8
  "email", 9
  "expirationDate", 10
  "expiryDate", 11
  "favorite",12
  "firstName",13
  "gender",14
  "hexColor", 15
  "issueDate", 16
  "issuedDate", 17
  "lastName", 18
  "login", 19
  "memberId", 20
  "memberSince", 21
  "memo",22
  "name",23
  "note",24
  "number", 25
  "password", 26
  "phoneNumber", 27
  "protectedWithPassword", 28
  "state", 29
  "street",30
  "streetNumber", 31
  "subdomainOnly", 32
  "telephone",33
  "title", 34
  "type",35
  "url", 36
  "website", 37
  "zipCode", 38
  "tk_export_version" 39
  */
  {
    column: 36,
    columnType: 'URL'
  }, {
    column: 19,
    columnType: 'User Name'
  }, {
    column: 26,
    columnType: 'Password'
  }, {
    column: 24,
    columnType: 'Note'
  }, {
    column: 23,
    columnType: 'Entry Name'
  }];
  var setColumnsTrueKey = (0, _partial.default)(setupDisplay, trueKeyColumnMapping, true);
  var onePasswordColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 2,
    columnType: 'User Name'
  }, {
    column: 3,
    columnType: 'Password'
  }, {
    column: 1,
    columnType: 'Note'
  }, {
    column: 4,
    columnType: 'URL'
  }];
  var setColumns1Password = (0, _partial.default)(setupDisplay, onePasswordColumnMapping, true);
  var keepassColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 3,
    columnType: 'URL'
  }];
  var setColumnsKeepass = (0, _partial.default)(setupDisplay, keepassColumnMapping, true);
  var passwordWalletColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 3,
    columnType: 'URL'
  }];
  var setColumnsPasswordWallet = (0, _partial.default)(setupDisplay, passwordWalletColumnMapping, true);
  var clipperzColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 2,
    columnType: 'User Name'
  }, {
    column: 3,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 1,
    columnType: 'URL'
  }];
  var setColumnsClipperz = (0, _partial.default)(setupDisplay, clipperzColumnMapping, true);
  var dashlaneColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 2,
    columnType: 'User Name'
  }, {
    column: 3,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 1,
    columnType: 'URL'
  }];
  var setColumnsDashlane = (0, _partial.default)(setupDisplay, dashlaneColumnMapping, false);
  var keeperColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 3,
    columnType: 'URL'
  }];
  var setColumnsKeeper = (0, _partial.default)(setupDisplay, keeperColumnMapping, false);
  var nortonColumnMapping = [{
    column: 3,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 5,
    columnType: 'Note'
  }, {
    column: 0,
    columnType: 'URL'
  }];
  var setColumnsNorton = (0, _partial.default)(setupDisplay, nortonColumnMapping, true);
  var passpackVer7ColumnMapping = [//Name                User ID                 Password                 Link                 Tags     Notes   Email
  {
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 5,
    columnType: 'Note'
  }, {
    column: 6,
    columnType: 'Email'
  }, {
    column: 3,
    columnType: 'URL'
  }, {
    column: 4,
    columnType: 'Tags',
    extractor: function extractor(tags) {
      return (0, _compact.default)((0, _map.default)((0, _split.default)(tags, " "), item => {
        if (item.length > 0) {
          return item;
        }
      }));
    }
  }];
  var setColumnsPasspackVer7 = (0, _partial.default)(setupDisplay, passpackVer7ColumnMapping, false);
  var passpackVer8ColumnMapping = [//Name                User ID                 Password                 Link                 Tags     Notes   Email
  {
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 2,
    columnType: 'User Name'
  }, {
    column: 3,
    columnType: 'Password'
  }, {
    column: 4,
    columnType: 'Note'
  }, {
    column: 1,
    columnType: 'URL'
  }, {
    column: 6,
    columnType: 'Tags',
    extractor: function extractor(tags) {
      var tagsObject = window.JSON.parse(tags);
      return (0, _map.default)(tagsObject.tags, function (tag) {
        return window.JSON.parse(tag).tag;
      });
    }
  }, {
    column: 8,
    columnType: "Email"
  }, {
    column: 7,
    columnType: 'Extra',
    extractor: function extractor(extra) {
      var extraObject = window.JSON.parse(extra);
      return (0, _map.default)(extraObject.extraFields, function (field) {
        return window.JSON.parse(field);
      });
    }
  }];
  var setColumnsPasspackVer8 = (0, _partial.default)(setupDisplay, passpackVer8ColumnMapping, true);
  var genericCSVColumnMapping = [{
    column: 0,
    columnType: 'Entry Name'
  }, {
    column: 1,
    columnType: 'User Name'
  }, {
    column: 2,
    columnType: 'Password'
  }, {
    column: 3,
    columnType: 'URL'
  }, {
    column: 4,
    columnType: 'Note'
  }];
  var setColumnsGenericCSV = (0, _partial.default)(setupDisplay, genericCSVColumnMapping, false);
  var parseClipperz = inputTypeAction("Clipperz", (0, _flowRight.default)(setColumnsClipperz, parseIt, clipperzToCSV, JSON));
  var parseFsecure = inputTypeAction("F-Secure", parseIt);
  var parseKeepass = inputTypeAction("Keepass", (0, _flowRight.default)(setColumnsKeepass, parseIt));
  var parseLastpass = inputTypeAction("Lastpass", (0, _flowRight.default)(setColumnsLastPass, parseIt));
  var parse1Password = inputTypeAction("1Password", (0, _flowRight.default)(setColumns1Password, parseIt));
  var parseDashlane = inputTypeAction("Dashlane", (0, _flowRight.default)(setColumnsDashlane, parseIt));
  var parseKeeper = inputTypeAction("Keeper", (0, _flowRight.default)(setColumnsKeeper, parseIt));
  var parseNorton = inputTypeAction("Norton Identity Safe", (0, _flowRight.default)(setColumnsNorton, parseIt));
  var parsePasswordWallet = inputTypeAction("Password Wallet 4", (0, _flowRight.default)(setColumnsPasswordWallet, parseIt));
  var parsePasspackVer7 = inputTypeAction("Passpack Ver. 7", (0, _flowRight.default)(setColumnsPasspackVer7, parseIt));
  var parsePasspackVer8 = inputTypeAction("Passpack Ver. 8", (0, _flowRight.default)(setColumnsPasspackVer8, parseIt));
  var parseGenericCSV = inputTypeAction("Generic CSV", (0, _flowRight.default)(setColumnsGenericCSV, parseIt));
  var parseTrueKey = inputTypeAction("TrueKey", (0, _flowRight.default)(setColumnsTrueKey, parseIt));
  var parseStuff = (0, _miscUtils.dispatch)(parseGenericCSV, parseClipperz, parseFsecure, parseKeepass, parseLastpass, parse1Password, parseDashlane, parseKeeper, parseNorton, parsePasswordWallet, parseTrueKey, parsePasspackVer7, parsePasspackVer8);

  var parseInput = function parseInput(type, data) {
    var parsedContents = parseStuff({
      type: type,
      text: data
    });
    return parsedContents;
  };

  _exports.parseInput = parseInput;
});