define("web-client/components/stat-display-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["stat-display-header"],
    classNameBindings: ["color"],
    color: ''
  });

  _exports.default = _default;
});