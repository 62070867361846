define("web-client/components/settings-list-menu-item/component", ["exports", "ui-core/components/nb-tree-view-item/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['settings-list-menu-item'],
    classNameBindings: ['selected'],
    selected: false,
    attributeBindings: ["touchAction:touch-action"],
    touchAction: "none",

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});