define("web-client/services/init-app-utils/get-oob-options", ["exports", "web-client/api", "lodash-es/eq", "lodash-es/get"], function (_exports, _api, _eq, _get) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getOOBOptions;

  function getOOBOptions() {
    let reduxStore = this.reduxStore;
    return function getOOBOptions(callback) {
      (0, _api.startupLog)({
        actionText: "Checking Multi Factor Options"
      });
      (0, _api.getOOBOptions)().then(data => {
        reduxStore.dispatch({
          type: 'INITIALIZE_OOB_OPTIONS_SUCCESS',
          oobPreferences: Ember.Object.create(data)
        });
        callback();
      }).catch(data => {
        reduxStore.dispatch({
          type: 'INITIALIZE_OOB_OPTIONS_FAILURE',
          mustUpdatePassword: (0, _eq.default)((0, _get.default)(data, "status.code"), 3)
        });
        callback('Failed getOOBOptions');
      });
    };
  }
});