define("web-client/actions/submit-token", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = submitToken;

  function submitToken(securityToken) {
    (0, _api.submitToken)({
      token: securityToken
    }).then(() => {
      this.reduxStore.dispatch({
        type: 'OOB_STATE',
        oobValid: true
      }); //  let reinitializeEnvironment = getOwner(this).lookup('route-action:helpers').compute([ 'reinitializeEnvironment' ]);
      // return reinitializeEnvironment();

      this.initApp.setup();
    }).catch(data => {
      if (data.status.code) {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      } else {
        this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'));
        this.send('cancelOOB');
      }
    });
  }
});