define("web-client/settings/company/subscription/controller", ["exports", "web-client/api", "lodash-es/each", "lodash-es/filter"], function (_exports, _api, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    isUpdating: false,
    showDefaultCardUpdate: false,
    currencyFormatter: {},
    mayViewSubscription: false,
    mayChangeSubscription: false,
    upgradeCredit: 0,
    upgradeRenewalAmount: 0,
    upgradeCostPerSeat: 0,
    upgradeTotalDueAfterTrial: 0,
    checkingOfferStatus: false,
    showBusinessOfferSectionValue: false,
    upgradeTrialEndDate: {},
    upgradeOffer: {},

    init() {
      this._super.apply(this, arguments);

      this.currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      this.mayViewSubscription = this.globalActions.doesPermissionExist('settings', 'company.subscription', 'ro');
      this.mayChangeSubscription = this.globalActions.doesPermissionExist('settings', 'company.subscription', 'rw'); //this.checkUpgradeOffer();
    },

    validLicense: Ember.computed('reduxStore.state.{subscriptionSummary}', function () {
      let expired = this.get('reduxStore.state.subscriptionSummary.expired');
      return !expired;
    }),
    amtPerLicense: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let sku = this.get('reduxStore.state.subscriptionSummary.subscriptionSku');

      if (sku === 'team' || sku === 'business') {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amountPerUser')) + '  per user/year';
      } else {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      }
    }),
    nextInvoiceAmountOriginal: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalAmount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      return originalAmount;
    }),
    nextInvoiceAmount: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalAmount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.amount'));
      let discount = this.get('reduxStore.state.subscriptionSummary.discount');

      if (discount && discount > 0) {
        return this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.discountedAmount'));
      } else {
        return originalAmount;
      }
    }),
    discount: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let discount = this.currencyFormatter.format(this.get('reduxStore.state.subscriptionSummary.discount'));
      return discount;
    }),
    discountName: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let discount = this.get('reduxStore.state.subscriptionSummary.discountName');
      return discount;
    }),
    legacyConvertSubscriptionNextPayment: Ember.computed('reduxStore.state.subscriptionSummary', 'reduxStore.state.nextSubscription', 'reduxStore.state.subscriptionSummary.licensesUsed', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';
        let assigned = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
        let numLicenses = assigned;
        let action = this.get('reduxStore.state.nextSubscription.action');

        if (action === 'cancel') {
          numLicenses = 0;
        } else if (action === 'auto-renew') {
          let count = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
          numLicenses = count;
        } else if (action === 'downgrade-team') {
          let count = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
          numLicenses = count;
        } else if (action === 'fewer') {
          let count = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
          numLicenses = count;
        }

        let estimatedBill = this.currencyFormatter.format(18 * numLicenses);

        try {
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate');
          const date = new Date(nextBillDateOrig);
          conversionDate = this.formatDate(date);
        } catch (error) {
          console.log(error);
        }

        let conversionFromLegacyPlan = this.get('reduxStore.state.nextSubscription.conversionFromLegacyPlan');

        if (conversionFromLegacyPlan) {
          let newDescription = this.get('reduxStore.state.nextSubscription.description');
          return 'Note: Your grandfathered plan, ' + originalDescription + ' (legacy), is set to convert to the ' + newDescription + ' plan on ' + conversionDate + ' unless you set your renewal setting to \"cancel\".';
        } else {
          conversionDate = this.addOneYear(date);
          return 'Note: You have been grandfathered into your existing ' + originalDescription + ' plan for one more billing cycle.  After your next bill you will be automatically subscribed to the Team plan unless you set your renewal setting to \"cancel\".';
        }
      }
    }),
    subscriptionDescription: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      if (this.validLicense) {
        let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

        if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
          return originalDescription;
        } else {
          // Legacy subscription types
          return originalDescription + ' (legacy) plan';
        }
      } else {
        return "Expired";
      }
    }),
    showBusinessOfferSection: Ember.computed('reduxStore.state.subscriptionSummary', 'showBusinessOfferSectionValue', function () {
      if (this.validLicense && !this.checkingOfferStatus) {
        this.checkUpgradeOffer();
      }

      return this.validLicense && this.showBusinessOfferSectionValue;
    }),
    checkUpgradeOffer: function checkUpgradeOffer() {
      this.set('checkingOfferStatus', true);
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');
      let self = this;

      if (originalDescription === 'Business Plan') {
        self.set('showBusinessOfferSectionValue', false);
      } else {
        var enabled = false;
        let deferred = Ember.RSVP.defer();
        (0, _api.getUpgradeOffer)().then(data => {
          if (data) {
            enabled = data.elligible;
            this.set('upgradeOffer', data); // Get the dollar figures

            self.set('upgradeCredit', self.currencyFormatter.format(data.credit));
            self.set('upgradeRenewalAmount=', self.currencyFormatter.format(data.totalSubscriptionRenewalAmount));
            self.set('upgradeCostPerSeat', self.currencyFormatter.format(data.perSeatCost));
            self.set('upgradeTotalDueAfterTrial', self.currencyFormatter.format(data.totalSubscriptionRenewalAmount - data.credit));
          }

          self.set('showBusinessOfferSectionValue', enabled);
          deferred.resolve();
        }).catch(error => {
          console.log(error);
          deferred.reject();
        });
      }

      this.set('checkingOfferStatus', false);
    },
    addOneYear: function addOneYear(date) {
      // Making a copy with the Date() constructor
      const dateCopy = new Date(date);
      dateCopy.setFullYear(dateCopy.getFullYear() + 1);
      return this.formatDate(dateCopy);
    },
    formatDate: function formatDate(date) {
      return date.format('mm/dd/yy');
    },
    planOverviewExtra: Ember.computed('reduxStore.state.subscriptionSummary', function () {
      let originalDescription = this.get('reduxStore.state.subscriptionSummary.description');

      if (originalDescription === 'Team Plan' || originalDescription === 'Business Plan') {
        return '';
      } else {
        // Legacy subscription types
        let conversionDate = '';

        try {
          let nextBillDateOrig = this.get('reduxStore.state.subscriptionSummary.subscriptionRenewalDate');
          const date = new Date(nextBillDateOrig);
          conversionDate = this.formatDate(date);
        } catch (error) {}

        let conversionFromLegacyPlan = this.get('reduxStore.state.nextSubscription.conversionFromLegacyPlan');

        if (conversionFromLegacyPlan) {
          let newDescription = this.get('reduxStore.state.nextSubscription.description');
          return 'Important! Your subscription will be converting from the ' + originalDescription + ' (legacy) to the ' + newDescription + ' plan on ' + conversionDate + '.';
        } else {
          conversionDate = this.addOneYear(date);
          return 'Important! You will be billed one more time at the existing ' + originalDescription + ' (legacy) plan.  Unless you convert earlier you will be automatically converted to the Team Plan on ' + conversionDate + '.';
        }
      }
    }),

    updatePlanData() {
      this.globalActions.updateSubscriptionData();
    },

    availableLicenses: Ember.computed('plans', function () {
      const included = this.get('reduxStore.state.subscriptionSummary.baseLicensesIncluded');
      const extra = this.get('reduxStore.state.subscriptionSummary.totalLicenseCount');
      const used = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
      return included + extra - used;
    }),
    planRenewalAction: Ember.computed('reduxStore.state.{nextSubscription,subscriptionSummary}', function () {
      let action = this.get('reduxStore.state.nextSubscription.action');

      if (action === 'cancel') {
        return "Cancel my contract ";
      } else if (action === 'auto-renew') {
        let count = this.get('reduxStore.state.subscriptionSummary.licensesUsed');
        return "Auto renew my contract with the number of utilized licenses.  Currently: " + count + " license" + (count > 1 ? "s" : "") + ". Renews ";
      } else if (action === 'downgrade-team') {
        return "Downgrade my contract to team ";
      } else if (action === 'fewer') {
        return "Auto renew my contract with fewer licenses ";
      }
    }),
    actions: {
      showBeginBusinessTrialDialog: function showBeginBusinessTrialDialog() {
        this.standardDialogs.showDialog('business-upgrade-trial-dialog', {
          upgradeCredit: this.upgradeCredit,
          upgradeRenewalAmount: this.upgradeRenewalAmount,
          upgradeCostPerSeat: this.upgradeCostPerSeat,
          upgradeTotalDueAfterTrial: this.upgradeTotalDueAfterTrial,
          upgradeTrialEndDate: this.upgradeTrialEndDate,
          upgradeOffer: this.upgradeOffer
        });
      },
      showCancelBusinessTrialDialog: function showCancelBusinessTrialDialog() {
        this.standardDialogs.showDialog('business-cancel-trial-dialog', {});
      },
      changeRenewalSettingsDialog: function changeRenewalSettingsDialog() {
        this.standardDialogs.showDialog('change-account-renewal-settings-dialog', {});
      },
      immediatelyRenewMyExpiredSubscription: function immediatelyRenewMyExpiredSubscription() {
        this.loading.show('Renewing your subscription');
        (0, _api.immediatelyRenewMyExpiredSubscription)().then(() => {
          this.globalActions.updateSubscriptionData();
          this.globalActions.updateOrganizationLicenses();
          window.location.reload(true);
        }).catch(() => {
          this.globalActions.updateSubscriptionData();
          this.globalActions.updateOrganizationLicenses();
          window.location.reload(true);
        });
        self.loading.dismiss();
      }
    }
  });

  _exports.default = _default;
});