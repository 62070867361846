define("web-client/register/business/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/unauthed-route"], function (_exports, _baseRoute, _unauthedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _unauthedRoute.default, {
    init() {
      this._super.apply(this, arguments);
    },

    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.dispatch({
        type: "SET_REGISTRATION_EDITION",
        registrationEdition: "Business"
      });
    },

    renderTemplate() {
      this.render('register/business', {
        into: 'application',
        outlet: 'content'
      });
    }

  });

  _exports.default = _default;
});