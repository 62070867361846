define("web-client/components/ion-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-select',
    classNames: [],
    cancelText: "Cancel",
    compareWith: null,
    disabled: false,
    interface: "alert",
    multiple: false,
    okayText: "Ok",
    placeholder: null,
    selectedText: null,
    value: '',
    parentFocusTrap: null,
    readonly: false,
    attributeBindings: ["tab", "slot", "value", 'placeholder', "cancelText", "disabled", "interface", "selectedText", "okayText", "readonly"],

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.componentOnReady().then(() => {
        this.element.setAttribute('value', this.value);
        this.element.addEventListener('ionChange', () => {
          this.set('value', this.element.value);
        });
        this.element.shadowRoot.querySelector('button').addEventListener('click', e => {
          if (!this.readonly) {
            this.element.open().then(e => {
              e.addEventListener('ionAlertDidDismiss', () => {
                if (this.parentFocusTrap) {
                  this.element.focus();
                  this.parentFocusTrap.unpause();
                }
              });

              if (this.parentFocusTrap) {
                this.parentFocusTrap.pause();
              }
            });
          }

          e.preventDefault();
          e.stopPropagation();
        });
      });
    }
  });

  _exports.default = _default;
});