define("web-client/validators/notrailingleadingspace", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const NoTrailingLeadingSpace = _base.default.extend({
    validate(value, options
    /*, model, attribute */
    ) {
      if (/\s$/.test(value) && !/^\s/.test(value)) {
        return options.message;
      } else {
        return true;
      }
    }

  });

  var _default = NoTrailingLeadingSpace;
  _exports.default = _default;
});