define("web-client/components/loading-dialog/dialog-types/dialog-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    type: 'reading-import-file-loading',
    title: "Dialog.LoadingImportFile.Heading.Text"
  }, {
    type: 'delete-label-loading',
    title: "Dialog.DeletingLabelLoading.Heading.Text"
  }, {
    type: 'signing-out-session',
    title: "Dialog.EditUsername.SigningOut.Text"
  }, {
    type: 'deleting-account',
    title: "Dialog.DeletingAccount.Deleting.Text"
  }, {
    type: 'password-loading',
    title: "Dialog.PassswordLoading.Loading.Text"
  }, {
    type: 'saving-password',
    title: "Dialog.PassswordSaving.Saving.Text"
  }, {
    type: 'syncing-transferred-passwords',
    title: "Dialog.SyncingTransferred.Syncing.Text"
  }, {
    type: 'updating-team-membership',
    title: "Dialog.UpdatingTeamMembership.Updating.Text"
  }, {
    type: 'deleting-team',
    title: "Dialog.DeletingTeamLoading.Updating.Text"
  }, {
    type: 'updating-favorites',
    title: "Dialog.UpdatingFavoritesLoading.Updating.Text"
  }];
  _exports.default = _default;
});