define("web-client/settings/import/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route", "web-client/mixins/progress-handler"], function (_exports, _baseRoute, _authedRoute, _progressHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, _progressHandler.default, {
    i18n: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    subSectionNameTranslation: "Settings.SubHeadings.Import.Text",
    menuController: "app/settings/settings-menu",
    importText: '',
    reduxStore: Ember.inject.service(),

    //  standardDialogs: service(),
    //  subscription: alias('reduxStore.state.subscription'),
    //  rows: alias('reduxStore.state.importData'),
    //  canImport: computed(
    //    'subscription.limits.{maxPasswordEntryCount,usedPasswordEntryCount}',
    //    'rows.selected.content.length',
    //    function () {
    //      return ((this.get("subscription.limits.maxPasswordEntryCount") - this.get('subscription.limits.usedPasswordEntryCount')) >= this.get("rows.selected.content.length"));
    //    }
    //  ),
    activate() {
      this._super.apply(this, arguments);

      this.reduxStore.state.set('contentClass', 'scroll-content');
      this.reduxStore.state.set('contentSlot', '');
      Ember.run.scheduleOnce('afterRender', () => {
        window.dispatchEvent(new Event('resize'));
      });
    },

    deactivate() {
      this._super.apply(this, arguments);
    },

    actions: {
      didTransition() {
        this.reduxStore.dispatch({
          type: "SET_SIDEBAR_TITLE",
          sidebarTitle: "Import"
        });
        this.reduxStore.dispatch({
          type: "SET_CURRENT_SETTINGS_SECTION",
          settingsSection: "Import"
        });
      }

    },

    renderTemplate() {
      this.render('settings/import', {
        outlet: 'content',
        into: 'application'
      });
    }

  });

  _exports.default = _default;
});