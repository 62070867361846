define("web-client/api/database", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initDatabase = void 0;

  /**
   * params
   * databaseName, key, statusToken, privateKey
   */
  const initDatabase = _ipcClient.ipc.makeRequestFunction('initDatabase', 300000);

  _exports.initDatabase = initDatabase;
});