define("web-client/initializers/ember-cli-redux", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'emberCliRedux',
    initialize: function initialize(application) {
      application.inject('controller', 'reduxStore', 'service:redux-store');
    }
  };
  _exports.default = _default;
});