define("web-client/oob/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a5Q/vEt1",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[29,\"security-code-dialog\",null,[[\"submitToken\"],[[29,\"service-action\",[\"global-actions\",\"submitToken\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/oob/template.hbs"
    }
  });

  _exports.default = _default;
});