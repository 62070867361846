define("web-client/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mbmJzDRI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[29,\"sign-in-dialog\",null,[[\"model\",\"queryParameters\",\"resetPassword\",\"goToAbout\",\"goToSupport\",\"goToPasspackSite\",\"goToForums\",\"retrieveUserID\",\"goToLogin\",\"register\",\"clearNotifications\",\"loginToSite\",\"resendVerificationEmail\",\"emailVerificationSent\",\"emailVerificationRequired\"],[[25,[\"model\"]],[25,[\"queryParameters\"]],[29,\"service-action\",[\"global-actions\",\"goToResetPW\"],null],\"goToAbout\",\"goToSupport\",\"goToPasspackSite\",\"goToForums\",\"goToRetrieveUserId\",[29,\"service-action\",[\"global-actions\",\"goToLogin\"],null],[29,\"service-action\",[\"global-actions\",\"goToRegister\"],null],\"clearNotifications\",[29,\"service-action\",[\"global-actions\",\"loginToSite\"],null],[29,\"service-action\",[\"global-actions\",\"resendVerificationEmail\"],null],[25,[\"reduxStore\",\"state\",\"emailVerificationSent\"]],[25,[\"reduxStore\",\"state\",\"emailVerificationRequired\"]]]]],false],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/login/template.hbs"
    }
  });

  _exports.default = _default;
});