define("web-client/components/password-dialog-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ikza0Y8E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[\"Password Action Menu\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"menu-replacement\"],[9],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Transfer Ownership\",\"Transfer Ownership\",[29,\"perform\",[[25,[\"transferOwnership\"]]],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Manage Labels\",\"Manage Labels for password\",[29,\"perform\",[[25,[\"manageLabels\"]]],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Manage Teams\",\"Manage Teams\",[29,\"perform\",[[25,[\"manageTeams\"]]],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Create New Field\",\"Create New Field\",[29,\"perform\",[[25,[\"createExtraField\"]]],null]]]],false],[0,\"\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Delete\",\"Delete Password\",[29,\"perform\",[[25,[\"deletePassword\"]]],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,[[\"small\"],[true]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-dialog-menu/template.hbs"
    }
  });

  _exports.default = _default;
});