define("web-client/components/ion-chip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-chip',
    expand: "",
    fill: undefined,
    size: "",
    classNames: [],
    buttonType: "button",
    disabled: "false",
    href: undefined,
    shape: undefined,
    strong: false,
    type: "button",
    icon: '',
    color: "primary",
    text: "",
    attributeBindings: ["color"],
    actions: {}
  });

  _exports.default = _default;
});