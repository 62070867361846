define("web-client/api/post-mulit-part-form-data", ["exports", "web-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = postMultiPartFormData;
  let baseURL = _environment.default.apiHost;

  function postMultiPartFormData(url, formData, csrfToken, contentType) {
    let defered = Ember.RSVP.defer();
    let headers = {
      'Accept': '*/*',
      'X-Csrf-Token': csrfToken
    };

    if (contentType) {
      headers = {
        'Accept': '*/*',
        'Content-Type': contentType,
        'X-Csrf-Token': csrfToken
      };
    }

    fetch(baseURL + url, {
      method: 'post',
      credentials: 'include',
      body: formData,
      headers: headers
    }).then(function (response) {
      if (response.status !== 200) {
        response.json().then(function (data) {
          defered.reject(data);
        });
      } else {
        response.json().then(function (data) {
          if (data.status.code === 0) {
            defered.resolve(data);
          } else {
            defered.reject(data);
          }
        });
      }
    }).catch(function (response) {
      defered.reject(response.status);
    });
    return defered.promise;
  }
});