define("web-client/components/color-picker-dialog/color-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHNx3r5S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-label\"],[12,\"class\",[30,[\"color-picker-item color-fill \",[25,[\"item\",\"value\"]]]]],[9],[1,[23,\"text\"],false],[10],[0,\"\\n\"],[7,\"ion-radio\"],[11,\"slot\",\"start\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/color-picker-dialog/color-item/template.hbs"
    }
  });

  _exports.default = _default;
});