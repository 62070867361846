define("web-client/api/delete-account", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteAccount = void 0;

  /**
   * params
   * label
   *
   */
  const deleteAccount = _ipcClient.ipc.makeRequestFunction('deleteAccount');

  _exports.deleteAccount = deleteAccount;
});