define("web-client/components/edit-team-dialog/component", ["exports", "ui-core/mixins/dialog-base", "debug", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/utils/color-options", "lodash-es/clone", "web-client/api/update-team", "web-client/api", "lodash-es/cloneDeep", "ember-get-config", "lodash-es/includes", "lodash-es/filter", "lodash-es/map", "xxhashjs", "lodash-es/reduce", "lodash-es/sortBy", "lodash-es/difference", "lodash-es/concat", "lodash-es/compact", "lodash-es/find", "lodash-es/each"], function (_exports, _dialogBase, _debug, _emberRedux, _emberConcurrency, _emberCpValidations, _colorOptions, _clone, _updateTeam, _api, _cloneDeep, _emberGetConfig, _includes, _filter, _map, _xxhashjs, _reduce, _sortBy, _difference, _concat, _compact, _find, _each) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const log = (0, _debug.default)("");
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Name can't be blank"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    classNames: ['edit-team-dialog'],
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    loading: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    managingMembers: false,
    apiHost: _emberGetConfig.default.apiHost,
    activeTab: "details",
    media: Ember.inject.service(),
    isOwned: Ember.computed('team', function () {
      return this.team.role === 1;
    }),
    membersListWidth: Ember.computed('managingMembers', function () {
      return this.get('managingMembers') ? 5 : 12;
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    name: Ember.computed.alias('team.name'),
    color: Ember.computed.alias('team.color'),
    colorOptions: _colorOptions.colorOptions,
    hasChanged: Ember.computed('team.{name,color,defaultRole,description}', 'connections', 'connections.[]', 'connections.@each.perm', function () {
      return this.get('team.name') !== this.get('originalTeam.name') || this.get('team.color') !== this.get('originalTeam.color') || this.get('team.defaultRole') !== this.originalTeam.defaultRole || this.team.description !== this.originalTeam.description || this.connectionsHash !== this.originalConnectionsHash;
    }),
    segmentButtonLayout: Ember.computed('media.classNames', function () {
      return !(this.media.get('isMediaLG') || this.media.get('isMediaXL') || this.media.get('isMediaMD')) ? "icon-start" : "icon-top";
    }),
    showErrors: false,
    showNameErrors: Ember.computed('showErrors', 'name', function () {
      return this.showErrors || this.name.length;
    }),
    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "createTeam" : "cancelDialog";
    }),
    currentConnections: Ember.computed('connections', 'connections.[]', 'connections.@each.perm', function () {
      return this.connections;
    }),
    availableConnections: Ember.computed('reduxStore.state.data.connections', 'reduxStore.state.data.connections.content.[]', 'currentConnections', 'currentConnections.[]', function () {
      return (0, _filter.default)(this.get('reduxStore.state.data.connections.content'), connection => {
        return connection.active && !(0, _includes.default)((0, _map.default)(this.get('connections'), 'id'), connection.id);
      });
    }),
    originalConnectionsHash: Ember.computed('originalConnections', 'originalConnections.{[],@each}', function () {
      if (this.originalConnections && this.originalConnections.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.originalConnections, 'id'), function (acc, item) {
          acc = _xxhashjs.default.h32(item.id + item.perm.toString(), 1) + acc;
          return acc;
        }, 0), 0).toString();
      } else {
        return "0";
      }
    }),
    connectionsHash: Ember.computed('connections', 'connections.[]', 'connections.@each.perm', function () {
      if (this.connections && this.connections.length) {
        return _xxhashjs.default.h32((0, _reduce.default)((0, _sortBy.default)(this.connections, 'id'), function (acc, item) {
          let perm = item.perm === undefined ? "0" : item.perm.toString();
          acc = _xxhashjs.default.h32(item.id + perm, 1) + acc;
          return acc;
        }, 0), 0).toString();
      } else {
        return "0";
      }
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('team', (0, _cloneDeep.default)(this.tm));
      this.set('originalTeam', (0, _cloneDeep.default)(this.tm));
      this.set('originalConnections', Ember.A([]).pushObjects((0, _cloneDeep.default)(this.cn)));
      this.set('connections', Ember.A((0, _map.default)((0, _cloneDeep.default)(this.cn), item => Ember.Object.create(item))));
      this.set('selectedAvailableConnections', Ember.A([]));
      this.set('selectedAssignedConnections', Ember.A([]));
      this.set('selectedAvailableAssignedConnections', Ember.A([]));

      if (this.showEditMembersTab) {
        this.set('currentTab', 'members');
      }
    },

    setColor: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.standardDialogs.showDialog('color-picker-dialog', {
        complete: deferred
      }, "small");
      let colorOption = yield deferred.promise;

      if (colorOption) {
        this.set('team.color', colorOption.value);
      }
    }).drop(),
    permissionOptions: Ember.computed(function () {
      return [{
        value: 4,
        display: 'Read Only'
      }, {
        value: 3,
        display: 'Read Write'
      }];
    }),
    permissionChanges: Ember.computed('connections', 'connections.[]', function () {
      //[{"otherId":"adf4117dfd6c4f9bb82c55bf5848caa1","groupId":"95d4d6a755554074988924807ff014c1","role":4}]
      let newConnectionIds = (0, _difference.default)((0, _map.default)(this.get('connections'), 'id'), (0, _map.default)(this.get('originalConnections'), 'id'));
      let newConnectionChanges = (0, _compact.default)((0, _map.default)(this.connections, connection => {
        if ((0, _includes.default)(newConnectionIds, connection.id)) {
          return {
            otherId: connection.id,
            "groupId": this.team.id,
            role: connection.perm
          };
        }
      }));
      let existingConnectionChanges = (0, _compact.default)((0, _map.default)(this.connections, connection => {
        let found = (0, _find.default)(this.originalConnections, foundConnection => {
          if (foundConnection.id === connection.id) {
            return foundConnection;
          }
        });

        if (found && found.perm !== connection.perm) {
          return {
            otherId: connection.id,
            groupId: this.team.id,
            role: connection.perm
          };
        }
      }));
      return (0, _concat.default)(newConnectionChanges, existingConnectionChanges);
    }),
    connectionChanges: Ember.computed('connections', 'connections.[]', function () {
      let connectionChanges = {
        removeConnections: (0, _difference.default)((0, _map.default)(this.get('originalConnections'), 'id'), (0, _map.default)(this.get('connections'), 'id')),
        addConnections: (0, _difference.default)((0, _map.default)(this.get('connections'), 'id'), (0, _map.default)(this.get('originalConnections'), 'id'))
      };
      return connectionChanges;
    }),
    updateTeam: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          this.loading.show('Updating Team');
          (0, _updateTeam.updateTeam)(this.team.id, this.team.name, this.team.description, this.team.color, this.team.defaultRole, this.connectionChanges, this.permissionChanges).then(e => {
            this.globalActions.getPasswords().then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_PASSWORDS',
                passwords: data
              });
              return (0, _api.getTeams)();
            }).then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_TEAMS',
                teams: data
              });
              self.send('remove');
              this.loading.dismiss();
              deferred.resolve();

              if (self.onComplete) {
                self.onComplete.resolve(e);
              }
            }).catch(e => {
              this.loading.dismiss();
              this.globalActions.actionUIErrorHandler(e);
            });
          }).catch(e => {
            this.loading.dismiss();
            this.globalActions.actionUIErrorHandler(e);
            deferred.reject(e);
          });
        } else {
          self.set('showErrors', true);
          deferred.resolve();
        }
      });
      yield deferred.promise;
    }).drop(),
    selectConnectionToAdd: (0, _emberConcurrency.task)(function* (team) {
      let deferred = Ember.RSVP.defer();

      if (!(0, _includes.default)(this.get('selectedAvailableConnections'), team.id)) {
        this.get('selectedAvailableConnections').pushObject(team.id);
        deferred.resolve();
      } else {
        this.get('selectedAvailableConnections').removeObject(team.id);
        deferred.resolve();
      }

      yield deferred.promise;
    }).drop(),
    selectConnectionToRemove: (0, _emberConcurrency.task)(function* (team) {
      let deferred = Ember.RSVP.defer();

      if (!(0, _includes.default)(this.get('selectedAssignedConnections'), team.id)) {
        this.get('selectedAssignedConnections').pushObject(team.id);
        deferred.resolve();
      } else {
        this.get('selectedAssignedConnections').removeObject(team.id);
        deferred.resolve();
      }

      yield deferred.promise;
    }).drop(),
    deleteTeam: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.standardDialogs.showConfirmDialog('confirm-delete-team', true).then(() => {
        this.loading.show('Deleting Team');
        (0, _api.deleteTeams)([this.team.id]).then(e => {
          this.reduxStore.state.data.teams.selected.removeObject(this.team.id);
          return (0, _api.getTeams)();
        }).then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_TEAMS',
            teams: data
          });
          return this.globalActions.getPasswords();
        }).then(data => {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: data
          });
          this.loading.dismiss();
          deferred.resolve();
          this.send('remove');

          if (this.onComplete) {
            this.onComplete.resolve('deleted');
          }
        }).catch(data => {
          deferred.resolve();
          this.loading.dismiss();

          if (data !== 'cancelled') {
            deferred.resolve();
            this.globalActions.actionUIErrorHandler(data);
          }
        });
      }).catch(() => {
        deferred.resolve();
      });
      yield deferred.promise;
    }).drop(),
    showTeamDialogMenu: (0, _emberConcurrency.task)(function* (event) {
      let args = {
        deleteTeam: this.deleteTeam,
        manageMembers: this.manageMembers
      };
      yield this.globalActions.showTeamDialogMenu(args);
    }),
    currentTab: "details",
    actions: {
      setTab(tab) {
        this.set('currentTab', tab);
      },

      removeSelectedConnections() {
        let removeThese = (0, _filter.default)(this.get('connections'), connection => {
          return (0, _includes.default)(this.get('selectedAssignedConnections'), connection.id);
        });
        this.get('connections').removeObjects(removeThese);
        this.selectedAssignedConnections.clear();
        this.notifyPropertyChange('connections');
      },

      addSelectedConnections() {
        let addThese = (0, _filter.default)(this.get('reduxStore.state.data.connections.content'), connection => {
          return (0, _includes.default)(this.get('selectedAvailableConnections'), connection.id);
        });
        (0, _each.default)(addThese, con => {
          if (con.perm === undefined) {
            Ember.set(con, "perm", 0);
          }
        });
        this.get('connections').addObjects(addThese);
        this.selectedAvailableConnections.clear();
        this.notifyPropertyChange('connections');
      },

      togglePermission(connection, perm) {
        Ember.set(connection, "perm", perm);
        this.notifyPropertyChange('connections');
      },

      toggleManageMembers() {
        this.set('managingMembers', !this.managingMembers);
      },

      onSelectColor: function onSelectColor(color) {
        this.set('team.color', color);
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      },

      setDefaultPermissions(value) {
        this.set("team.defaultRole", value);
      },

      discardChanges() {
        this.set('team', (0, _clone.default)(this.originalTeam));
        this.set('connections', this.originalConnections);
        this.set('selectedAvailableConnections', Ember.A([]));
        this.set('selectedAssignedConnections', Ember.A([]));
        this.set('selectedAvailableAssignedConnections', Ember.A([]));
        let input = this.get('element').querySelector("ion-input:first-of-type");
        Ember.run.later(this, function () {
          if (input) {
            input.componentOnReady().then(() => {
              input.setFocus();
            });
          }
        }, 300);
      }

    },
    manageMembers: (0, _emberConcurrency.task)(function* () {
      let self = this;
      let currentIds = (0, _map.default)(self.connections, 'id');
      let items = (0, _map.default)(this.reduxStore.state.data.connections.content, function (item) {
        return {
          name: item.nickname,
          value: item.id,
          group: "123",
          selected: (0, _includes.default)(currentIds, item.id),
          icon: "contact",
          color: item.color
        };
      });
      let multiSelectOptions = {
        title: 'Manage Members',
        size: 'size-3',
        okText: 'Save',
        cancelText: 'Cancel',
        listItems: items,
        addNewTask: this.addNewLabel,
        grouping: false
      };
      let newItems = yield this.standardDialogs.showMultiSelectDialog(multiSelectOptions);
      let newConnections = (0, _filter.default)(this.reduxStore.state.data.connections.content, function (item) {
        return (0, _includes.default)(newItems, item.id);
      });
      this.set('connections', newConnections);
    }).drop()
  });

  _exports.default = _default;
});