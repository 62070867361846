define("web-client/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "34mc/Bwn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"local-class\",\"c-notification__icon\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[30,[[23,\"notificationIcon\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"local-class\",\"c-notification__content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"notification\",\"htmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,[\"notification\",\"message\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"notification\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"div\"],[11,\"local-class\",\"c-notification__close\"],[11,\"title\",\"Dismiss this notification\"],[9],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[30,[[23,\"closeIcon\"]]]],[9],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"removeNotification\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"local-class\",\"c-notification__countdown\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});