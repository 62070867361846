define("web-client/passwords/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    globalActions: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    media: Ember.inject.service(),
    columns: [{
      type: 'row-header',
      dataItem: "id",
      columnHeaderText: "",
      width: 100,
      minWidth: 100,
      maxWidth: 100
    }, {
      type: 'text',
      dataItem: "name",
      columnHeaderText: "Name",
      width: 300,
      minWidth: 200,
      maxWidth: 400,
      onSort: null,
      sortDirection: 'ASC',
      isSorted: false
    }, {
      type: 'text',
      dataItem: "url",
      columnHeaderText: "URL",
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      sortDirection: 'none',
      isSorted: false
    }, {
      type: 'text',
      dataItem: "lastUpdate",
      columnHeaderText: "Entry Updated",
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      format: 'moment-from-now',
      sortDirection: 'none',
      isSorted: false
    }, {
      type: 'text',
      dataItem: "passwordLastUpdate",
      columnHeaderText: "Password Changed",
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      format: 'moment-from-now',
      sortDirection: 'none',
      isSorted: false
    }, {
      type: 'text',
      dataItem: "score",
      columnHeaderText: "Score",
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      sortDirection: 'none',
      isSorted: false
    }],

    init() {
      this._super.apply(this, arguments);

      let self = this;
      var isLicensed = this.get('reduxStore.state.licenseSummary.licensed');

      if (isLicensed) {
        let updateColumnSorts = () => {
          this.columns[1].isSorted = this.reduxStore.state.data.passwords.sortOnName;
          this.columns[1].sortDirection = this.reduxStore.state.data.passwords.sortDirection;
          this.columns[2].isSorted = this.reduxStore.state.data.passwords.sortOnURL;
          this.columns[2].sortDirection = this.reduxStore.state.data.passwords.sortDirection;
          this.columns[3].isSorted = this.reduxStore.state.data.passwords.sortOnLastUpdate;
          this.columns[3].sortDirection = this.reduxStore.state.data.passwords.sortDirection;
          this.columns[4].isSorted = this.reduxStore.state.data.passwords.sortOnLastPasswordUpdate;
          this.columns[4].sortDirection = this.reduxStore.state.data.passwords.sortDirection;
          this.columns[5].isSorted = this.reduxStore.state.data.passwords.sortOnPasswordStrength;
          this.columns[5].sortDirection = this.reduxStore.state.data.passwords.sortDirection;
        };

        updateColumnSorts();

        this.columns[1].onSort = () => {
          let deferred = Ember.RSVP.defer();
          self.globalActions.setSortOrder.perform("sortOnName").then(() => {
            updateColumnSorts();
            deferred.resolve();
          });
          return deferred.promise;
        };

        this.columns[2].onSort = () => {
          let deferred = Ember.RSVP.defer();
          self.globalActions.setSortOrder.perform('sortOnURL').then(() => {
            updateColumnSorts();
            deferred.resolve();
          });
          return deferred.promise;
        };

        this.columns[3].onSort = () => {
          let deferred = Ember.RSVP.defer();
          self.globalActions.setSortOrder.perform('sortOnLastUpdate').then(() => {
            updateColumnSorts();
            deferred.resolve();
          });
          return deferred.promise;
        };

        this.columns[4].onSort = () => {
          let deferred = Ember.RSVP.defer();
          self.globalActions.setSortOrder.perform('sortOnLastPasswordUpdate').then(() => {
            updateColumnSorts();
            deferred.resolve();
          });
          return deferred.promise;
        };

        this.columns[5].onSort = () => {
          let deferred = Ember.RSVP.defer();
          self.globalActions.setSortOrder.perform('sortOnPasswordStrength').then(() => {
            updateColumnSorts();
            deferred.resolve();
          });
          return deferred.promise;
        };
      } // end init

    },

    page: Ember.computed.alias('reduxStore.state.data.passwords.currentPage'),
    fetchRecords: (0, _emberConcurrency.task)(function* () {
      this.set('page', this.page + 1);
      yield this.globalActions.getPasswordPage(this.page);
    }).drop(),
    actions: {
      goToDashboard: function goToDashboard() {
        this.globalActions.goToSettingsReplace();
      }
    }
  });

  _exports.default = _default;
});