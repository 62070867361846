define("web-client/components/stat-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["stat-display"],
    classNameBindings: ["color"],
    color: '',
    variableNumber: "--",
    fixedNumber: "--"
  });

  _exports.default = _default;
});