define("web-client/app", ["exports", "web-client/resolver", "ember-load-initializers", "web-client/config/environment", "web-client/api", "buffer"], function (_exports, _resolver, _emberLoadInitializers, _environment, _api, _buffer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  window.Buffer = window.Buffer || _buffer.default.Buffer;
  Ember.run.backburner.DEBUG = true;
  (0, _api.startupLog)({
    actionText: 'Initializing Passpack'
  });
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});