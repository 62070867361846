define("web-client/mixins/previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // router: service(),
    init() {
      this._super.apply(this, arguments);

      this.on('routeWillChange', () => {
        let previous = Ember.getOwner(this).lookup('previous:main');
        Ember.setProperties(previous, {
          'path': this.currentPath,
          'url': this.currentURL,
          'route': this.currentRouteName
        });
      });
    }

  });

  _exports.default = _default;
});