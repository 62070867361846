define("web-client/api/create-team", ["exports", "platform-specific/ipc/ipc-client", "web-client/api/get-teams", "neo-async"], function (_exports, _ipcClient, _getTeams, _neoAsync) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createTeam = void 0;

  /**
   * params
   * label
   * color
   */
  const createTeam = _ipcClient.ipc.makeRequestFunction('createTeam');
  /*
  function create200(){
    let teams = [];
    for (let i =0;i<200;i++){
      teams.push(["test_team_"+i,"test_team_"+i+"description",'red-100',4]);
  
    }
    async.eachSeries(teams,function(item,callback){
  
      createTeam(item[0],item[2],item[2],item[3]).then(()=>{
        callback();
      }).catch(()=>{
        callback();
      })
  
    },function(){
      console.log('done');
    })
  }
  window.create200 = create200;
  
  function create100(){
    let teams = [];
    for (let i =0;i<100;i++){
      teams.push(["test_team_"+i,"test_team_"+i+"description",'red-100',4]);
  
    }
    async.eachSeries(teams,function(item,callback){
  
      createTeam(item[0],item[2],item[2],item[3]).then(()=>{
        callback();
      }).catch(()=>{
        callback();
      })
  
    },function(){
      console.log('done');
    })
  }
  window.create100 = create100;
  */


  _exports.createTeam = createTeam;
});