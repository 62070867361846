define("web-client/utils/map-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = GMapCircle;

  function createEncodings(coords) {
    var i = 0;
    var plat = 0;
    var plng = 0;
    var encoded_points = "";

    for (i = 0; i < coords.length; ++i) {
      var lat = coords[i][0];
      var lng = coords[i][1];
      encoded_points += encodePoint(plat, plng, lat, lng);
      plat = lat;
      plng = lng;
    } // close polyline


    encoded_points += encodePoint(plat, plng, coords[0][0], coords[0][1]);
    return encoded_points;
  }

  function encodePoint(plat, plng, lat, lng) {
    var late5 = Math.round(lat * 1e5);
    var plate5 = Math.round(plat * 1e5);
    var lnge5 = Math.round(lng * 1e5);
    var plnge5 = Math.round(plng * 1e5);
    var dlng = lnge5 - plnge5;
    var dlat = late5 - plate5;
    return encodeSignedNumber(dlat) + encodeSignedNumber(dlng);
  }

  function encodeSignedNumber(num) {
    var sgn_num = num << 1;

    if (num < 0) {
      sgn_num = ~sgn_num;
    }

    return encodeNumber(sgn_num);
  }

  function encodeNumber(num) {
    var encodeString = "";

    while (num >= 0x20) {
      encodeString += String.fromCharCode((0x20 | num & 0x1f) + 63);
      num >>= 5;
    }

    encodeString += String.fromCharCode(num + 63);
    return encodeString;
  }

  function GMapCircle($Lat, $Lng, $Rad) {
    let $Detail = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 8;
    var $R = 6371;
    var $pi = Math.PI;
    $Lat = $Lat * $pi / 180;
    $Lng = $Lng * $pi / 180;
    var $d = $Rad / $R;
    var $points = [];

    for (var $i = 0; $i <= 360; $i += $Detail) {
      var $brng = $i * $pi / 180;
      var $pLat = Math.asin(Math.sin($Lat) * Math.cos($d) + Math.cos($Lat) * Math.sin($d) * Math.cos($brng));
      var $pLng = ($Lng + Math.atan2(Math.sin($brng) * Math.sin($d) * Math.cos($Lat), Math.cos($d) - Math.sin($Lat) * Math.sin($pLat))) * 180 / $pi;
      $pLat = $pLat * 180 / $pi;
      $points.push([$pLat, $pLng]);
    }

    let $PolyEnc = createEncodings($points);
    return $PolyEnc;
  }
});