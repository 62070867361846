define("web-client/components/password-generator-dialog/generated-passwords-list-header/component", ["exports", "ui-core/utils/column-resizer", "web-client/components/password-generator-dialog/generated-passwords-list-header/template"], function (_exports, _columnResizer, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    resize: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    tagName: 'div',
    classNames: ['generated-passwords-list-header'],
    nameSize: 100,
    intersection: Ember.inject.service(),

    didInsertElement() {
      this._super.apply(this, arguments);

      const self = this;
      const nameElement = document.querySelector('#generated-passwords-list-resizer .generated-item');
      const valueElement = document.querySelector('#generated-passwords-list-resizer .date-item');
      this.resizer = new _columnResizer.default(document.getElementById('generated-passwords-list-resizer'), {
        liveDrag: true,
        draggingClass: "rangeDrag",
        gripInnerHtml: "<div class='rangeGrip'></div>",
        minWidth: 100,
        headerOnly: true,
        onResize: function onResize() {
          if (!self.get('isDestroyed')) {
            self.set('nameSize', nameElement.offsetWidth);
            self.set('valueSize', valueElement.offsetWidth);
          }
        },
        onDrag: function onDrag() {
          if (!self.get('isDestroyed')) {
            self.set('nameSize', nameElement.offsetWidth);
            self.set('valueSize', valueElement.offsetWidth);
          }
        }
      });

      this._resize = function () {
        if (!self.get('isDestroyed')) {
          self.set('nameSize', nameElement.offsetWidth);
          self.set('valueSize', valueElement.offsetWidth);
        }
      };

      this.get('resize').observe(this.get('element'));
      this.element.addEventListener('resize', this._resize);
      Ember.run.next(this, function () {
        window.dispatchEvent(new Event('resize'));
      });
      this.get('intersection').observe(this.get('element'));

      this._inView = function ()
      /*event*/
      {
        window.dispatchEvent(new Event('resize'));
      };

      this.element.addEventListener('inview', this._inView);
    }

  });

  _exports.default = _default;
});