define("web-client/services/init-app-utils/init-database", ["exports", "web-client/api", "web-client/api/get-labels", "web-client/api/get-teams", "web-client/api/get-connections", "platform-specific/ipc/ipc-client", "xxhashjs", "lodash-es/each", "lodash-es/find", "ui-core/neo-async-es/parallel", "debug"], function (_exports, _api, _getLabels, _getTeams, _getConnections, _ipcClient, _xxhashjs, _each, _find, _parallel, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initDatabase;
  //init the DB
  const log = (0, _debug.default)('init-database');
  const PASSWORD_FEED = 1;
  const LABEL_FEED = 2;
  const SETTINGS_FEED = 3;
  const GROUP_FEED = 4;
  const SENT_INVITATIONS_FEED = 5;
  const RECEIVED_INVITATIONS_FEED = 6;
  const CONNECTION_FEED = 7;
  const LEGACY_MESSAGE_FEED1 = 8;
  const LEGACY_MESSAGE_FEED2 = 9;
  const LIMITS_FEED = 10;
  const AUTOSAVE_FEED = 11;
  const GENERATED_DATA_FEED = 12;
  const SHARING_STATS_FEED = 13;
  const ACCOUNT_LICENSE_FEED = 14;
  const ORG_LICENSE_FEED = 15;
  const ACTION_FEED = 16;
  let listenerInited = false;

  function handleLabels(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _getLabels.getLabels)().then(function (data) {
      reduxStore.dispatch({
        type: 'UPDATE_LABELS',
        labels: data
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function handleGeneratedPasswords(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _api.getGeneratedPasswords)().then(function (data) {
      reduxStore.dispatch({
        type: 'UPDATE_GENERATED_PASSWORDS',
        generatedPasswords: data
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function handlePasswords(reduxStore, globalActions) {
    let deferred = Ember.RSVP.defer();
    globalActions.getPasswords().then(function (data) {
      reduxStore.dispatch({
        type: 'UPDATE_PASSWORDS',
        passwords: data
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function handleConnections(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _getConnections.getConnections)().then(function (data) {
      reduxStore.dispatch({
        type: 'UPDATE_CONNECTIONS',
        connections: data
      });
      let offset = reduxStore.state.data.connectionsForManagement.currentPage * reduxStore.state.data.connectionsForManagement.pageSize - reduxStore.state.data.connectionsForManagement.pageSize;
      (0, _api.getConnectionsForManagement)({
        limit: reduxStore.state.data.connectionsForManagement.pageSize,
        offset: offset,
        pageSize: reduxStore.state.data.connectionsForManagement.pageSize,
        filterOnActive: reduxStore.state.data.connectionsForManagement.filterOnActive,
        filterOnInactive: reduxStore.state.data.connectionsForManagement.filterOnInactive,
        teams: reduxStore.state.data.teamsForConnectionManagement.selected,
        sortName: reduxStore.state.data.connectionsForManagement.sortName,
        sortDirection: reduxStore.state.data.connectionsForManagement.sortDirection
      }).then(function (data) {
        reduxStore.dispatch({
          type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
          connections: data
        });
        deferred.resolve();
      });
    });
    return deferred.promise;
  }

  function handleAccountLicenseFeed(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _api.getAccountLicenseSummary)().then(data => {
      reduxStore.dispatch({
        type: 'INITIALIZE_ACCOUNT_LICENSE_SUMMARY',
        licenseSummary: data.licenseSummary
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function handleOrgLicenseFeed(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _api.getOrganizationLicenses)().then(data => {
      reduxStore.dispatch({
        type: 'UPDATE_ORGANIZATION_LICENSES',
        licenses: data.licenses,
        notLicensed: data.notLicensed
      });
    }); // These two should be linked

    (0, _api.getSubscriptionSummary)().then(data => {
      reduxStore.dispatch({
        type: 'INITIALIZE_SUBSCRIPTION_SUMMARY',
        nextSubscription: data.next,
        subscriptionSummary: data.subscriptionSummary
      });
    });
    deferred.resolve();
    return deferred.promise;
  }

  function handleActionFeed(reduxStore, globalActions) {
    let deferred = Ember.RSVP.defer();
    globalActions.processActionFeed();
    deferred.resolve();
    return deferred.promise;
  }

  function handleTeams(reduxStore) {
    let deferred = Ember.RSVP.defer();
    (0, _getTeams.getTeams)().then(function (data) {
      reduxStore.dispatch({
        type: 'UPDATE_TEAMS',
        teams: data
      });
      deferred.resolve();
    });
    return deferred.promise;
  }

  function dummyHandler() {
    let deferred = Ember.RSVP.defer();
    deferred.resolve();
    return deferred.promise;
  }

  function initDatabase() {
    let self = this;
    let reduxStore = this.reduxStore;

    if (!listenerInited) {
      let feed = [];
      feed[PASSWORD_FEED] = handlePasswords;
      feed[LABEL_FEED] = handleLabels;
      feed[SETTINGS_FEED] = dummyHandler;
      feed[GROUP_FEED] = handleTeams;
      feed[SENT_INVITATIONS_FEED] = dummyHandler;
      feed[RECEIVED_INVITATIONS_FEED] = dummyHandler;
      feed[CONNECTION_FEED] = handleConnections;
      feed[LIMITS_FEED] = dummyHandler;
      feed[AUTOSAVE_FEED] = dummyHandler;
      feed[GENERATED_DATA_FEED] = handleGeneratedPasswords;
      feed[SHARING_STATS_FEED] = dummyHandler;
      feed[LEGACY_MESSAGE_FEED1] = dummyHandler;
      feed[LEGACY_MESSAGE_FEED2] = dummyHandler;
      feed[ACCOUNT_LICENSE_FEED] = handleAccountLicenseFeed;
      feed[ORG_LICENSE_FEED] = handleOrgLicenseFeed;
      feed[ACTION_FEED] = handleActionFeed;
      listenerInited = true;

      _ipcClient.ipc.addListener('syncUpdates', function (request, data) {
        (0, _each.default)(data, function (item) {
          feed[item.feed](reduxStore, self.globalActions);
        });
        log.apply(void 0, ['syncupdates'].concat(Array.prototype.slice.call(arguments)));
      });

      _ipcClient.ipc.addListener('reloadAccountLicense', function (request, data) {
        handleAccountLicenseFeed(reduxStore);
      });

      _ipcClient.ipc.addListener('reloadOrgLicense', function (request, data) {
        handleOrgLicenseFeed(reduxStore);
      });

      _ipcClient.ipc.addListener('getActionFeed', function (request, data) {
        handleActionFeed(reduxStore, self.globalActions);
      });
    }

    return function (callback) {
      (0, _api.startupLog)({
        actionText: 'Initializing Database'
      });

      if (reduxStore.get('state.userId') && reduxStore.get('state.decryptedDbKey') && reduxStore.get('state.statusToken') && reduxStore.get('state.decryptedPrivateKey')) {
        window.h32 = _xxhashjs.default.h32;
        (0, _api.initDatabase)(_xxhashjs.default.h32(reduxStore.get('state.userId').toString(), 0xABCD).toString().substring(0, 16), reduxStore.get('state.decryptedDbKey'), reduxStore.get('state.statusToken'), reduxStore.get('state.decryptedPrivateKey')).then(() => {
          (0, _parallel.default)([function (callback) {
            self.globalActions.getPasswords().then(data => {
              callback(null, data);
            });
          }, function (callback) {
            (0, _getLabels.getLabels)().then(data => {
              callback(null, data);
            }).catch(e => {
              log(e);
            });
          }, function (callback) {
            (0, _getTeams.getTeams)().then(data => {
              let defaultTeam = (0, _find.default)(data, team => {
                return team.name === 'Default';
              });

              if (defaultTeam) {
                callback(null, data);
              } else {
                (0, _api.createTeam)("Default", "Default Group", 'green-500', 3).then(() => {
                  (0, _getTeams.getTeams)().then(data => {
                    callback(null, data);
                  });
                }).catch(e => {
                  callback(null, []);
                });
              }
            });
          }, function (callback) {
            (0, _getConnections.getConnections)().then(data => {
              callback(null, data);
            });
          }, function (callback) {
            self.globalActions.getConnectionsForManagement().then(data => {
              callback(null, data);
            });
          }, function (callback) {
            (0, _api.getGeneratedPasswords)().then(function (data) {
              callback(null, data);
            });
          }], function (err, results) {
            reduxStore.dispatch({
              type: 'UPDATE_PASSWORDS',
              passwords: results[0]
            });
            reduxStore.dispatch({
              type: 'UPDATE_LABELS',
              labels: results[1]
            });
            reduxStore.dispatch({
              type: 'UPDATE_TEAMS',
              teams: results[2]
            });
            reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS',
              connections: results[3]
            });
            reduxStore.dispatch({
              type: 'UPDATE_CONNECTIONS_FOR_MANAGEMENT',
              connections: results[4]
            });
            reduxStore.dispatch({
              type: 'UPDATE_GENERATED_PASSWORDS',
              generatedPasswords: results[5]
            }); //            reduxStore.dispatch({
            //              type: 'UPDATE_GENERATED_PASSWORDS',
            //              generatedPasswords: results[5]
            //            });
            //
            //            reduxStore.dispatch({
            //          type: 'UPDATE_ORGANIZATION_LICENSES',
            //          licenses: results[6].licenses
            //        });
            //         reduxStore.dispatch({
            //          type: 'INITIALIZE_SUBSCRIPTION_SUMMARY',
            //          currentSubscription: results[7].current,
            //          nextSubscription: results[7].next,
            //          subscriptionSummary: results[7].subscriptionSummary
            //        });

            reduxStore.dispatch({
              type: 'SET_IS_INITIALIZED'
            });
            (0, _api.startupLog)({
              actionText: 'Database Loaded'
            });
            callback();
          });
        }).catch(e => {
          callback('%c initDatabase in init-database.js', e);
        });
      } else {
        callback('cannot init the database (no userid) or (no key)');
      }
    };
  }
});