define("web-client/components/session-history-list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4olzBxOM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"date\"],[9],[0,\"\\n  \"],[1,[25,[\"session\",\"logintimeformatted\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"active\"],[9],[0,\"\\n  \"],[1,[29,\"if\",[[29,\"eq\",[[25,[\"session\",\"active\"]],true],null],\"YES\",\"NO\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"success\"],[9],[0,\"\\n  \"],[1,[29,\"if\",[[29,\"eq\",[[25,[\"session\",\"active\"]],true],null],[29,\"if\",[[29,\"eq\",[[25,[\"session\",\"isCurrentSession\"]],true],null],\"YES\",\"YES\"],null],[29,\"if\",[[29,\"eq\",[[25,[\"session\",\"success\"]],true],null],\"YES\",\"NO\"],null]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"ip\"],[9],[0,\"\\n  \"],[1,[25,[\"session\",\"ipaddress\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"location\"],[9],[0,\"\\n  \"],[1,[25,[\"session\",\"locationFormatted\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"actions\"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[29,\"if\",[[29,\"or\",[[29,\"not\",[[25,[\"session\",\"activeSession\"]],false],null],[25,[\"session\",\"isCurrentSession\"]]],null],false,true],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Sign out Session\",\"Sign out session\",[29,\"service-action\",[\"global-actions\",\"killSession\",[25,[\"session\",\"id\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[\"Details\",\"Show session details\",[29,\"service-action\",[\"global-actions\",\"showSessionDialog\",[25,[\"session\"]]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/session-history-list-item/template.hbs"
    }
  });

  _exports.default = _default;
});