define("web-client/components/invites-selection-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ymhPRma",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-menu\",null,[[\"tether\",\"class\"],[[25,[\"tether\"]],\"offset-menu-20-15\"]],{\"statements\":[[0,\"\\n\\n\\n\\n\\n\\n\\n\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/invites-selection-menu/template.hbs"
    }
  });

  _exports.default = _default;
});