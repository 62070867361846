define("web-client/validators/passwordstrength", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PasswordStrength = _base.default.extend({
    validate(value, options, model
    /*, attribute*/
    ) {
      if (true !== model.get(options.scoreProperty + '.password.score') >= 3) {
        return options.message;
      } else {
        return true;
      }
    }

  });

  PasswordStrength.reopenClass({
    getDependentsFor(attribute, options) {
      return ['model.' + options.scoreProperty, 'model.' + options.scoreProperty + '.password.score'];
    }

  });
  var _default = PasswordStrength;
  _exports.default = _default;
});