define("web-client/actions/action-ui-error-handler", ["exports", "web-client/api", "lodash-es/has", "lodash-es/isNumber"], function (_exports, _api, _has, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = actionUIErrorHandler;

  function actionUIErrorHandler(data) {
    if (!(0, _has.default)(data, 'status.code') || (0, _isNumber.default)(data)) {
      this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'));
    } else {
      this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
    }
  }
});