define("web-client/components/avatar-dialog/component", ["exports", "ui-core/mixins/dialog-base", "web-client/api"], function (_exports, _dialogBase, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    //override default options of cropper
    isEditing: false,
    reduxStore: Ember.inject.service(),
    i18n: Ember.inject.service(),
    size: 'size-3',
    notifications: Ember.inject.service(),
    croppie: null,
    scaleObserver: Ember.observer('scale', function () {
      this.cropper.imgResize(this.scale);

      if (this.scale !== 0) {
        this.set('hasImage', true);
      }
    }),
    hasImage: false,
    actions: {
      uploadNewAvatar: function uploadNewAvatar() {
        document.getElementById("uploadfile").click();
      },
      resetToDefault: function resetToDefault() {
        this.set('isEditing', true);
        this.croppie.bind({
          url: "data:image/png;base64," + new Identicon((0, _api.sha256asHex)(this.get('reduxStore.state.userId').toString()), 100).toString()
        }).then(() => {
          this.croppie.setZoom(0);
        });
      },
      saveImage: function saveImage() {
        let self = this;
        this.croppie.result({
          type: 'blob',
          size: {
            width: 100,
            height: 100
          }
        }).then(function (blob) {
          // Do something with the blob object,
          // e.g. creating a multipart form for file uploads:
          let fd = new FormData();
          fd.append('fileData', blob, "avatar.png");
          (0, _api.getCsrfToken)().then(function (result) {
            return result;
          }).then(data => {
            (0, _api.uploadAvatar)(fd, data).then(() => {
              self.get('reduxStore').dispatch({
                type: 'RESET_PROFILE_URL'
              });
              self.get('reduxStore').dispatch({
                type: 'SET_HAS_SYSTEM_DEFAULT_IMAGE',
                hasSystemDefaultImage: false
              });
              (0, _api.updateSettingsData)('hasSystemDefaultImage', 'false').then(function ()
              /*data*/
              {
                self.send('remove');
              }).catch(function ()
              /*data*/
              {
                self.send('remove');
              });
            }).catch(data => {
              if (data.status && data.status.code) {
                this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
              } else {
                this.notifications.show("Custom profile image service temporarily unavailable.");
              }
            });
          });
        });
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    },
    didInsertElement: function didInsertElement() {
      /*
       this._super();
         var self = this;
        //  cropper settings
       // --------------------------------------------------------------------------
        // create new object crop
       // you may change the "one" variable to anything
       var one = new CROP();
       self.set('cropper', one);
       // link the .default class to the crop function
       one.init('.default');
        // load image into crop
       one.loadImg(this.get('session.profileImageURL'));
         //  on click of .upload class, open .uploadfile (input file)
       // --------------------------------------------------------------------------
       */
      this._super.apply(this, arguments);

      let self = this;

      function loadImageFile() {
        if (document.getElementById("uploadfile").files.length === 0) return;
        var oFile = document.getElementById("uploadfile").files[0];

        if (!rFilter.test(oFile.type)) {
          return;
        }

        oFReader.readAsDataURL(oFile);
      }

      let uploadfile = document.getElementById('uploadfile');
      uploadfile.addEventListener('change', function () {
        loadImageFile();
        let uploadform = document.getElementById('uploadform'); // resets input file

        uploadform.reset();
      }); //  get input type=file IMG through base64 and send it to the cropper
      // --------------------------------------------------------------------------

      let oFReader = new FileReader();
      /* eslint-disable no-useless-escape */

      let rFilter = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i;

      oFReader.onload = function (oFREvent) {
        self.get('croppie').bind({
          url: oFREvent.target.result
        }).then(() => {
          self.get('croppie').setZoom(0);
          self.set('isEditing', true);
        });
      };

      this.set('croppie', new Croppie(document.getElementsByClassName('croppie')[0]));
      self.get('croppie').bind({
        url: self.get('reduxStore.state.profileImageURL')
      }).then(() => {
        self.get('croppie').setZoom(0);
      }).catch(() =>
      /*e*/
      {// console.log(e)
      });
    } // end didInsertElement

  });

  _exports.default = _default;
});