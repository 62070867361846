define("web-client/services/init-app-utils/get-account-license-summary", ["exports", "web-client/api", "lodash-es/eq", "lodash-es/get"], function (_exports, _api, _eq, _get) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAccountLicenseSummary;

  function getAccountLicenseSummary() {
    let reduxStore = this.reduxStore;
    return function getAccountLicenseSummary(callback) {
      (0, _api.startupLog)({
        actionText: "Loading Account Summary"
      });
      (0, _api.getAccountLicenseSummary)().then(function (data) {
        reduxStore.dispatch({
          type: 'INITIALIZE_ACCOUNT_LICENSE_SUMMARY',
          licenseSummary: data.licenseSummary
        });
        callback();
      }).catch(function (data) {
        reduxStore.dispatch({
          type: 'INITIALIZE_ACCOUNT_LICENSE_SUMMARY',
          mustUpdatePassword: (0, _eq.default)((0, _get.default)(data, "status.code"), 3)
        });
        callback('Failed getAccountLicenseSummary');
      });
    };
  }
});