define("web-client/components/notlicensed-management-table/component", ["exports", "tabulator-tables", "lodash-es/each", "lodash-es/filter", "lodash-es/sortBy", "lodash-es/map", "lodash-es/compact", "lodash-es/split", "web-client/api"], function (_exports, _tabulatorTables, _each, _filter, _sortBy, _map, _compact, _split, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: [''],
    globalActions: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    notifications: Ember.inject.service(),
    loading: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    item: {},
    mayChange: {},
    customerId: "123123",
    invResent: false,
    table: null,
    filteredItems: {},
    filterUsername: '',
    filterEmail: '',
    filterMfa: '',
    filterName: '',
    filterStatus: 'Any',
    filterRole: 'Any',
    selectedCount: 0,
    tableBuilt: false,

    init() {
      this._super.apply(this, arguments);
    },

    didUpdate() {
      // this._super(...arguments);
      //console.log("did update element " + this.tableBuilt);
      if (!this.tableBuilt) this.generateTable();
    },

    totalItemsAvailable: Ember.computed('filteredItems', function () {
      if (this.tableBuilt) this.generateTable();
      return this.filteredItems.length;
    }),
    selectedCountDisplay: Ember.computed('selectedCount', function () {
      return this.selectedCount;
    }),
    showRemoveSelectedMembers: Ember.computed('filteredItems', 'selectedCount', function () {
      let allMatch = true;
      let table = this.get('table');
      let rowCount = 0;

      if (null != table && this.tableBuilt) {
        for (const item of table.getSelectedData()) {
          rowCount += 1;

          if (item.status === 'no license') {// This is a row which is OK
          } else {
            allMatch = false;
            break;
          }
        }
      }

      return rowCount > 0 && allMatch;
    }),

    getSelectedIds() {
      let ids = [];
      let table = this.get('table');

      if (null != table && this.tableBuilt) {
        for (const item of table.getSelectedData()) {
          ids.push(item.customerId);
        }
      }

      return ids;
    },

    deselectAllRows() {
      let table = this.get('table');

      if (null != table) {
        table.deselectRow();
      }
    },

    generateTable() {
      var tableData = JSON.stringify(this.filteredItems);
      this.set("selectedCount", 0);
      this.set('tableBuilt', false);
      var self = this;
      var table = new _tabulatorTables.TabulatorFull("#notlicensed-table", {
        layout: "fitColumns",
        movableRows: true,
        reactiveData: true,
        movableColumns: true,
        responsiveLayout: "collapse",
        selectable: true,
        //          selectableRangeMode:"click",
        //          selectablePersistence:false, // disable rolling selection
        index: "customerId",
        columns: [{
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active" //only toggle the values of the active filtered rows

          },
          headerHozAlign: "center",
          hozAlign: "center",
          headerSort: false,
          width: 20
        }, {
          title: "",
          field: "domainVerified",
          width: 40,
          hozAlign: "center",
          formatter: function formatter(cell, formatterParams, onRendered) {
            //cell - the cell component
            //formatterParams - parameters set for the column
            //onRendered - function to call when the formatter has been rendered
            let rv = "";

            if (cell.getValue() === true) {
              rv = rv.concat("<ion-icon name=\"verified-badge\" size=\"small\" class=\"gold\"></ion-icon>");
            }

            return rv;
          }
        }, {
          title: "Username",
          field: "username"
        }, {
          title: "Email",
          field: "email"
        }, {
          title: "Name",
          field: "name",
          formatter: "plaintext"
        }, {
          title: "Last Login",
          field: "lastLogin",
          formatter: "plaintext"
        }, {
          title: "Status",
          field: "status",
          hozAlign: "center",
          cssClass: "license-table-status"
        }]
      });
      table.on("tableBuilt", function () {
        //console.log("setting data table built: " + tableData);
        table.setData(tableData);
        self.set('tableBuilt', true);
      });
      table.on("rowSelectionChanged", function (data, rows) {
        let count = data.length; //          document.getElementById("select-stats").innerHTML = count;

        self.set('selectedCount', count);
      });
      this.set('table', table);
    },

    filteredRoles: Ember.computed('item', function () {
      // Return the original list
      return this.item.roles;
    }),
    allowRemoveUser: Ember.computed('item', function () {
      var allow = true;
      this.item.roles.forEach(function (role, index) {
        //console.log(role);
        if ("owner" === role || "admin" === role) {
          allow = false; //console.log("setting flag to false");
        }
      });
      return allow;
    }),
    actions: {
      removeSelectedMembers: function removeSelectedMembers() {
        let ids = this.getSelectedIds();

        if (ids.length > 0) {
          const deferred = Ember.RSVP.defer();
          const self = this;
          self.loading.show('Removing team members');
          (0, _api.removeVerifiedDomainMemberships)(ids).then(data => {
            self.loading.dismiss();
            deferred.resolve();
          }).catch(data => {
            if (!has(data, 'status.code') || isNumber(data)) {
              this.notifications.show(this.i18n.t('Common.Errors.UnexpectedConnectionFailure.Text'), 'alert');
            } else {
              if (data.status.code === 100) {
                this.set('showInviteErrors', true);
                this.inviteErrors.push("While trying to remove team members the server reported a general error.");
              }
            }

            self.loading.dismiss();
            deferred.reject(data);
          });
          deferred.resolve();
        }

        this.deselectAllRows();
      },
      deselectAll: function deselectAll() {
        this.deselectAllRows();
      }
    }
  });

  _exports.default = _default;
});