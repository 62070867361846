define("web-client/actions/retrieve-user-id", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = retrieveUserID;

  function retrieveUserID(emailAddress) {
    (0, _api.lostUserID)({
      email: emailAddress
    }).then(() => {
      this.reduxStore.dispatch({
        type: 'RETRIEVE_USER_SUCCESS'
      });
    }).catch(data => {
      this.actionUIErrorHandler(data);
    });
  }
});