define("web-client/mixins/base-route", ["exports", "lodash-es/flowRight", "lodash-es/compact", "lodash-es/flatten", "lodash-es/map", "lodash-es/includes", "web-client/actions/action-ui-error-handler"], function (_exports, _flowRight, _compact, _flatten, _map, _includes, _actionUiErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function arraysIdentical(a, b) {
    var i = a.length;

    if (i !== b.length) {
      return false;
    }

    while (i--) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }

  var getValidAuthStates = function getValidAuthStates(currentState) {
    var isLoggedIn = true;
    var isOobValid = true;
    var isKeySet = true;
    var isPrivateKeySet = true;
    var isPublicKeySet = true;
    var mustUpdatePassword = true;
    var isPackingKeySet = true;
    var currentStateArray = [currentState.loggedIn, currentState.oobValid, currentState.isKeySet, currentState.isPublicKeySet, currentState.isPrivateKeySet, currentState.mustUpdatePassword, currentState.packingKeySet];
    var states = [{
      valid: ["login", "register", "register.team", "register.business", "register.invited", "resetpw", "retrieveuser", "validate", "validate-email-change", "verifyreset", "about"],
      state: [!isLoggedIn, !isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["login", "register", "register.team", "register.business", "register.invited", "resetpw", "retrieveuser", "validate", "validate-email-change", "verifyreset", "about"],
      state: [!isLoggedIn, isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["login", "register", "register.team", "register.business", "register.invited", "resetpw", "retrieveuser", "about", "validate-email-change"],
      state: [!isLoggedIn, !isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["already-open"],
      state: [!isLoggedIn, !isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["already-open"],
      state: [!isLoggedIn, isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["oob"],
      state: [isLoggedIn, !isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["oob"],
      state: [isLoggedIn, !isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["createpassphrase"],
      state: [isLoggedIn, isOobValid, !isKeySet, !isPrivateKeySet, !isPublicKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["createpassphrase"],
      state: [isLoggedIn, isOobValid, isKeySet, !isPrivateKeySet, !isPublicKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["updatepassword"],
      state: [isLoggedIn, isOobValid, !isKeySet, !isPublicKeySet, !isPrivateKeySet, mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["locked"],
      state: [isLoggedIn, isOobValid, isKeySet, isPublicKeySet, isPrivateKeySet, !mustUpdatePassword, !isPackingKeySet]
    }, {
      valid: ["passwords", "dashboard", "dashboard.security-stats", "dashboard.system-updates", "verifyreset", "validate-email-change", "validate", "connections", "settings", "validate-email-change", "settings.license-summary", "settings.company", "settings.company.billing", "settings.company.company-settings", "settings.company.license-management", "settings.company.subscription", "settings.import", "settings.export", "settings.local-data", "settings.multi-factor", "settings.session-settings", "settings.session-history", "collected", "collected.password-history", "componenttest"],
      state: [isLoggedIn, isOobValid, isKeySet, isPublicKeySet, isPrivateKeySet, !mustUpdatePassword, isPackingKeySet]
    }];
    return (0, _flowRight.default)(_compact.default, _flatten.default)((0, _map.default)(states, function (item) {
      if (arraysIdentical(currentStateArray, item.state)) {
        return item.valid;
      }
    }));
  };

  var _default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    notifications: Ember.inject.service('notifications'),
    router: Ember.inject.service(),
    actionUIErrorHandler: _actionUiErrorHandler.default,
    actions: {
      goToApplication: function goToApplication() {
        this.transitionTo('passwords');
      },

      willTransition(transition) {
        if (this.reduxStore.state.allowTransitions > 0) {
          transition.abort();

          if (window.history) {
            window.history.forward();
          }
        }
      }

    },

    deactivate() {},

    destroy() {
      this._super.apply(this, arguments);
    },

    init() {
      this._super.apply(this, arguments);
    },

    afterModel: function afterModel()
    /*model, transition*/
    {
      var self = this;
      var path = this.routeName;
      var currentState = {
        loggedIn: this.get('reduxStore.state.loggedIn'),
        oobValid: this.get('reduxStore.state.oobValid'),
        isKeySet: this.get('reduxStore.state.isKeySet'),
        isPublicKeySet: this.get('reduxStore.state.isPublicKeySet'),
        isPrivateKeySet: this.get('reduxStore.state.isEncryptedPrivateKeySet'),
        mustUpdatePassword: this.get('reduxStore.state.mustUpdatePassword'),
        packingKeySet: !this.get('reduxStore.state.isLocked')
      };
      var validstates = getValidAuthStates(currentState);

      if (!(0, _includes.default)(validstates, path)) {
        if (validstates.length === 0) {
          //May need to reset state to allow login state to be reached here.
          this.transitionTo('login');
        } else {
          self.transitionTo(validstates[0]);
        }
      }
    }
  });

  _exports.default = _default;
});