define("web-client/components/password-list-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resize: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    tagName: 'div',
    classNames: ['password-list-header'],
    nameSize: 150,
    urlSize: 150,
    updatedSize: 150,
    changedSize: 150,
    scoreSize: 150,

    didInsertElement() {
      /*
        let nameElement = document.querySelector('#password-list-resizer .name-item');
       let urlElement = document.querySelector('#password-list-resizer .url-item');
       let updatedElement = document.querySelector('#password-list-resizer .updated-item');
       let changedElement = document.querySelector('#password-list-resizer .changed-item');
       let scoreElement = document.querySelector('#password-list-resizer .score-item');
        let resizer = new ColumnResizer(document.getElementById('password-list-resizer'), {
         liveDrag: true,
         draggingClass: "rangeDrag",
         gripInnerHtml: "<div class='rangeGrip'></div>",
         minWidth: 100,
         headerOnly: true,
         onResize: function () {
           if (!self.get('isDestroyed')) {
             self.set('nameSize', nameElement.offsetWidth);
             self.set('urlSize', urlElement.offsetWidth);
             self.set('changedSize', changedElement.offsetWidth);
             self.set('updatedSize', updatedElement.offsetWidth);
             self.set('scoreSize', scoreElement.offsetWidth);
           }
         },
         onDrag: function () {
           if (!self.get('isDestroyed')) {
             self.set('nameSize', nameElement.offsetWidth);
             self.set('urlSize', urlElement.offsetWidth);
             self.set('changedSize', changedElement.offsetWidth);
             self.set('updatedSize', updatedElement.offsetWidth);
             self.set('scoreSize', scoreElement.offsetWidth);
           }
         }
       })
       this._resize = function () {
         if (!self.get('isDestroyed')) {
           self.set('nameSize', nameElement.offsetWidth);
           self.set('urlSize', urlElement.offsetWidth);
           self.set('changedSize', changedElement.offsetWidth);
           self.set('updatedSize', updatedElement.offsetWidth);
           self.set('scoreSize', scoreElement.offsetWidth);
         }
       };
       this.get('resize').observe(this.get('element'));
       this.element.addEventListener('resize', this._resize);
       next(this, function () {
         window.dispatchEvent(new Event('resize'));
       })
        */
    }

  });

  _exports.default = _default;
});