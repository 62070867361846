define("web-client/services/init-app", ["exports", "platform-specific/ipc/ipc-client", "platform-specific/initialize-app/platform-init", "debug", "ui-core/neo-async-es/series", "web-client/services/init-app-utils/get-language", "web-client/services/init-app-utils/get-account-details", "web-client/services/init-app-utils/get-system-messages", "web-client/services/init-app-utils/get-account-license-summary", "web-client/services/init-app-utils/get-organization-list", "web-client/services/init-app-utils/get-key-data", "web-client/services/init-app-utils/get-oob-options", "web-client/services/init-app-utils/get-settings-data", "web-client/services/init-app-utils/get-account-limits", "web-client/services/init-app-utils/init-database", "web-client/services/init-app-utils/setup-workers", "web-client/services/init-app-utils/get-subscription-summary", "web-client/api"], function (_exports, _ipcClient, _platformInit, _debug, _series, _getLanguage, _getAccountDetails, _getSystemMessages, _getAccountLicenseSummary, _getOrganizationList, _getKeyData, _getOobOptions, _getSettingsData, _getAccountLimits, _initDatabase, _setupWorkers, _getSubscriptionSummary, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const log = (0, _debug.default)("");
  Error.stackTraceLimit = 150;
  (0, _platformInit.platformInit)(); //asmCrypto.getRandomValues.skipSystemRNGWarning = true;
  //import { getStatus } from '/api/api';

  _ipcClient.ipc.addListener('startupLog', function (action) {
    (0, _api.startupLog)(action);
  });

  var _default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    router: Ember.inject.service(),
    getLanguage: _getLanguage.default,
    getSystemMessages: _getSystemMessages.default,
    getAccountDetails: _getAccountDetails.default,
    getAccountLicenseSummary: _getAccountLicenseSummary.default,
    getKeyData: _getKeyData.default,
    getOOBOptions: _getOobOptions.default,
    getSettingsData: _getSettingsData.default,
    getAccountLimits: _getAccountLimits.default,
    initDatabase: _initDatabase.default,
    setupWorkers: _setupWorkers.default,
    getOrganizationList: _getOrganizationList.default,
    getSubscriptionSummary: _getSubscriptionSummary.default,

    startGUI() {
      //      if (this.get('reduxStore.state.networkUnavailable')) {
      //          this.router.transitionTo('system-unavailable');
      //      } else {
      if (true) {
        _ipcClient.ipc.addListener('goToAccount', () => {
          this.router.transitionTo('settings');
        });

        _ipcClient.ipc.addListener('goToComponentTest', () => {
          this.router.transitionTo('componenttest');
        });

        _ipcClient.ipc.addListener('showEditLabelDialog', () => {
          this.globalActions.showEditLabelDialog();
        });

        _ipcClient.ipc.addListener('showEditPasswordDialog', () => {
          this.globalActions.showEditPasswordDialog.perform();
        });

        _ipcClient.ipc.addListener('sessionTimedOut', () => {
          window.location.reload(true);
        });

        _ipcClient.ipc.addListener('sessionLoggedOut', () => {
          storage.remove('sessionToken');
          window.location.reload(true);
        });

        _ipcClient.ipc.addListener('versionUpdate', () => {
          let diffMins = null;
          let diffMs = null;

          if (storage.get('waitToNotifyUpdatesAgain')) {
            const previousTime = Date.parse(storage.get('waitToNotifyUpdatesAgain'));
            diffMs = previousTime - new Date();
            diffMins = Math.floor(diffMs % 86400000 % 3600000 / 60000);
          }

          if (!this.showingUpdateDialog && (Math.abs(diffMins) >= 5 || diffMins === null)) {
            const updatesAvailableHandler = Ember.RSVP.defer();
            updatesAvailableHandler.promise.then(() => {
              this.set('showingUpdateDialog', false);
              storage.set('waitToNotifyUpdatesAgain', null);
              window.location.reload(true);
            }).catch(() => {
              storage.set('waitToNotifyUpdatesAgain', new Date());
              this.set('showingUpdateDialog', false);
            });

            if (this.get('reduxStore.state.allowTransitions') < 1) {
              this.standardDialogs.showInfoDialog('updates_available', updatesAvailableHandler, false, 'size-4');
            } else {
              this.standardDialogs.showConfirmDialog('updates_available').then(() => {
                (0, _api.deleteLocalDatabase)().then(() => {
                  this.set('showingUpdateDialog', false);
                  storage.set('waitToNotifyUpdatesAgain', null);
                  window.location.reload(true);
                }).catch(() => {
                  this.set('showingUpdateDialog', false);
                  storage.set('waitToNotifyUpdatesAgain', null);
                  window.location.reload(true);
                });
              }).catch(() => {
                storage.set('waitToNotifyUpdatesAgain', new Date());
                this.set('showingUpdateDialog', false);
              });
            }

            this.set('showingUpdateDialog', true);
          }
        });
      }
    },

    startApp() {
      this.router.transitionTo('passwords');
    },

    showingUpdateDialog: false,

    reInitDatabase() {
      const loader = document.getElementById('loader');

      let callback = function callback() {
        loader.classList.add('hide');
      };

      loader.classList.remove('hide');
      this.initDatabase()(callback);
    },

    login() {
      const self = this; //document.addEventListener("securitypolicyviolation", function ( e ) {
      //
      //});

      return new Ember.RSVP.Promise(resolve => {
        (0, _series.default)([this.getLanguage(), this.setupWorkers(), this.getAccountDetails(), this.getKeyData(), this.getOrganizationList(), this.getAccountLicenseSummary(), this.getOOBOptions(), this.getSettingsData(), this.getAccountLimits(), this.getSubscriptionSummary(), this.initDatabase()], s => {
          log(s); //    self.get('reduxStore').dispatch({ type: 'INITALIZED' });

          Ember.run(self, function () {
            resolve();
            self.startApp();
          });
        });
      });
    },

    setup() {
      log("Initializing App Setup");
      const self = this;

      try {
        return new Ember.RSVP.Promise(resolve => {
          (0, _series.default)([this.getLanguage(), this.setupWorkers(), this.getSystemMessages(), this.getAccountDetails(), this.getKeyData(), this.getOrganizationList(), this.getAccountLicenseSummary(), this.getOOBOptions(), this.getSettingsData(), this.getAccountLimits(), this.getSubscriptionSummary(), this.initDatabase()], s => {
            log(s); //    self.get('reduxStore').dispatch({ type: 'INITALIZED' });

            Ember.run(self, function () {
              resolve();
              self.startGUI();
            });
          });
        });
      } catch (e) {
        console.log(e);
      }
    }

  });

  _exports.default = _default;
});