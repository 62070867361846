define("web-client/components/sign-in-dialog/component", ["exports", "web-client/mixins/auth-base-dialog", "ember-cp-validations", "ember-concurrency", "web-client/api", "web-client/config/environment", "lodash-es/filter"], function (_exports, _authBaseDialog, _emberCpValidations, _emberConcurrency, _api, _environment, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  window.getAccountDetails = _api.getAccountDetails;
  /**
   *
   */

  const Validations = (0, _emberCpValidations.buildValidations)({
    userName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "User ID can't be blank"
    }),
    password: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Password can't be blank"
    })
  });

  var _default = Ember.Component.extend(_authBaseDialog.default, Validations, {
    classNames: ['sign-in-dialog'],
    classNameBindings: [],
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    loading: Ember.inject.service(),
    loginInProgress: false,
    code: '',
    featureCode: '',
    reduxStore: Ember.inject.service(),
    showErrors: false,
    userName: '',
    password: '',
    defaultAction: 'loginToSite',
    queryParameters: null,
    initApp: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);

      try {
        if (this.queryParameters) {
          let queryCode = this.queryParameters.get('code');

          if (queryCode && queryCode.length > 0) {
            this.set('code', queryCode);
          }

          let queryFeatureCode = this.queryParameters.get('featureCode');

          if (queryFeatureCode && queryFeatureCode.length > 0) {
            this.set('featureCode', queryFeatureCode);
          }
        }
      } catch (_unused) {
        console.log("error getting code from " + this.queryParameters);
      }
    },

    didRender() {
      // If there is a code -display a message
      if (this.code) {
        //console.log(this.code);
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(this.code)), 'alert');
      } else {//console.log("no code");
      }
    },

    featurecodeunlock: Ember.computed(function () {
      let queryFeatureCode = this.get('featureCode');

      if (queryFeatureCode && queryFeatureCode === 'google') {
        return true;
      } else {
        return false;
      }
    }),
    currentSize: Ember.computed('emailVerificationRequired', function () {
      return this.emailVerificationRequired ? 'size-2' : 'size-2';
    }),
    watchEmailVerification: Ember.observer('emailVerificationRequired', function () {
      Ember.run.later('afterRender', () => {
        if (this.emailVerificationRequired) {
          let input = this.get('element').querySelector("#verificationCode");

          if (input) {
            input.componentOnReady().then(() => {
              input.setFocus();
            });
          }
        }
      }, 200);
    }),
    headerMessages: Ember.computed(function () {
      var data = this.get('reduxStore.state.systemMessages');
      return (0, _filter.default)(data, element => {
        return element.type === 'login-header';
      });
    }),
    verifyEmail: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let self = this;
      this.reduxStore.dispatch({
        type: 'SET_VALIDATE_DIALOG_LOADING',
        isValidateDialogLoading: true
      });
      (0, _api.verifyEmail)(this.verificationCode).then(function (ret) {
        //        $FPROM.trackSignup({
        //            uid: ret.cus
        //          },
        //          function () {
        (0, _api.getAccountDetails)();
        var username = self.get('userName');
        var password = self.get('password');
        self.get('reduxStore').dispatch({
          type: 'SET_VALIDATE_DIALOG_LOADING',
          isValidateDialogLoading: false
        });
        self.get('reduxStore').dispatch({
          type: 'SET_EMAIL_VALID'
        });
        self.get('loginToSite')(username, password); //            self.get('loginToSiteGoogle')();

        deferred.resolve(); //          );

        /*  let username = self.get('userName');
          let password = self.get('password');
          self.get('reduxStore').dispatch({
            type: 'SET_VALIDATE_DIALOG_LOADING',
            isValidateDialogLoading: false
          });
          self.get('reduxStore').dispatch({ type: 'SET_EMAIL_VALID' });
          self.get('reduxStore').dispatch({ type: 'EMAIL_VERIFICATION_REQUIRED_RESET' });
        */

        deferred.resolve();
      }) //      .catch((data) => {
      //        console.error(data);
      //        });
      .catch(function ()
      /*data*/
      {
        self.get('notifications').show("The verification code is invalid or expired.", 'alert');
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      blockSubmit()
      /*e*/
      {},

      goToLogin: function goToLogin() {
        // $(this.get('element')).hide();
        this.goToLogin();
      },

      resetPassword() {
        this.resetPassword();
      },

      register() {
        this.register();
      },

      retrieveUserID() {
        this.retrieveUserID();
      },

      goToPreviousVersion() {
        window.location.href = "https://www.passpack.com/online";
      },

      goToTransitionInstructions() {
        window.location.href = "https://support.passpack.com/hc/en-us/articles/115005898126";
      },

      loginToSite: function loginToSite() {
        this.set('loginInProgress', true);
        this.loading.show('Logging In');
        let username = this.userName;
        let password = this.password;
        let self = this;
        this.validate().then(validResult => {
          if (validResult.validations.get('isValid')) {
            self.get('loginToSite')(username, password);
          }
        }).finally(function () {
          self.set('showErrors', true);
        });
        this.set('loginInProgress', false);
        this.loading.dismiss();
      },
      loginToSiteGoogle: function loginToSiteGoogle() {
        var baseUrl = _environment.default.apiHost;
        window.location.href = baseUrl + '/portal/googleLogin';
      },
      resendVerificationEmail: function resendVerificationEmail() {
        this.resendVerificationEmail(this.userName);
      },
      closeEmailVerification: function closeEmailVerification() {
        this.set('emailVerificationRequired', false);
        this.set('emailVerificationSent', false);
        this.goToLogin();
      }
    }
  });

  _exports.default = _default;
});