define("web-client/settings/company/controller", ["exports", "lodash-es/each", "lodash-es/filter"], function (_exports, _each, _filter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    reduxStore: Ember.inject.service(),
    globalActions: Ember.inject.service(),
    i18n: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    mayViewCompany: false,
    mayViewCompanyDetails: false,
    mayChangeCompanyDetails: false,

    init() {
      // if the user is not allowed redirect them elsewhere.
      let companySettingRight = this.globalActions.doesPermissionExist('settings', 'company', 'ro');
      let sponsoredOnly = this.get('reduxStore.state.licenseSummary.sponsoredOnly');
      this.mayViewCompany = !sponsoredOnly && companySettingRight;
      this.mayViewCompanyDetails = this.globalActions.doesPermissionExist('settings', 'company.details', 'ro');
      this.mayChangeCompanyDetails = this.globalActions.doesPermissionExist('settings', 'company.details', 'rw');
    },

    actions: {}
  });

  _exports.default = _default;
});