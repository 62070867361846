define("web-client/components/team-dialog-menu/component", ["exports", "ui-core/mixins/dialog-base"], function (_exports, _dialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-fit',
    classNames: [''],
    content: null,
    escAction: "cancelDialog",

    init() {
      this._super.apply(this, arguments);
    },

    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});