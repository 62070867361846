define("web-client/components/ion-tree-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-item',
    classNames: [],
    layoutValue: "icon-start",
    button: true,
    detail: true,
    attributeBindings: ['layoutValue:layout', 'button', 'detail'],
    actions: {},

    willDestroyElement() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); //  this.element.setAttribute('layout',this.get('layout'));


      this.element.addEventListener('click', () => {
        this.click();
      });
    }
  });

  _exports.default = _default;
});