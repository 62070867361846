define("web-client/api/get-language-error-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLanguageErrorKey = getLanguageErrorKey;

  function getLanguageErrorKey(error) {
    //var text = errors[error];
    var text = "API.statusCode." + error;
    return text;
  }
});