define("web-client/components/manage-labels-dialog/component", ["exports", "ui-core/mixins/dialog-base", "lodash-es/includes", "web-client/api", "lodash-es/filter", "lodash-es/sortBy", "ember-concurrency", "web-client/config/environment"], function (_exports, _dialogBase, _includes, _api, _filter, _sortBy, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dialogBase.default, {
    size: 'size-3',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    reduxStore: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    version: _environment.default.clientVersion,
    selectedItems: null,
    loading: Ember.inject.service(),
    filterText: '',
    filteredItems: Ember.computed('items.[]', 'items.@each', 'filterText', function () {
      return Ember.A((0, _sortBy.default)((0, _filter.default)(this.items, item => {
        return item.label.toLowerCase().includes(this.filterText.toLowerCase());
      }), "name"));
    }),
    items: Ember.computed('reduxStore.state.data.labels.content', 'reduxStore.state.data.labels.content.[]', function () {
      return this.reduxStore.state.data.labels.content;
    }),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "saveLabel" : "cancelDialog";
    }),
    deleteSelectedLabels: (0, _emberConcurrency.task)(function* () {
      const deferred = Ember.RSVP.defer();
      let self = this;
      this.standardDialogs.showConfirmDialog('confirm-delete-label').then(() => {
        this.loading.show('Deleting label');
        return (0, _api.deleteLabels)(this.selectedItems);
      }).then(() => {
        this.reduxStore.state.data.labels.selected.removeObjects(this.selectedItems);
        return (0, _api.getLabels)();
      }).then(data => {
        self.reduxStore.dispatch({
          type: 'UPDATE_LABELS',
          labels: data
        });
        self.loading.dismiss();
        deferred.resolve();
      }).catch(data => {
        this.loading.dismiss();

        if (data !== 'cancelled') {//  this.actionUIErrorHandler(data);
        }

        deferred.reject();
      });
      yield deferred.promise;
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('selectedItems', Ember.A([]));
    },

    addNewLabel: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      let args = {
        newLabel: "",
        onComplete: deferred
      };
      this.standardDialogs.showDialog('label-dialog', args);
      yield deferred.promise;
    }).drop(),
    actions: {
      setItemSelectedState(itemId, newState) {
        if (newState) {
          if (!(0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.pushObject(itemId);
          }
        } else {
          if ((0, _includes.default)(this.selectedItems, itemId)) {
            this.selectedItems.removeObject(itemId);
          }
        }
      },

      cancelDialog: function cancelDialog() {
        this.send('remove');
        this.complete.resolve();
      }
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      let self = this;
      let search = this.element.getElementsByClassName('item-search')[0];

      if (search) {
        search.addEventListener('ionChange', function (e) {
          self.set('filterText', e.detail.value);
        });
      }
    }

  });

  _exports.default = _default;
});