define("web-client/components/header-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bt+owssV",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"ion-segment\"],[11,\"class\",\"header-navigation-segment\"],[11,\"value\",\"reduxStore.state.selectedHeaderButton\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"licensed\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,\"company-selector\"],false],[0,\"\\n      \"],[1,[29,\"ion-segment-button\",null,[[\"value\",\"tab\",\"layout\",\"icon\",\"text\",\"tap\"],[\"dashboard\",\"dashboard\",\"icon-start\",\"stats\",\"DASHBOARD\",[29,\"service-action\",[\"global-actions\",\"goToDashboard\"],null]]]],false],[0,\"\\n      \"],[1,[29,\"ion-segment-button\",null,[[\"value\",\"tab\",\"layout\",\"icon\",\"text\",\"tap\"],[\"passwords\",\"passwords\",\"icon-start\",\"list-box\",\"PASSWORDS\",[29,\"service-action\",[\"global-actions\",\"goToPasswords\"],null]]]],false],[0,\"\\n      \"],[1,[29,\"ion-segment-button\",null,[[\"value\",\"tab\",\"icon\",\"layout\",\"text\",\"tap\",\"badge\"],[\"connections\",\"connections\",\"contact\",\"icon-start\",\"CONNECTIONS\",[29,\"service-action\",[\"global-actions\",\"goToConnections\"],null],[25,[\"reduxStore\",\"state\",\"data\",\"invitationCount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[29,\"ion-segment-button\",null,[[\"value\",\"tab\",\"icon\",\"layout\",\"text\",\"tap\"],[\"settings\",\"settings\",\"settings\",\"icon-start\",\"SETTINGS\",[29,\"service-action\",[\"global-actions\",\"goToSettings\"],null]]]],false],[0,\"\\n    \"],[1,[29,\"ion-segment-button\",null,[[\"value\",\"tab\",\"icon\",\"layout\",\"text\",\"tap\"],[\"userManual\",\"userManual\",\"paper\",\"icon-start\",\"USER MANUAL\",[29,\"service-action\",[\"global-actions\",\"goToUserManual\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/header-navigation/template.hbs"
    }
  });

  _exports.default = _default;
});