define("web-client/components/stat-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zh/qrtJw",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stat-text-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"variable-number\"],[9],[1,[23,\"variableNumber\"],false],[0,\" / \"],[1,[23,\"fixedNumber\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"stat-label\"],[9],[1,[23,\"statLabel\"],false],[10],[0,\"\\n\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/stat-display/template.hbs"
    }
  });

  _exports.default = _default;
});