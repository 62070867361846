define("web-client/components/company-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    color: "primary",
    attributeBindings: ["color"],
    reduxStore: Ember.inject.service(),
    showCompanySelector: false,
    companySections: Ember.computed('reduxStore.state.companyData', function () {
      let companyData = this.reduxStore.state.get('companyData');
      return companyData;
    }),
    actions: {
      selectWorkingCompany: function selectWorkingCompany(value) {
        console.log("selecting working company: " + value);
      }
    }
  });

  _exports.default = _default;
});