define("web-client/components/about-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pquBZ3uH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nb-dialog\",null,null,{\"statements\":[[0,\"  \"],[1,[29,\"nb-dialog-header\",null,[[\"title\",\"cancelDialog\"],[\"About Passpack\",[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n\\n\\n\\n\"],[4,\"nb-dialog-body\",null,null,{\"statements\":[[7,\"ion-grid\"],[9],[0,\"\\n\"],[7,\"ion-row\"],[9],[0,\"\\n\"],[7,\"ion-col\"],[11,\"padding-top\",\"24px\"],[11,\"padding-bottom\",\"24px\"],[11,\"class\",\"ion-col-overflow-hidden\"],[9],[0,\"\\n\"],[7,\"ion-item\"],[9],[0,\"\\n\"],[7,\"ion-label\"],[9],[0,\"\\nPasspack Team Password Manager\\n\"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[7,\"ion-item\"],[11,\"lines\",\"full\"],[9],[0,\"\\n\\n\"],[7,\"ion-label\"],[9],[0,\"\\nCopyright 2024 Passpack Inc. All rights reserved.\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ion-item\"],[11,\"lines\",\"none\"],[9],[0,\"\\n\"],[7,\"ion-label\"],[9],[0,\"\\n         Passpack v\"],[1,[23,\"version\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[4,\"nb-dialog-footer\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"left-buttons\"],[9],[0,\"\\n\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n\\n      \"],[1,[29,\"nb-button\",null,[[\"text\",\"title\",\"on-tap\"],[[29,\"t\",[\"Common.Buttons.Close.Text\"],null],[29,\"t\",[\"Common.Buttons.Close.Text\"],null],[29,\"action\",[[24,0,[]],\"cancelDialog\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/about-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});