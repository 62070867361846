define("web-client/components/create-team-dialog/component", ["exports", "ui-core/mixins/dialog-base", "debug", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/utils/color-options", "lodash-es/clone", "web-client/api/create-team", "web-client/api/get-teams", "lodash-es/find"], function (_exports, _dialogBase, _debug, _emberRedux, _emberConcurrency, _emberCpValidations, _colorOptions, _clone, _createTeam, _getTeams, _find) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const log = (0, _debug.default)("");
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Name can't be blank"
    })
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    size: 'size-4',
    content: null,
    i18n: Ember.inject.service(),
    originalContent: "",
    isEditMode: false,
    classNames: ['create-team-dialog'],
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),
    escAction: "cancelDialog",
    notifications: Ember.inject.service(),
    standardDialogs: Ember.inject.service(),
    globalActions: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    name: Ember.computed.alias('team.name'),
    color: Ember.computed.alias('team.color'),
    colorOptions: _colorOptions.colorOptions,
    hasChanged: Ember.computed('team.{name,color,defaultRole,description}', function () {
      return this.get('team.name') !== this.get('originalTeam.name') || this.get('team.color') !== this.get('originalTeam.color') || this.get('team.defaultRole') !== this.originalTeam.defaultRole || this.team.description !== this.originalTeam.description;
    }),
    showErrors: false,
    showNameErrors: Ember.computed('showErrors', 'name', function () {
      return this.showErrors || this.name.length;
    }),
    defaultAction: Ember.computed('hasChanged', function () {
      return this.hasChanged ? "createTeam" : "cancelDialog";
    }),

    init() {
      this._super.apply(this, arguments);

      this.set('team', Ember.Object.extend({
        name: '',
        description: '',
        defaultRole: 4,
        color: "grey-800"
      }).create());
      this.set('originalTeam', (0, _clone.default)(this.team));
    },

    setColor: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.standardDialogs.showDialog('color-picker-dialog', {
        complete: deferred
      }, "small");
      let colorOption = yield deferred.promise;

      if (colorOption) {
        this.set('team.color', colorOption.value);
      }
    }).drop(),
    permissionOptions: Ember.computed(function () {
      return [{
        value: 4,
        display: 'Read Only'
      }, {
        value: 3,
        display: 'Read Write'
      }];
    }),
    createTeam: (0, _emberConcurrency.task)(function* () {
      const self = this;
      const deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          this.loading.show('Creating Team');
          (0, _createTeam.createTeam)(this.team.name, this.team.description, this.team.color, this.team.defaultRole).then(teamCreationResult => {
            this.globalActions.getPasswords().then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_PASSWORDS',
                passwords: data
              });
              return (0, _getTeams.getTeams)();
            }).then(data => {
              self.reduxStore.dispatch({
                type: 'UPDATE_TEAMS',
                teams: data
              });
              this.loading.dismiss();

              if (teamCreationResult.id) {
                let found = (0, _find.default)(this.reduxStore.state.data.teams.content, team => {
                  if (team.id === teamCreationResult.id) {
                    return team;
                  }
                });

                if (found) {
                  this.globalActions.showEditTeamDialog(found, true);
                }
              }

              self.send('remove');
              deferred.resolve();

              if (self.onComplete) {
                self.onComplete.resolve(teamCreationResult);
              }
            }).catch(e => {
              self.loading.dismiss();
            });
          }).catch(e => {
            self.loading.dismiss();
            this.globalActions.actionUIErrorHandler(e);
            deferred.reject(e);
          });
        } else {
          self.loading.dismiss();
          self.set('showErrors', true);
          deferred.resolve();
        }
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      onSelectColor: function onSelectColor(color) {
        this.set('team.color', color);
      },
      cancelDialog: function cancelDialog() {
        this.send('remove');

        if (this.onComplete) {
          this.onComplete.resolve();
        }
      },

      setDefaultPermissions(value) {
        this.set("team.defaultRole", value);
      },

      discardChanges() {
        this.set('team', (0, _clone.default)(this.originalTeam));
        let input = this.get('element').querySelector("ion-input:first-of-type");
        Ember.run.later(this, function () {
          if (input) {
            input.componentOnReady().then(() => {
              input.setFocus();
            });
          }
        }, 300);
      }

    },

    afterShow() {
      this._super.apply(this, arguments);

      let input = this.get('element').querySelector("ion-input:first-of-type");
      Ember.run.later(this, function () {
        if (input) {
          input.componentOnReady().then(() => {
            input.setFocus();
          });
        }
      }, 300);
    }

  });

  _exports.default = _default;
});