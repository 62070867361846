define("web-client/retrieveuser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j3KNhERJ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[29,\"account-recovery-dialog\",null,[[\"goToLogin\",\"retrieveUserID\",\"goToRetrieveUserID\",\"retrieveUserSuccess\",\"goToResetPW\"],[[29,\"service-action\",[\"global-actions\",\"goToLogin\"],null],[29,\"service-action\",[\"global-actions\",\"retrieveUserID\"],null],[29,\"service-action\",[\"global-actions\",\"goToRetrieveUserID\"],null],[25,[\"reduxStore\",\"state\",\"retrieveUserSuccess\"]],[29,\"service-action\",[\"global-actions\",\"goToResetPW\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/retrieveuser/template.hbs"
    }
  });

  _exports.default = _default;
});