define("web-client/api/verify-email-change", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verifyEmailChange = void 0;

  const verifyEmailChange = _ipcClient.ipc.makeRequestFunction('verifyEmailChange');

  _exports.verifyEmailChange = verifyEmailChange;
});