define("web-client/locked/route", ["exports", "web-client/mixins/base-route", "web-client/mixins/authed-route"], function (_exports, _baseRoute, _authedRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_baseRoute.default, _authedRoute.default, {
    fromRoute: '',
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    prevRoute: null,
    actions: {
      goToPreviousRoute() {
        if (this.get('previous.route') && this.get('previous.route') !== 'lock') {
          this.transitionTo(this.get('previous.route'));
        } else {
          this.transitionTo('passwords');
        }
      }

    },

    renderTemplate() {
      this.render('locked', {
        into: 'application',
        outlet: 'content'
      });
    }

  });

  _exports.default = _default;
});