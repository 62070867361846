define("web-client/components/header-infobar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tZt0JYVN",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"header-grid\"],[9],[0,\"\\n \"],[7,\"table\"],[11,\"class\",\"system-message-grid\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"color\",\"primary\"],[11,\"id\",\"ember5\"],[11,\"class\",\"ember-view\"],[9],[0,\"  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"td\"],[11,\"style\",\"vertical-align:middle\"],[9],[7,\"ion-icon\"],[11,\"class\",\"sytemMessageIcon red\"],[11,\"name\",\"warning\"],[11,\"size\",\"large\"],[9],[10],[10],[0,\"\\n    \"],[7,\"td\"],[11,\"style\",\"vertical-align:middle\"],[11,\"class\",\"bold red\"],[9],[0,\"This version of Passpack will is deprecated and will be removed from service 8/30/2024 .  Please change any bookmarks to point to https://app.passpack.com . All your passwords and teams are ready for use; no data migration required.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/header-infobar/template.hbs"
    }
  });

  _exports.default = _default;
});