define("web-client/components/rail-button/component", ["exports", "ui-core/components/nb-button/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['rail-button'],
    classNameBindings: ['show', 'selected'],
    countClass: '',
    show: false,
    count: 0,
    size: 1,
    type: ''
  });

  _exports.default = _default;
});