define("web-client/settings/left-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X+uQX/KN",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"settings-sidebar-menu\",null,[[\"setCurrentTab\",\"currentTab\"],[[29,\"service-action\",[\"global-actions\",\"setCurrentPasswordsSidebarMenuTab\"],null],[25,[\"reduxStore\",\"state\",\"passwordsSidebarMenuTab\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/settings/left-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});