define("web-client/helpers/currency-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencyFormat = currencyFormat;
  _exports.default = void 0;
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2 // the default value for minimumFractionDigits depends on the currency
    // and is usually already 2

  });

  function currencyFormat(params) {
    let value = params[0];

    if (value) {
      return formatter.format(value / 100);
    } else {
      return formatter.format(0);
    }
  }

  var _default = Ember.Helper.helper(currencyFormat);

  _exports.default = _default;
});