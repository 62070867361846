define("web-client/components/quick-copy-dialog/quick-copy-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LVU8E824",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ion-label\"],[11,\"position\",\"fixed\"],[9],[1,[25,[\"field\",\"name\"]],false],[10],[0,\"\\n\"],[7,\"ion-icon\"],[11,\"name\",\"copy\"],[11,\"slot\",\"end\"],[11,\"class\",\"item-icon\"],[9],[10],[0,\"\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/quick-copy-dialog/quick-copy-item/template.hbs"
    }
  });

  _exports.default = _default;
});