define("web-client/components/password-search-item/component", ["exports", "lodash-es/keys", "lodash-es/map", "lodash-es/flatten", "lodash-es/uniq", "ember-drag-drop/components/draggable-object", "web-client/utils/misc-utils", "tabbable", "lodash-es/indexOf"], function (_exports, _keys, _map, _flatten, _uniq, _draggableObject, _miscUtils, _tabbable, _indexOf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  /*
  const openHilightSpan = "\x3C\x73\x70\x61\x6E\x20\x63\x6C\x61\x73\x73\x3D\x22\x73\x65\x61\x72\x63\x68\x2D\x68\x69\x67\x68\x6C\x69\x67\x68\x74\x22\x3E";// utf8.encode('<span class="search-highlight">');
  const closeHilightSpan = "\x3C\x2F\x73\x70\x61\x6E\x3E";
  */
  var _default = _draggableObject.default.extend({
    reduxStore: Ember.inject.service(),
    gestures: Ember.inject.service(),
    classNames: ['password-search-item'],
    classNameBindings: ['password::fade-it', 'password.isSelected:selected', 'hover'],
    selected: true,
    attributeBindings: ["touchAction:touch-action"],
    touchAction: "none",
    propName: 'password',
    password: null,
    copyData: '',
    nameSize: 0,
    urlSize: 0,
    nameElement: null,
    urlElement: null,
    globalActions: Ember.inject.service(),
    SizeObserver: Ember.observer('nameSize', 'nameElement', 'urlSize', 'urlElement', 'updatedSize', 'changedSize', 'updatedElement', 'changedElement', function () {
      Ember.run.once(this, this.performResize);
    }),
    performResize: function performResize() {
      const self = this;
      requestAnimationFrame(function () {
        self.get('nameElement').style.flexBasis = 'calc(' + self.get('nameSize') + 'px)';
        self.get('urlElement').style.flexBasis = 'calc(' + self.get('urlSize') + 'px)';
        self.get('changedElement').style.flexBasis = 'calc(' + self.get('changedSize') + 'px)';
        self.get('updatedElement').style.flexBasis = 'calc(' + self.get('updatedSize') + 'px)';
      });
    },
    isSelected: Ember.computed('reduxStore.state.data.passwords.{selected.[],selected}', 'password', function () {
      return this.get('reduxStore.state.data.passwords.selected').includes(this.get('password.id'));
    }),
    matches: Ember.computed('password', function () {
      const matches = (0, _keys.default)(this.get('password.matchData.metadata'));
      const matches2 = (0, _uniq.default)((0, _flatten.default)((0, _map.default)(matches, key => {
        return (0, _keys.default)(this.get('password.matchData.metadata')[key]);
      })));
      return (0, _map.default)(matches2, function (item) {
        switch (item) {
          case 'name':
            return 'Name';

          case 'url':
            return 'URL';

          case 'email':
            return 'Email Address';

          case 'username':
            return 'Username';

          case 'password':
            return 'Password';

          case 'sharedNotes':
            return 'Shared Notes';

          case 'notes':
            return 'Notes';

          case 'labels':
            return 'Label';

          case 'teams':
            return 'Team';

          case 'extraFields':
            return 'Additional Fields';

          default:
            return '';
        }
      }).join(', ');
    }),
    displayName: Ember.computed('password.name', 'filterText', function () {
      const self = this; //var filterText = self.get('filterText').toUpperCase();
      //var regex = new RegExp("(" + utf8.encode(escapeRegExp(escapeHtml(filterText))) + ")", "ig");

      /*  if (self.get('filterText') !== '') {
           if (escapeHtml(self.get('password.name')).toUpperCase().indexOf(escapeHtml(filterText).toUpperCase()) !== -1) {
            //  return escapeHtml(self.get('decryptedName')).replace(regex, openHilightSpan + '$1' + closeHilightSpan);
            return utf8.decode(utf8.encode(escapeHtml(self.get('name'))).replace(regex, openHilightSpan + '$1' + closeHilightSpan));
          }
          else {
            return escapeHtml(self.get('password.name'));
          }
        }
        else {
          return escapeHtml(self.get('password.name'));
        }*/

      return (0, _miscUtils.escapeHtml)(self.get('password.name'));
    }),
    filterText: Ember.computed('reduxStore.state.data.passwords.filterText', function () {
      return this.get('reduxStore.state.data.passwords.filterText');
    }),
    actions: {
      onCopyButtonDown: function () {
        var _onCopyButtonDown = _asyncToGenerator(function* ()
        /*context , field , e*/
        {
          /*   e.preventDefault();
             e.stopPropagation();
              loadingForCopy = true;
             let self = this;
             let password = null;
             let pwKey = null;
              let xdata = await getPasswordById(context.id);
             password = xdata.password;
              getTeamById(password.groups[0]["id"])
                .then((groupData) => {
                  return rsaDecrypt(groupData.encryptedGroupKey, self.get('reduxStore.state.privateKey'));
               })
               .then((groupKey) => {
                  return aesDecrypt(password.key, groupKey);
               })
               .then((data) => {
                  pwKey = data;
                 return aesDecrypt(password.data, pwKey);
               })
               .then((data) => {
                  var payload = JSON.parse(data);
                 switch (field) {
                   case "password":
                     this.set('copyData', payload.password);
                     break;
                   case "username":
                     this.set('copyData', payload.username);
                     break;
                   case "email":
                     this.set('copyData', payload.email);
                     break;
                 }
                  loadingForCopy = false;
               }).catch(() => {
                 loadingForCopy = false;
               });
          */
        });

        function onCopyButtonDown() {
          return _onCopyButtonDown.apply(this, arguments);
        }

        return onCopyButtonDown;
      }(),
      copyToClipboard: function copyToClipboard()
      /*context, field, showSnackbar*/
      {
        /*   if (window.bv.name !== "Firefox") {
              let wait = () => {
               setTimeout(() => {
                 if (loadingForCopy) {
                   wait();
                 }
                 else {
                   if (this.get('copyData')) {
                     $('#copyNode').text(this.get('copyData'));
                       // "Optional" Select some text
                     var range = document.createRange();
                     range.selectNodeContents(document.getElementById('copyNode'));
                     var sel = window.getSelection();
                     sel.removeAllRanges();
                     sel.addRange(range);
                      // Use try & catch for unsupported browser
                     try {
                        // The important part (copy selected text)
                       var successful = document.execCommand('copy');
                        // "Optional" remove selected text
                       //     sel.removeAllRanges();
                       $('#copyNode').text("");
                       if (successful) {
                          showSnackbar("Field copied to clipboard");
                          //  this.sendAction('attrs.on-copied', 'success');
                         }
                        else {
                         showSnackbar("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations.", "alert");
                        }
                       } catch (err) {
                       showSnackbar("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations", "alert");
                      }
                   }
                   else {
                     showSnackbar("Nothing to copy to clipboard.", "alert");
                   }
                 }
                 }, 500);
             }
             wait();
           }
           else {
             getOwner(this).lookup('service-action:helpers').compute(['global-actions', 'showQuickCopyDialog'])(this.get('password'), true);
           }
        */
      },
      selectPassword: function selectPassword(context) {
        if (!this.reduxStore.state.data.passwords.selected.includes(context.id)) {
          this.reduxStore.state.data.passwords.selected.pushObject(context.id);
        }
      },

      tap() {
        if (this.password.isReadOnly) {
          this.globalActions.showViewPasswordDialog(this.password);
        } else {
          this.globalActions.showEditPasswordDialog.perform(this.password);
        } //   this.get('attrs.on-tap')();

      }

    },
    dragHandle: '.dragHandle',

    init() {
      this._super.apply(this, arguments);

      this.set('content', this);
    },

    dragStartAction(context, event) {
      const clonedItem = context.get('element').cloneNode(true);
      clonedItem.style.position = "absolute";
      clonedItem.style.background = "#aaa";
      clonedItem.style.top = "-200px";
      clonedItem.style.right = "0px";
      clonedItem.style.height = "36px";
      clonedItem.style.width = "500px";
      clonedItem.style.zIndex = 10000;
      clonedItem.style.opacity = 1;
      document.body.appendChild(clonedItem);
      event.dataTransfer.setDragImage(clonedItem, 0, 0); //  document.body.removeChild(clonedItem);

      context.send('selectPassword', context.get('password')); //   var count = drag.get('data.passwords.selected.content.length');
      //   $('.dragHelper').html("<div class='dragHandle'   ></div><span class='drag-text'>" + count + " passwords selected </span>").addClass('elevation-2dp');
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      const element = this.element;
      const self = this;

      this._mouseover = function () {
        if (!self.get('disabled')) {
          self.set('hover', true);
        }
      };

      element.addEventListener('mouseenter', this._mouseover);

      this._mouseout = function () {
        if (!self.get('disabled')) {
          self.set('hover', false);
        }
      };

      element.addEventListener('mouseleave', this._mouseout);
      const gestures = this.gestures;

      this._down = function (inEvent) {
        inEvent.preventDefault();
        inEvent.stopPropagation(); //    $element.addClass('hover');
      };

      this.gestures.addEventListener(this.element, 'down', this._down);

      this._tap = function (event) {
        event.preventDefault();
        event.stopPropagation();
        self.send('tap');
        var evt = new MouseEvent('down', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        document.body.dispatchEvent(evt);
      };

      this._touchstart = function (event) {
        if (!self.get('disabled')) {
          event.preventDefault();
          event.stopPropagation();
          self.send('tap', event);
        }
      };

      this._click = function (event) {
        if (!self.get('disabled')) {
          event.preventDefault();
          event.stopPropagation();
          self.send('tap', event);
        }
      };

      if (self.get('useNativeClick')) {
        element.addEventListener('click', this._click);
        element.addEventListener('touchend', this._touchstart);
      } else {
        gestures.addEventListener(element, 'tap', this._tap);
      }

      this.element.addEventListener('focusin', function () {
        if (!self.get('isDestroyed')) {
          self.set('focused', true);
        }
      });
      this.element.addEventListener('focusout', function () {
        if (!self.get("isDestroyed")) {
          self.set('focused', false);
        }
      });
      this.element.addEventListener('keydown', function (evt) {
        const key = evt.which || evt.keyCode; // enter key

        if (key === 13) {
          self.send('tap');
        } // space key


        if (key === 32) {
          self.send('tap');
        } // up arrow


        if (key === 38) {
          evt.preventDefault();
          evt.stopPropagation();
          const tabs = (0, _tabbable.default)(element.closest('.nb-menu'));

          if (tabs && tabs.length) {
            const currentIndex = (0, _indexOf.default)(tabs, element);

            if (currentIndex === 0) {
              tabs[tabs.length - 1].focus();
            } else {
              tabs[currentIndex - 1].focus();
            }
          }
        } // down arrow


        if (key === 40) {
          evt.preventDefault();
          evt.stopPropagation();
          const tabs = (0, _tabbable.default)(element.closest('.nb-menu'));

          if (tabs && tabs.length) {
            const currentIndex = (0, _indexOf.default)(tabs, element);

            if (currentIndex === tabs.length - 1) {
              tabs[0].focus();
            } else {
              tabs[currentIndex + 1].focus();
            }
          }
        }
      });
    }

  });

  _exports.default = _default;
});