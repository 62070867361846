define("web-client/helpers/passpack-date", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatDate = formatDate;
  _exports.default = void 0;

  function formatDate(params) {
    let value = params[0]; // Date format: 2024-03-19T03:55:13.729+00:00

    let format = "long";

    if (params.length > 1) {
      format = params[1];
    }

    if (format === "long") {
      if (value) {
        return new Date(value.replace(/([.])\w+/, "")).format(_api.dateFormat);
      } else {
        return "invalid date";
      }
    }

    if (format === "short") {
      if (value) {
        return new Date(value.replace(/([.])\w+/, "")).format('mm/dd/yy');
      } else {
        return "invalid date";
      }
    }
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});