define("web-client/actions/sign-out", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = signOut;

  function signOut() {
    (0, _api.logout)().then(() => {
      session.remove('show-install-dialog');
      storage.remove('sessionToken');
      this.reduxStore.dispatch({
        type: 'RESET'
      });
      this.transitionTo('login');
    }).catch(() => {// TODO: should probably do something if there is an unexpected network error.
    });
  }
});