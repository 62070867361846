define("web-client/validators/noleadingspace", ["exports", "ember-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    init: function init() {
      // this call is necessary, don't forget it!
      this._super.apply(this, arguments);
    },
    call: function call() {
      let value = this.model.get(this.property);

      if (/^\s/.test(value)) {
        this.errors.pushObject(this.i18n.t("errors.noleadingspace"));
      }
    }
  });

  _exports.default = _default;
});