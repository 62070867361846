define("web-client/actions/global-actions/select-all-unowned-teams-for-password-filter", ["exports", "lodash-es/includes", "lodash-es/pull", "lodash-es/filter", "lodash-es/each"], function (_exports, _includes, _pull, _filter, _each) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = selectAllUnOwnedTeamForPasswordFilter;

  function selectAllUnOwnedTeamForPasswordFilter()
  /* args, ctrlKey, shiftKey */
  {
    Ember.run.later(() => {
      if (this.get('reduxStore.state.data.teams.allUnOwnedSelected')) {
        let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
          return item.role !== 1 && item.name !== 'Default' && !item.individual;
        });
        let currentGroups = storage.get('groups', []);
        (0, _each.default)(owned, function (group) {
          if ((0, _includes.default)(currentGroups, group.id)) {
            (0, _pull.default)(currentGroups, group.id);
          }
        });
        storage.set('groups', currentGroups); //this.send('updatePasswordRouteState');

        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS',
          teams: currentGroups
        });
      } else {
        let owned = (0, _filter.default)(this.get('reduxStore.state.data.teams.content'), function (item) {
          return item.role !== 1 && item.name !== 'Default' && !item.individual;
        });
        let currentGroups = storage.get('groups', []);
        (0, _each.default)(owned, function (group) {
          if (!(0, _includes.default)(currentGroups, group.id)) {
            currentGroups.push(group.id);
          }
        });
        storage.set('groups', currentGroups); //   this.send('updatePasswordRouteState');

        this.reduxStore.dispatch({
          type: 'SET_SELECTED_TEAMS',
          teams: currentGroups
        });
        this.getPasswords().then(passwords => {
          this.reduxStore.dispatch({
            type: 'UPDATE_PASSWORDS',
            passwords: passwords
          });
        });
      }
    }, 0);
  }
});