define("web-client/components/ionic-components-ready/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    resize: Ember.inject.service(),

    willDestroyElement() {
      this._super.apply(this, arguments);

      window.removeEventListener('resize', this._resize);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      const loader = document.getElementById('loader');
      var observer = new MutationObserver(function (mutations) {
        for (var mutation of mutations) {
          if (mutation.target.className.includes('hydrated')) {
            observer.disconnect();
            loader.classList.add('hide');
            Ember.run.later(() => {
              loader.classList.add('fade-out');
              Ember.run.later(() => {
                loader.style.visibility = 'hidden ;';
              }, 300);
            }, 500);
          }
        }
      });
      observer.observe(document.querySelector('.content-placeholder'), {
        attributes: true,
        attributeFilter: ['class']
      });
    }
  });

  _exports.default = _default;
});