define("web-client/components/ion-range/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-range',
    classNames: [],
    disabled: false,
    checked: false,
    color: "primary",
    debounce: 0,
    dualKnobs: false,
    max: 100,
    min: 0,
    name: "",
    pin: true,
    snaps: true,
    step: 1,
    ticks: false,
    value: 0,
    valueObserver: Ember.observer('value', function () {
      if (this.value !== this.element.value) {
        this.element.setAttribute('value', this.value);
      }

      this.get('change')(this.element.value); //}
    }),
    attributeBindings: ["color", "disabled", "debounce", "dualKnobs", "max", "min", "name", "pin", "snaps", "step", "ticks"],
    gestures: Ember.inject.service(),

    init() {
      this._super.apply(this, arguments);
    },

    actions: {},

    willDestroyElement() {
      this.get('element').removeEventListener('ionChange', this.clickEvent);
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.clickEvent = e => {
        if (!this.disabled) {
          this.set('value', e.detail.value);
        }
      };

      this.get('element').addEventListener('ionChange', this.clickEvent);
    }
  });

  _exports.default = _default;
});