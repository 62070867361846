define("web-client/components/quick-copy-dialog/quick-copy-item/component", ["exports", "web-client/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ion-item',
    layoutValue: "icon-end",
    classNames: ['quick-copy-item', 'disable-ripple'],
    disabled: false,
    button: true,
    attributeBindings: ['button'],
    actions: {},
    gestures: Ember.inject.service(),
    notifications: Ember.inject.service(),
    passwordId: '',

    willDestroyElement() {},

    init() {
      this._super.apply(this, arguments); //  console.log(isPlatform(window, 'ios'));

    },

    copyField: function copyField() {
      this.set('copyData', this.field.value);

      if (this.copyData) {
        document.getElementById('copyNode').innerText = this.copyData;

        if (this.passwordId) {
          var params = {
            "pid": "" + this.passwordId,
            "field": this.field.name
          };
          (0, _api.actionUpdate)("passwordQuickDataCopied", params);
        } // "Optional" Select some text


        let range = document.createRange();
        range.selectNodeContents(document.getElementById('copyNode'));
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range); // Use try & catch for unsupported browser

        try {
          // The important part (copy selected text)
          var successful = document.execCommand('copy'); // "Optional" remove selected text
          //     sel.removeAllRanges();

          document.getElementById('copyNode').innerText = "";
          this.set('copyData', "");

          if (successful) {
            this.notifications.show(this.field.name + " copied to clipboard"); //  this.sendAction('attrs.on-copied', 'success');
          } else {
            this.notifications.show("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations.", "alert");
          }
        } catch (err) {
          this.notifications.show("Copy to clipboard failed. Your browser may not support asynchronous clipboard operations", "alert");
        }
      } else {
        this.notifications.show("Nothing to copy to clipboard.", "alert");
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('click', () => {
        this.copyField();
      });
    }
  });

  _exports.default = _default;
});