define("web-client/api/action-update", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.actionUpdate = void 0;

  /**
   * params
   *params
   *
   */
  const actionUpdate = _ipcClient.ipc.makeRequestFunction('actionUpdate');

  _exports.actionUpdate = actionUpdate;
});