define("web-client/components/validate-email-change-dialog/component", ["exports", "ui-core/mixins/dialog-base", "ember-cli-redux/mixins/ember-redux", "ember-concurrency", "ember-cp-validations", "web-client/api", "lodash-es/merge"], function (_exports, _dialogBase, _emberRedux, _emberConcurrency, _emberCpValidations, _api, _merge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    validationCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Validation code can't be blank"
    }), (0, _emberCpValidations.validator)('length', {
      min: 7,
      max: 7,
      message: "Invalid code"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, _emberRedux.default, {
    classNames: 'size-3',
    validationCode: '',
    isValid: true,
    showErrors: false,
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    showValidationCodeErrors: Ember.computed('showErrors', 'validationCode', function () {
      return this.showErrors || this.validationCode.length;
    }),
    defaultAction: 'goToLogin',
    confirmEmail: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          (0, _api.verifyEmailChange)(this.validationCode).then(() =>
          /*data*/
          {
            (0, _api.getAccountDetails)().then(data => {
              this.reduxStore.dispatch({
                type: 'SET_ACCOUNT_DETAILS',
                data: (0, _merge.default)({
                  loggedIn: true,
                  oobValid: true
                }, data)
              });
              deferred.resolve();
              this.send('remove');
            });
          }).catch(() =>
          /* data*/
          {
            this.notifications.show("You entered an invalid verification code or your code has expired. Codes are valid for 24 hours after request.");
            deferred.reject();
          });
        } else {
          return 'validation-failed';
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    actions: {
      cancelDialog() {
        this.send('remove');
      }

    }
  });

  _exports.default = _default;
});