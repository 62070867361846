define("web-client/components/ion-treeview-item/component", ["exports", "@ionic/core", "lodash-es/isBoolean"], function (_exports, _core, _isBoolean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //ios="ios-remove-circle" md="md-remove-circle"
  // <ion-icon ios="ios-checkmark-circle" md="md-checkmark-circle">
  var _default = Ember.Component.extend({
    tagName: 'ion-item',
    layoutValue: "icon-start",
    classNames: ['ion-treeview-item'],
    disabled: false,
    attributeBindings: ['layoutValue:layout', 'disabled'],
    selected: false,
    actions: {},
    gestures: Ember.inject.service(),
    showExpander: false,
    iconColor: "grey-800",
    expandedIndicator: Ember.computed('expanded', function () {
      return this.expanded ? 'remove' : 'add';
    }),
    selectedIcon: Ember.computed('selected', function () {
      return !(0, _isBoolean.default)(this.selected) ? 'remove-circle' : this.selected ? 'checkmark-circle' : 'checkbox-blank-circle-outline';
    }),
    checked: Ember.computed('selected', function () {
      return this.selected;
    }),
    selectedClass: Ember.computed('selected', function () {
      return this.selected === true ? 'selected' : this.selected === false ? 'not-selected' : "indeterminate";
    }),
    expanded: false,

    willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('gestures').removeEventListener(this.get('element'), 'tap', this.clickEvent);

      if (this.showExpander) {
        let expander = this.element.getElementsByClassName('expander')[0];
        this.get('gestures').removeEventListener(expander, 'tap', this.expandEvent);
      }
    },

    init() {
      this._super.apply(this, arguments); //  console.log(isPlatform(window, 'ios'));

    },

    didInsertElement: function didInsertElement() {
      let self = this;

      this._super.apply(this, arguments);

      this.indicator = this.get('element').getElementsByClassName('selected-indicator')[0];

      this.clickEvent = e => {
        self.get('tap')(self.get('item'), e.ctrlKey, e.shiftKey);
      };

      this.get('gestures').addEventListener(this.get('element'), 'tap', this.clickEvent);

      if (this.showExpander) {
        let expander = this.element.querySelectorAll(' *> .expander')[0];

        this.expandEvent = function (event) {
          event.preventDefault();
          event.stopPropagation();
          self.set('expanded', !self.expanded);
        };

        this.gestures.addEventListener(expander, 'tap', this.expandEvent);
      }
    }
  });

  _exports.default = _default;
});