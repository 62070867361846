define("web-client/components/password-search-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IFLEwg0S",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"name-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inner-text\"],[9],[0,\"\\n   \"],[7,\"div\"],[11,\"class\",\"password-search-link\"],[11,\"title\",\"Open entry\"],[9],[0,\"\\n      \"],[1,[23,\"displayName\"],true],[0,\"\\n   \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"match-item\"],[9],[0,\"\\n \"],[7,\"span\"],[11,\"class\",\"title-text\"],[9],[0,\"Matches Fields: \"],[10],[7,\"span\"],[11,\"class\",\"matches-text\"],[9],[1,[23,\"matches\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/password-search-item/template.hbs"
    }
  });

  _exports.default = _default;
});