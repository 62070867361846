define("web-client/register/invited/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QTDVzNN0",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n      \"],[1,[29,\"registration-dialog-invite\",null,[[\"model\",\"queryParameters\",\"registerUserInvite\",\"acceptUserInvite\",\"goToSettings\",\"goToLogin\",\"success\"],[[25,[\"model\"]],[25,[\"queryParameters\"]],[29,\"service-action\",[\"global-actions\",\"registerUserInvite\"],null],[29,\"service-action\",[\"global-actions\",\"acceptUserInvite\"],null],[29,\"service-action\",[\"global-actions\",\"goToSettings\"],null],[29,\"service-action\",[\"global-actions\",\"goToLogin\"],null],[25,[\"reduxStore\",\"state\",\"registrationSuccessful\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/register/invited/template.hbs"
    }
  });

  _exports.default = _default;
});