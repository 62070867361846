define("web-client/mixins/route-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    //routeinfo:service(),
    init() {
      this._super.apply(this, arguments);

      this.router.on('routeDidChange', function () {
        // console.log('this',this /* this.get('currentState.router').currentRouteInfos[0]._route.actions*/);
        let routeinfo = Ember.getOwner(this).lookup('service:routeinfo');
        Ember.setProperties(routeinfo, {
          'infox': this
        });
      });
    }

  });

  _exports.default = _default;
});