define("web-client/components/content-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "acHoqA/v",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content-loader-text\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"content-loader-text-wrapper elevation-1dp\"],[9],[0,\"\\n   \"],[7,\"ion-spinner\"],[11,\"color\",\"white\"],[9],[10],[0,\" \"],[7,\"ion-label\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/content-loader/template.hbs"
    }
  });

  _exports.default = _default;
});