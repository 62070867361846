define("web-client/components/change-company-notification-email-dialog/component", ["exports", "ember-concurrency", "ui-core/mixins/dialog-base", "ember-cp-validations", "web-client/api"], function (_exports, _emberConcurrency, _dialogBase, _emberCpValidations, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    newEmail: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: "Email address can't be blank"
    }), (0, _emberCpValidations.validator)('email', {
      message: "Must be a valid email address"
    })]
  });

  var _default = Ember.Component.extend(_dialogBase.default, Validations, {
    size: 'size-3',
    email: Ember.computed.alias('reduxStore.state.orgDetails.notificationEmail'),
    newEmail: '',
    notifications: Ember.inject.service(),
    i18n: Ember.inject.service(),
    reduxStore: Ember.inject.service(),
    loading: Ember.inject.service(),

    onShow() {
      this.reduxStore.dispatch({
        type: 'BLOCK_TRANSITIONS'
      });
    },

    onRemove() {
      this.reduxStore.dispatch({
        type: 'ALLOW_TRANSITIONS'
      });
    },

    hasChanged: Ember.computed('email', 'newEmail', function () {
      return this.email !== this.newEmail;
    }),
    showErrors: false,
    showEmailErrors: Ember.computed('showErrors', 'newEmail', function () {
      return this.showErrors || this.newEmail.length;
    }),
    saveEmail: (0, _emberConcurrency.task)(function* () {
      let deferred = Ember.RSVP.defer();
      this.validate().then(validResult => {
        if (validResult.validations.get('isValid')) {
          return (0, _api.updateCompanyNotificationEmail)(this.newEmail);
        } else {
          return 'validation-failed';
        }
      }).then(result => {
        if (result !== 'validation-failed') {
          // OrganizationDetails
          (0, _api.getOrganizationDetails)().then(data => {
            this.reduxStore.dispatch({
              type: 'INITIALIZE_ORGANIZATION_DETAILS',
              details: data
            });
          }).catch(() => {//console.log('no extra data')
          });
          this.send('remove');
        }
      }).catch(data => {
        this.notifications.show(this.i18n.t((0, _api.getLanguageErrorKey)(data.status.code)));
      }).finally(() => {
        this.set('showErrors', true);
        deferred.reject();
      });
      yield deferred.promise;
    }).drop(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newEmail', this.get('reduxStore.state.orgDetails.notificationEmail'));
    },
    actions: {
      cancelDialog: function cancelDialog() {
        this.send('remove');
      }
    }
  });

  _exports.default = _default;
});