define("web-client/components/password-list-item-menu/component", ["exports", "ui-core/mixins/nb-menu", "web-client/components/password-list-item-menu/template", "web-client/utils/icon-server-alias-hash"], function (_exports, _nbMenu, _template, _iconServerAliasHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nbMenu.default, {
    layout: _template.default,
    imageServerDown: false,
    imgSource: Ember.computed('password.url', 'imageServerDown', function () {
      if (this.imageServerDown) {
        return "https://" + window.location.hostname + "/assets/static-assets/images/default-favicon.png";
      } else {
        return (0, _iconServerAliasHash.default)(this.get('password.url'));
      }
    }),

    willDestroyElement() {
      this.element.removeEventListener('error', this._imageError);
    },

    didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;

      this._imageError = function () {
        Ember.run.next(function () {
          self.set('imageServerDown', true);
        });
      };

      this.element.addEventListener('error', this._imageError);
    }

  });

  _exports.default = _default;
});