define("web-client/api/bulk-update-teams-for-passwords", ["exports", "platform-specific/ipc/ipc-client"], function (_exports, _ipcClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bulkUpdateTeamsForPasswords = void 0;

  const bulkUpdateTeamsForPasswords = _ipcClient.ipc.makeRequestFunction('bulkUpdateTeamsForPasswords');

  _exports.bulkUpdateTeamsForPasswords = bulkUpdateTeamsForPasswords;
});