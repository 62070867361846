define("web-client/services/init-app-utils/get-key-data", ["exports", "web-client/api", "lodash-es/isEmpty", "debug"], function (_exports, _api, _isEmpty, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getKeyData;
  const log = (0, _debug.default)("debug");

  function getKeyData() {
    const reduxStore = this.reduxStore;
    return function (callback) {
      (0, _api.startupLog)({
        actionText: "Loading Encryption Keys"
      }); // Check Key data and send to Create Packing Key if all components of the key are not Initialized

      (0, _api.getKeyData)().then(function (data) {
        if (!(0, _isEmpty.default)(data.data)) {
          reduxStore.dispatch({
            type: 'SET_PASSPHRASE_CHECK',
            passphraseCheck: data.data
          });
          reduxStore.dispatch({
            type: 'SET_PUBLIC_KEY',
            publicKey: data.publicKey
          });
          reduxStore.dispatch({
            type: 'SET_ENCRYPTED_PRIVATE_KEY',
            encryptedPrivateKey: data.privateKey
          });
        }

        const sessionKey = storage.get('sessionToken');

        if (sessionKey) {
          (0, _api.aesDecrypt)(sessionKey, reduxStore.get('state.statusToken')).then(function (passphrase) {
            if (passphrase) {
              (0, _api.aesDecrypt)(data.data, passphrase).then(function (check) {
                if (check === "c42963b150bf47ad") {
                  reduxStore.dispatch({
                    type: 'SET_PASSPHRASE',
                    passphrase: passphrase
                  });
                  (0, _api.aesDecrypt)(data.privateKey, passphrase).then(function (decryptedPrivateKey) {
                    reduxStore.dispatch({
                      type: 'SET_DECRYPTED_PRIVATE_KEY',
                      decryptedPrivateKey: decryptedPrivateKey
                    });
                    callback();
                  }).catch(function (error) {
                    log(error);
                    callback();
                  });
                } else {
                  reduxStore.dispatch({
                    type: 'SET_PASSPHRASE',
                    passphrase: passphrase
                  });
                  callback();
                }
              }).catch(e => {
                log(e);
              });
            } else {
              reduxStore.dispatch({
                type: 'SET_PASSPHRASE',
                passphrase: passphrase
              });
              reduxStore.dispatch({
                type: 'SET_PASSPHRASE_CHECK',
                passphraseCheck: data.data
              });
              callback();
            }
          });
        } else {
          storage.remove('sessionToken');
          reduxStore.dispatch({
            type: 'SET_PASSPHRASE',
            passphrase: ''
          });
          callback();
        }
      }).catch(function (data) {
        log(data);
        callback('Failed getKeyData');
      });
    };
  }
});