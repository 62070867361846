define("web-client/validators/username", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Username = _base.default.extend({
    validate(value, options
    /*, model, attribute*/
    ) {
      if (!/^[a-zA-Z0-9@_\-.]{0,}$/.test(value)) {
        return options.message;
      } else {
        return true;
      }
    }

  });

  var _default = Username;
  _exports.default = _default;
});