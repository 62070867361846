define("web-client/components/rail-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E8Lqad1K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"inner inkMe \",[23,\"elevation\"]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"ink\"],[9],[10],[0,\"\\n  \"],[1,[29,\"svg-icon\",null,[[\"class\",\"icon\"],[\"button-icon\",[29,\"if\",[[29,\"or\",[[25,[\"hover\"]],[25,[\"focused\"]],[25,[\"selected\"]]],null],[25,[\"hoverIcon\"]],[25,[\"icon\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text\"],[9],[0,\"\\n    \"],[1,[23,\"text\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"count\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[\"count \",[23,\"countClass\"]]]],[9],[0,\"\\n    \"],[1,[23,\"count\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "web-client/components/rail-button/template.hbs"
    }
  });

  _exports.default = _default;
});